import { connect } from "react-redux";
import { AppButton } from "../Widget/Button/AppButton";
import { editAction, getAction } from "../../store/actions/creators/request.action";
import { AuthHeader } from "../Layout/AuthLayout";
import { useForm } from "react-hook-form";
import { RadioboxGroup } from "../Widget/Form/FormComponent";
import { useLocation } from "react-router-dom";
import { showNextForm } from "../../store/actions/creators/ui.action";
import { OfferingSkeleton } from "../Widget/Loader/Skeleton";

const OfferingsOne = ({ product, loading, ...props }) => {
  const location = useLocation();
  const { handleSubmit, formState, control, register } = useForm({
    mode: "onChange",
    top_interested_product: props.next_form_data?.top_interested_product,
  });
  const onSubmit = data => {
    // alert(JSON.stringify({ ...data, ...location.state?.data }));
    props.showNextForm(true, { ...data, ...location.state?.data, step: 1 });
    // alert(JSON.stringify(props.next_form_data));
  };

  return (
    <div className="py-8 xl:py-10 px-8 xl:px-14 bg-white shadow-[0_15px_30px_0px_rgba(0,0,0,0.05)] w-full gap-10 flex flex-col justify-center border-[0.5px] border-[#E4E4E7] rounded-lg">
      <AuthHeader name="Main Reason For Using GDL Plus" description="Select one product you are most interested in." />
      {loading ? (
        <OfferingSkeleton />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start gap-5">
            <RadioboxGroup label="product_name" items={product} className="justify-between" control={control} {...register("top_interested_product", { required: true })} />
          </div>
          <footer className="flex flex-col mt-6 xl:mt-14">
            <AppButton name="Next" className="w-[138px]" disabled={!formState.isDirty} />
          </footer>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  token: "",
  product: state.register.get_offerings.offerings,
  loading: state.register.get_offerings.loading,
  user_info: state.auth.login.data,
  access_token: state.register.more_info.access_token,
  next_form_data: state.ui.show_next_form.form_data,
});

export default connect(mapStateToProps, {
  editAction,
  getAction,
  showNextForm,
})(OfferingsOne);
