import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.action";
import { setDataId, clearState } from "../../store/actions/creators/ui.action";
import { GET_USER_DETAILS, GET_USER_DOCS, GET_USER_SECURITY_QUESTION } from "../../store/actions/action_types/action.types";
import { usersURL, userDocURL, securityUserURL } from "../../utils/endpoints";
import { showModal } from "../../store/actions/creators/ui.action";
import { NavLink as Link, Outlet, useLocation } from "react-router-dom";
import { currencyFormatter } from "../../utils/helper";
import { Profile } from "iconsax-react";

const List = props => {
  const { pathname } = useLocation();
  return (
    <Link to={props.url} className={` hover:text-white hover:bg-[#3BB3C2] hover:rounded-lg font-medium px-5 py-2 ${pathname === props.url ? "text-white bg-[#3BB3C2] rounded-lg " : "text-[#666666]"}`}>
      {props.name}
    </Link>
  );
};

const ProfileWrapper = ({ token, loading, details, children, total_bal, ...props }) => {
  const userId = props.user_info?.id;
  const url = `${usersURL}/${userId}`;

  useEffect(() => {
    props.setDataId(userId);
    details = props?.user_info;
    props.getAction(GET_USER_DOCS, `${userDocURL}/${userId}`, token);
  }, [props.set_docs_msg]);

  useEffect(() => {
    if ((props.user_profile && props.user_profile != null) || props.txn_pin_success) {
      props.getAction(GET_USER_DETAILS, url, token);
    }
  }, [props.user_profile, props.txn_pin_success]);

  useEffect(() => {
    if (props.set_docs_msg) {
      // call user details api and clearstate
      const url = `${usersURL}/${props.user_info?.id}`;
      props.getAction(GET_USER_DETAILS, url, token);
      props.clearState();
    }
  }, [props.set_docs_msg]);

  return (
    <>
      <div className=" h-full">
        <div className="grid grid-cols-10 gap-8">
          <div className="sm:col-span-3 bg-white rounded-lg col-span-4 flex flex-col p-5 gap-[40px]">
            <div className="flex flex-col gap-2.5 justify-center items-start">
              <div className="rounded-full w-[90px] h-[90px] object-cover bg-gray-100 flex justify-center items-center">
                {!details?.user_avatar_url ? (
                  <Profile size={50} variant="Bulk" />
                ) : (
                  <img src={details?.user_avatar_url || ""} alt="display_picture" className="rounded-full w-[90px] h-[90px] object-cover bg-gray-100" />
                )}
              </div>
              <h3 className="text-primary 2xl:text-[22px] xl:text-[20px] lg:text-[18px] text-[18px] font-medium">
                {details?.first_name} {details?.last_name}
              </h3>
              <p className="text-gray01 2xl:text-[15px] xl:text-[14px] lg:text-[14px] text-[14px]">{details?.email}</p>
              <p className="text-gray01 2xl:text-[15px] xl:text-[14px] lg:text-[14px] text-[14px]">{details?.address}</p>
            </div>
            <div className="flex flex-col gap-2.5">
              <div className="flex justify-between items-center">
                <span className="text-gray01 2xl:text-base xl:text-[15px] lg:text-sm text-base">Balance</span>
                <span className="font-semibold text-primary 2xl:text-base xl:text-[15px] lg:text-sm text-base">{currencyFormatter(total_bal)}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray01 2xl:text-base xl:text-[15px] lg:text-sm text-base">Total Portfolio</span>
                <span className="font-semibold text-primary 2xl:text-base xl:text-[15px] lg:text-sm text-base"> - </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray01 2xl:text-base xl:text-[15px] lg:text-sm text-base">KYC Level</span>
                <span className="font-semibold lowercase first-letter:uppercase text-primary 2xl:text-base xl:text-[15px] lg:text-sm text-base">{details?.kyc_level?.level_name}</span>
              </div>
            </div>
            <hr className="w-full border-[#E4E4E7]" />
            <div className="flex flex-col gap-y-2.5">
              <span className="text-[#D3D8E1] px-5 py-2.5">Your Details</span>
              <ul className="flex flex-col gap-y-1">
                <List name="Overview" url="/profile" />
                <List name="User Document" url="/profile/document" />
                <List name="Login History" url="/profile/login-history" />
                <List name="Security" url="/profile/security" />
                <List name="Transfer Limit" url="/profile/transfer-limit" />
                <List name="Privacy" url="/profile/privacy" />
              </ul>
            </div>
          </div>
          <div className="overflow-hidden rounded-lg h-full md:col-span-7 col-span-3 sm:mt-0 mt-5">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  open: state.ui.modal.show_profile_modal,
  token: state.auth.login.token,
  loading: state.user.user_details.loading,
  details: state.user.user_details.data,
  user_info: state.auth.login.data,
  modal_data: state.ui.modal.data,
  total_bal: state.account.get_wallets.total_bal,
  user_profile: state.user.update_profile.data,
  set_docs_msg: state.user.set_docs.data,
  txn_pin_success: state.user.set_txn_pin.success,
});

export default connect(mapStateToProps, { getAction, setDataId, showModal, clearState })(ProfileWrapper);
