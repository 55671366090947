import React, { useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import { useDotButton } from "../../../hooks/useDotButton";
import { usePrevNextButtons } from "../../../hooks/usePrevNextButton";

const Carousel = ({ data, chunkSize, renderItem, ignore_pagination }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [showArrows, setShowArrows] = useState(false);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);
  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  if (data.length === 0) return;

  const chunks = [];
  for (let i = 0; i < data.length; i += chunkSize) {
    chunks.push(data.slice(i, i + chunkSize));
  }

  return (
    <div onMouseEnter={() => setShowArrows(true)} onMouseLeave={() => setShowArrows(false)} className="relative w-full mt-4">
      <div className="overflow-x-hidden w-full" ref={emblaRef}>
        <div className="flex">
          {chunks.map((chunk, index) => (
            <div className="relative min-w-full" key={index}>
              <div className="grid grid-cols-3 2xl:gap-[30px] xl:gap-[25px] lg:gap-[20px]">
                {chunk.map((data, index) => (
                  <React.Fragment key={index}>{renderItem({ data, index })}</React.Fragment>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {!prevBtnDisabled && showArrows && (
        <button onClick={onPrevButtonClick} className="absolute -left-4 top-1/2 transform -translate-y-1/2">
          <ArrowLeft2 />
        </button>
      )}

      {!nextBtnDisabled && showArrows && (
        <button onClick={onNextButtonClick} className={`absolute -right-4 top-1/2 transform -translate-y-1/2`}>
          <ArrowRight2 />
        </button>
      )}

      {!ignore_pagination && (
        <div className="flex items-center justify-center mt-1">
          {scrollSnaps.map((_, index) => (
            <div
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={`rounded-2xl h-[3px] mr-2 transition-all duration-300 ${index === selectedIndex ? "w-[40px] bg-brand01 " : "w-[11px] bg-[#D3D8E1]"} cursor-pointer`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Carousel;
