import { LOGOUT } from "../action_types/action.types";

export const logOut = () => ({
  type: LOGOUT,
  payload: true,
});

export const clearState = (type = "CLEAR_STATE") => ({
  type,
  payload: true,
});

export const setRouteName = name => ({
  type: "SET_ROUTE",
  payload: name,
});

export const setModalView = (type, payload) => ({
  type,
  payload,
});

export const showDeleteModal = (type, payload = null) => ({
  type,
  payload,
});

export const showModal = (type, ...payload) => ({
  type,
  payload,
});

export const fadeContent = (type, payload) => ({
  type,
  payload,
});

export const setDataId = payload => ({
  type: "SET_ID",
  payload,
});

export const showIframe = (type, payload = null) => ({
  type,
  payload,
});

export const openNotification = (type, payload) => ({
  type,
  payload: !payload,
});

export const showContent = (type, payload = null) => ({
  type,
  payload,
});

export const setTabIndex = (payload = null) => ({
  type: "SET_TAB_INDEX",
  payload,
});

export const showNextForm = (...payload) => ({
  type: "SHOW_NEXT_FORM",
  payload,
});

export const showMessage = (type, payload) => ({
  type,
  payload,
});

export const selectData = payload => ({
  type: "SELECT_OPTION",
  payload,
});

export const setData = payload => ({
  type: "SET_DATA",
  payload,
});

export const setGroupData = payload => ({
  type: "SET_GROUP_DATA",
  payload,
});

export const setNextOfKin = payload => ({
  type: "SET_NEXT_OF_KIN",
  payload,
});

export const setPagination = payload => ({
  type: "PAGINATE",
  payload,
});

export const setFilterStatus = (type, payload) => ({
  type: type,
  payload,
});

export const setFilter = (type, payload) => ({
  type: type,
  payload: payload,
});

export const activateIdle = (...payload) => ({
  type: "ISIDLE",
  payload,
});

export const emergencyModal = (type, ...payload) => ({
  type,
  payload,
});

export const openSideBar = payload => ({
  type: "OPEN_SIDE_BAR",
  payload: payload,
});

export const openOfferings = (type, ...payload) => ({
  type,
  payload,
});

export const activateFiltering = payload => ({
  type: "ACTIVATE_FILTER",
  payload,
});

export const setProductCode = payload => ({
  type: "SET_PRODUCT_CODE",
  payload,
});

export const setBeneficiaryData = payload => ({
  type: "SET_BENEFICIARY",
  payload,
});

export const setSubscribed = (type, payload = null) => ({
  type,
  payload,
});
