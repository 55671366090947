import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import * as pages from "../../pages";

const NotificationRoute = () => {
  return (
    <>
      <Routes>
        <Route exact path={``} element={<pages.Notification />} />
        {/* <Route exact path={`${match.path}/:id`} element={pages.LoanDetails} /> */}
      </Routes>
    </>
  );
};

export default connect(null, {})(NotificationRoute);
