import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { openNotification } from "../../../store/actions/creators/ui.action";
import { InfoCircle } from "iconsax-react";

const NotificationBox = props => (
  <div className="bg-white rounded-md shadow-lg overflow-y-auto w-[26rem] px-8">
    <div className="pt-4 pb-2 border-b text-[#1F2734] font-medium">Notifications</div>
    {props.notifications?.slice(0, 4).map((content, index) => (
      <div key={index} className="flex items-start w-full py-5 overflow-y-scroll border-b cursor-pointer bg-{{ !content?.detail_viewed ? 'bg-gray-100' : 'green' }}">
        <div className="flex items-center">
          <div className="w-[6px] h-[6px] rounded-full bg-[#9A2333] mr-1"></div>
          <div className="flex items-center justify-center w-6 h-6 rounded-full bg-[#FFF4F6]">
            <InfoCircle size={18} />
          </div>
        </div>

        <div className="flex flex-col justify-center px-2">
          <p className="text-[#1F2734]">
            <span className="font-medium" href="#">
              {content.title}
            </span>
          </p>

          <span className="text-[#1F2734] mb-2 truncate w-72">{content?.message}</span>

          <span className="font-normal text-[#8C8C8C] opacity-70 ">
            {new Date(content.created_at).toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })}
            {/* {new Date(content.created_at).toDateString()}, {new Date(content.created_at).toLocaleTimeString()} */}
          </span>
        </div>
      </div>
    ))}
    <Link to={"/notifications"} onClick={() => props.openNotification("CLOSE_NOTIFICATION")} className="block pt-8 pb-6 text-center text-brand01 hover:underline font-medium cursor-pointer">
      See All Notifications
    </Link>
  </div>
);

const mapStateToProps = state => ({
  notifications: state.notification.get_notification.data,
});

export default connect(mapStateToProps, { openNotification })(NotificationBox);
