const initialState = {
  isLoading: false,
  error: null,
};

const loadingReducer = (state = initialState, action) => {
  const { type } = action;

  if (type.endsWith("LOADING")) {
    return { ...state, isLoading: true, error: null };
  }

  if (type.endsWith("_SUCCESS")) {
    return { ...state, isLoading: false, error: null };
  }

  if (type.endsWith("_ERROR_OCCURED")) {
    return { ...state, isLoading: false, error: action.payload };
  }

  return state;
};

export default loadingReducer;
