import React, { useEffect } from "react";
import { connect } from "react-redux";
import { TopCardDisplay } from "../../components/Card/Card";
import { DetailTabs } from "../../components/DetailsComponent/DetailsTab";
import { tabNames } from "./loan.data";
import { setDataId } from "../../store/actions/creators/ui.action";
import { useLocation } from "react-router-dom";

const InvestmentDetails = ({ loading, token, ...props }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    let path_id = pathname;
    path_id = path_id.split("/");
    path_id = path_id[path_id.length - 1];
    props.setDataId(path_id);
  }, []);
  const top_data = {
    title: "Total Investment Balance",
    sub_title: "50,000,000.00",
    statistics: {
      "Total Withdrawn": "75,000",
      "Total Credited": "105,000",
    },
  };
  return (
    <div className="py-4">
      <TopCardDisplay {...top_data} />
      <div className="mx-2 p-4 px-0 rounded-lg col-span-2">
        <DetailTabs tabs={tabNames} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
});

export default connect(mapStateToProps, { setDataId })(InvestmentDetails);
