import { connect } from "react-redux";
import HouseMoniHeader from "../../../components/HouseMoni/HouseMoniHeader";
import { Badge } from "../../../components/Widget/Badge/Badge";
import { AppButton } from "../../../components/Widget/Button/AppButton";
import CustomProgressBar from "../../../components/Widget/ProgressBar/Progressbar";
import { showNextForm, showModal } from "../../../store/actions/creators/ui.action";
import { SubDetailView, HistoryLink, DetailRow } from "../../../components/HouseMoni/HouseMoniSubComps";
import AppModal from "../../../components/Widget/Modal/Modal";
import PaymentHistory from "./PaymentHistory";
import { useNavigate } from "react-router-dom";
import { currencyFormatter } from "../../../utils/helper";

const HouseMoniSub = props => {
  const navigate = useNavigate();

  const data = {
    "Pre-Eligibility Period": `${props.housemoni_subs?.pre_eligibility_period / 12} Years`,
    "Repayment Period": `${props.housemoni_subs?.repayment_tenure / 12} Years`,
    "Monthly Repayment": currencyFormatter(props.housemoni_subs?.monthly_repayment),
    "Monthly Contribution": currencyFormatter(props.housemoni_subs?.monthly_contribution),
    "Target Amount": currencyFormatter(props.housemoni_subs?.target_amount),
  };
  const data_two = { "Home Cost Limit": currencyFormatter(props.housemoni_subs?.home_cost_limit), "Eligible Amount": currencyFormatter(props.housemoni_subs?.total_amount_eligible) };
  const progressData = {
    min_monthly_contribution: 200,
    pre_eligibility_period: props.housemoni_subs?.pre_eligibility_period,
    total_tenure: props.housemoni_subs?.hpa_config?.total_tenure_months,
    total_months_passed: props.housemoni_subs?.total_months_passed,
    target_amount: props.housemoni_subs?.target_amount,
    total_monthly_contributions: props.housemoni_subs?.total_monthly_contributions,
  };

  // get the housemoni type of subscription
  const housemoni_type = props.housemoni_subs.product_version?.product_code;

  const renderItem = ({ content, data, index }) => <DetailRow content={content} data={data} index={index} />;

  const navigateGroupDetails = () => {
    navigate(`/housemoni/group-details`, { state: { id: props.housemoni_subs?.group.id } });
  };

  const navigetActivities = () => {
    navigate("/housemoni/group-activities");
  };

  const handlePaymentHistory = () => {
    props.showModal("OPEN_MODAL", true);
  };

  const handlePayment = () => {
    const payment_data = {
      amount: props.housemoni_subs?.monthly_contribution,
      request_ref: props.housemoni_subs.request_ref,
      service_type: props.product_code,
      hpa_subscription_id: props.housemoni_subs.id,
      percent: "0",
    };
    navigate("make-payment", { state: payment_data });
  };

  return (
    <>
      <div className="flex w-full py-5 px-[52px] flex-col items-start gap-[30px] h-[870px] overflow-auto">
        <HouseMoniHeader no_back title={`HouseMoni ${housemoni_type == "HPA" ? "Classic" : "Community"} Summary`} />
        <div className="flex flex-col items-start gap-[25px] self-stretch">
          {/* <HistoryLink openLink={handlePaymentHistory} /> */}
          <SubDetailView renderItem={renderItem} data={data} />
          <SubDetailView renderItem={renderItem} data={data_two} header="Home Cost Limit Details" />
          <div className="flex justify-between items-center self-stretch">
            <span className="text-primary font-medium">Status</span>
            <Badge content={props.housemoni_subs?.status.toLowerCase()} />
          </div>
          <div className="flex py-[30px] px-[15px] self-stretch overflow-x-auto scrollbar justify-start items-center rounded-lg border bg-white border-[#F0F0F0]">
            <CustomProgressBar data={progressData} />
          </div>
          <div className="flex justify-between items-center self-stretch">
            <div className="flex flex-col items-start gap-[10px]">
              <span className="text-gray01 text-[15px]">Monthly Payment</span>
              <span className="text-primary text-xl font-semibold">{data["Monthly Contribution"]}</span>
            </div>
            {props.housemoni_subs?.status == "PENDING" ? (
              <AppButton name="Subscribe" className="md:w-[138px]" onPress={handlePayment} />
            ) : (
              <div className="flex flex-col items-end gap-[10px]">
                <span className="text-gray01 text-[15px]">Target Amount</span>
                <span className="text-primary font-semibold">{data["Target Amount"]}</span>
              </div>
            )}
          </div>
          {props.housemoni_subs.product_version.product_code != "HPA" && (
            <div className="flex justify-between items-center self-stretch">
              <AppButton name="View Group Details" className="font-medium" onPress={navigateGroupDetails} />
              <AppButton name="View Group Activities" className="bg-brand02 text-brand01 border-brand02 font-medium" onPress={navigetActivities} />
            </div>
          )}
        </div>
      </div>
      <AppModal
        className="w-[40%] min-h-[30em]"
        title="Payment History"
        open={props.open}
        onClose={() => props.showModal("HIDE_MODAL", false)}
        content={
          <div className="px-10 py-7 max-h-[30em] overflow-auto">
            <PaymentHistory />
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  housemoni_subs: state.hpa.get_hpa_sub.hpa_data,
  open: state.ui.modal.show,
  product_code: state.ui.product_code.product_code || state.hpa.get_hpa_sub.hpa_data?.product_version.product_code,
});

export default connect(mapStateToProps, { showNextForm, showModal })(HouseMoniSub);
