import { z } from "zod";

export const transfer_schema = (from_account_number, balance) =>
  z.object({
    sender_account_number: z.string().refine(
      val => {
        if (val !== from_account_number) {
          return false;
        }

        return true;
      },
      { message: "Sender Account Number must match the account number in session" }
    ),
    amount: z.number().refine(
      val => {
        if (val > balance) {
          return false;
        }

        return true;
      },
      { message: "Insufficient Amount" }
    ),
  });

export const withdrawal_schema = z.object({
  bank_code: z.object({ label: z.string(), value: z.string() }),
  account_number: z.string().min(10, { message: "Account Number should not be lesser than 10 digits" }).max(10, { message: "Account Number should not be greater than 10 digit" }),
  amount: z.number({ message: "Amount is required" }).refine(val => val > 19, { message: "Amount is too low for this transaction" }),
  narration: z.string().optional(),
  beneficiary: z.boolean().optional(),
});
