import React from "react";
import { Link } from "react-router-dom";
import { ComingSoon } from "./assets";

const Loans = ({ data, url }) => {
  return (
    <div>
      <div className="flex justify-end">
        {url && (
          <Link to={url} className="text-brand01 underline cursor-pointer">
            View Portfolio
          </Link>
        )}
      </div>
      <>
        {data.map(content => (
          <div key={content.name}>
            <div className="flex justify-between items-center my-5">
              <div className="flex items-center gap-4">
                <div className="w-[42px] h-[42px] rounded-full bg-[#FFF4F6] flex justify-center items-center">{content.icon}</div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-5 items-center">
                    <h2 className="font-medium text-primary">{content.name}</h2>
                    {content.status && <ComingSoon />}
                  </div>
                  <p>{content.description}</p>
                </div>
              </div>
            </div>

            <hr className="mx-auto w-full" />
          </div>
        ))}
      </>
    </div>
  );
};

export default Loans;
