import { Link } from "react-router-dom";
import CanaryFunds from "../../assets/icons/canary-funds";
import EmergencyFunds from "../../assets/icons/emergency-funds";
import FixedDeposit from "../../assets/icons/fixed-deposit";
import GoalSavings from "../../assets/icons/goal-savings";
import IncomeFunds from "../../assets/icons/income-funds";
import Investment from "../../assets/icons/investment";
import MoneyMarket from "../../assets/icons/money-market";
import { AssetsList, InvestmentList, LoansList, SavingsList, TimedDepositsList } from "./lists";
import Housemoni from "../../assets/icons/housemoni";

export const PRODUCTS_DATA = [
  {
    title: "HouseMoni",
    list: (
      <Link to={"/housemoni"} className="flex justify-between items-center my-5">
        <div className="flex items-center gap-4">
          <div className="w-[42px] h-[42px] rounded-full bg-[#FFF4F6] flex justify-center items-center">
            <Housemoni />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-5 items-center">
              <h2 className="font-medium text-primary">HouseMoni</h2>
            </div>
            <p>Become a homeowner in just 3½ years.</p>
          </div>
        </div>
      </Link>
    ),
  },
  {
    title: "Investments",
    url: "invest",
    list: (
      <InvestmentList
        data={[
          { id: 1, name: "Investor's Note (NGN)", status: "Coming Soon", description: "Short-term investment in Naira", icon: <Investment /> },
          { id: 2, name: "Investor's Note (USD)", status: "Coming Soon", description: "Short-term investment in US Dollars", icon: <Investment /> },
        ]}
        // url={"invest"}
      />
    ),
  },
  {
    title: "Savings",
    list: (
      <SavingsList
        data={[{ id: 1, name: "Goal Savings", status: "Coming Soon", description: "Save for a specific goal or target", icon: <GoalSavings /> }]}
        // url={"savings"}
      />
    ),
    url: "savings",
  },
  {
    title: "Asset Management",
    list: (
      <AssetsList
        data={[
          { name: "Canary Fund", description: "Long-term investment for growth", icon: <CanaryFunds />, url: "assets/canary-fund" },
          { name: "Money Market Fund", description: "Safe and easy access to your money", icon: <MoneyMarket />, url: "assets/money-market-fund" },
          { name: "Income Fund", description: "Earn regular interest", icon: <IncomeFunds />, url: "assets/income-fund" },
        ]}
        url={""}
      />
    ),
    // url: "",
  },
  {
    title: "Loans",
    list: <LoansList url={""} data={[{ name: "Emergency Funds", description: "Save for a specific goal or target", status: "Coming Soon", icon: <EmergencyFunds /> }]} />,
  },
  {
    title: "Timed Deposits",
    list: <TimedDepositsList data={[{ name: "Fixed Deposits", url: "timed-deposit", description: "Lock in fixed interest rate for a set term", icon: <FixedDeposit /> }]} />,
  },
];
