import React from "react";
import { connect } from "react-redux";
import { Toaster } from "react-hot-toast";

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <Toaster />
    </>
  );
};

const mapStateToProps = state => ({
  token: "",
});

export default connect(mapStateToProps, {})(Layout);
