import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { TextField } from "../../components/Widget/Form/FormComponent";
import Wrapper from "../../components/FundWithdraw/Wrapper";
import { useForm } from "react-hook-form";
import { showModal } from "../../store/actions/creators/ui.action";
import { useNavigate } from "react-router-dom";

const CardPayment = ({ token, loading, ...props }) => {
  const [amounts, setAmounts] = useState(0);
  const navigate = useNavigate();
  const { control, formState, register, watch, handleSubmit } = useForm({
    mode: "onChange",
  });

  /** listen for when amount changes and only make button active when amount is 1000 and above */
  useEffect(() => {
    const subscription = watch(({ amount }, { name, type }) => {
      if (name == "amount" && +amount) {
        setAmounts(amount);
      }
      if (amount == "") {
        setAmounts(0);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = data => {
    // open checkout modal
    props.showModal("OPEN_CONFIRM_MODAL", true, data);
  };

  const handleCancel = e => {
    e.preventDefault();
    navigate("/dashboard/fund-wallet");
  };

  return (
    <>
      <Wrapper name="Naira Debit Card" desc="Your are funding your main wallet.">
        <form className="flex flex-col gap-10 self-stretch items-end" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start self-stretch gap-10">
            <TextField label="Amount" type="currency" name="amount" control={control} placeholder="₦0.00" error={formState.errors.amount} {...register("amount", { required: false })} />
          </div>
          <div className="flex gap-7 justify-end items-center self-stretch">
            <AppButton name="Cancel" variant="outline" className="w-[138px]" onPress={handleCancel} />
            <AppButton name="Next" className="w-[138px]" />
          </div>
        </form>
      </Wrapper>
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  ip_data: state.auth.get_ip.data,
  confirm_open: state.ui.modal.show_confirm,
  confirm_data: state.ui.modal.confirm_data,
});

export default connect(mapStateToProps, { showModal })(CardPayment);
