import React, { useState, useEffect, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";

const IdleTimer = ({ timeout, activateIdle, logOut }) => {
  const [remaining, setRemaining] = useState(timeout);
  const [isIdle, setIsIdle] = useState(false);

  const handleIdle = () => {
    setIsIdle(true);
  };

  const handleActive = () => {
    setIsIdle(false);
  };

  const { reset, getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleIdle,
    onActive: handleActive,
  });

  const getRemainingTimeRef = useRef(getRemainingTime);

  useEffect(() => {
    const interval = setInterval(() => {
      const newRemainingTime = getRemainingTimeRef.current();
      // Only update if remaining time has changed
      setRemaining(prevRemaining => {
        if (prevRemaining !== newRemainingTime) {
          return newRemainingTime;
        }
        return prevRemaining;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (remaining <= 10000) {
      activateIdle(true, {
        reset_timer: reset,
        time_remaining: remaining,
      });
    }
  }, [remaining, activateIdle, reset]);

  useEffect(() => {
    if (isIdle) {
      logOut();
      reset();
    }
  }, [isIdle, logOut, reset]);

  return null; // This component does not render anything
};

export default React.memo(IdleTimer);
