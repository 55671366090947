import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TopCardDisplay } from "../../components/Card/Card";
import AppModal from "../../components/Widget/Modal/Modal";
import { showModal, fadeContent, showMessage } from "../../store/actions/creators/ui.action";
import InvestContent, { InvestmentProducts } from "./InvestContent";
import { getAction, editAction } from "../../store/actions/creators/request.action";
import { GET_TENOR, GET_SUB_TYPE, GET_INVEST_SUB, } from "../../store/actions/action_types/action.types";
import { tenorURL, investSubTypeURL, investUserSubURL, } from "../../utils/endpoints";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { HeadlessTab } from "../../components/DetailsComponent/DetailsTab";
import { Add } from "iconsax-react";
import ProductInterestHistory from "./ProductInterestHistory";
import TransactionHistory from "./TransactionHistory";
import { useGetInvestmentProductDetailsQuery, useGetUserInvestmentWalletDetailsQuery } from "../../services/products/invest.services";

const InvestmentWrapper = ({ token, loading, children, ...props }) => {
  const [id, setId] = useState(null);
  const [historyType, setHistoryType] = useState("All Transactions");
  const historyTab = { "All Transactions": "", "Interest History": "" };
  const { data: investWalletData, isLoading, isError } = useGetUserInvestmentWalletDetailsQuery({ userId: props.user_info?.id, wallet_ref: props.user_info?.user_txn_ref });

  const { data: investmentProduct = {} } = useGetInvestmentProductDetailsQuery(id, { skip: !id });

  useEffect(() => {
    const invest_url = `${investUserSubURL}/${props.user_info.id}`;

    // props.getAction(GET_INVEST_PRODUCT, investmentURL, token);
    props.getAction(GET_TENOR, tenorURL, token);
    props.getAction(GET_SUB_TYPE, investSubTypeURL, token);
    props.getAction(GET_INVEST_SUB, invest_url, token);
  }, [id]);

  useEffect(() => {
    props.showModal("HIDE_MODAL", false);
    props.showModal("HIDE_SUB_MODAL", false);
  }, []);

  const handleClick = () => {
    if (props.user_info?.txn_pin || props.user_info?.has_documents) {
      props.showModal("OPEN_ALERT", true);
    } else {
      props.showModal("OPEN_MODAL", true);
    }
  };

  return (
    <>
      <div className="h-full w-full">
        <div className="flex flex-col gap-6 h-full">
          <div className="grid grid-cols-6">
            <div className="grid grid-cols-2 col-span-4 gap-2 2xl:gap-8">
              <TopCardDisplay isCard wallet={investWalletData?.[0]} isLoading={isLoading} imageSrc={"/invest_1.svg"} name="Investment Balance (NGN)" className="bg-white" />
              <TopCardDisplay isCard wallet={investWalletData?.[1]} isLoading={isLoading} imageSrc={"/invest_2.svg"} name="Investment Balance (USD)" desc="Enjoy Upto 2% Interest Yearly" />
            </div>
            <div className="col-span-2 flex justify-end items-start">
              <AppButton icon={<Add size="18" color="#FFF" variant="Outline" />} name="Add New Investment " size="215px" onPress={handleClick} />
            </div>
          </div>
          <div className="grid grid-cols-6 h-full gap-8">
            <div className="col-span-4 sm:col-span-4 flex flex-col gap-8">{children}</div>
            <div className="col-span-6 bg-white md:col-span-2 rounded-lg w-full p-5">
              <div className="">
                <div className="flex flex-col gap-4">
                  <div className="w-full flex justify-between items-center">
                    <HeadlessTab
                      tabs={historyTab}
                      handlePress={index => {
                        setHistoryType(Object.keys(historyTab)[index]);
                      }}
                    />
                  </div>
                  <div className="">
                    {historyType === "All Transactions" && <TransactionHistory {...props} />}
                    {historyType === "Interest History" && <ProductInterestHistory userId={props.user_info?.id} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppModal
        open={props.open}
        title="Available Investment"
        onClose={() => props.showModal("HIDE_MODAL", false)}
        content={<InvestmentProducts {...props} setId={id => setId(id)} onClose={() => props.showModal("HIDE_MODAL", false)} />}
        className="w-[40%]"
      />
      <AppModal
        open={props.sub_open}
        title={investmentProduct?.product_name || "Investment"}
        onClose={() => props.showModal("HIDE_SUB_MODAL", false)}
        content={<InvestContent product_id={id} product_details={investmentProduct} {...props} />}
        className="w-[40%]"
      />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal.show,
  invest_sub: state.investment.get_sub.data,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  sub_open: state.ui.modal.show_sub_modal,
  invest_wallet: state.investment.invest_wallet.data,
  add_error_msg: state.investment.add_sub.error_msg,
  interest_loading: state.investment.interest_history.loading,
  interest_data: state.investment.interest_history.data,
  message: state.ui.open_msg.response_msg,
  statement_details: state.investment.get_invest_statement?.data?.data,
});

export default connect(mapStateToProps, {
  showModal,
  fadeContent,
  getAction,
  showMessage,
  editAction,
})(InvestmentWrapper);
