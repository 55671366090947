import { TickSquare } from "iconsax-react";
import { AppButton } from "../Button/AppButton";
import { useDispatch } from "react-redux";
import { clearState, setSubscribed, showDeleteModal } from "../../../store/actions/creators/ui.action";

export const SubscribedBox = ({ onBack, onViewPortfolio, btn_name, message }) => {
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(setSubscribed("CLOSE_SUBSCRIBE"));
    onBack();
    dispatch(clearState());
  };

  const handleNavigateToPortfolio = () => {
    dispatch(setSubscribed("CLOSE_SUBSCRIBE"));
    onViewPortfolio();
    dispatch(clearState());
  };
  return (
    <div className="flex flex-col p-[20px_51px] pt-0 gap-[40px] items-center">
      <div className="flex flex-col gap-5 items-center">
        <TickSquare className="sm:w-[54px] sm:h-[54px] text-green01" variant="Bold" />
        <div className="flex flex-col gap-2.5">
          <p className="font-bold text-primary leading-[20.16px]">Subscribed</p>
          <p className="text-primary leading-[20.16px]">{message ?? "Your investment was successfully subscribed."}</p>
        </div>
      </div>
      <div className="flex gap-5 justify-center items-center">
        <AppButton name={btn_name ?? "Back to Products"} variant="outline" onPress={handleBack} />
        <AppButton name="View Portfolio" onPress={handleNavigateToPortfolio} />
      </div>
    </div>
  );
};
