import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import AssetForm from "../../../components/Products/assets/asset.form";

const IncomeFund = () => {
  const { state } = useLocation();

  return (
    <div>
      <AssetForm />
    </div>
  );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(IncomeFund);
