import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAction, postAction } from "../../store/actions/creators/request.action";
import { showModal, showMessage } from "../../store/actions/creators/ui.action";
import { GET_SAVING_SUB_DETAILS, TOP_UP_SAVING } from "../../store/actions/action_types/action.types";
import { savingSubURL } from "../../utils/endpoints";
import DetailsCard from "../../components/Card/DetailsCard";
import Loader from "../../components/Widget/Loader/Loader";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { CardSlide } from "../../components/Card/Card";
import { useForm } from "react-hook-form";
import { TextField } from "../../components/Widget/Form/FormComponent";
// import { toast } from "react-toastify";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

export const TopUpSavings = props => {
  const { register, handleSubmit, control, getValues, formState } = useForm({
    mode: "onChange",
  });
  // const { latitude, longitude } = useLocation({ url: "" });

  const onSubmit = data => {
    data.user_savings_id = props.save_data.u_save?.id;
    data.user_id = props.user_info?.id;
    data.transaction_location = "location to add";
    data.user_ip = "ip to add";
    data.channel = "web";
    // alert(JSON.stringify(data));

    if (Number(props.user_wallet[0].available_balance) < data.topup_amount) {
      toast("Sorry! you do not have sufficient funds in your Main Wallet");
    } else {
      const url = `${savingSubURL}/topup`;
      props.postAction(TOP_UP_SAVING, url, data, props.token);
    }
  };

  useEffect(() => {
    if (props.topup_res && getValues("topup_amount") != undefined) {
      toast("topped up successful");
      props.closeModal();
    }
  }, [props.topup_res]);

  useEffect(() => {
    if (props.topup_error && getValues("topup_amount") != undefined) props.closeModal();
  }, [props.topup_error]);

  return (
    <div className="">
      <div className=" px-8 xl:px-12 py-8">
        <CardSlide content={props.save_wallet} imageSrc={"/safe.svg"} name="Savings Balance" showBtn={false} {...props} />
        <form className="pt-5" onSubmit={handleSubmit(onSubmit)}>
          <TextField label="Top Up Amount (NGN)" placeholder="enter amount" type="currency" control={control} error={formState.errors.topup_amount} {...register("topup_amount", { required: true })} />
          <div className="flex pt-5 justify-end">
            <div className="flex items-center gap-2">
              <AppButton name="Cancel" onPress={() => props.showModal("HIDE_SUB_MODAL", false)} variant={"outline"} />
              <AppButton name={`TopUp Savings`} className="rounded-lg" loading={props.topup_loading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const SaveInfo = ({ loading, token, saving_data, ...props }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    let path_id = pathname;
    path_id = path_id.split("/");
    path_id = path_id[path_id.length - 1];
    const url = `${savingSubURL}/${path_id}`;
    // alert(props.save_id);
    props.getAction(GET_SAVING_SUB_DETAILS, url, token);
  }, []);

  return (
    <>
      <div className="w-full h-full">
        {loading == true ? (
          <div className="col-span-3 h-full flex justify-center items-center">
            <Loader loading={true} color="#922333" />
          </div>
        ) : (
          <div className="w-full h-full">
            <DetailsCard {...saving_data} />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  save_id: state.ui.id_setter.id,
  loading: state.saving.get_sub_detail.loading,
  save_data: state.saving.get_sub_detail.data,
  error_msg: state.saving.get_sub_detail.error_msg,
  confirm_data: state.ui.modal.confirm_data,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  topup_res: state.saving.topup.data,
  topup_loading: state.saving.topup.loading,
  topup_error: state.saving.topup.error,
  save_wallet: state.saving.save_wallet.data,
});

export default connect(mapStateToProps, {
  getAction,
  showModal,
  postAction,
  showMessage,
})(SaveInfo);
