import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { TextField, AssetRadioField, Checkbox } from "../../components/Widget/Form/FormComponent";
import AppModal from "../../components/Widget/Modal/Modal";
import { SubscribedBox } from "../../components/Widget/MessageBox/SubscribedBox";
import { zodResolver } from "@hookform/resolvers/zod";
import { bank_details_schema } from "../../schema/assets.schema";
import { useLocation, useNavigate } from "react-router-dom";
import { postAction } from "../../store/actions/creators/request.action";
import { ADD_ASSET_MGT_FUND } from "../../store/actions/action_types/action.types";
import { assetMgtUrl } from "../../utils/endpoints";
import { useEffect } from "react";
import { setSubscribed, showMessage } from "../../store/actions/creators/ui.action";

const check_data = [{ title: "Reinvested" }, { title: "Transferred To Bank Account" }];

const AssetBankDetails = ({ token, user_wallet, user_info, asset_error, asset_error_msg, loading, asset_success, subscribe_open }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const wallet_data = user_wallet[0]?.wallets[0];
  const account_name = `${user_info?.first_name} ${user_info?.last_name}`.toUpperCase();
  const account_number = wallet_data?.nuban_account ?? "";

  const { register, handleSubmit, control, formState, setValue } = useForm({
    mode: "onChange",
    resolver: zodResolver(bank_details_schema),
    defaultValues: {
      bank_name: "GDL Finance",
      account_name,
      account_number,
    },
  });
  const { errors, isDirty, isValid } = formState;

  // when you refresh the page and account number isn't currently available
  useEffect(() => {
    if (account_number) {
      setValue("account_number", account_number);
    }
  }, [account_number, setValue]);

  /** listen for any error from the server */
  useEffect(() => {
    if (asset_error) dispatch(showMessage("OPEN_MSG_MODAL", { message: asset_error_msg }));
  }, [asset_error, dispatch]);

  useEffect(() => {
    if (asset_success) dispatch(setSubscribed("OPEN_SUBSCRIBE"));
  }, [asset_success]);

  const onSubmit = async data => {
    const info_data = { ...data, ...state, country: state.country.name };
    dispatch(postAction(ADD_ASSET_MGT_FUND, assetMgtUrl, info_data, token));
  };

  const backToProduct = () => navigate("/products");

  const navigateToPortfolio = () => {
    const url = asset_success.product_type.toLowerCase().replace(/ /g, "-");
    navigate(`/products/assets/${url}/subscription`);
  };

  const subscribe_obj = { onBack: backToProduct, onViewPortfolio: navigateToPortfolio };

  return (
    <>
      <form className="flex flex-col gap-10" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <TextField label="Bank Name" type="text" disabled className="text-[#1F2734] font-medium !bg-white" {...register("bank_name")} />
          <TextField label="Account Number" type="text" disabled className="text-[#1F2734] font-medium !bg-white" {...register("account_number")} />
          <TextField label="Account Name" type="text" disabled className="text-[#1F2734] font-medium !bg-white" {...register("account_name")} />
          <AssetRadioField label="My dividends should be" control={control} items={check_data} {...register("dividends_should_be")} />
          <div className="flex flex-col gap-[30px]">
            <hr className="border-[#F0F0F0]" />
            <Checkbox control={control} label="I am 18 years old or over." {...register("confirmed_age_declaration")} />
            <Checkbox
              control={control}
              label="The information given is correct to the best of my knowledge and belief, and I will inform GDL of any change in the information given within 10 working days of such change."
              {...register("confirmed_correctness_of_provided_information")}
            />
            <Checkbox
              control={control}
              label="I certify that the funds and sources of such funds and or assets are legitimate and not directly or indirectly the proceeds of any unlawful activity."
              {...register("certified_legitimacy_of_provided_funds")}
            />
            <Checkbox
              control={control}
              label="I understand that as with stock market investments, the value of my/our investment(s) in equity-based funds may go up or down and that past performance is not necessarily an indication of future performance."
              {...register("confirmed_they_understand_the_nature_of_investments")}
            />
          </div>
          <TextField label="Name Initials" className="w-[100px]" placeholder="AAA" type="text" {...register("initials")} />
        </div>
        <AppButton name="Subscribe" className="self-end md:w-[138px]" disabled={!isDirty || !isValid} loading={loading} />
      </form>
      <AppModal open={subscribe_open} no_header className="sm:w-[500px]" onClose={() => null} content={<SubscribedBox {...subscribe_obj} />} />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_wallet: state.account.get_wallets.data,
  user_info: state.auth.login.data,
  loading: state.assets.add_asset.loading,
  asset_success: state.assets.add_asset.data,
  asset_error_msg: state.assets.add_asset.error_msg,
  asset_error: state.assets.add_asset.error,
  subscribe_open: state.ui.subscribe.open_subscribe,
});

export default connect(mapStateToProps, {})(AssetBankDetails);
