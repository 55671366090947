import { connect } from "react-redux";
import { ArrowLeft2 } from "iconsax-react";
import { showNextForm } from "../../store/actions/creators/ui.action";
import { useNavigate } from "react-router-dom";

const HouseMoniHeader = ({ title, no_back, product_code_data, ignore_navigation, ...props }) => {
  const navigate = useNavigate();
  const product_title = product_code_data?.product_code == "HPA" ? "Classic" : "Community";

  const goBackward = () => {
    if (!no_back && props.housemoni_subs == null && ignore_navigation) {
      props.showNextForm(true, { ...props.next_form_data, step: props.next_form_data.step - 1 });
      return;
    }
    !ignore_navigation && navigate(-1);
  };

  return (
    <div className="flex flex-col gap-[30px] self-stretch">
      <div className="flex items-center gap-[30px] self-start text-primary cursor-pointer" onClick={goBackward}>
        {!no_back && <ArrowLeft2 size={24} />}
        <span className="font-medium">{title || `HouseMoni ${product_title} Requirements`}</span>
      </div>
      <hr className="bg-gray04 w-full" />
    </div>
  );
};

const mapStateToProps = state => ({
  next_form_data: state.ui.show_next_form.form_data,
  housemoni_subs: state.hpa.get_hpa_sub.hpa_data,
  product_code_data: state.ui.product_code.product_code_data,
});

export default connect(mapStateToProps, { showNextForm })(HouseMoniHeader);
