import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { AppButton } from "../../../components/Widget/Button/AppButton";
import { Checkbox } from "../../../components/Widget/Form/FormComponent";
import HouseMoniHeader from "../../../components/HouseMoni/HouseMoniHeader";
import { showModal, showMessage } from "../../../store/actions/creators/ui.action";
import AppModal from "../../../components/Widget/Modal/Modal";
import JoinGroupContent from "../../../components/HouseMoni/JoinGroupComp";
import CreateGroupContent from "../../../components/HouseMoni/CreateGroupComp";
import { postAction } from "../../../store/actions/creators/request.action";
import { SUB_HPA } from "../../../store/actions/action_types/action.types";
import { SubscriptionInfo } from "../../../components/HouseMoni/HouseMoni";
import { addHPAURL } from "../../../utils/endpoints";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HouseMoniFormThree = ({ token, selected_home_cost, hpa_error, ...props }) => {
  const navigate = useNavigate();
  const { formState, handleSubmit, control, register } = useForm({
    mode: "onChange",
    defaultValues: {
      user_id: props.user_info?.id,
      wallet_ref: props.user_info?.user_txn_ref,
      group: props.group_data ?? null,
      selected_loan_multiple: Number(selected_home_cost.loan_multiple),
    },
  });

  /** use useeffect to handle when subscription was successful */
  useEffect(() => {
    // show confirm modal when subscription is successful
    if (props.hpa_success) {
      navigate("make-payment", { state: { ...props.hpa_success_data } });
    }

    // show error modal if an error occured
    if (hpa_error) {
      const updated_error = hpa_error.replace("HAA", "HouseMoni Community").replace("HPA", "HouseMoni Classic");
      props.showMessage("OPEN_MSG_MODAL", { message: updated_error });
    }
  }, [props.hpa_success, hpa_error]);

  const onSubmit = data => {
    // TODO --- call endpoint to subscribe to HouseMoni then on success, open this modal

    const completed_data = { ...props.next_form_data, ...data, group: props.group_data };
    if (props.bvn) {
      // navigate("make-payment", { state: { ...props.confirm_data } });
      // submit data
      props.postAction(SUB_HPA, addHPAURL, completed_data, token);
    } else {
      // show attention modal to show user they need to set BVN
      props.showMessage("OPEN_MSG_MODAL", { message: "No assigned account number. Please Provide your BVN to be assigned an account number" });
    }
  };

  /** method to open the join group modal */
  const handleJoinGroup = () => {
    props.showModal("OPEN_MODAL", true);
  };

  /** method to open the create group modal */
  const handleCreateGroup = () => {
    props.showModal("OPEN_GROUP_MODAL", true);
  };

  return (
    <>
      <div className="flex w-full py-5 px-[52px] flex-col items-start gap-[35px]">
        <HouseMoniHeader title="Eligibility Assessment" ignore_navigation />
        <div className="flex flex-col items-end gap-10 self-stretch">
          <SubscriptionInfo {...props} joinGroup={handleJoinGroup} createGroup={handleCreateGroup} />
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-10 self-stretch items-end">
            <div className="flex flex-col items-start gap-[30px] self-stretch">
              <Checkbox control={control} label="I acknowledge that I can repay this amount monthly after I move into my home." {...register("agreement_one", { required: true })} />
              <Checkbox control={control} label="I have read & accepted the terms and conditions." {...register("agreement_two", { required: true })} />
              <Checkbox control={control} label="I authorise for monthly debits on my card for my HouseMoni Community monthly payments." {...register("agreement_three", { required: true })} />
            </div>
            <AppButton loading={props.hpa_loading} name="Subscribe" className="md:w-[138px]" disabled={!formState.isDirty || !formState.isValid} />
          </form>
        </div>
      </div>
      <AppModal title="Select Group" className="md:w-[600px]" open={props.join_group} onClose={() => props.showModal("HIDE_MODAL", false)} content={<JoinGroupContent />} />
      <AppModal title="Create Group" className="md:w-[400px]" open={props.create_group} onClose={() => props.showModal("HIDE_MODAL", false)} content={<CreateGroupContent />} />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  product_code: state.ui.product_code.product_code_data?.product_code,
  join_group: state.ui.modal.show,
  create_group: state.ui.modal.open_group,
  confirm_data: state.ui.modal.confirm_data,
  next_form_data: state.ui.show_next_form.form_data,
  adjusted_data: state.hpa.adjust.data,
  group_data: state.ui.set_group_data.group_data,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  hpa_loading: state.hpa.add_hpa.loading,
  hpa_success: state.hpa.add_hpa.success,
  hpa_error: state.hpa.add_hpa.error_msg,
  hpa_success_data: state.hpa.add_hpa.data,
  bvn: state.account.get_wallets.bvn,
  selected_home_cost: state.ui.set_data.selected_data,
});

export default connect(mapStateToProps, { showModal, postAction, showMessage })(HouseMoniFormThree);
