import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { WalletCardDisplay, TotalBalance, JoinTribe, ProductView, StatisticView, WalletCard, SecurityView, SetupAccount } from "../../components/Card/Card";
import { RecentTransactionView } from "../../components/Transaction/TransactionRow";
import AppModal from "../../components/Widget/Modal/Modal";
import { processData } from "./home.data";
import { showModal } from "../../store/actions/creators/ui.action";
import HomeModalContent from "./HomeModalContent";
import { getAction, postAction } from "../../store/actions/creators/request.action";
import { GET_USER_TRANS } from "../../store/actions/action_types/action.types";
import { userTransactionURL } from "../../utils/endpoints";
import { ProductCard } from "../../components/Card/ProductCard";
import { useHasAccount } from "../../hooks/account.hook";
import { useNavigate } from "react-router-dom";
import { useProductList } from "../../hooks/product.hook";
import { useChartData } from "../../hooks/chart.hook";

const Home = ({ loading, token, login_token, user_info, details, ...props }) => {
  const navigate = useNavigate();
  const { is_valid_user } = useHasAccount();
  const { products } = useProductList();
  const { chart_data } = useChartData();

  const account_number = props.user_wallet[0]?.wallets[0]?.nuban_account;
  const user_id = user_info?.id;

  const callTransaction = () => {
    let start_date = new Date(new Date().setDate(new Date().getDate() - 7)).toJSON();
    let end_date = new Date(Date.now()).toJSON();

    end_date = end_date.substring(0, end_date.indexOf("T"));
    start_date = start_date.substring(0, start_date.indexOf("T"));

    const url = `${userTransactionURL}/${user_id}/${account_number}?page=${1}&per_page=4&startDate=2021-11-01`;
    props.getAction(GET_USER_TRANS, url, token);
  };

  useEffect(() => {
    /** wait for account number to be available before calling transaction */
    if (account_number) {
      callTransaction();
    }
  }, [account_number]);

  useEffect(() => {
    props.showModal("HIDE_MODAL", false);
  }, []);

  // when view more is clicked, navigate to the right product page
  const handleNavigationToProduct = url => {
    navigate(url);
  };

  const cardInfo = useMemo(() => props.user_wallet.map(processData), [props.user_wallet]);

  const _renderItem = ({ data, index }) => <ProductCard {...data} index={index} onPress={handleNavigationToProduct} />;

  const _renderWallet = ({ item, index }) => <WalletCard item={item} index={index} />;

  const recent_transaction = useMemo(() => props.user_transactions?.slice(0, 10), [props.user_transactions]);

  return (
    <>
      <div className="grid grid-cols-12 2xl:gap-x-[32px] xl:gap-x-[25px] lg:gap-x-[20px] md:gap-y-0 gap-y-[32px] h-auto">
        <TotalBalance total_balance={props.total_bal ?? 0} {...props} />
        <JoinTribe />
        <WalletCardDisplay loading={loading} data={cardInfo} renderItem={_renderWallet} />

        {!is_valid_user && (
          <>
            <SetupAccount />
            <SecurityView />
          </>
        )}

        {is_valid_user && <ProductView data={products} renderItem={_renderItem} />}

        <div className="col-start-1 md:col-end-9 col-end-13 md:row-span-1 2xl:self-start xl:self-start lg:self-start row-start-4 2xl:mt-[30px] sm:flex hidden md:flex-row flex-col justify-between gap-[30px]">
          {props.user_wallet[0] ? <StatisticView data={chart_data} total={props.total_bal} /> : <div></div>}
        </div>
        <div
          className={`md:col-start-9 pb-2 ${
            props.user_transactions?.length != 0 ? "sm:min-h-[550px]" : "sm:min-h-[300px]"
          } relative col-start-1 col-end-13 p-[20px] py-0 bg-white rounded-b-lg flex flex-col gap-[20px] items-center ${!is_valid_user && "row-start-3 row-end-6"} `}
        >
          <hr className="border-b-gray03 w-full pb-[20px]" />
          <RecentTransactionView user_transactions={recent_transaction} loading={props.trans_loading} account_number={account_number} />
        </div>
      </div>

      <AppModal title="Add to Portfolio" className="2xl:w-[618px]" open={props.open} onClose={() => props.showModal("HIDE_MODAL", false)} content={<HomeModalContent {...props} />} />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  login_token: state.auth.login.login_token,
  open: state.ui.modal.show,
  loading: state.account.get_wallets.loading,
  user_wallet: state.account.get_wallets.data,
  total_bal: state.account.get_wallets.total_bal,
  user_info: state.auth.login.data,
  validate_success: state.auth.login.success,
  trans_loading: state.transaction.user_trans.loading,
  user_transactions: state.transaction.user_trans.data,
  user_trans_error: state.transaction.user_trans.error,
  saving_sub: state.saving.get_sub.savings_data,
  invest_sub: state.investment.get_sub.data,
  load_invest_withdrawal: state.withdrawal.withdraw_investment.sending,
  load_save_withdrawal: state.withdrawal.withdraw_savings.sending,
  save_withdraw_err: state.withdrawal.withdraw_savings.error_msg,
  invest_withdraw_err: state.withdrawal.withdraw_investment.error_msg,
  ip_data: state.auth.get_ip.data,
  details: state.user.user_details.data,
});

export default connect(mapStateToProps, { showModal, getAction, postAction })(Home);
