import { InfoCircle } from "iconsax-react";
import { AppButton } from "../Button/AppButton";
import { useDispatch } from "react-redux";
import { showDeleteModal } from "../../../store/actions/creators/ui.action";

export const DeleteBox = ({ onDelete, loading }) => {
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(showDeleteModal("CLOSE_DELETE_MODAL"));
  };
  return (
    <div className="flex flex-col p-[20px_51px] pt-0 gap-[40px] items-center">
      <div className="flex flex-col gap-5 items-center">
        <InfoCircle className="sm:w-[54px] sm:h-[54px] text-brand01" variant="Bold" />
        <div className="flex flex-col gap-2.5">
          <p className="font-bold text-primary leading-[20.16px]">Delete Beneficiary?</p>
          <p className="text-primary leading-[20.16px]">Are you sure you want to delete beneficiary?</p>
        </div>
      </div>
      <div className="flex gap-5 justify-center items-center">
        <AppButton name={loading ? "Deleting..." : "Delete"} variant="outline" className="sm:w-[138px]" onPress={() => onDelete()} />
        <AppButton name="Go Back" className="sm:w-[138px]" onPress={handleBack} />
      </div>
    </div>
  );
};
