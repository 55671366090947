import React from "react";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.action";
import Loader from "../../components/Widget/Loader/Loader";
import { processInterestData } from "./invest.data";
import { OptionButton } from "../../components/Widget/Button/OptionButton";
import { Calendar2, ExportSquare } from "iconsax-react";
import TransactionView, { TransactionRow } from "../../components/Transaction/TransactionRow";
import { useGetUserInvestmentHistoryByUserCurrencyQuery } from "../../services/products/invest.services";
import { transformDataToGroupForm } from "../../utils/helper";

const ProductInterestHistory = ({ userId }) => {
  const { data: investHistoryData = [], isLoading } = useGetUserInvestmentHistoryByUserCurrencyQuery({ userId: userId, currency: "NGN" });

  const rawData = investHistoryData?.map(processInterestData);
  const tableData = transformDataToGroupForm(rawData);

  return (
    <div className="overflow-auto" style={{ height: "35em" }}>
      <div className="flex gap-2 pb-5">
        <OptionButton name="Export" icon={<ExportSquare size={"18"} variant="Outline" />} onPress={() => props.showModal("OPEN_PRINT_MODAL", true)} />
        <OptionButton name="Date Range" icon={<Calendar2 size={"18"} variant="Outline" />} />
      </div>
      {isLoading ? (
        <div className="flex justify-center mt-20 items-center">
          <Loader loading={isLoading} color="#922333" />
        </div>
      ) : (
        <div className="border-t pt-5 border-gray04">
          {investHistoryData.length == 0 ? (
            <div className="flex flex-col gap-2 items-center pt-5">
              <h3 className="text-primary font-medium text-[22px]">No records</h3>
              <p className="text-gray01">There are no data yet.</p>
            </div>
          ) : (
            <div className="overflow-auto h-[700px]">
              {tableData && (
                <TransactionView {...props}>
                  <>
                    {tableData.map(content => (
                      <div key={content.transactions.id}>
                        <h4 className="text-sm">{content?.date}</h4>
                        {content?.transactions.map(data => (
                          <TransactionRow {...data} />
                        ))}
                      </div>
                    ))}
                  </>
                </TransactionView>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductInterestHistory;
