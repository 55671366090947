import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { HouseMoniCustomLabel, WhatIsThis } from "../../../components/HouseMoni/HouseMoni";
import { AppButton } from "../../../components/Widget/Button/AppButton";
import { SelectField, TextField } from "../../../components/Widget/Form/FormComponent";
import { currencyFormat, handleFrequency } from "../../../utils/helper";
import HouseMoniHeader from "../../../components/HouseMoni/HouseMoniHeader";
import { ADJUST_HOME_COST, GET_HPA_ELIGIBILITY } from "../../../store/actions/action_types/action.types";
import { postAction } from "../../../store/actions/creators/request.action";
import { hpaAdjustHomeCostURL, hpaEligibilityURL } from "../../../utils/endpoints";
import { percent } from "../../../utils/helper";
import { showMessage, showNextForm } from "../../../store/actions/creators/ui.action";

const HouseMoniFormTwo = ({ token, ...props }) => {
  const { register, formState, control, handleSubmit, watch, getValues, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      // total_rsa_balance: 0,
      repayment_frequency: "monthly",
    },
  });
  const watchRSA = watch("rsa");
  const [show, setShow] = useState(false);
  const [stagedata, setStageData] = useState({});
  const [frequencies] = useState(props.selected_home_cost?.repayment_amount);
  const balance = props.home_cost?.expected_min_home_cost_limit;
  const home_cost_val = props.adjusted_data?.adjusted_home_cost_limit || props.selected_home_cost?.home_cost_limit;

  useEffect(() => {
    const subscription = watch(({ annual_rent, ...value }, { name }) => {
      /**
       * watch for when user enters annual rent and annual rent percent to update home cost
       *  */
      if (name == "percentage_of_rent_contribution_for_repayment" && annual_rent) {
        const data = {
          monthly_contribution: props.next_form_data.monthly_contribution,
          product_code: props.product_code,
          annual_rent,
          percentage_of_rent_contribution_for_repayment: parseInt(value.percentage_of_rent_contribution_for_repayment),
        };
        props.postAction(ADJUST_HOME_COST, hpaAdjustHomeCostURL, data, token); // API to adjust home cost
      }

      /**
       * check for when users selects yes and has to enter total rsa and if they want to allocate 25% of their rsa
       *  */
      if (name == "allocation" && value.total_rsa_balance) {
        // run the eligibility API again
        runEligibility();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /** watch for when user selects YES or NO for RSA and show a modal for the percentage eligibility */
  useEffect(() => {
    if (watchRSA == undefined) {
      return;
    }
    if (watchRSA == "Yes") {
      setShow(true);
    } else {
      setShow(false);
      setValue("total_rsa_balance", 0);
      runEligibility(); // call method that calculates eligibility
    }
  }, [watchRSA]);

  /** method that handles the calculation of eligibility of housemoni */
  const runEligibility = () => {
    // get all values and construct new data
    const all_values = getValues();

    all_values.product_code = props.product_code;
    all_values.monthly_repayment = props.selected_home_cost?.repayment_amount;
    all_values.percentage_of_rent_contribution_for_repayment = parseInt(all_values.percentage_of_rent_contribution_for_repayment);

    const data = { ...all_values, ...props.next_form_data }; // merge data from previous page to this

    setStageData(data); // this is to allow the onSubmit method pass the same data to the thirdform

    props.postAction(GET_HPA_ELIGIBILITY, hpaEligibilityURL, data, token); // call API to get eligibility status
  };

  /** send data to the next page i.e formthree.js by submitting form using the useForm method */
  const onSubmit = data => {
    /** set form data */
    data.payment_frequency = props.next_form_data.payment_frequency;
    data.repayment_frequency = handleFrequency(data.repayment_frequency) || data.repayment_frequency;
    const form_data = { ...data, ...stagedata, step: "3" };
    props.showNextForm(true, form_data);
  };

  /** listen to the eligibility response to open modal to show if user passed or not */
  useEffect(() => {
    if (props.eligibility_resp && !props.eligibility_resp?.is_eligible) {
      const message = `Your percentage eligibility is ${props.eligibility_resp?.percentage_eligibility} . Additional ₦${currencyFormat(
        props.eligibility_resp.amount_left
      )} required. To qualify, raise your Annual Rent Repayment Percentage or add new income source to meet the minimum expected Home Cost Limit of ₦${currencyFormat(balance)}.`;

      // props.showMessage("OPEN_MSG_MODAL", message);
    }
  }, [props.eligibility_resp]);

  return (
    <div className="flex w-full py-5 px-[52px] flex-col items-start gap-[35px]">
      <HouseMoniHeader title="Eligibility Assessment" ignore_navigation />
      <form className="flex flex-col items-end gap-10 w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-start gap-10 self-stretch transition-all ease-in-out">
          <TextField label="Annual Rent" type="currency" control={control} placeholder="₦0.00" error={formState.errors.annual_rent} {...register("annual_rent", { required: true })} />
          <SelectField
            label={
              <HouseMoniCustomLabel
                title="Annual Rent Repayment Percentage"
                sub_title={<WhatIsThis text="What is this?" description="What percentage of your rent would you be willing to use to pay down the cost of your home after accessing your home?" />}
              />
            }
            item={percent}
            selected_item="amount"
            defaultValue="Select Percentage"
            type="currency"
            control={control}
            error={formState.errors.percentage_of_rent_contribution_for_repayment}
            {...register("percentage_of_rent_contribution_for_repayment", {
              required: true,
            })}
          />
          {/* <TextField
            label={<HouseMoniCustomLabel title="Adjusted Home Cost Limit" balance={balance || 0} />}
            type="currency"
            disabled
            control={control}
            value={home_cost_val}
            placeholder="₦0.00"
            error={formState.errors.home_cost_limit}
            {...register("home_cost_limit", { required: false })}
          /> */}

          <TextField
            label={
              <HouseMoniCustomLabel
                title="Required Monthly Repayment Amount Post PEP"
                sub_title={<WhatIsThis text="What is this?" description="Monthly amount you will be paying after you move into your home." />}
              />
            }
            type="currency"
            disabled
            control={control}
            value={props.adjusted_data?.adjusted_repayment_amount || props.selected_home_cost?.repayment_amount}
            placeholder="N80,000.00"
            error={formState.errors.monthly_repayment}
            {...register("monthly_repayment", { required: false })}
          />
          <SelectField
            label="Repayment Tenure"
            item={[]}
            disabled
            error={formState.errors.investment_tenor}
            defaultValue={`${props.selected_home_cost.repayment_tenure / 12} Years`}
            control={control}
            {...register("investment_tenor", { required: false })}
          />
          <SelectField
            label={<HouseMoniCustomLabel title="Retirement Savings Account (RSA) Ownership" sub_title={<WhatIsThis text="What is this?" description="Do you have a Retirement Savings Account?" />} />}
            item={["Yes", "No"]}
            defaultValue={`Select Response`}
            disabled={props.load_adjusted_data} // if there's an adjusted home cost set inactive until the adjusted home cost returns a value
            error={formState.errors.rsa}
            control={control}
            {...register("rsa", { required: true })}
          />
          {show && (
            <>
              <TextField
                label="Total Retirement Savings Amount"
                control={control}
                type="currency"
                placeholder="₦0.00"
                error={formState.errors.total_rsa_balance}
                {...register("total_rsa_balance", { required: show })}
              />
              <SelectField
                label="Allocate 25% from Retirement Savings to Home Loan Repayment"
                item={["Yes", "No"]}
                error={formState.errors.allocation}
                control={control}
                {...register("allocation", { required: show })}
              />
            </>
          )}
          <SelectField
            label="Preferred Repayment Frequency"
            item={[`₦${currencyFormat(frequencies / 30)} - Daily Post PEP`, `₦${currencyFormat(frequencies / 4)} -Weekly Post PEP`]}
            control={control}
            {...register("repayment_frequency", { required: false })}
          />
        </div>
        <div className="flex">
          <AppButton name="Next" className="w-[138px]" disabled={!formState.isDirty || !formState.isValid || props.loading_eligibility} />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  adjusted_data: state.hpa.adjust.data,
  load_adjusted_data: state.hpa.adjust.loading,
  home_cost: state.hpa.cost_limit.data,
  next_form_data: state.ui.show_next_form.form_data,
  eligibility_resp: state.hpa.hpa_eligibility.eligibility,
  loading_eligibility: state.hpa.hpa_eligibility.loading,
  product_code: state.ui.product_code.product_code_data?.product_code,
  selected_home_cost: state.ui.set_data.selected_data,
});
export default connect(mapStateToProps, { postAction, showMessage, showNextForm })(HouseMoniFormTwo);
