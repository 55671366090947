import styled from "styled-components";
// import backgroundPattern from "../../assets/images/back-pattern.png";

export const AuthContainer = styled.div`
  background-color: #fafafa;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .login-container {
    height: 80vh;
  }
  .pattern {
    /* background-image: ${`url(/back-pattern.png)`}; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /* .auth-board {
    width: 65%;
  } */
`;
export const SecurityText = styled.p`
  font-size: 12px;
  @media (max-width: 1024px) {
    font-size: 9px;
  }
  @media (max-width: 1536px) {
    font-size: 11px;
  }
`;

export const TitleText = styled.small`
  font-size: 14px;
  line-height: 1.3;
  @media only screen and (max-width: 1024px), (max-width: 1556px) {
    font-size: 12px;
    text-align: center;
  }
`;

export const SubTitleText = styled.span`
  font-size: 14px;
  @media only screen and (max-width: 1024px), (max-width: 1556px) {
    font-size: 10px;
  }
`;

export const SubTitle = styled.small`
  font-size: 12px;
  line-height: 1;
  @media (max-width: 1024px) {
    font-size: 8px;
  }
  @media (max-width: 1556px) {
    font-size: 10px;
  }
`;

// style={{ lineHeight: "1", fontSize: "12px" }}
