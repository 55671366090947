import React, { useRef, useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Divider } from "@mui/material";
import { Badge } from "../Widget/Badge/Badge";
import AppModal from "../Widget/Modal/Modal";
import { showModal } from "../../store/actions/creators/ui.action";
import { FaUser } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import Loader, { LoaderII } from "../Widget/Loader/Loader";
import { AppButton } from "../Widget/Button/AppButton";
import { currencyFormatter, formatDate, formatTimeToAmPm } from "../../utils/helper";
import { uploadFile } from "react-s3";
import { gdlAWSConfig } from "../../utils/helper";
import { UPDATE_USER_PROFILE } from "../../store/actions/action_types/action.types";
import { usersURL } from "../../utils/endpoints";
import { makeRequest } from "../../api/request.api";
import { editAction } from "../../store/actions/creators/request.action";

export const ProfileInfo = connect(null, { editAction })(props => {
  const fileRef = useRef();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (image) {
      const data = {
        user_id: props.id,
        user_avatar_url: image,
      };
      props.editAction(UPDATE_USER_PROFILE, `${usersURL}/${props.id}`, data, props.token, "PATCH");
    }
  }, [image]);

  const onFileChange = useCallback(async event => {
    if (event.target.files[0]) {
      setLoading(true);
      try {
        const file_upload = await uploadFile(event.target.files[0], gdlAWSConfig);
        // alert("loading...");
        if (file_upload) {
          setImage(file_upload.location);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  return (
    <div className="flex items-center">
      <div className="flex justify-center items-center rounded-full  border-2 border-red-900 p-1" style={{ width: 80, height: 80 }}>
        <input type="file" ref={fileRef} name="profile" onChange={onFileChange} hidden accept={[".jpg", ".png"]} />
        <div className={`bg-transparent ${loading == true ? "block" : "hidden"} absolute w-[75px] h-[75px] rounded-full flex justify-center items-center`}>
          <Loader loading={true} size={30} color="#992333" />
        </div>
        <div onClick={() => fileRef.current.click()} className="bg-gray-100 w-full h-full flex rounded-full justify-center items-center">
          {props.user_avatar_url == null ? (
            <span>
              <FaUser size={50} />
            </span>
          ) : (
            <img src={props.user_avatar_url} alt="image" className="h-full rounded-full" />
          )}
        </div>
      </div>
      <div className="flex flex-col mx-5">
        <span className="text-medium text-lg text-red-800">{props.first_name + props.last_name}</span>
        <small className="opacity-70">{props.email}</small>
      </div>
    </div>
  );
});

export const InterestCard = props => (
  <>
    {props.index == 0 ? (
      <div className="shadow w-full border-b border-gray-200 sm:rounded-md p-4 md:px-10 px-5 bg-white flex flex-col">
        <span className="text-base">Interest Rate</span>
        <span className="font-normal text-3xl">{props.interest || "0"}%</span>
        <small className="text-gray-500">Per Annum</small>
      </div>
    ) : (
      <div>
        <div className="border-b pb-4 te">
          <span className="font-medium uppercase text-sm text-gray-500">INTEREST HISTORY DETAILS</span>
        </div>
        {props.loading == true ? (
          <div className="flex justify-center items-center" style={{ height: "30em" }}>
            <Loader loading={props.loading} color="#922333" />
          </div>
        ) : (
          <>
            {!props.data.interest ? (
              <div className="bg-white mt-5 shadow w-full flex flex-col justify-center overflow-auto border-b border-gray-200 sm:rounded-lg">
                <div className="flex justify-center items-center py-10 px-5">
                  <span className="md:text-sm text-sm px-5 py-3 flex items-center leading-5 rounded-lg bg-gray-50 text-gray-500">Click on individual roles to view more details</span>
                </div>
              </div>
            ) : (
              <>
                {props.data && props.data.length < 1 ? (
                  <div className="bg-white mt-5 shadow w-full flex flex-col justify-center overflow-auto border-b border-gray-200 sm:rounded-lg">
                    <div className="flex justify-center items-center py-10 px-5">
                      <span className="md:text-sm text-sm px-5 py-3 flex items-center leading-5 rounded-lg bg-gray-50 text-gray-500">
                        <IoWarning size={20} /> There are currently no available data for this service
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="shadow w-full border-b border-gray-200 sm:rounded-md p-4 px-10 bg-white mt-5">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col my-5">
                        <small className="opacity-60">Interest</small>
                        <span className="text-base font-medium">{props.data.interest}</span>
                      </div>
                      <div className="flex flex-col">
                        <small className="opacity-60">Total Amount</small>
                        <span className="">{props.data.total_amount}</span>
                      </div>
                    </div>

                    <Divider />

                    <div className="flex flex-col my-5 mt-0">
                      <div className="grid grid-cols-3 gap-5 gap-y-6 pt-5">
                        {Object.keys(props.data.row_two).map(content => (
                          <div className={`flex flex-col p-1`}>
                            <small className="text-gray-500">{content || "somename"}</small>
                            <span className={` text-sm ${"font-normal"}`}>{props.data.row_two[content]}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    )}
  </>
);

const DetailsItem = props => {
  return (
    <div className="py-2 mt-3 border-b border-gray04 flex justify-between items-center">
      <div className="flex-1 flex flex-col items-start gap-1">
        <span className="text-[#8C8C8C]">{props.item1_title}</span>
        <span className="text-primary">{props.item1_value}</span>
      </div>
      <div className="flex-1 flex flex-col items-end gap-1">
        <span className="text-[#8C8C8C]">{props.item2_title}</span>
        <span className="text-primary">{props.item2_value}</span>
      </div>
    </div>
  );
};

const DetailsCard = ({ topic, ...props }) => {
  const openDetailModal = id => () => {
    props.showModal("OPEN_MODAL", true, id);
  };

  const handleCheckout = () =>
    props.showModal("OPEN_CONFIRM_MODAL", true, {
      amount: props.lastpay_amount != null ? props.lastpay_amount : props.row_two["starting amount"],
      request_ref: props.payment_reference,
      service_type: "SAVEINVEST",
      user_savings_id: props.id,
    });

  return (
    <>
      <div className="w-full border border-gray-200 rounded-xl px-5 py-4 bg-white">
        {/* <div className="flex justify-between items-center">
          <div className="flex flex-col md:w-full w-4/6">
            {props.isFee && <small>Recipient Account</small>}
            <small className="text-base capitalize font-semibold">
              {props.name}
            </small>
            {props.status && (
              <div className="">
                <Badge
                  content={props.status}
                  color={`${
                    props.status == "PENDING"
                      ? "bg-yellow-500"
                      : props.status == "ENDED"
                      ? "bg-red-500"
                      : "bg-green-500"
                  } text-white py-1`}
                  width="8%"
                />
              </div>
            )}
          </div>

          <div className="flex flex-col-reverse w-full items-end">
            {!topic &&
            (props.lastpay_status == "PENDING" || props.status == "PENDING") ? (
              <div className="">
                <AppButton
                  name={
                    <>
                      <span className="mr-1">proceed to pay</span>
                      {props.lastpay_amount != null
                        ? currencyFormatter(props.lastpay_amount)
                        : props.row_two["starting amount"]}
                    </>
                  }
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm md:px-4 px-2 py-3 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 sm:ml-3 sm:w-auto sm:text-sm"
                  onPress={() =>
                    props.showModal("OPEN_CONFIRM_MODAL", true, {
                      amount:
                        props.lastpay_amount != null
                          ? props.lastpay_amount
                          : props.row_two["starting amount"],
                      request_ref: props.payment_reference,
                      service_type: "SAVEINVEST",
                      user_savings_id: props.id,
                    })
                  }
                />
              </div>
            ) : (
              <div className="flex flex-col items-end">
                {props.isFee ? (
                  <span className="text-sm opacity-60">Fee Amount</span>
                ) : (
                  <span className="text-sm opacity-60">
                    {topic ? "Target Amount Invested" : "Total Amount Earned"}
                  </span>
                )}

                <span className="font-medium text-2xl">{props.amount}</span>
              </div>
            )}
          </div>
        </div> */}
        <div className="py-2 border-b border-gray04 flex justify-between items-center">
          <div className="flex-1 flex flex-col items-start gap-1">
            <span className="text-[#8C8C8C]">{topic ? "Investment Amount" : "Savings Amount"}</span>
            <span className="text-primary">{currencyFormatter(props.amount)}</span>
          </div>

          <div className="flex-1 flex flex-col-reverse w-full items-end">
            {!topic && (props.lastpay_status == "PENDING" || props.status == "PENDING") ? (
              <div className="">
                <AppButton
                  name={
                    <>
                      proceed to pay
                      {props.lastpay_amount != null ? currencyFormatter(props.lastpay_amount) : props.row_two["starting amount"]}
                    </>
                  }
                  className="w-fit text-sm font-medium sm:text-sm"
                  onPress={handleCheckout}
                />
              </div>
            ) : (
              <div className="flex flex-col items-end">
                {props.isFee ? <span className="text-sm opacity-60">Fee Amount</span> : <span className="text-sm opacity-60">{topic ? "Total Amount Earned" : "Total Amount Earned"}</span>}

                <span className="text-primary">{currencyFormatter(props.amount)}</span>
              </div>
            )}
          </div>
        </div>
        <DetailsItem
          item1_title={topic ? "Date Created" : "Saving purpose"}
          item1_value={topic ? props.row_two["start date"] : props.purpose}
          item2_title={topic ? "Maturity Date" : "Date Created"}
          item2_value={`${formatDate(props.row_two["end date"], "DD MMM YYYY")}`}
        />
        <DetailsItem
          item1_title={topic ? "Subscription Type" : "Savings Purpose Description"}
          item1_value={topic ? props.row_two["subscription type"] : props.purpose_desc}
          item2_title={topic ? "Currency" : "Maturity Date"}
          item2_value={topic ? props.row_two["Currency Type"] : `${formatDate(props.maturity_date, "DD MMM YYYY")}`}
        />
        <DetailsItem
          item1_title={topic ? "Product Name" : "Product Name"}
          item1_value={topic ? props.name : props.savings_product_name}
          item2_title={"Lockup Period"}
          item2_value={`${formatDate(topic ? props.row_two["locked until"] : props.locked_until, "DD MMM YYYY") || "not provided"}`}
        />
        <DetailsItem
          item1_title={"Product Description"}
          item1_value={topic ? props.row_two["investment description"] : props.savings_product_desc}
          item2_title={"Interest Rate"}
          item2_value={`${topic ? props.row_three["Interest rate"] : props.interest}%`}
        />
        <DetailsItem
          item1_title={"Interest Earned"}
          item1_value={currencyFormatter(topic ? props.interest_earned : props.amount)}
          item2_title={"Target amount"}
          item2_value={currencyFormatter(topic ? props.row_two["maximum amount"] : props.target_amount)}
        />
        <div className="py-2 mt-4 flex justify-between items-center">
          <div className="flex-1 flex flex-col items-start gap-1">
            <span className="text-[#8C8C8C]">Recurring</span>
            <span className="text-primary">{topic ? props.row_two.recurring : props.saving_recurring}</span>
          </div>
          <div className="flex-1 flex flex-col items-end gap-1">
            <span className="text-[#8C8C8C]">Status</span>
            {props.status && (
              <div className="">
                <Badge content={props.status} color={`${props.status == "PENDING" ? "bg-yellow-500" : props.status == "ENDED" ? "bg-red-500" : "bg-green-500"} text-white py-1`} width="8%" />
              </div>
            )}
          </div>
        </div>

        {/* <div className="flex flex-col my-5 mt-0">
          <small>{props.type_name}</small>
          <span className="text-lg font-bold">{props.type}</span>
          <div className="grid md:grid-cols-3 gap-5 gap-y-6 pt-5">
            {props.row_two &&
              Object.keys(props.row_two).map(content => (
                <div
                  className={`flex flex-col ${
                    content == "investment description"
                      ? "col-span-3 bg-gray-50 p-1"
                      : ""
                  }`}
                >
                  <small className="text-gray-500 capitalize">{content}</small>
                  <span
                    className={` text-sm ${
                      content == "investment description"
                        ? "font-normal"
                        : "font-medium"
                    }`}
                  >
                    {props.row_two[content]}
                  </span>
                </div>
              ))}
          </div>
        </div>
        <Divider />

        <div className="mt-3">
          {!props.isFee && (
            <span className="text-sm font-semibold">
              {props.topic || "Savings Product and Tenor Details"}
            </span>
          )}
          <div className="grid md:grid-cols-3 mt-2 gap-4">
            {props.row_three &&
              Object.keys(props.row_three).map(content => (
                <div className="flex flex-col">
                  <small className="text-gray-500 capitalize">{content}</small>
                  <span className="text-sm font-medium">
                    {props.row_three[content]}
                  </span>
                </div>
              ))}
          </div>
        </div> */}
      </div>
      <AppModal no_header open={props.open} onClose={() => props.showModal("HIDE_MODAL", false)} content={props.modal_content} />
    </>
  );
};

const mapStateToProps = state => ({
  open: state.ui.modal.show,
});

export default connect(mapStateToProps, { showModal })(DetailsCard);
