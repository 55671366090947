import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { AppButton } from "../Widget/Button/AppButton";
import { TextField } from "../Widget/Form/FormComponent";
import { postAction } from "../../store/actions/creators/request.action";
import { refGenerator } from "../../utils/helper";
import { setGroupData, showModal } from "../../store/actions/creators/ui.action";

const CreateGroupContent = props => {
  const full_name = `${props.user_info?.first_name} ${props.user_info?.last_name}`; // get full name of the person creating a group
  const member = { user_id: props.user_info?.id, full_name, user_avater_url: props.user_info?.user_avatar_url || props.details?.user_avatar_url }; // set creator as the only initial member
  const { handleSubmit, formState, register } = useForm({
    mode: "onChange",
    defaultValues: {
      group_ref: refGenerator(10),
      group_action: "CREATE",
      monthly_contribution: props.next_form_data?.monthly_contribution,
      group_member: [member],
    },
  });

  const onSubmit = data => {
    // set data with the setData redux function
    props.setGroupData(data);
    props.showModal("HIDE_MODAL", false);
  };

  return (
    <form className="py-8 px-12 flex flex-col items-end gap-10" onSubmit={handleSubmit(onSubmit)}>
      <TextField label="Group Name" {...register("group_name", { required: true })} type="text" />
      <AppButton name="Create Group" disabled={!formState.isDirty || !formState.isValid} />
    </form>
  );
};

const mapStateToProps = state => ({
  token: "",
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  home_cost: state.hpa.cost_limit.data,
  next_form_data: state.ui.show_next_form.form_data,
  details: state.user.user_details.data,
});

export default connect(mapStateToProps, { postAction, setGroupData, showModal })(CreateGroupContent);
