import React from "react";
import { connect } from "react-redux";
import TransactionView from "../../../components/Transaction/TransactionRow";

const processData = data => ({
  recipient_account: data.payment_reference,
  "Payment Status": <Badge content={data.payment_status} />,
  amount: data.amount_paid,
  txn_date: new Date(data.createdAt).toLocaleDateString(),
});

const RepaymentDefaults = props => {
  const tableInfo = props.hpa_member_data.repayment_defaults?.map(processData);

  return (
    <div>
      <TransactionView transaction={tableInfo} />
    </div>
  );
};

const mapStateToProps = state => ({
  hpa_member_data: state.hpa.hpa_member_data.data,
});

export default connect(mapStateToProps, {})(RepaymentDefaults);
