import React from "react";
import { connect } from "react-redux";
import { SubDetailView } from "../../../components/HouseMoni/HouseMoniSubComps";
import { Badge } from "../../../components/Widget/Badge/Badge";
import Loader from "../../../components/Widget/Loader/Loader";
import { currencyFormatter } from "../../../utils/helper";

const SubDetailRow = ({ data, index }) => (
  <>
    <div className="flex py-[10px] justify-between items-end self-stretch">
      <div className="flex flex-col items-start gap-[10px] flex-1">
        <span className="text-gray05 capitalize">{Object.keys(data)[0]}</span>
        <span className="text-primary capitalize">{data[Object.keys(data)[0]]}</span>
      </div>
      <div className="flex flex-col items-end gap-[10px] flex-1">
        <span className="text-gray05 capitalize">{Object.keys(data)[1]}</span>
        <span className="text-primary capitalize">{data[Object.keys(data)[1]]}</span>
      </div>
    </div>
    {index != Object.keys(data).length && <hr className="w-full" />}
  </>
);

const SubscriptionDetails = props => {
  const data = props.hpa_member_data?.sub;

  const member_sub = {
    data_one: { "Monthly Contribution": currencyFormatter(data?.monthly_contribution), "Payment Frequency": data?.payment_frequency },
    data_two: { "Monthly Repayment": currencyFormatter(data?.monthly_repayment), "Pre-Eligibility Period": `${parseInt(data?.pre_eligibility_period)} Days` },
    data_three: { "Repayment Tenure": `${parseInt(data?.repayment_tenure)} Days`, "Total Monthly Contributions": currencyFormatter(data?.total_monthly_contributions) },
  };

  const renderItem = ({ content, index, data }) => <SubDetailRow key={index} data={data[content]} index={index} />;

  return (
    <div className="flex py-[17px] px-5 flex-col items-start gap-[10px] self-stretch border rounded-xl border-gray04">
      <SubDetailView data={member_sub} renderItem={renderItem} />
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.hpa.hpa_member_data.loading,
  hpa_member_data: state.hpa.hpa_member_data.data,
  error_msg: state.hpa.hpa_member_data.error_msg,
});
export default connect(mapStateToProps, {})(SubscriptionDetails);
