import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import * as pages from "../../pages";
import HouseMoniWrapper from "../../pages/HPA/HouseMoniWrapper";

const HpaRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<HouseMoniWrapper />}>
        <Route index element={<pages.HouseMoni />} />
        <Route path={`make-payment`} element={<pages.SubscriptionPayment />} />
        <Route path={`group-details`} element={<pages.HouseMoniGroupDetails />} />
        <Route path={`group-activities`} element={<pages.HouseMoniActivities />} />
        <Route path={`group-activities/:id`} element={<pages.HouseMoniMemberInfo />} />
      </Route>
      <Route path="*" element={() => <div>Error Page</div>} />
    </Routes>
  );
};

export default connect(null, {})(HpaRoute);
