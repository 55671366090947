import styled from "styled-components";

export const TransactionDiv = styled.div`
  /* background-color: #f2f2f2; */
  height: 100%;
  padding: 0;
`;

export const StatusRounded = styled.div`
  height: 40px;
  width: 40px;
  @media (max-width: 1024px) {
    height: 30px;
    width: 30px;
  }
`;
export const Narration = styled.div`
  .narration_text {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    .narration_text {
      font-size: 8px;
    }
    .time_text {
      font-size: 8px;
    }
  }
  @media (max-width: 1560px) {
    .narration_text {
      font-size: 12px;
    }
    .time_text {
      font-size: 10px;
    }
  }
`;

export const MoneyValue = styled.div`
  .money_text {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    .money_text {
      font-size: 8px;
    }
  }
  @media (max-width: 1560px) {
    .money_text {
      font-size: 12px;
    }
  }
`;
