import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SelectField, TextField } from "../../../components/Widget/Form/FormComponent";
import { AppButton } from "../../../components/Widget/Button/AppButton";
import { useDispatch, useSelector } from "react-redux";
import { useCreateFixedDepositRequestMutation, useGetUserFixedDepositRequestsQuery } from "../../../services/accounts/fixedDeposit.services";
import toast from "react-hot-toast";
import { showMessage, showModal } from "../../../store/actions/creators/ui.action";

const TimedDepositModal = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.auth.login.data.id);

  const { handleSubmit, register, control, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      user_id: userId,
    },
  });

  const [createFixedDepositRequest, { isLoading, error, isSuccess }] = useCreateFixedDepositRequestMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(showModal("HIDE_MODAL", false));
      dispatch(showMessage("OPEN_MSG_MODAL", { message: "Your request has been sent successfully", msg_type: "success" }));
    }
    if (error) {
      toast.error(error.data?.response_description);
    }
  }, [error, isSuccess]);

  const onSubmit = data => {
    createFixedDepositRequest(data);
  };

  const locked_periods = ["60", "90", "120", "180", "360"];

  return (
    <div className="px-12 pb-14">
      <p className="my-10 text-left">Send us your fixed deposit request and we’ll get back to you with next steps.</p>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-10">
        <div className="grid grid-cols-2 gap-5">
          <TextField label="Deposit Amount" type="currency" control={control} name="depositAmount" {...register("deposit_amount")} />
          <SelectField label="Tenure (in months)" control={control} defaultValue="Locked Period" item={locked_periods} {...register("deposit_tenure")} />
        </div>
        <TextField label="Purpose of Deposit" type="text" mb={"flex-1"} placeholder="Remarks" error={formState.errors.purpose} {...register("purpose", { required: true })} />
        <div className="flex justify-end">
          <div className="flex gap-x-5 items-center">
            <AppButton name="Cancel" type={"button"} onPress={() => dispatch(showModal("HIDE_MODAL", false))} variant={"outline"} className="w-[135px]" />
            <AppButton name="Send Request" type={"submit"} loading={isLoading} disabled={!formState.isDirty || !formState.isValid} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TimedDepositModal;
