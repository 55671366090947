import InvestHistory from "./InvestHistory";
import InvestInfo from "./InvestInfo";
import InvestInterestHistory from "./InterestHistory";
import { Badge } from "../../components/Widget/Badge/Badge";
import ProductInterestHistory from "./ProductInterestHistory";
import { currencyFormatter } from "../../utils/helper";
import TransactionHistory from "./TransactionHistory";

export const processData = (data, index) => ({
  id: data.id,
  // "S/N": index + 1,
  type: data.investment_product.product_name,
  "investment tenor": data.investment_tenor?.tenor_description,
  "interest accured": currencyFormatter(data.accrued_interest),
  status: data.subscription_status,
  // "start date": new Date(data.start_date).toLocaleDateString(),
  "end date": new Date(data.end_date).toLocaleDateString(),
  hidden: data.hidden,
  // "locked until": new Date(data.locked_until).toLocaleDateString(),
});

export const processInterestData = (data, index) => ({
  id: data.id,
  "S/N": index + 1,
  currency_type: data.currency_type,
  interest: data.accrued_interest,
  narration: data.investment_product?.product_name,
  txn_date: data.end_date,
  total_amount: data.total_amount,
  accrued_interest: data.investment_subscription?.accrued_interest,
  subscription_amount: data.investment_subscription?.subscription_amount,
  subscription_status: <Badge content={data.investment_subscription?.subscription_status} width="20%" />,
  status: data.investment_subscription?.subscription_status,
});

export const statementProcessData = currentPage => (data, index) => ({
  "Trans Date": formatDate(new Date(data.txn_date)),
  Reference: data.txn_ref,
  "Debited Account": data.debited_account,
  Debits: data?.transaction_type == "DEBIT" ? data.amount : "",
  Credits: data?.transaction_type != "DEBIT" ? data.amount : "",
  Balance: "",
  "Transaction Status": (
    <Badge
      content={data.transaction_status}
      color={data.transaction_status == "PENDING" ? "bg-yellow-100 text-yellow-500" : data.transaction_status == "COMPLETED" ? "bg-green-100 text-green-500" : "bg-red-100 text-red-800"}
    />
  ),
  Channel: data.channel_id,
  Narration: data.narration,
});

export const tabNames = {
  "Investment Detail": <InvestInfo />,
  // "Investment Transaction History": <InvestHistory />,
  "Interest History": <InvestInterestHistory />,
};

export const interestTabs = currency_type => ({
  "INVESTMENT TRANSACTIONS": <InvestHistory />,
  "INVESTMENT INTEREST HISTORY (NGN)": <ProductInterestHistory currency={currency_type} />,
  "INVESTMENT INTEREST HISTORY (USD)": <ProductInterestHistory currency={currency_type} />,
});

export const statementTabs = currency_type => ({
  "CURRENCY (NGN)": <TransactionHistory currency={currency_type} />,
  "CURRENCY (USD)": <TransactionHistory currency={currency_type} />,
});
