import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import * as pages from "../../pages";

const TransactionRoute = ({}) => {
  return (
    <>
      <Routes>
        <Route exact path={`/`} element={<pages.Transaction />} />
        <Route exact path={`:id`} element={<pages.TransactionDetail />} />
      </Routes>
    </>
  );
};

export default connect(null, {})(TransactionRoute);
