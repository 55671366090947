import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { Add } from "iconsax-react";

export const HeaderView = props => (
  <div className="px-12 pt-4 pb-0">
    <div className="flex justify-between items-center pb-4 border-b border-[#E4E4E7]">
      <h3 className="font-medium text-primary">{props.title}</h3>
      <Add onClick={props.onClose} size="24" color="#666666" className="cursor-pointer -rotate-45" />
    </div>
  </div>
);

const AppModal = props => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog as="div" className={`fixed ${props.z_index || "z-[100]"} inset-0 overflow-y-auto`} initialFocus={cancelButtonRef} onClose={props.onClose}>
        <div className="flex sm:items-end items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`inline-block align-top bg-white min-w-[335px] rounded-lg overflow-hidden shadow-xl transform transition-all pt-5 sm:align-middle ${props.className}`}>
              {!props.no_header && <HeaderView title={props.title} sub_title={props.sub_title} onClose={props.onClose} />}

              {props.content}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const mapStateToProps = state => ({
  modal_data: state.ui.modal.data,
});

export default connect(mapStateToProps, {})(AppModal);
