import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled as styler } from "@mui/material/styles";

export const CustomTabs = styled(Tabs)`
  background: var(--base-50-light, rgba(26, 26, 26, 0.05));
  /* border: ${props => (props.isHPA ? "1.5px solid #000" : "none")}; */
  /* border-bottom: ${props => (!props.isHPA ? "" : "1px solid #000")}; */

  /* border-radius: ${props =>
    props.isHPA ? "10px" : !props.isInterest ? "5px" : null}; */

  & .MuiTabs-indicator {
    background-color: ${props => "transparent"};
  }
`;

export const CustomTab = styler(props => <Tab disableRipple {...props} />)(
  ({ theme, ...props }) => ({
    textTransform: "none",
    padding: 0,
    // fontWeight: `${props.bold ? "bold" : theme.typography.fontWeightRegular}`,
    // fontSize: props.fontSize || theme.typography.pxToRem(15),

    // marginRight: theme.spacing(1),
    // color: `${
    //   props.isHPA || props.isInterest || props.isInvest ? "#BCBCBC" : "#FFF"
    // }`,
    // width: `${
    //   props.isHPA && props.isGroup
    //     ? "35%"
    //     : props.isHPA || props.isInvest
    //     ? "50%"
    //     : ""
    // }`,
    "&.Mui-selected": {
      color: `${props.isHPA ? "#fff" : "#922333"}`,
      backgroundColor: "#F3F4F6",
      borderRadius: `${props.isHPA ? "5px" : props.isInvest ? "" : "3px"}`,
      borderBottom: "0px",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export const TabHead = styled.div`
  background: linear-gradient(136.67deg, #8d2e36 8.34%, #ca3832 95.26%);
  padding: 1em 2em 3em;
  opacity: 1;
`;
