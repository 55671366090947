import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import * as pages from "../../pages";
import ProfileWrapper from "../../pages/Profile/ProfileWrapper";

const ProfileRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ProfileWrapper />}>
          <Route index element={<pages.Profile />} />
          <Route path={`/document`} element={<pages.UserDocument />} />
          <Route path={`/login-history`} element={<pages.LoginHisotry />} />
          <Route path={`/security`} element={<pages.Security />} />
          <Route path={`/transfer-limit`} element={<pages.TransferLimit />} />
          <Route path={`/privacy`} element={<pages.Privacy />} />
        </Route>
      </Routes>
    </>
  );
};

export default connect(null, {})(ProfileRoute);
