import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VERIFY_ACCOUNT } from "../store/actions/action_types/action.types";
import { verifyGDLAccountURL, verifyOtherAccountURL } from "../utils/endpoints";
import { postAction } from "../store/actions/creators/request.action";

export const useHasAccount = () => {
  const user_info = useSelector(state => state.auth.login.data);
  const details = useSelector(state => state.user.user_details.data);

  const has_doc = details?.has_identity_documents ?? user_info.has_identity_documents;
  const has_proof = details?.has_adress_documents ?? user_info.has_adress_documents;
  const has_txn_pin = details?.has_txn_pin ?? user_info?.has_txn_pin;

  const is_valid_user = has_doc && has_proof && has_txn_pin;

  return { has_doc, has_proof, has_txn_pin, is_valid_user };
};

/** hook to verify account */
export const useVerifyAccount = (watch_acct_num, watch_bank_code, is_gdl_account, getValues) => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.login.token);

  /** watch for the account number field and bank field...call verification api when account number length is up to 10 */
  useEffect(() => {
    if (!watch_acct_num || !watch_bank_code) {
      return;
    }

    if (is_gdl_account) {
      verifyGDLAccount(watch_acct_num);
    } else {
      verifyOtherAccount(watch_bank_code, watch_acct_num);
    }
  }, [watch_acct_num, watch_bank_code]);

  /** function to verify and get account details of a gdl account */
  const verifyGDLAccount = account_number => {
    if (account_number?.length == 10) {
      const data = { account_number };

      // call api to verify account number
      dispatch(postAction(VERIFY_ACCOUNT, verifyGDLAccountURL, data, token));
    }
  };

  /** function to verify and get account details of other account */
  const verifyOtherAccount = (bank_code, account_number) => {
    if (account_number?.length == 10 && bank_code) {
      const bank_code = getValues("bank_code").value; // get bank code
      const data = { bank_code, account_number };

      // call api to verify account number and bank
      dispatch(postAction(VERIFY_ACCOUNT, verifyOtherAccountURL, data, token));
    }
  };
};
