import React from "react";
import { currencyFormatter, formatDate, formatTimeToAmPm } from "../../utils/helper";

export const ReceiptTransactionUnit = ({ show_sub_value, title, value, sub_value }) => {
  return (
    <div className="w-full border-b flex justify-between py-3">
      <span className="text-sm">{title}</span>
      <div>
        <span className="text-primary font-medium">{value}</span>
        {show_sub_value && <span className="text-xs">{sub_value}</span>}
      </div>
    </div>
  );
};

const Receipt = React.forwardRef(({ transaction }, ref) => {
  return (
    <div className="flex flex-col items-center w-full py-20 px-10" style={{ display: "none" }} ref={ref}>
      <div className="w-full flex justify-center">
        <img src="/logo.png" alt="logo" className="w-[34px] h-12 mb-3" />
      </div>
      <div className="flex flex-col items-center">
        <h4 className="text-base font-semibold text-primary">Transaction Receipt</h4>
        <p className="text-sm">Generated from GDL Plus</p>
        <div className="flex flex-col w-full mb-5">
          <ReceiptTransactionUnit title="Amount" value={currencyFormatter(transaction.amount)} />
          <ReceiptTransactionUnit title="Transaction Fee" value={transaction.fee} />
          <ReceiptTransactionUnit title="Date/Time" value={formatDate(transaction.txn_date, "DD MMM YYYY") + " / " + formatTimeToAmPm(transaction.txn_date)} />
          <ReceiptTransactionUnit title="Narration" value={transaction.narration} />
          <ReceiptTransactionUnit title="Reference" value={transaction.txn_ref} />
          <ReceiptTransactionUnit title="Transaction type" value={transaction.transaction_type} />
          <ReceiptTransactionUnit title="Transaction Status" value={transaction.transaction_status} />
        </div>
        <div className="flex flex-col gap-1">
          <h5 className="text-[10px]">DISCLAIMER</h5>
          <p className="text-[10px] text-left">
            Your transaction has been successful and the beneficiary’s account will be credited. However, this does not serve as confirmation of credit into the beneficiary’s account. Due to the
            nature of the internet, transactions may be subject to interruption, transmission blackout, delayed transmission, and incorrect data transmission. GDL Plus is not liable for malfunctions
            in communications facilities not within its control that may affect the accuracy or timeliness of messages and transactions you send. All transactions are subject to verification and our
            normal fraud checks.
          </p>
        </div>
      </div>
    </div>
  );
});

export default Receipt;
