import { InfoCircle } from "iconsax-react";
import { connect } from "react-redux";
import { AppButton } from "../Button/AppButton";
import { showModal } from "../../../store/actions/creators/ui.action";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { headerResolver } from "../../../utils/helper";
import { useHasAccount } from "../../../hooks/account.hook";

const SectionTask = ({ title, index, done }) => (
  <div className={`flex items-center gap-[8px] ${done ? "text-[#8C8C8C]" : "text-[#FF9C33]"}`}>
    <div
      className={`flex sm:w-[25px] sm:h-[25px] py-[9px] px-3 border ${done ? "border-[#8C8C8C] bg-[#F1F1F1]" : "border-[#FF9C33] bg-[#FEF7EF]"} rounded-full justify-center items-center text-[12px]`}
    >
      {index}
    </div>
    <span className="text-[14px] text-center">{title}</span>
  </div>
);

const AlertMessageBox = ({ user_info, ...props }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { has_doc, has_proof, has_txn_pin } = useHasAccount();

  const item = [
    { title: "Verify your identity", done: has_doc && has_proof, url: "/profile/document" },
    { title: "Secure your account", done: has_txn_pin, url: "/profile/security" },
  ];
  const [url, setUrl] = useState(item[0].url);

  useEffect(() => {
    if (item[0].done) {
      setUrl(item[1].url);
    }
  }, [user_info]);

  const handleNavigation = () => {
    navigate(url);
    props.showModal("CLOSE_ALERT", false);
  };

  const handleClose = () => {
    const resolver = headerResolver(pathname);
    if (resolver.path == "withdrawal") {
      navigate("/");
    }
    props.showModal("CLOSE_ALERT", false);
  };
  return (
    <div className="py-[20px] px-[30px] pt-[10px]">
      <div className="flex flex-col items-center gap-10">
        <div className="flex flex-col items-center gap-5 self-stretch">
          <InfoCircle size={54} variant="Bold" color="#9A2333" />
          <div className="flex flex-col items-center gap-2.5 self-stretch">
            <span className="text-[#1F2734] font-bold">Attention!</span>
            <span className="text-[14px] text-[#1F2734]">To have unlimited access to your account, please setup your account in just 2 steps:</span>
            <div className="flex flex-col justify-center items-start gap-[8px] mt-3">
              {item.map((content, index) => (
                <React.Fragment key={index}>
                  <SectionTask {...content} index={index + 1} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-start justify-between gap-5 self-stretch pt-3">
          <AppButton name="Not Now" variant="outline" className="flex-1 py-[9px] sm:h-[50px]" onPress={handleClose} />
          <AppButton name="Setup" className="flex-1 py-[9px] sm:h-[50px]" onPress={handleNavigation} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: "",
  user_info: state.auth.login.data,
  details: state.user.user_details.data,
});

export default connect(mapStateToProps, { showModal })(AlertMessageBox);
