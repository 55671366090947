import { Bank, Card } from "iconsax-react";

const extraData = [
  {
    url: "naira-bank-transfer",
    icon: <Bank size={24} color="#9A2333" variant="Bold" />,
  },
  {
    url: "card-payment",
    icon: <Card size={24} color="#9A2333" variant="Bold" />,
  },
  { url: "", icon: <Card size={24} color="#9A2333" variant="Bold" /> },
];
export const processData = (data, index) => ({
  id: data.id,
  name: data.option_name,
  fee: data.fee,
  timeline: data.processing_timeline,
  url: "card-payment",
  ...extraData[index],
});

export const process_virtual_account = data => ({
  Bank: data?.bank_name,
  "Account Number": data?.account_number,
  "Account Name": data.account_name,
});
