import React from "react";
import ProfileWrapper from "./ProfileWrapper";

const Privacy = () => {
  return (
    <div className=" px-12 ">
      <div className="flex flex-col gap-2 py-5 border-b border-[#E4E4E7]">
        <h3 className="text-[#1F2734] font-medium">Basic Information</h3>
        <small className="text-[#666666]">Last Updated: Jul 9, 2021</small>
      </div>
      <div className="pt-4">
        <p className="py-5 border-b border-gray02">
          Your privacy is important to us. We respect your privacy and are committed to protecting your personal information. This Privacy Statement clarifies how we collect, transfer, process, use
          and disclose your data and sets out GDL security practices. By providing us with your personal information, you agree and consent to its transfer, processing, use and disclosure as outlined
          in this Privacy Statement.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
