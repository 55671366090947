import { Badge } from "../../../components/Widget/Badge/Badge";
import { formatDate } from "../../../utils/helper";
import PaymentHistory from "./PaymentHistory";
import RepaymentHistory from "./RepaymentHistory";
import PaymentDefaults from "./PaymentDefaults";
import RepaymentDefaults from "./RepaymentDefaults";
import SubscriptionDetails from "./SubscriptionDetails";

export const statementProcessData = currentPage => (data, index) => ({
  "Trans Date": formatDate(new Date(data.createdAt)),
  Reference: data.payment_reference,
  "Amount Initiated": data.amount_initiated,
  "Amount Paid": data?.amount_paid,
  "Transaction Status": (
    <Badge
      content={data.payment_status}
      case={"uppercase"}
      color={data.payment_status == "PENDING" ? "bg-yellow-100 text-yellow-500" : data.payment_status == "PAID" ? "bg-green-100 text-green-500" : "bg-red-100 text-red-800"}
    />
  ),
});

export const tabs = {
  "Subscription Details": <SubscriptionDetails />,
  "Payment History": <PaymentHistory />,
  "Repayment History": <RepaymentHistory />,
  "Payment Defaults": <PaymentDefaults />,
  "Repayment Defaults": <RepaymentDefaults />,
};
