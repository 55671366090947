import React from "react";
import { connect } from "react-redux";
import { AppButton } from "../Widget/Button/AppButton";
import { showContent, showNextForm } from "../../store/actions/creators/ui.action";

const Content = ({ title, desc }) => (
  <>
    <div className="flex py-5 flex-col items-start gap-[10px] self-stretch">
      <div className="flex flex-col items-start gap-[10px] flex-1 text-left">
        <span className="text-primary font-medium">{title}</span>
        <span className="text-gray01">{desc}</span>
      </div>
    </div>
    <hr className="w-full" />
  </>
);

export const HouseMoniClassicRules = props => (
  <div className="flex flex-col px-12 py-10 items-start gap-[60px] self-stretch">
    <div className="flex flex-col items-end self-stretch">
      <Content
        title="Overview"
        desc="The HouseMoni Community is another rent-to-own home ownership product designed to empower you as an account holder to acquire constructed homes at a single-digit cost of funds through a
        community acquisition strategy. The objective of this strategy is to enable you alongside other members to construct a home in a community (HouseMoni Community - HMC) of 10 – 25 friends and
        partners operating as a mini-cooperative at the end of the Pre-eligibility period."
      />
      <Content
        title="How To Qualify"
        desc="You become eligible to move into your home as a HouseMoni Community Account (HMC) Customer after contributing for 60 months in your pre-eligibility period. During this period, you would be required to pay a fixed amount monthly in the HouseMoni Community (HMC). The amount is determined by the value of the house you intend to purchase."
      />

      <Content
        title="Forming Home Acquisition Communities"
        desc="If you decide to be part of the HouseMoni Community, you will be required to either form a community of 10 – 25 friends and partners or join an existing community. If you create or join a group, you and your group members would have 12 months to get the number of members of your group to be up to 10 or more members. The members of your community must be known to each other and must be in similar contribution categories. I.e, If your monthly contribution is N 30,000 monthly, the members of your community would have to also be contributing the same amount towards the acquisition of their own homes."
      />
    </div>
    <div className="flex justify-end self-stretch gap-5">
      <AppButton name="I don't Agree" variant="outline" className="md:w-[138px]" onPress={() => props.setOpen(false)} />
      <AppButton
        name="I Agree"
        className="md:w-[138px]"
        onPress={() => {
          props.onSelect("HPA");
        }}
      />
    </div>
  </div>
);

export const HouseMoniCommunityRules = props => (
  <div className="flex flex-col px-12 py-10 items-start gap-[60px] self-stretch">
    <div className="flex flex-col items-end self-stretch">
      <Content
        title="Overview"
        desc="The HouseMoni Community is another rent-to-own home ownership product designed to empower you as an account holder to acquire constructed homes at a single-digit cost of funds through a
        community acquisition strategy. The objective of this strategy is to enable you alongside other members to construct a home in a community (HouseMoni Community - HMC) of 10 – 25 friends and
        partners operating as a mini-cooperative at the end of the Pre-eligibility period."
      />
      <Content
        title="How To Qualify"
        desc="You become eligible to move into your home as a HouseMoni Community Account (HMC) Customer after contributing for 60 months in your pre-eligibility period. During this period, you would be required to pay a fixed amount monthly in the HouseMoni Community (HMC). The amount is determined by the value of the house you intend to purchase."
      />

      <Content
        title="Forming Home Acquisition Communities"
        desc="If you decide to be part of the HouseMoni Community, you will be required to either form a community of 10 – 25 friends and partners or join an existing community. If you create or join a group, you and your group members would have 12 months to get the number of members of your group to be up to 10 or more members. The members of your community must be known to each other and must be in similar contribution categories. I.e, If your monthly contribution is N 30,000 monthly, the members of your community would have to also be contributing the same amount towards the acquisition of their own homes."
      />
    </div>
    <div className="flex justify-end self-stretch gap-5">
      <AppButton name="I don't Agree" variant="outline" className="md:w-[138px]" onPress={() => props.setOpen(false)} />
      <AppButton
        name="I Agree"
        className="md:w-[138px]"
        onPress={() => {
          props.onSelect("HAA");
        }}
      />
    </div>
  </div>
);
