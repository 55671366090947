import React from "react";
import { OpButton } from "./optionbutton.style";

export const OptionButton = ({ onPress, className, ...props }) => (
  // <OpButton {...props} className={className} onClick={onPress}>
  //   {props.icon && props.icon}
  //   <small className="ml-1 md:block hidden">{props.name}</small>
  // </OpButton>
  <button
    onClick={props.onPress}
    className="flex items-center gap-2 px-4 py-1 text-primary border border-gray02 rounded-lg"
  >
    {props.icon && props.icon}
    <small>{props.name}</small>
  </button>
);

// export const FilterButton = ({onPress, className, ...props}) =>(
//   <OpButton {...props} className={className} onClick={onPress}>
//     {props.icon && props}
//   </OpButton>
// )
