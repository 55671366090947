import React from "react";
import { Badge } from "../Widget/Badge/Badge";
import { currencyFormatter, formatDate, formatTimeToAmPm } from "../../utils/helper";
import { useNavigate } from "react-router-dom";

const SavingsCard = ({ ...props }) => {
  // console.log(props);
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(`${props.id}`)} className="border cursor-pointer border-gray02 rounded-xl px-4 py-3">
      <div className="border-b border-gray02 py-2">
        <div className="flex gap-1">
          <div className="w-11 h-11 rounded-full bg-[#FFF4F6] flex justify-center items-center">
            <img src={"/safe-green.svg"} className="w-8 h-7" />
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-primary uppercase font-medium">{props.savings_purpose}</h3>
            <p>
              Date: <span>{`${formatDate(props.start_date, "DD MMM YYYY")} / ${formatTimeToAmPm(props.start_date)}`}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-2 gap-1">
        <div className="flex justify-between py-2">
          <div className="flex flex-col gap-2">
            <span>Amount Saved</span>
            <span className="text-primary">{currencyFormatter(props.total_amount_saved || props.total_balance)}</span>
          </div>
          <div className="flex flex-col gap-2">
            <span>Interest Rate</span>
            <Badge content={`${props.savings_rate?.annual_interest_rate || props.investment_tenor.interest_rate}% /year`} />
          </div>
        </div>
        <div className="flex justify-between py-2">
          <div className="flex flex-col gap-2">
            <span>Amount To Be Released</span>
            <span className="text-brand01">{currencyFormatter(props.target_amount || props.subscription_amount)}</span>
          </div>
          <div className="flex flex-col items-end gap-2">
            <span>Status</span>
            <Badge
              case={"capitalize"}
              className={`${props.status == "PENDING" ? "bg-[#FEF7EF] text-[#FF9C33]" : props.status == "ENDED" ? "bg-brand02 text-brand01" : ""}`}
              content={props.status || props.subscription_status}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavingsCard;
