import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  data: [],
  total_bal: 0,
  bvn: null,
  success: false,
  success_msg: null,
  sending: false,
  error_msg: null,
  payment_options: null,
  rates: [],
  beneficiary: null,
  fund_data: null,
};

const getWalletsReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_WALLETS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_WALLETS:
      return {
        ...state,
        data: action.payload.data.data.wallets,
        total_bal: action.payload.data.data.total_portfolio,
        bvn: action.payload.data.data.bvn,
        loading: false,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

// this is actually using a post action but to get funding type
const getFundingType = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.GET_FUND_TYPE:
      return {
        ...state,
        sending: true,
      };
    case type.GET_FUND_TYPE:
      // alert(JSON.stringify(action.payload.data.data));
      return {
        ...state,
        sending: false,
        error: false,
        payment_options: action.payload.data.data.payment_options,
        exchange_rates: action.payload.data.data.exchange_rates,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getVirtualAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_VIRTUAL_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case type.GET_VIRTUAL_ACCOUNT:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getNubanAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_NUBAN_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case type.GET_NUBAN_ACCOUNT:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getBanksReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_BANKS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_BANKS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const withdrawReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.WITHDRAW:
      return {
        ...state,
        loading: true,
      };
    case type.WITHDRAW:
      return {
        ...state,
        loading: false,
        error: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    case "CLOSE_MSG_MODAL":
      return {
        error: false,
        error_msg: null,
      };
    default:
      return state;
  }
};

const bvnSetupReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.VALIDATE_BVN:
      return {
        ...state,
        loading: true,
        error_msg: null,
        error: false,
      };
    case type.VALIDATE_BVN:
      return {
        ...state,
        loading: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        message: null,
        error: true,
        error_msg: action.payload,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        loading: false,
        error: false,
        data: [],
        success: false,
        error_msg: null,
      };
    default:
      return state;
  }
};

const getGenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.GET_GENDER:
      return {
        ...state,
        loading: false,
      };
    case type.GET_GENDER:
      return {
        ...state,
        loading: false,
        error: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getMaritalStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.GET_MARITAL_STATUS:
      return {
        ...state,
        loading: false,
      };
    case type.GET_MARITAL_STATUS:
      return {
        ...state,
        loading: false,
        error: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const fundCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.FUND_CARD:
      return {
        ...state,
        loading: true,
      };
    case type.FUND_CARD:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        fund_data: action.payload.data.data,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        error: false,
        error_msg: null,
        success: false,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const fundNubanReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.FUND_WITH_NUBAN:
      return {
        ...state,
        loading: true,
      };
    case type.FUND_WITH_NUBAN:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        fund_data: action.payload.data.data,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        error: false,
        error_msg: null,
        success: false,
        fund_data: null,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getExchangeRateReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_EXCHANGE_RATES:
      return {
        ...state,
        loading: true,
      };
    case type.GET_EXCHANGE_RATES:
      return {
        ...state,
        laoding: false,
        error: false,
        rates: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getBeneficiaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_BENEFICIARY:
      return {
        ...state,
        loading: true,
      };
    case type.GET_BENEFICIARY:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getBeneficiaryIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_BENEFICIARY_ID:
      return {
        ...state,
        loading: false,
      };
    case type.GET_BENEFICIARY_ID:
      return {
        ...state,
        loading: false,
        error: false,
        beneficiary: action.payload.data.data,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        beneficiary: null,
        error: false,
        error_msg: null,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const deleteBeneficiaryIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.DELETING + "_" + type.DELETE_BENEFICIARY:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_BENEFICIARY:
      return {
        ...state,
        loading: false,
        error: false,
        success: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        error: false,
        error_msg: null,
        success: false,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const accountReducer = combineReducers({
  get_wallets: getWalletsReducer,
  fund_type: getFundingType,
  banks: getBanksReducer,
  withdraw: withdrawReducer,
  virtual_acct: getVirtualAccountReducer,
  nuban_acct: getNubanAccountReducer,
  card: fundCardReducer,
  fund_nuban: fundNubanReducer,
  bvn_validate: bvnSetupReducer,
  gender_types: getGenderReducer,
  marital_statuses: getMaritalStatusReducer,
  exchange_rates: getExchangeRateReducer,
  get_beneficiaries: getBeneficiaryReducer,
  get_beneficiary_id: getBeneficiaryIdReducer,
  delete_beneficiary: deleteBeneficiaryIdReducer,
});

export default accountReducer;
