import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AppButton } from "../Widget/Button/AppButton";
import { editAction } from "../../store/actions/creators/request.action";
import { MORE_INFO } from "../../store/actions/action_types/action.types";
import { signupURL } from "../../utils/endpoints";
import { AuthHeader } from "../Layout/AuthLayout";
import { useForm } from "react-hook-form";
import { Checkbox } from "../Widget/Form/FormComponent";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { showMessage, showNextForm } from "../../store/actions/creators/ui.action";
import { current_login_err_msg } from "../../utils/helper";

const OfferingsTwo = ({ product, loading, session_token, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { handleSubmit, watch, control, register, getValues } = useForm({
    mode: "onChange",
  });
  const [active, setActive] = useState(false);
  const watchCheckbox = watch();

  useEffect(() => {
    if (props.error_msg) props.showMessage("OPEN_MSG_MODAL", { message: props.error_msg });
  }, [props.error_msg]);

  useEffect(() => {
    if (session_token) props.showMessage("OPEN_MSG_MODAL", { message: "User Successfully Registered", msg_type: "success", redirect: () => navigate("/auth/pin-setup", { state: { session_token } }) });
  }, [session_token]);

  const onSubmit = data => {
    const options = Object.keys(data).filter(content => data[content]);
    const full_data = {
      ...props.next_form_data,
      other_interested_products: JSON.stringify(options),
    };

    delete full_data.step;

    // props.editAction(MORE_INFO, `${signupURL}/2`, full_data);
    props.showMessage("OPEN_MSG_MODAL", { message: current_login_err_msg });
  };

  useEffect(() => {
    if (Object.values(watchCheckbox).includes(true)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [watchCheckbox]);

  const handlePrev = () => {
    const data = getValues();
    props.showNextForm(true, { ...props.next_form_data, ...data, step: 0 });
  };

  return (
    <div className="py-8 xl:py-10 px-8 xl:px-14 bg-white shadow-[0_15px_30px_0px_rgba(0,0,0,0.05)] w-full gap-10 flex flex-col justify-center border-[0.5px] border-[#E4E4E7] rounded-lg">
      <AuthHeader name="Other Reasons For Using GDL Plus" description="Select all products you are also interested in." />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-start gap-5">
          {product.map((content, index) => (
            <div key={content.id} className="min-h-[60px] px-5 border border-gray04 rounded-lg flex flex-col justify-center items-center self-stretch">
              <Checkbox
                control={control}
                label={content.product_name}
                className="w-full flex-row-reverse justify-between py-[10px] font-medium"
                {...register(content.product_name.replace(/'/g, ""), {
                  required: false,
                })}
              />
            </div>
          ))}
        </div>
        <footer className="flex mt-6 xl:mt-14 gap-5">
          <AppButton name="Done" className="w-[138px]" disabled={!active} loading={props.load_more_info} />
          <AppButton name="Previous" type={"button"} variant="outline" className="w-[138px]" onPress={handlePrev} /> {/** come back to this */}
        </footer>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  token: "",
  product: state.register.get_offerings.offerings,
  loading: state.register.get_offerings.loading,
  user_info: state.auth.login.data,
  session_token: state.register.more_info.access_token,
  next_form_data: state.ui.show_next_form.form_data,
  load_more_info: state.register.more_info.loading,
  error_msg: state.register.more_info.error_msg,
});

export default connect(mapStateToProps, { editAction, showMessage, showNextForm })(OfferingsTwo);
