import { EyeSlash, Eye, Refresh, More, ImportCurve, Send2 } from "iconsax-react";
import React, { useState } from "react";
import { currencyFormatter, formatDate } from "../../utils/helper";
import { Menu } from "@headlessui/react";

const TimedDepositCard = ({ amount, deposit_status, tenor_days, maturation_date }) => {
  const [visible, setVisible] = useState(true);
  return (
    <div className="w-full bg-gray06 rounded-lg py-4 px-5 flex flex-col gap-y-[10px]">
      <div className="flex justify-between">
        <div className="flex flex-col gap-1">
          <div className="flex gap-x-2 items-center">
            <span className="text-xs font-medium">Deposit Balance</span>
            <span onClick={() => setVisible(!visible)}>{visible ? <Eye size={18} color="#1F2734" variant="Outline" /> : <EyeSlash size={18} color="#1F2734" variant="Outline" />}</span>
          </div>
          <span className="font-semibold text-primary">{visible ? (true ? currencyFormatter(amount) : "#1,000") : "*".repeat(10)}</span>
        </div>
        <div className="flex flex-col text-xs items-end gap-1">
          <span className={`px-3 ${deposit_status == "Active" ? "bg-green02 text-green01" : "bg-brand02 text-brand01"} `}>{deposit_status}</span>
          <span>
            <span>Tenure: </span>
            <span className="text-primary">{tenor_days} days</span>
          </span>
          <span>
            <span>Maturation: </span>
            <span className="text-primary">{formatDate(maturation_date, "DD MMM YYYY")}</span>
          </span>
        </div>
      </div>
      <div className="flex items-center gap-5">
        <button className="flex gap-2 text-primary items-center py-2 px-5 bg-white border rounded-lg">
          <Refresh size={18} color="#1F2734" variant="Outline" />
          <span className="text-sm font-medium">Request Rollover</span>
        </button>
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="py-2 px-5 rounded-lg bg-white border">
            <More size={18} color="#1F2734" variant="Outline" />
          </Menu.Button>
          <Menu.Items className="absolute bottom-8 left-0 w-56 mt-2 origin-top-right bg-white rounded-[10px] shadow-lg">
            <div className="px-1 py-1 ">
              {deposit_status == "Closed" && (
                <Menu.Item>
                  {({ active }) => (
                    <button className={`${active ? "bg-gray05" : ""} group flex gap-2 w-full items-center rounded-md px-2 text-primary py-2 text-sm`}>
                      <Send2 size={14} color="#1F2734" variant="Outline" />
                      <span>Request Withdrawal</span>
                    </button>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <button className={`${active ? "bg-gray05" : ""} group flex w-full gap-2 items-center rounded-md px-2 text-primary py-2 text-sm`}>
                    <ImportCurve size={14} color="#1F2734" variant="Outline" />
                    <span>Download investment letter</span>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  );
};

export default TimedDepositCard;
