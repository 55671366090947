/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { connect } from "react-redux";
import { ArrowDown2, Profile } from "iconsax-react";
import { clsx } from "clsx";

export const TopbarDropDiv = ({ details, user_info }) => (
  <>
    <div className="w-[28px] h-[28px] xl:w-[45px] xl:h-[45px] rounded-full bg-gray-100 flex justify-center items-center">
      {details?.user_avatar_url ?? user_info?.user_avatar_url ? (
        <img src={details?.user_avatar_url ?? user_info?.user_avatar_url} alt="" className="w-[28px] h-[28px] xl:w-[45px] xl:h-[45px] rounded-full object-cover" />
      ) : (
        <Profile variant="Bulk" size={30} />
      )}
    </div>
    <h3 className="md:block text-base hidden">{details?.first_name ?? user_info?.first_name}</h3>
    <ArrowDown2 size={18} />
  </>
);

const Dropdown = ({ children, className, content }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center gap-[10px] rounded-[15px]">{children}</Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={clsx("absolute mt-2 w-40 right-0 z-10 rounded-md shadow-lg bg-white divide-y divide-gray-100 focus:outline-none", className)}>{content}</Menu.Items>
      </Transition>
    </Menu>
  );
};

const mapStateToProps = state => ({
  details: state.user.user_details.data,
});

export default connect(mapStateToProps, {})(Dropdown);
