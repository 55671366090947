import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";
import { data } from "autoprefixer";

const initialState = {
  loading: false,
  error: false,
  data: null,
  total_bal: 0,
  success: false,
  success_msg: null,
  sending: false,
  error_msg: null,
};
const verifyAccount = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.VERIFY_ACCOUNT:
      return {
        ...state,
        sending: true,
      };
    case type.VERIFY_ACCOUNT:
      return {
        ...state,
        sending: false,
        error: false,
        success_msg: action.payload.data.success,
        data: action.payload.data.data,
      };
    case "SET_BENEFICIARY":
      return {
        ...state,
        data: action.payload,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        error: false,
        error_msg: null,
        success_msg: null,
        sending: false,
        data: null,
      };
    default:
      return state;
  }
};

const withdrawSavings = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.WITHDRAW_SAVINGS:
      return {
        ...state,
        sending: true,
      };
    case type.WITHDRAW_SAVINGS:
      return {
        ...state,
        sending: false,
        error: false,
        success_msg: action.payload.data.success,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    case "CLOSE_MSG_MODAL":
      return {
        error: false,
        error_msg: null,
        success_msg: null,
      };
    default:
      return state;
  }
};
const withdrawInvestment = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.WITHDRAW_INVESTMENT:
      return {
        ...state,
        sending: true,
      };
    case type.WITHDRAW_INVESTMENT:
      return {
        ...state,
        sending: false,
        error: false,
        success_msg: action.payload.data.success,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    case "CLOSE_MSG_MODAL":
      return {
        error: false,
        error_msg: null,
        success_msg: null,
      };
    default:
      return state;
  }
};

const feeReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.TRANSFER_FEE:
      return {
        ...state,
        sending: true,
      };
    case type.TRANSFER_FEE:
      return {
        ...state,
        sending: false,
        error: false,
        success_msg: action.payload.data.success,
        data: action.payload.data.data,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        sending: false,
        error: false,
        success_msg: null,
        data: null,
        success: false,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sending: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const withdrawalReducer = combineReducers({
  verify_account: verifyAccount,
  withdraw_investment: withdrawInvestment,
  withdraw_savings: withdrawSavings,
  fee: feeReducer,
});

export default withdrawalReducer;
