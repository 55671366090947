import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ArrowDown2, InfoCircle } from "iconsax-react";

const AppAccordion = props => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [read, setRead] = useState(null);

  useEffect(() => {
    if (expanded != "panel1") {
      setRead(expanded);
    }
  }, [expanded]);

  useEffect(() => {
    if (expanded == read && expanded) {
      //call endpoint
    }
  }, [read]);

  return (
    <Disclosure defaultOpen={props.defaultOpen}>
      {({ open }) => (
        <div className={`w-full ${props.detail_viewed === true || open ? "bg-white" : "bg-[#F9FAFB]"}`}>
          <Disclosure.Button
            onClick={() => props.onPress && props.onPress(props.id, props.detail_viewed)}
            className={`flex w-full justify-between pt-5 items-start ${props.hideDate ? "px-0" : "px-[30px]"}`}
          >
            <div className="flex flex-1 items-start gap-[12px]">
              {!props.hideDate && (
                <div className="flex items-center gap-[5px] justify-end">
                  <span className={` 2xl:w-[8px] 2xl:h-[8px] rounded-full ${props.detail_viewed == true ? "" : "bg-brand01"}`} />
                  <div className="flex w-[42px] py-[9px] px-[12px] justify-center items-center rounded-full bg-brand02 text-brand01">
                    <InfoCircle size={24} />
                  </div>
                </div>
              )}
              <div className="flex flex-col items-start gap-2.5 flex-1">
                <span className={`text-[15px] ${props.hideDate ? "text-[#8C8C8C]" : "text-primary font-medium"}`}>{props.title}</span>
                <span className="text-primary tex-[14px] font-normal">{props.description}</span>
              </div>
            </div>
            <div className={`flex flex-1 justify-end items-center gap-[5px] ${props.hideDate ? "text-[#666666]" : "text-brand01"}`}>
              {!props.hideDate && <span className="text-sm font-medium">{open ? "Show Less" : "Show More"}</span>}
              <ArrowDown2 size={20} className={`${open ? "rotate-180 transform" : ""}`} />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel className={props.accordionItemList ? "" : "2xl:px-[30px] xl:px-[40px] w-[90%] mx-auto py-5"}>
            {props.message ? props.message : props.accordionItemList ? <>{props.accordionItemList}</> : null}
          </Disclosure.Panel>
          {!props.hideDate && (
            <div className="2xl:px-[30px] xl:px-[40px] w-[90%] mx-auto">
              <span className="text-[#8C8C8C] text-[13px]">{new Date(props.created_at).toLocaleTimeString()}</span>
            </div>
          )}

          {!props.hideDate && <hr className="mx-auto w-[95%]" />}
        </div>
      )}
    </Disclosure>
  );
};

export default AppAccordion;
