import clsx from "clsx";
import React from "react";

export const SkeletonWallet = props => (
  <div className="border shadow-sm flex flex-col md:h-[154px] h-[12.5em] justify-center rounded-2xl p-4 md:max-w-sm max-w-[22rem] w-full md:mx-2 mx-auto relative z-0">
    <div className="animate-pulse flex space-x-4">
      <div className="rounded-full bg-gray-300 h-10 w-10"></div>
      <div className="flex-1 space-y-6 py-1">
        <div className="h-2 bg-gray-300 rounded"></div>
        <div className="space-y-3">
          <div className="grid grid-cols-3 gap-4">
            <div className="h-2 bg-gray-300 rounded col-span-2"></div>
            <div className="h-2 bg-gray-300 rounded col-span-1"></div>
          </div>
          <div className="h-2 bg-gray-300 rounded"></div>
        </div>
      </div>
    </div>
  </div>
);

export const SkeletonTransaction = props => (
  <div className="flex flex-col p-0 w-full">
    <div className="animate-pulse flex flex-col gap-[20px]">
      {Array(6)
        .fill()
        .map((content, index) => (
          <div key={index + 1} className="flex items-center justify-between">
            <div className="flex items-start justify-start w-full gap-2">
              <div className="rounded-full">
                <div className="rounded-full bg-gray-300 2xl:w-11 xl:w-9 2xl:h-11 xl:h-9"></div>
              </div>
              <div className="flex justify-between flex-1 mt-1 items-start">
                <div className="flex flex-col">
                  <div className="h-4 w-40 bg-gray-300 rounded"></div>
                  <div className="h-2 mt-1 w-20 bg-gray-200 rounded"></div>
                </div>

                <div className="h-3 bg-gray-300 flex items-start w-20" />
              </div>
            </div>
            {/* <div>
              <div class="mt-1 h-2 w-20 bg-gray-200 rounded"></div>
            </div> */}
          </div>
        ))}
    </div>
  </div>
);

export const GroupSkeleton = () => (
  <div className="md:my-7 md:mx-12 flex flex-col items-start gap-8 self-stretch">
    <div className="flex flex-col items-start self-stretch gap-11 md:my-7 animate-pulse">
      <div className="bg-gray-300 h-10 w-full rounded-lg" />

      {Array(6)
        .fill()
        .map((content, index) => (
          <div key={index} className={`flex justify-between items-start self-stretch transition-all ease-in-out`}>
            <div className="flex items-center gap-[12px]">
              <div className="flex w-[44px] h-[44px] p-[10px] justify-center items-center rounded-full bg-gray-300"></div>
              <div className="flex flex-col items-start gap-[10px]">
                <span className="text-primary font-medium bg-gray-300 h-3 w-48"></span>
                <div className="flex items-start gap-[5px] text-[14px] bg-gray-300 h-2 w-80">
                  <span className="text-gray01"></span>
                  <span className="text-primary"></span>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
);

export const ListCardSkeleton = () => (
  <div className={`flex justify-between py-4 items-center animate-pulse border-b border-[#E4E4E7]`}>
    <div className={`flex flex-col gap-y-5`}>
      <div className={`bg-gray-300 h-4 w-20 rounded`} />
      <div className={`bg-gray-200 h-3 w-40`} />
    </div>
    <div className={`w-28 h-4 bg-gray-300`} />
  </div>
);

export const BeneficiarySkeleton = () => (
  <div className="animate-pulse flex flex-col gap-[30px]">
    <div className="bg-gray-300 h-10 w-full rounded-lg" />
    <div>
      {Array(6)
        .fill()
        .map((content, index) => (
          <div key={index} className="flex justify-between p-[20px_0px] self-stretch items-center">
            <div className="flex gap-[12px] items-center cursor-pointer flex-1">
              <div className={`md:w-[42px] bg-gray-300 flex justify-center items-center md:h-[42px] rounded-full p-[9px_12px]`} />
              <div className="flex flex-col gap-[10px] items-start">
                <div className="bg-gray-300 h-4 w-40 rounded" />
                <div className="bg-gray-300 h-3 w-80 rounded" />
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
);

export const HouseMoniSkeleton = () => (
  <div className="animate-pulse w-full flex flex-col sm:col-span-3 col-span-4 p-5 items-start gap-10 rounded-lg bg-white md:max-h-auto">
    <div className="flex flex-col items-start gap-[20px] w-full">
      <div className="bg-gray-300 h-4 w-40" />
      <div className="bg-gray-300 h-3 w-full" />
    </div>
    {Array(2)
      .fill()
      .map((content, index) => (
        <React.Fragment key={index}>
          <div className="flex flex-col justify-center items-center gap-5 self-stretch rounded-2xl">
            <div className="flex w-[42px] h-[42px] py-[9px] px-[12px] flex-col justify-center items-center rounded-xl bg-gray-300" />
            <div className="bg-gray-300 h-4 w-3/6" />
            <div className="bg-gray-300 w-full h-3" />
            <div className="bg-gray-300 h-3 w-4/6" />
          </div>
          {index == 0 && <hr className={`w-full bg-gray04`} />}
        </React.Fragment>
      ))}
  </div>
);

export const DetailSkeleton = () => {
  const widths = ["w-10", "w-12", "w-14", "w-16", "w-20", "w-24", "w-28", "w-32", "w-36", "w-40"];

  // Function to get a random width class
  const getRandomWidth = () => widths[Math.floor(Math.random() * widths.length)];

  return (
    <div className="animate-pulse flex flex-col gap-5 w-full">
      {Array(5)
        .fill()
        .map((content, index) => (
          <div key={index} className="flex justify-between items-center self-stretch">
            <div className={clsx("bg-gray-300 h-4", getRandomWidth())} />
            <div className={clsx("bg-gray-300 h-4", getRandomWidth())} />
          </div>
        ))}
    </div>
  );
};

export const HouseMoniSubSkeleton = () => (
  <div className="flex w-full py-5 px-[52px] flex-col items-start gap-[30px] h-[870px] overflow-auto">
    <div className="flex items-center gap-[30px] self-stretch h-4 w-56 bg-gray-300" />

    <hr className="bg-gray04 w-full" />
    <div className="flex flex-col items-start gap-[25px] self-stretch">
      <div className="flex items-center self-end gap-[5px] justify-end h-3 w-60 mb-10 bg-gray-300" />
      <div className="flex flex-col items-start gap-20 self-stretch">
        <DetailSkeleton />
        <DetailSkeleton />
      </div>
    </div>
  </div>
);

export const OfferingSkeleton = () => (
  <div className="flex flex-col gap-5 items-start animate-pulse">
    {Array(5)
      .fill()
      .map((content, index) => (
        <div key={index} className="bg-gray-200 h-[60px] w-full rounded-lg"></div>
      ))}
    <div className="w-[135px] bg-gray-200 rounded-lg h-10 mt-10"></div>
  </div>
);
