import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { editAction, getAction } from "../../../store/actions/creators/request.action";
import { OUR_OFFERINGS } from "../../../store/actions/action_types/action.types";
import { offeringsURL } from "../../../utils/endpoints";
import AuthLayout from "../../../components/Layout/AuthLayout";
import OfferingOne from "../../../components/Offerings/OfferingOne";
import OfferingTwo from "../../../components/Offerings/OfferingTwo";
import { useLocation, useNavigate } from "react-router-dom";

const offering_steps = { 0: <OfferingOne />, 1: <OfferingTwo /> };

const Offerings = ({ product, loading, ...props }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    /** get all offerings */
    props.getAction(OUR_OFFERINGS, offeringsURL, props.token);
  }, []);

  /** if this page has no location data coming from the more info page, redirect back to the more info page */
  useEffect(() => {
    if (state == undefined) {
      // redirect back
      navigate(-1);
    }
  }, [state]);

  return <AuthLayout hasBanner>{!props.next_form.step && offering_steps[props.next_form_data.step]}</AuthLayout>;
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  product: state.register.get_offerings.offerings,
  loading: state.register.get_offerings.loading,
  user_info: state.auth.login.data,
  access_token: state.register.more_info.access_token,
  next_form: state.ui.show_next_form.next_form,
  next_form_data: state.ui.show_next_form.form_data,
});

export default connect(mapStateToProps, { editAction, getAction })(Offerings);
