import { useEffect } from "react";
import { FUND_CARD } from "../../store/actions/action_types/action.types";
import { fundCardURL } from "../../utils/endpoints";
import { fundRequest } from "../../api/request.api";
import { useNavigate } from "react-router-dom";
import { currencyFormatter, refGenerator } from "../../utils/helper";
import { AppButton } from "../Widget/Button/AppButton";
import { postAction } from "../../store/actions/creators/request.action";
import { connect } from "react-redux";

const ConfirmContent = ({ no_border, data }) => {
  const data_one = Object.keys(data)[0].toString();
  const data_two = Object.keys(data)[1].toString();
  return (
    <div className={`flex py-5 justify-between items-start self-stretch ${!no_border && "border-b"}`}>
      <div className="flex flex-col items-start gap-[10px] flex-1">
        <span className="text-gray01 text-[15px] capitalize">{data_one}</span>
        <span className="text-primary font-medium capitalize">{data[data_one]}</span>
      </div>
      <div className="flex flex-col items-end gap-[10px]">
        <span className="text-gray01 text-[15px] capitalize">{data_two.replace(/_/g, " ")}</span>
        <span className="text-primary font-medium capitalize">{data[data_two]}</span>
      </div>
    </div>
  );
};

const ConfirmAmount = ({ bvn, ...props }) => {
  const navigate = useNavigate();
  const processCost = (amount, percent = 1.4) => {
    const new_amount = parseInt(amount);
    let result = (percent / 100) * new_amount;
    return currencyFormatter(result + new_amount);
  };

  // for checkout display
  const data_one = {
    amount: currencyFormatter(props.confirm_data.amount),
    processing_fee: props.confirm_data.percent ? `${props.confirm_data.percent}%` : "1.40%",
  };
  const data_two = {
    name: `${props.user_info.first_name} ${props.user_info.last_name}`,
    total: processCost(props.confirm_data.amount, props.confirm_data.percent),
  };

  const handleSubmit = () => {
    /** check if BVN is setup */
    if (bvn == null) {
      props.showMessage("OPEN_MSG_MODAL", "No assigned account number. Please Provide your BVN to be assigned an account number");
    } else {
      /** send data to the backend */
      const localhost = "http://localhost:3000"; // actual callback url...for dev it's localhos but should be set in env

      //data to send to server
      let card_data = {
        user_id: props.user_info.id,
        wallet_ref: props.user_wallet[0]?.wallet_ref,
        amount: parseInt(props.confirm_data.amount),
        channel_id: "WEB",
        user_ip: props.ip_data?.ip || "N/A", // should be gotten
        transaction_location: JSON.stringify({
          long: props.ip_data?.longitude.toString() || "N/A",
          lat: props.ip_data?.latitude.toString() || "N/A",
        }), // use ip to get location
        funding_option: "card",
        user_savings_id: props.confirm_data.user_savings_id || null,
        hpa_subscription_id: props.confirm_data.hpa_subscription_id || null,
        request_ref: props.confirm_data.request_ref || refGenerator(6), //should be generated later
        service_type: props.confirm_data.service_type || "ACCOUNTS",
        redirect_url: process.env.REACT_APP_CALLBACK_URL + "/dashboard/fund-wallet/card-payment",
      };

      // use for Housemoni subscription since we use same checkout
      if (card_data.hpa_subscription_id == null) {
        delete card_data.hpa_subscription_id;
      }

      // use for topping up savings
      if (card_data.user_savings_id == null) {
        delete card_data.user_savings_id;
      }

      // call action from redux to send data to server
      // props.postAction(FUND_CARD, fundCardURL, card_data, props.token, fundRequest);
      props.showModal("HIDE_MODAL", false, data_one);

      navigate("/checkout");
    }
  };

  return (
    <div className="flex flex-col gap-10 py-10">
      <div className="flex flex-col items-start gap-[60px] self-stretch mx-12">
        <div className="flex py-[17px] px-5 flex-col gap-[10px] self-stretch rounded-xl border border-gray04">
          <div className="flex flex-col items-start gap-[5px] self-stretch">
            {/** component to render details of payment */}
            <ConfirmContent data={data_one} />
            <ConfirmContent no_border data={data_two} />
          </div>
        </div>
        <div className="flex justify-end gap-7 self-stretch items-center">
          <AppButton name="Cancel" variant="outline" className="w-[138px]" onPress={() => props.showModal("HIDE_MODAL", false)} />
          <AppButton name="Checkout" className="w-[138px]" onPress={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  bvn: state.account.get_wallets.bvn,
});
export default connect(mapStateToProps, { postAction })(ConfirmAmount);
