import React from "react";
import { FlatList } from "../../components/Widget/List/List";
import AppAccordion from "../../components/Widget/Accordion/Accordion";
import { PRODUCTS_DATA } from "./products.data";

const Products = () => {
  const renderProductItem = ({ item }) => <AppAccordion title={item.title} accordionItemList={item.list} detail_viewed hideDate defaultOpen />;

  return (
    <div className="bg-white py-6 px-8 h-full rounded-lg">
      <h2 className=" text-primary font-semibold">Products</h2>
      <FlatList data={PRODUCTS_DATA} renderItem={renderProductItem} />
    </div>
  );
};

export default Products;
