import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TopCardDisplay } from "../../components/Card/Card";
import { DetailTabs, HeadlessTab } from "../../components/DetailsComponent/DetailsTab";
import { setDataId } from "../../store/actions/creators/ui.action";
import { useLocation, useNavigate } from "react-router-dom";
import SavingWrapper from "./SavingWrapper";
import { ArrowLeft2 } from "iconsax-react";
import SaveInfo, { TopUpSavings } from "./SaveInfo";
import SaveInterestHistory from "./SaveInterestHistory";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { currencyFormatter } from "../../utils/helper";
import { FiCheck } from "react-icons/fi";
import AppModal from "../../components/Widget/Modal/Modal";
import { GET_SAVING_WALLET } from "../../store/actions/action_types/action.types";
import { saveWalletURL } from "../../utils/endpoints";
import { getAction } from "../../store/actions/creators/request.action";

const SavingDetails = ({ loading, token, ...props }) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [type, setType] = useState("Details");
  const tabs = { Details: "", "Interest History": "" };
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    let path_id = pathname;
    path_id = path_id.split("/");
    path_id = path_id[path_id.length - 1];
    props.setDataId(path_id);

    props.getAction(GET_SAVING_WALLET, `${saveWalletURL}/${props.user_info?.id}/${props.user_wallet[0]?.wallet_ref}`, token);
  }, []);
  const top_data = {
    title: "Total Amount Saved",
    sub_title: props.save_data.u_save?.total_amount_saved || "0.00",
    statistics: {
      "Total Withdrawn": "N/A",
      "Total Credited": "N/A",
      "Current Saved Amount": "N/A",
    },
  };

  const saving_data = {
    id: props.save_data.u_save?.id,
    name: props.save_data.u_save?.savings_purpose,
    amount: props.save_data.u_save?.total_interest,
    starting_amount: props.save_data.u_save?.starting_amount,
    target_amount: props.save_data.u_save?.target_amount,
    total_balance: props.save_data.u_save?.total_balance,
    purpose_desc: props.save_data.u_save?.purpose_description,
    purpose: props.save_data.u_save?.savings_purpose,
    status: props.save_data.u_save?.status,
    lastpay_status: props.save_data.last_payment?.payment_status,
    lastpay_amount: props.save_data.last_payment?.amount,
    payment_reference: props.save_data.last_payment?.payment_reference,
    start_date: props.save_data.u_save?.start_date,
    maturity_date: props.save_data.u_save?.maturity_date,
    savings_product_name: props.save_data.u_save?.savings_product.product_name,
    savings_product_desc: props.save_data.u_save?.savings_product.product_description,
    locked_until: props.save_data.u_save?.locked_until,
    interest: props.save_data.u_save?.savings_rate?.annual_interest_rate,
    saving_recurring: props.save_data.u_save?.savings_product.recurring ? "recurring" : "not recurring",
    row_two: {
      "savings name": props.save_data.u_save?.savings_product?.product_name,
      "start date": new Date(props.save_data.u_save?.start_date).toLocaleDateString(),
      "Maturity Date": new Date(props.save_data.u_save?.maturity_date).toLocaleDateString(),
      "locked until": new Date(props.save_data.u_save?.locked_until).toLocaleDateString() || "not provided",
      "Savings Purpose description": props.save_data.u_save?.purpose_description,
      "starting amount": props.save_data.u_save?.starting_amount,
      "target amount": currencyFormatter(props.save_data.u_save?.target_amount),
      "auto save": props.save_data.u_save?.auto_save ? (
        <div className="flex items-center text-green-500">
          <FiCheck className="text-green-500 text-lg font-bold" />
          Auto Save
        </div>
      ) : (
        "Manual Save"
      ),
      "total interest earned": props.save_data.u_save?.total_interest,
    },
    row_three: {
      "saving recurring?": props.save_data.u_save?.savings_product.recurring ? "recurring" : "not recurring",
      "savings product name": props.save_data.u_save?.savings_product.product_name,
      "savings product description": props.save_data.u_save?.savings_product.product_description,
      "Savings Rate Name": props.save_data.u_save?.savings_rate.rate_name,
      "rate description": props.save_data.u_save?.savings_rate?.rate_name,
    },
  };

  return (
    <SavingWrapper>
      <div className="bg-white rounded-lg h-full py-5 px-12">
        <div className="flex justify-between items-center">
          <ArrowLeft2 size="24" onClick={() => navigate(-1)} />
          <HeadlessTab
            tabs={tabs}
            handlePress={index => {
              setType(Object.keys(tabs)[index]);
            }}
          />

          {saving_data.status == "RUNNING" && <AppButton name="Topup Savings" className="text-[12px] py-2.5 px-5 h-[28px] rounded-[7px]" onPress={() => setOpen(true)} />}
        </div>
        <div className="mt-5 rounded-lg col-span-2 flex sm:flex-row flex-col-reverse gap-x-5 overflow-auto max-h-[600px]">
          {type === "Details" && <SaveInfo saving_data={saving_data} />}
          {type === "Interest History" && <SaveInterestHistory />}
        </div>
      </div>
      <AppModal title="Top Up Savings" open={open} className="w-[40%] 2xl:w-[30%]" onClose={() => setOpen(false)} content={<TopUpSavings {...props} closeModal={() => setOpen(false)} />} />
    </SavingWrapper>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  save_data: state.saving.get_sub_detail.data,
  save_wallet: state.saving.save_wallet.data,
});

export default connect(mapStateToProps, { setDataId, getAction })(SavingDetails);
