import { LOADING, SENDING, DELETING } from "../action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED, LOGIN_ERROR } from "../action_types/error.types";
import { makeRequest } from "../../../api/request.api";

export const postAction =
  (type, url, app_data = null, token = null, fetchFn = makeRequest) =>
  async dispatch => {
    dispatch({ type: SENDING + "_" + type });
    try {
      const { data } = await fetchFn(url, app_data, token);
      dispatch({ type, payload: { data } });
    } catch (error) {
      if (token == null && type == "SIGN_IN") {
        const payload_data = error.message == "Failed to fetch" ? "We ran into a problem trying to connect to the Internet" : error.message;
        dispatch({
          type: LOGIN_ERROR,
          payload: payload_data,
        });
      } else {
        dispatch({
          type: POST_ERROR_OCCURED,
          payload: error.message == "Failed to fetch" ? "We ran into a problem trying to connect to the Internet" : error.message,
        });
      }
    }
  };

export const getAction =
  (type, url, token = null, fetchFn = makeRequest) =>
  async dispatch => {
    dispatch({ type: LOADING + "_" + type });

    try {
      const { data } = await fetchFn(url, null, token);
      dispatch({ type, payload: { data } });
    } catch (error) {
      dispatch({
        type: ERROR_OCCURED,
        payload: error.message == "Failed to fetch" ? "We ran into a problem trying to connect to the Internet" : error.message,
      });
    }
  };

export const editAction =
  (type, url, app_data = null, token = null, method_type = "PATCH", fetchFn = makeRequest) =>
  async dispatch => {
    dispatch({ type: SENDING + "_" + type });

    try {
      const { data } = await fetchFn(url, app_data, token, method_type);
      dispatch({ type, payload: { data } });
    } catch (error) {
      dispatch({
        type: POST_ERROR_OCCURED,
        payload: error.message == "Failed to fetch" ? "We ran into a problem trying to connect to the Internet" : error.message,
      });
      // toast.error(error.message == "Failed to fetch" ? "We ran into a problem trying to connect to the Internet" : error.message);
    }
  };

export const deleteAction =
  (type, url, token = null, method_type = "DELETE", fetchFn = makeRequest) =>
  async dispatch => {
    dispatch({ type: DELETING + "_" + type });
    try {
      const { data } = await fetchFn(url, null, token, method_type);
      dispatch({ type, payload: { data } });
    } catch (error) {
      dispatch({
        type: ERROR_OCCURED,
        payload: error.message == "Failed to fetch" ? "We ran into a problem trying to connect to the Internet" : error.message,
      });
    }
  };
