export const processBanks = (data, index) => ({
  id: data.ID,
  label: data.Name,
  value: data.Code,
});

export const processBeneficiaries = (data, index) => ({
  id: data.id,
  account_name: data.receiver_account_name.toLowerCase(),
  account_number: data.receiver_account_number,
  bank_name: data.receiver_bank_name,
  bank_code: data.receiver_bank_code,
  initials: data.receiver_account_name
    .split(" ")
    .slice(0, 2)
    .map(content => `${content[0]}`),
});
