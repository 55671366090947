import { combineReducers } from "redux";
import { FUND_CARD } from "../actions/action_types/action.types";
import { data } from "autoprefixer";

const initialState = {
  selectedItems: [],
  show: false,
  show_confirm: false,
  show_terms: false,
  show_delete: false,
  fade: false,
  data: null,
  show_option: false,
  show_profile_modal: false,
  show_sub_modal: false,
  open: false,
  show_content: false,
  content: null,
  tab_index: 0,
  next_form: false,
  open_group: false,
  msg_modal: false,
  response_msg: "",
  id: null,
  callback: null,
  show_interest: false,
  show_emergency_fund: false,
  is_app_idle: false,
  selected_data: null,
  group_data: null,
  open_emr: false,
  show_pass: false,
  new_pass: false,
  error: null,
  sidebar: true,
  show_offering_modal: false,
  open_success: false,
  show_new_pass: false,
  filter_savings: "",
  product_code_data: null,
  isFilter: false,
  show_alert: false,
  card_type: 0,
  beneficiary_data: null,
  form_data: {
    step: 0,
  },

  show_modal: false,
  options: null,
  open_delete_modal: false,
  open_subscribe: false,
};

const routeReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_ROUTE":
      return {
        ...state,
        routename: action.payload,
      };
    default:
      return state;
  }
};

const appModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_MODAL":
      return {
        ...state,
        show_modal: true,
        options: action.payload,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        show_modal: false,
        options: null,
      };
    default:
      return state;
  }
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return {
        ...state,
        show: action.payload[0],
        data: action.payload[1],
      };
    case "OPEN_PRINT_MODAL":
      return {
        ...state,
        show_modal: action.payload[0],
        data: action.payload[1],
      };
    case "OPEN_SUB_PRINT_MODAL":
      return {
        ...state,
        show_modal_sub: action.payload[0],
        data: action.payload[1],
      };
    case "OPEN_TERMS":
      return {
        ...state,
        show_terms: action.payload[0],
        data: action.payload[1],
      };
    case "OPEN_PROFILE_MODAL":
      return {
        ...state,
        show_profile_modal: action.payload[0],
        data: action.payload[1],
        type: action.payload[2],
      };
    case "OPEN_INVEST_MODAL":
      return {
        ...state,
        show_interest: true,
        // show_sub_modal: false,
        // data: action.payload[1],
      };
    case FUND_CARD:
      return {
        ...state,
        show_confirm: false,
      };
    case "OPEN_ALERT":
      return {
        ...state,
        show_alert: true,
      };
    case "CLOSE_ALERT":
      return {
        ...state,
        show_alert: false,
      };
    case "OPEN_SUB_MODAL":
      return {
        ...state,
        show_sub_modal: action.payload[0],
        data: action.payload[1],
      };
    case "OPEN_EMERGENCY_FUND":
      return {
        ...state,
        show_emergency_fund: action.payload[0],
        data: action.payload[1],
      };
    case "OPEN_PASSWORD_MODAL":
      return {
        ...state,
        show_pass: true,
      };
    case "OPEN_NEW_PASSWORD_MODAL":
      return {
        ...state,
        show_new_pass: true,
      };
    case "CLOSE_EMERGENCY_FUND":
      return {
        ...state,
        show_emergency_fund: false,
        open_emr: true,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        show: action.payload[0],
        show_modal: action.payload[0],
        show_option: action.payload[0],
        show_delete: action.payload[0],
        show_pass: action.payload[0],
        show_confirm: false,
        show_terms: action.payload[0],
        show_profile_modal: action.payload[0],
        data: action.payload[1] || null,
        open_group: action.payload[0],
        msg_modal: false,
        show_new_pass: action.payload[0],
      };
    case "HIDE_SUB_MODAL":
      return {
        ...state,
        show_sub_modal: action.payload[0],
        data: null,
      };
    case "HIDE_SUB_PRINT_MODAL":
      return {
        ...state,
        show_modal_sub: action.payload[0],
      };
    case "HIDE_INVEST_MODAL":
      return {
        ...state,
        show_interest: action.payload[0],
      };
    case "OPEN_OPTION_MODAL":
      return {
        ...state,
        show_option: action.payload[0],
      };
    case "OPEN_CONFIRM_MODAL":
      return {
        ...state,
        show_confirm: action.payload[0],
        confirm_data: action.payload[1],
      };
    case "CLOSE_OPTION_MODAL":
      return {
        ...state,
        show_option: action.payload[0],
        data: action.payload[1],
      };
    case "OPEN_DELETE_MODAL":
      return {
        ...state,
        // show: false,
        show_delete: action.payload[0],
      };
    case "OPEN_GROUP_MODAL":
      return {
        ...state,
        open_group: action.payload[0],
        group_data: action.payload[1],
      };
    default:
      return state;
  }
};

const fadeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FADE":
      return {
        ...state,
        fade: action.payload,
      };
    default:
      return state;
  }
};

const idSetterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ID":
      return {
        ...state,
        id: action.payload,
      };
    default:
      return state;
  }
};

const showIframeReducer = (state = {}, action) => {
  switch (action.type) {
    case "SHOW_IFRAME":
      return {
        ...state,
        show: true,
      };
    case "HIDE_IFRAME":
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

const showNotification = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_NOTIFICATION":
      return {
        ...state,
        open: !state.open,
      };
    case "CLOSE_NOTIFICATION":
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

const showContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HPA_TYPE":
      return {
        ...state,
        show_content: true,
        content: action.payload,
      };
    case "HAA_TYPE":
      return {
        ...state,
        show_content: true,
        content: action.payload,
      };
    case "LOAD_HPA_HAA":
      return {
        ...state,
        show_content: false,
        content: action.payload,
      };
    default:
      return state;
  }
};

const setTabIndexReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TAB_INDEX":
      return {
        ...state,
        tab_index: action.payload,
      };
    default:
      return state;
  }
};

const showNextFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_NEXT_FORM":
      return {
        ...state,
        next_form: action.payload[0],
        form_data: action.payload[1],
      };
    case "CLEAR_HOUSEMONI":
      return {
        ...state,
        form_data: { step: 0 },
      };
    default:
      return state;
  }
};

const showMessageModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MSG_MODAL":
      return {
        ...state,
        show_pass: false,
        msg_modal: true,
        response_msg: action.payload.message || action.payload,
        message_type: action.payload.msg_type,
        title: action.payload.title,
        redirect: action.payload.redirect,
        is_login: action.payload.is_login,
      };
    case "CLOSE_MSG_MODAL":
      return {
        ...state,
        msg_modal: false,
        message: "closing modal",
        loading: false,
      };
    default:
      return state;
  }
};

const showSuccessModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_SUCCESS_MODAL":
      return {
        ...state,
        open_success: true,
        response_msg: action.payload,
      };
    case "CLOSE_SUCCESS_MODAL":
      return {
        ...state,
        open_success: false,
        loading: false,
      };
    default:
      return state;
  }
};

const selectOptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SELECT_OPTION":
      return {
        ...state,
        option_data: action.payload,
      };
    default:
      return state;
  }
};

const setDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        selected_data: action.payload,
      };
    case "CLEAR_HOUSEMONI":
      return {
        ...state,
        selected_data: null,
      };
    default:
      return state;
  }
};

const setNextOfKinReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_NEXT_OF_KIN":
      return {
        selected_data: action.payload,
      };
    default:
      return state;
  }
};

const setGroupDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GROUP_DATA":
      return {
        ...state,
        group_data: action.payload,
      };
    default:
      return state;
  }
};

const paginateReducer = (state = {}, action) => {
  switch (action.type) {
    case "PAGINATE":
      return {
        ...state,
        paginate_link: action.payload,
      };
    default:
      return state;
  }
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FILTER_SAVINGS":
      return {
        ...state,
        filter_savings: action.payload,
      };
    default:
      return state;
  }
};

const idleCheckerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ISIDLE":
      return {
        ...state,
        is_app_idle: action.payload[0],
        time_left: action.payload[1].time_remaining || null,
        handle_reset: action.payload[1].reset_timer || null,
      };
    default:
      return state;
  }
};

const openEmrModal = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_EMERGENCY_MODAL":
      return {
        ...state,
        open_emr: true,
        show_emergency_fund: false,
      };
    case "CLOSE_EMERGENCY_MODAL":
      return {
        ...state,
        open_emr: false,
      };
    default:
      return state;
  }
};

const openSidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_SIDE_BAR":
      return {
        ...state,
        sidebar: !state.sidebar,
      };
    default:
      return state;
  }
};

const openOfferingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_OFFERING":
      return {
        ...state,
        show_offering_modal: action.payload[0] || true,
        data: action.payload[1],
      };

    case "HIDE_OFFERING":
      return {
        ...state,
        show_offering_modal: action.payload[0] || false,
      };
    default:
      return state;
  }
};

const activateFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case "ACTIVATE_FILTER":
      return {
        ...state,
        isFilter: action.payload,
      };
    default:
      return state;
  }
};

const productCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRODUCT_CODE":
      // alert(JSON.stringify(action.payload));
      return {
        ...state,
        product_code_data: action.payload,
      };
    case "CLEAR_HOUSEMONI":
      return {
        ...state,
        product_code_data: null,
      };
    default:
      return state;
  }
};

const beneficiaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BENEFICIARY":
      return {
        ...state,
        beneficiary_data: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        beneficiary_data: null,
      };
    default:
      return state;
  }
};

const deleteModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_DELETE_MODAL":
      return {
        ...state,
        open_delete_modal: true,
        delete_id: action.payload,
      };
    case "CLOSE_DELETE_MODAL":
      return {
        ...state,
        open_delete_modal: false,
        delete_id: null,
      };
    default:
      return state;
  }
};

const subscribeModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_SUBSCRIBE":
      return {
        ...state,
        open_subscribe: true,
      };
    case "CLOSE_SUBSCRIBE":
      return {
        ...state,
        open_subscribe: false,
      };
    default:
      return state;
  }
};

const uiReducer = combineReducers({
  route: routeReducer,
  modal: modalReducer,
  new_modal: appModalReducer,
  fader: fadeReducer,
  id_setter: idSetterReducer,
  frame: showIframeReducer,
  notify_box: showNotification,
  show_hpa_content: showContentReducer,
  set_tab_index: setTabIndexReducer,
  show_next_form: showNextFormReducer,
  open_msg: showMessageModalReducer,
  open_success: showSuccessModalReducer,
  select_option: selectOptionReducer,
  set_data: setDataReducer,
  set_kin_data: setNextOfKinReducer,
  set_group_data: setGroupDataReducer,
  add_paginate: paginateReducer,
  filter_data: filterReducer,
  is_idle: idleCheckerReducer,
  emr_show: openEmrModal,
  sidebar_open: openSidebarReducer,
  open_offering: openOfferingReducer,
  is_filter: activateFilterReducer,
  product_code: productCodeReducer,
  beneficiary: beneficiaryReducer,
  delete_modal: deleteModalReducer,
  subscribe: subscribeModalReducer,
});
export default uiReducer;
