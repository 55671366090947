import styled, { keyframes } from "styled-components";

const slide_out = keyframes`
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0);
  }
`;

const slide_in = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100vw);
  }
`;

export const SideBarContainer = styled.div`
  height: 100dvh;
  ${"" /* grid-column: 1/3; */}
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  border-right: 1px solid #e4e4e7;
  overflow-y: scroll;
  @media (max-width: 1279px) {
    display: ${props => props.display || "block"};
    position: fixed;
    width: 100%;
    overflow-y: scroll;
    background-color: transparent;
    z-index: 1000;
    &.slideIn {
      height: 0;
      width: 0;
      top: 0;
      left: 0;
      z-index: 10;
      position: fixed;
      animation: ${slide_in} 0.5s ease;
      -webkit-animation: ${slide_in} 0.5s ease;
      -moz-animation: ${slide_in} 0.5s ease;
      -o-animation: ${slide_in} 0.5s ease;
      -ms-animation: ${slide_in} 0.5s ease;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      -o-animation-fill-mode: forwards;
      -ms-animation-fill-mode: forwards;
    }
    &.slideOut {
      animation: ${slide_out} 0.5s ease;
      -webkit-animation: ${slide_out} 0.5s ease;
      -moz-animation: ${slide_out} 0.5s ease;
      -o-animation: ${slide_out} 0.5s ease;
      -ms-animation: ${slide_out} 0.5s ease;
    }
  }
`;
