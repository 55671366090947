import React, { useEffect, useState } from "react";
import SuccessModal from "../Widget/Modal/SuccessModal";
import { useForm } from "react-hook-form";
import { AppButton } from "../Widget/Button/AppButton";
import { connect } from "react-redux";
import { showModal, showMessage } from "../../store/actions/creators/ui.action";
import { postAction } from "../../store/actions/creators/request.action";
import { PasswordField } from "../Widget/Form/FormComponent";
import { changePasswordURL, newPasswordURL } from "../../utils/endpoints";
import { CHANGE_NEW_PASSWORD } from "../../store/actions/action_types/action.types";

const ChangePasswordModal = ({ token, ...props }) => {
  const { handleSubmit, formState, register, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      email: props.user_info?.email,
    },
  });

  const disabledState = !formState.isDirty || !formState.isValid;

  useEffect(() => {
    if (props.success) props.showMessage("OPEN_MSG_MODAL", { message: "Your password was successfully set.", title: "Successfully Set!", msg_type: "success" });
  }, [props.success]);

  const onSubmit = data => {
    props.postAction(CHANGE_NEW_PASSWORD, changePasswordURL, data, token);
  };

  return (
    <div className="">
      {/* <HeaderView title="Change Password" onClose={props.onClose} /> */}
      <div className="px-12 pt-8 pb-12">
        <p className="text-sm text-left text-gray-500">Your password secures your account. Keep your password private.</p>
        <form className="pt-4 w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="pb-14 flex flex-col gap-4">
            <PasswordField label="Current Password" name="old_password" placeholder="******" error={formState.errors.old_password} {...register("old_password", { required: true })} />
            <div className="flex items-center gap-x-5 justify-between">
              <PasswordField label="New Password" name="new_password" placeholder="******" className="flex-1" error={formState.errors.new_password} {...register("new_password", { required: true })} />
              <PasswordField
                label="Confew New Password"
                name="confirm_new_password"
                placeholder="******"
                className="flex-1"
                error={formState.errors.confirm_new_password}
                {...register("confirm_new_password", { required: true })}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <div className="flex flex-row-reverse gap-x-8 items-center">
              <AppButton name={"Done"} loading={props.loading} disabled={disabledState} />
              <AppButton name="Cancel" onPress={props.onClose} variant={"outline"} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.register.change_password.loading,
  success: state.register.change_password?.success,
  data: state.register.change_password.data,
  user_info: state.auth.login.data,
});

export default connect(mapStateToProps, { showModal, showMessage, postAction })(ChangePasswordModal);
