import React from "react";
import { connect } from "react-redux";
import { showModal, showMessage } from "../../store/actions/creators/ui.action";
import { postAction, editAction, getAction } from "../../store/actions/creators/request.action";
import ProfileWrapper from "./ProfileWrapper";
import { ListCard } from "./Security";
import AppModal from "../../components/Widget/Modal/Modal";
import BVNModal from "../../components/Profile/BVNModal";
import UploadModal from "../../components/Profile/UploadModal";
import { useHasAccount } from "../../hooks/account.hook";

window.Buffer = window.Buffer || require("buffer").Buffer;

const IdentificationDoc = ({ token, bvn, loading_wallet, details_loading, details, ...props }) => {
  const { has_doc, has_proof } = useHasAccount();
  const docs_verification_status = props.user_doc?.owner_docs_verification_status || details?.documents_verified;

  return (
    <>
      <div className="bg-white h-full flex flex-col gap-2 px-12 py-5">
        <h3 className="text-[#1F2734] pb-5 font-medium">User Documents</h3>
        <div className="flex flex-col">
          <ListCard
            title={"Documents"}
            desc={
              <>
                ID card and proof of address
                <span
                  className={`rounded-md px-3 py-[2px] ml-1 ${
                    !has_doc || !has_proof ? "bg-brand02 text-brand01" : docs_verification_status === "NOT_VERIFIED" ? "text-[#FF9C33] bg-[#FEF7EF]" : "text-[#449E6A] bg-[#EDFDF3]"
                  }`}
                >
                  {!has_doc ? "Not Done" : !has_proof ? "Complete proof of address" : docs_verification_status === "NOT_VERIFIED" ? "Pending Approval" : "Verified"}
                </span>
              </>
            }
            disabled={has_doc && has_proof}
            loading={details_loading}
            actionName={"Upload Images"}
            onPress={() => (!has_doc || !has_proof ? props.showModal("OPEN_SUB_MODAL", true) : null)}
          />
          <ListCard
            title={"BVN"}
            desc={
              <>
                Bank Verification Number
                <span className={`rounded-md px-3 py-[2px] ml-1 ${bvn == null ? "bg-brand02 text-brand01" : "text-[#449E6A] bg-[#EDFDF3]"}`}>{bvn == null ? "Not Done" : "Verified"}</span>
              </>
            }
            loading={loading_wallet}
            disabled={bvn}
            actionName={"Set BVN"}
            onPress={() => (bvn == null ? props.showModal("OPEN_MODAL", true) : null)}
          />
        </div>
      </div>
      <AppModal open={props.open} title="BVN" onClose={() => props.showModal("HIDE_MODAL", false)} content={<BVNModal onClose={() => props.showModal("HIDE_MODAL", false)} />} />

      <AppModal open={props.open_sub} className="w-[50%]" title="Document" onClose={() => props.showModal("HIDE_SUB_MODAL", false)} content={<UploadModal />} />
    </>
  );
};

const mapStateToProps = state => ({
  open: state.ui.modal.show,
  open_sub: state.ui.modal.show_sub_modal,
  loading: state.user.set_docs.loading,
  token: state.auth.login.token,
  owner_docs: state.user.owner_docs.data,
  user_info: state.auth.login.data,
  user_doc: state.user.user_docs.data,
  proof_addr: state.user.owner_docs.proofs,
  set_docs_msg: state.user.set_docs.data,
  user_wallet: state.account.get_wallets.data,
  bvn: state.account.get_wallets.bvn,
  loading_wallet: state.account.get_wallets.loading,
  details: state.user.user_details.data,
  details_loading: state.user.user_details.loading,
});

export default connect(mapStateToProps, {
  showModal,
  getAction,
  postAction,
  editAction,
  showMessage,
})(IdentificationDoc);
