import { ArrowLeft2 } from "iconsax-react";
import React from "react";
import { Link } from "react-router-dom";
import { EmptyView2 } from "../../components/Widget/EmptyView/EmptyView";
import AppModal from "../../components/Widget/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../store/actions/creators/ui.action";
import TimedDepositModal from "./modal";
import { useGetAllFixedDepositRequestsQuery, useGetFixedDepositRequestsByPhoneNumberQuery, useGetUserFixedDepositRequestsQuery } from "../../services/accounts/fixedDeposit.services";
import { LoaderII } from "../../components/Widget/Loader/Loader";
import RecentRequests from "../../components/Products/RecentRequests";
import FixedDeposits from "./FixedDeposit";

const TimedDepositPage = () => {
  const open = useSelector(state => state.ui.modal.show);
  const dispatch = useDispatch();
  const { data = [], isLoading } = useGetAllFixedDepositRequestsQuery();
  const userData = useSelector(state => state.auth.login.data);

  const { data: userFixedDeposit = [], isLoading: isLoadingFixedDeposit } = useGetUserFixedDepositRequestsQuery(userData?.id, { skip: !userData?.id });
  const { data: userFixedDepositByPhoneNumber = [] } = useGetFixedDepositRequestsByPhoneNumberQuery(userData?.phone, { skip: !userData?.phone });

  return (
    <div className="bg-white py-6 px-8 h-[calc(100vh-153px)] rounded-lg">
      <div className="flex gap-7">
        <Link to={"/products"}>
          <ArrowLeft2 />
        </Link>
        <h2 className=" text-primary font-semibold">Timed Deposits</h2>
      </div>

      {isLoading ? (
        <div className="flex justify-center mt-[160px]">
          <LoaderII />
        </div>
      ) : data.length > 0 ? (
        <div>
          {userFixedDepositByPhoneNumber.length > 0 && <FixedDeposits data={userFixedDepositByPhoneNumber} loading={isLoadingFixedDeposit} />}

          <div className="mt-7 border-t border-gray02">
            <h4 className="my-5 text-primary font-medium">Recent Requests</h4>
            <RecentRequests recentRequests={userFixedDeposit} isLoading={isLoadingFixedDeposit} />
          </div>
        </div>
      ) : (
        <EmptyView2 has_btn description={"You don’t have any timed or fixed deposit. Send us your request and we’ll be in touch soon."} btn_name="Send Request" />
      )}
      <AppModal open={open} title="Timed Deposits" onClose={() => dispatch(showModal("HIDE_MODAL", false))} content={<TimedDepositModal />} />
    </div>
  );
};

export default TimedDepositPage;
