import React, { useEffect } from "react";
import { connect } from "react-redux";
import { processLoginHistory } from "./profile.data";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_USER_HISTORY } from "../../store/actions/action_types/action.types";
import { userLoginHistoryURL } from "../../utils/endpoints";
import Table from "../../components/Widget/Table/Table";
import ProfileWrapper from "./ProfileWrapper";
import { Sort } from "iconsax-react";

export const FilterButton = ({ ...props }) => {
  return (
    <span onClick={props.onPress} className="flex items-center gap-1 px-4 py-1 text-primary border border-gray02 rounded-lg">
      <Sort size="20" variant="Outline" />
      Filter
    </span>
  );
};

const UserLoginHistory = ({ token, loading, ...props }) => {
  useEffect(() => {
    const url = `${userLoginHistoryURL}/user/${props.user_info?.id}?page=${props.paginate_num || 1}&per_page=10`;
    props.getAction(GET_USER_HISTORY, url, token);
  }, [props.paginate_num]);

  const tableInfo = props.history_data?.map(processLoginHistory(props.paginate_num || 1));

  return (
    <div className="">
      <div className="py-6 px-8 bg-white flex justify-between items-start">
        <h3 className="text-primary font-semibold">Login History</h3>
        <FilterButton />
      </div>
      <Table {...props} tableData={tableInfo} loading={loading} no_action no_option error={props.error_msg} />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.user.user_history.loading,
  history_data: state.user.user_history.data,
  error_msg: state.user.user_history.error_msg,
  user_info: state.auth.login.data,
  paginate_num: state.ui.add_paginate.paginate_link,
  total: state.user.user_history.total,
  //   details: state.user.user_details.data,
});

export default connect(mapStateToProps, { getAction })(UserLoginHistory);
