import { useState } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { currencyFormatter } from "../../../utils/helper";
import Loader from "../Loader/Loader";
import clsx from "clsx";

const AppPieChart = ({ data, loading, balance }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Check if all data values are zeros
  const allZeros = data.every(entry => entry.Amount === 0);

  return (
    <>
      {loading == true ? (
        <div className="w-[45%] flex justify-center items-center">
          <Loader loading={loading} color="#992333" />
        </div>
      ) : (
        <div className="h-full w-full self-start flex justify-start items-center ">
          <ResponsiveContainer height={200}>
            <PieChart>
              <Pie
                dataKey="Amount"
                startAngle={360}
                endAngle={0}
                activeIndex={activeIndex}
                // activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                outerRadius={window.matchMedia("only screen and (max-width: 1280px)").matches ? 45 : 85}
                innerRadius={window.matchMedia("only screen and (max-width: 1280px)").matches ? 30 : 68}
                fill="rgb(244 63 94)"
                paddingAngle={1}
                // label={activeIndex == null ? renderCustomizedLabel : null}
                onMouseEnter={(_, index) => setActiveIndex(index)}
                onMouseLeave={(_, index) => setActiveIndex(null)}
              >
                {data?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={allZeros ? "#d3d3d3" : entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default AppPieChart;
