import React, { useCallback, useEffect, useState } from "react";
import { Badge } from "../Widget/Badge/Badge";
import { CardView, ProfileHeaderView } from "./card.style";
import { AppButton } from "../Widget/Button/AppButton";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import Carousel from "react-elastic-carousel";
import AppCarousel from "../Widget/Carousel/Carousel";
import { SkeletonWallet } from "../Widget/Loader/Skeleton";
import { currencyFormatter } from "../../utils/helper";
import Loader from "../Widget/Loader/Loader";
import AppModal from "../Widget/Modal/Modal";
import { useForm } from "react-hook-form";
import { SelectField, TextField } from "../Widget/Form/FormComponent";
import { Add, ArrowDown2, Clock, Copy, EmptyWallet, Eye, EyeSlash, InfoCircle, Lock1, More, ProfileCircle, Send2 } from "iconsax-react";
import useEmblaCarousel from "embla-carousel-react";
// import PlaystoreImg from '';
// import AppstoreImg from "../../assets/images/appstore.svg";
// import Mobile from "../../assets/images/Device2.svg";
import AppPieChart from "../Widget/Charts/PieChart";
import { HeadlessTab } from "../DetailsComponent/DetailsTab";
import AppAreaChart from "../Widget/Charts/AreaChart";
import Dropdown from "../Widget/Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import { CopyView } from "../Widget/Copy/Copy";
import { showModal } from "../../store/actions/creators/ui.action";
import { useHasAccount } from "../../hooks/account.hook";
import clsx from "clsx";

const WithdrawContent = props => {
  const { register, handleSubmit, control, getValues, formState } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    // const sub_url = `${savingSubURL}/user/${props.user_info.id}`;
    // const invest_url = `${investUserSubURL}/${props.user_info.id}`;
    // props.getAction(GET_SAVING_SUB, sub_url, props.token);
    // props.getAction(GET_INVEST_SUB, invest_url, props.token);
  }, []);

  useEffect(() => {
    if (props.invest_withdraw_err != null && getValues("amount") != "") {
      toast(props.save_withdraw_err || props.invest_withdraw_err);
    }
  }, [props.save_withdraw_err, props.invest_withdraw_err]);

  const onSubmit = data => {
    //
    data.user_id = props.user_info.id;
    data.channel = "WEB";
    data.user_ip = "N/A";
    data.amount = parseInt(data.amount);
    data.transaction_location = "N/A";
    if (props.wallet_type == "SAVINGS_WALLET") {
      data.user_savings_id = data.user_savings_id.id;
      props.postAction(WITHDRAW_SAVINGS, withdrawSavingURL, data, props.token);
    } else {
      data.investment_sub_id = data.user_savings_id.id;
      delete data.user_savings_id;
      props.postAction(WITHDRAW_INVESTMENT, withdrawInvestmentURL, data, props.token);
    }
  };

  return (
    <div className="h-auto w-[25em] p-5 py-2">
      <div className="py-5">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
          <TextField label="Amount to withdraw" placeholder="amount 200,000,000" {...register("amount")} type="currency" control={control} />
          <SelectField
            item={props.wallet_type == "SAVINGS_WALLET" ? props?.saving_sub : props.invest_sub}
            label={`Select ${props.wallet_type == "SAVINGS_WALLET" ? "Savings" : "Investment"} Subscription`}
            control={control}
            mb="2xl:mb-6 xl:mb-3 lg:mb-2 mb-6"
            error={formState.errors.user_savings_id}
            selected_item={props.wallet_type == "SAVINGS_WALLET" ? "savings_purpose" : "subscription_amount"}
            {...register("user_savings_id", { required: true })}
          />
          <div className="flex justify-end w-full mt-3">
            <AppButton name="withdraw" className="w-[138px]" />
          </div>
        </form>
      </div>
    </div>
  );
};

const WalletDropContent = ({ data, onSwitch }) => {
  return (
    <div className="flex flex-col items-start">
      {data.map((item, index) => (
        <Menu.Item>
          {({ active }) => (
            <div className="p-[8px_10px] flex gap-[5px] cursor-pointer" onClick={() => onSwitch(index)}>
              {item.img && <img className="w-[14px] h-[14px]" src={item.img} />}
              <span className="text-[12px] text-[#1F2734] leading-[15.12px]">{item.name}</span>
            </div>
          )}
        </Menu.Item>
      ))}
    </div>
  );
};

const Wallet = ({ selectedIndex, handleSwitch, ...props }) => {
  const bal = currencyFormatter(props.balance, props.base_currency);
  const book_bal = currencyFormatter(props.ledger_balance, props.base_currency);
  const [hide, setHide] = useState();
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user_info = useSelector(state => state.auth.login.data);
  const user_wallet = useSelector(state => state.account.get_wallets.data);

  const checker = user_wallet[0]?.wallets[0]?.nuban_account;

  const handleSubmit = () => {
    // run a check to see if user has account number
    if (!checker) {
      dispatch(showModal("OPEN_ALERT", true));
      return;
    }
    // onclick on the fund button, direct to the right pages...we bind custom urls to the data from the backend
    navigate(props.fund_url);
  };

  const handleWithdrawal = () => {
    // if (!props.user_info.has_txn_pin || !props.user_info.has_identity_documents || !props.user_info.has_adress_documents) {
    //   props.showModal("OPEN_ALERT", true);
    // } else {
    //   if (props.wallet_type !== "main") {
    //     setOpen(true);
    //   } else {
    //     history.push(props.withdraw_url);
    //   }
    // }
    if (!checker) {
      dispatch(showModal("OPEN_ALERT", true));
      return;
    }
    if (props.wallet_type.includes("account")) {
      navigate(props.withdraw_url);
    }
  };

  return (
    <div className={`flex flex-col w-full`}>
      <div className={`flex 2xl:p-5 xl:p-3 lg:p-3 p-3 flex-col items-start gap-2 rounded-[10px] shadow-lg w-full ${props.card_class} shadow-[#EAEAEA] 2xl:min-h-[154px]`}>
        <div className="flex items-end justify-between w-full">
          <div className="flex flex-col items-start flex-1 2xl:gap-5 xl:gap-3">
            <div className="flex flex-col items-start 2xl:gap-[4px] xl:gap-[2px] self-stretch">
              <div className="flex items-center gap-2">
                <span className="font-normal capitalize 2xl:text-sm xl:text-xs">{props.wallet_type} Balance</span>
                <span className="" onClick={() => setHide(!hide)}>
                  {hide ? <Eye className="2xl:w-[18px] xl:w-[15px]" /> : <EyeSlash className="2xl:w-[18px] xl:w-[15px]" />}
                </span>
              </div>
              <span className="2xl:text-2xl xl:text-lg font-bold font-grotesk-font">{hide ? bal : "*".repeat(10)}</span>
            </div>
            <div className="flex flex-col items-start self-stretch justify-center gap-[4px]">
              <div className="flex items-center">
                <span className="2xl:text-xs xl:text-[10px] font-normal">Book Balance</span>
              </div>
              <span className="2xl:text-xs xl:text-[10px] font-normal font-grotesk-font">{props.ledger_balance && hide ? book_bal : "*".repeat(10) || "N/A"}</span>
            </div>
          </div>
          {!props.hide_account_num_name && (
            <div className="flex flex-col justify-between items-end self-stretch">
              <Dropdown content={<WalletDropContent data={props.dp_data} onSwitch={handleSwitch} />} className="mt-[1px] !w-[84px] rounded-[10px] bg-[#ffffff] shadow-custom02">
                <div className={`border ${props.dropdown_class || "text-[#FFFFFF]"} rounded-[20px] font-light flex p-[8px_10px] gap-[5px] items-center md:w-[84px]`}>
                  <div className="flex items-center gap-[5px]">
                    {props.dp_data[selectedIndex]?.img && <img src={props.dp_data[selectedIndex]?.img} className="w-[14px] h-[14px]" />}
                    <span className="text-[12px] leading-[15.12px]">{props.dp_data[selectedIndex]?.name}</span>
                  </div>
                  <ArrowDown2 size={14} />
                </div>
              </Dropdown>
              <div className="flex flex-col gap-[4px] items-end">
                <span className="2xl:text-xs xl:text-[10px] font-normal">{props.account_num_name || "Currency"}</span>
                {props.account_num_name ? (
                  <CopyView text={props.wallet_number}>
                    <div className="flex gap-[5px]">
                      <span className="2xl:text-xs xl:text-[10px] font-medium">{props.wallet_number}</span>
                      <Copy variant="Bulk" size={14} />
                    </div>
                  </CopyView>
                ) : (
                  <span className="2xl:text-xs xl:text-[10px] font-medium">{props.currency_name}</span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="2xl:w-[80%] mx-auto mt-[15px] mb-[5px] justify-center flex 2xl:gap-x-[22px] xl:gap-x-[18px] lg:gap-x-[15px] gap-x-[15px]">
        <AppButton
          name={props.btn_name_one || "Fund Wallet"}
          className={`${props.btn1_class || props.card_class} rounded-[8px] 2xl:flex-1`}
          icon={props.icon || <Clock size={18} />}
          onPress={handleSubmit}
        />
        <AppButton
          name={props.btn_name_two || "Withdraw"}
          className={`${props.btn2_class || "!bg-brand02 !border-brand02 !text-brand01"} 2xl:flex-1`}
          icon={props.icon2 || <Send2 size={18} />}
          onPress={handleWithdrawal}
        />
      </div>
      <AppModal open={open} title="Withdraw From Wallet" onClose={() => setOpen(false)} content={<WithdrawContent {...props} wallet_type={props.wallet_type} />} />
    </div>
  );
};

/** individual wallet card for the dashboard */
export const WalletCard = ({ index, ...props }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const wallets = props.item[selectedIndex];

  const handleSwitch = idx => {
    setSelectedIndex(idx);
  };

  return <Wallet {...wallets} selectedIndex={selectedIndex} handleSwitch={handleSwitch} />;
};

export const TotalBalance = props => {
  const total = currencyFormatter(props.total_balance);
  const [visible, setVisible] = useState(false);

  const handleSubmit = () => {
    // if (!props.user_info.txn_pin || !props.user_info.has_identity_documents || !props.user_info.has_adress_documents) {
    //   props.showModal("OPEN_ALERT", true);
    // }else{
    props.showModal("OPEN_MODAL", true);
  };

  return (
    <div className="col-start-1 md:col-end-5 col-end-13 flex px-[20px] py-[20px] bg-[#FFF] justify-between items-start rounded-[8px]">
      <div className="flex flex-col items-start gap-[20px]">
        <div className="flex items-center gap-[20px]">
          <span className="text-[#666] 2xl:text-[16px] xl:text-[14px] font-medium">Portfolio Balance</span>
          <span className={clsx("text-[#1F2734] cursor-pointer")} onClick={() => setVisible(!visible)}>
            {visible ? <Eye size={20} /> : <EyeSlash size={20} />}
          </span>
        </div>
        <span className="text-[#1F2734] 2xl:text-[24px] xl:text-[22px] text-[22px] font-semibold font-grotesk-font">{visible ? total : "*".repeat(10)}</span>
        <AppButton name="Add to Portfolio" icon={<Add />} onPress={handleSubmit} />
      </div>
      <div className="flex 2xl:w-[42px] xl:w-[40px] py-[9px] px-3 flex-col justify-center items-center rounded-[100px] bg-[#FFF4F6]">
        <EmptyWallet className="2xl:w-[24px] xl:w-[20px]" color="#992333" />
      </div>
    </div>
  );
};

export const JoinTribe = props => {
  return (
    <div className="md:col-start-5 col-span-12 md:col-end-9 bg-[#FFF] rounded-lg p-5 pt-2 pb-0 flex flex-shrink-0 justify-between">
      <div className="inline-flex flex-col items-start gap-4 pt-3 2xl:gap-5 xl:gap-4">
        <span className="max-w-[191px] text-primary 2xl:text-base xl:text-[13px] text-sm font-semibold">Join our large tribe of investors</span>
        <span className="text-gray01 2xl:text-xs xl:text-[10px] text-xs font-normal self-stretch">
          An app built to suit your lifestyle <br /> specially designed for you.
        </span>
        <div className="flex items-start gap-[7px]">
          <a href="https://play.google.com/store/apps/details?id=com.gdl_application&hl=en&gl=US" target="_blank" rel="">
            <img src={"/playstore.svg"} className="md:w-auto 2xl:w-[80px] xl:w-[60px] lg:w-[60px] w-[60px]" />
          </a>

          <a href="https://apps.apple.com/us/app/gdl-plus/id6447128737" target="_blank" rel="">
            <img src={"/appstore.svg"} className="md:w-auto 2xl:w-[80px] xl:w-[60px] lg:w-[60px] w-[60px]" />
          </a>
        </div>
      </div>
      <div className="2xl:w-[151px] xl:w-[141px] w-[101px]">
        <img src={"/Device2.svg"} className="object-scale-down h-[100%]" />
      </div>
    </div>
  );
};

export const renderPagination = ({ pages, activePage, onClick }) => {
  return (
    <div className="flex items-center justify-center mt-3">
      {pages.map(page => {
        const isActivePage = activePage === page;
        return (
          <div
            key={page}
            onClick={() => onClick(page)}
            active={isActivePage}
            className={` rounded-2xl h-[3px] mr-2 transition-all duration-300 ${isActivePage ? "w-[40px] bg-brand01 " : "w-[11px] bg-[#D3D8E1]"} cursor-pointer`}
          />
        );
      })}
    </div>
  );
};

export const WalletCardDisplay = ({ data, loading, renderItem, ...rest }) => {
  const renderPagination = ({ pages, activePage, onClick }) => {
    return (
      <div className="flex items-center justify-center mt-3">
        {pages.map(page => {
          const isActivePage = activePage === page;
          return (
            <div
              key={page}
              onClick={() => onClick(page)}
              // active={isActivePage}
              className={` rounded-2xl h-[3px] mr-2 transition-all duration-300 ${isActivePage ? "w-[40px] bg-brand01 " : "w-[11px] bg-[#D3D8E1]"} cursor-pointer`}
            />
          );
        })}
      </div>
    );
  };

  const handlePageChange = page => {
    // alert(JSON.stringify(page));
  };
  return (
    <div className="flex flex-col h-full md:col-start-9 col-start-1 col-end-13 md:row-span-2 row-start-2 rounded-t-lg bg-white justify-start items-center p-5 px-0 gap-y-[20px]">
      <div className="flex items-start justify-between w-full 2xl:px-5 xl:px-3">
        <span className="font-medium text-gray01 2xl:text-base xl:text-sm">My Vaults</span>
        <More size={20} color="#666666" />
      </div>
      {loading ? (
        <SkeletonWallet />
      ) : (
        <Carousel
          itemPadding={window.matchMedia("only screen and (max-width: 768px)").matches ? [0, 0, 0, 0] : [0, 16, 0, 16]}
          showArrows={false}
          renderPagination={renderPagination}
          onChange={handlePageChange}
        >
          {data.map((item, index, arr) => (
            <React.Fragment key={index}>{renderItem({ item: item.wallets, index })}</React.Fragment>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export const ImageCarousel = ({ children, options, plugins }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ ...options }, plugins);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback(index => emblaApi.scrollTo(index), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);
  }, [emblaApi, setSelectedIndex]);
  return (
    <div ref={emblaRef} className="w-full overflow-hidden">
      <div className="flex w-full">{children}</div>
      <div className="flex items-center justify-center mt-1">
        {scrollSnaps.map((_, index) => {
          return (
            <div
              key={index}
              onClick={() => scrollTo(index)}
              className={` rounded-2xl h-[3px] mr-2 transition-all duration-300 ${index === selectedIndex ? "w-[40px] bg-brand01 " : "w-[11px] bg-[#D3D8E1]"} cursor-pointer`}
            />
          );
        })}
      </div>
    </div>
  );
};

export const SetupAccount = () => (
  <div className="bg-white flex p-[20px] items-center gap-[20px] md:row-span-1 row-start-3 col-start-1 md:col-end-9 col-end-13 md:mt-[30px]">
    <div className="flex 2xl:w-[54px] 2xl:h-[54px] xl:w-[40px] xl:h-[40px] 2xl:py-[11px] xl:py-[5px] 2xl:px-[14px] xl:px-[8px] justify-center items-center rounded-full bg-[#FEF7EF]">
      <InfoCircle className="2xl:w-[30px] xl:w-[25px]" color="#FF9C33" />
    </div>
    <div className="flex flex-col items-start 2xl:gap-[10px] xl:gap-[5px] flex-1">
      <span className="font-medium capitalize text-primary 2xl:text-lg xl:text-base">setup your Account</span>
      <span className="text-gray01 2xl:text-[15px] xl:text-[12px]">To have unlimited access to your account, please provide the information below in just 2 steps.</span>
    </div>
  </div>
);

const IdentityCard = ({ title, icon, is_disabled, description, url, ...props }) => (
  <div
    onClick={() => !is_disabled && props.onPress(url)}
    className={`flex flex-1 2xl:min-h-[160px] 2xl:p-[20px] xl:p-[12px] flex-col items-start gap-5 cursor-pointer rounded-lg ${
      is_disabled ? "bg-[#F1F1F1] border-[0.5px] border-[#E4E4E7]" : "bg-white"
    }`}
  >
    <div className="flex items-start gap-5">
      <div className={`2xl:w-[54px] xl:w-[40px] 2xl:h-[54px] xl:h-[40px] flex p-2.5 justify-center items-center rounded-full bg-brand02 ${is_disabled ? "text-[#E1D0D0]" : "text-[#9A2333]"}`}>
        {icon}
      </div>
      <div className="flex flex-col items-start gap-1.5 flex-1">
        <span className="font-medium capitalize text-primary 2xl:text-lg xl:text-base">{title}</span>
        <span className="text-gray01 2xl:text-[14px] xl:text-[10px]">
          {description || (
            <>
              For regulatory compliance, provide your <br /> BVN, valid ID, proof of address.
            </>
          )}
        </span>
      </div>
    </div>
    <Badge content={is_disabled ? "Done" : "Not Done"} className={`${is_disabled ? "text-[#E1D0D0]" : "text-brand01"} bg-brand02 self-end px-3`} />
  </div>
);

export const SecurityView = () => {
  const navigate = useNavigate();
  const { has_doc, has_proof, has_txn_pin } = useHasAccount();

  const setup_card = [
    {
      title: "Verify your identity",
      icon: <ProfileCircle className="2xl:w-[30px]" variant="Broken" />,
      is_disabled: has_doc && has_proof,
      url: "/profile/document",
    },
    {
      title: "Secure your Account",
      icon: <Lock1 className="2xl:w-[30px]" variant="Broken" />,
      is_disabled: has_txn_pin,
      url: "/profile/security",
      description: (
        <>
          Setup your security questions and transaction <br /> PIN.
        </>
      ),
    },
  ];
  return (
    <div className="col-start-1 md:col-end-9 col-end-13 md:row-span-1 row-start-3 md:mt-[30px] gap-[30px] flex flex-col">
      <div className="flex gap-[30px] justify-between">
        {setup_card.map((content, index) => (
          <React.Fragment key={index}>
            <IdentityCard {...content} onPress={() => navigate(content.url)} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export const ProductView = ({ data, renderItem, ...props }) => {
  return (
    <div className="col-start-1 md:col-end-9 col-end-13 md:row-span-1 row-start-3 2xl:mt-[14px] xl:mt-[9px] lg:mt-1 gap-[30px]">
      <AppCarousel data={data} chunkSize={3} renderItem={renderItem} ignore_pagination />
      {/* <Carousel showArrows={false} className="" itemsToScroll={2} itemsToShow={window.matchMedia("only screen and (max-width: 768px)").matches ? 1 : 3} renderPagination={() => <div />}>
        {data.slice(0, 3).map((item, index) => (
          <React.Fragment key={index}>{renderItem({ item, index })}</React.Fragment>
        ))}
      </Carousel> */}
    </div>
  );
};

const Stats = ({ color, title, amount, total }) => {
  const calc = (amount / total) * 100;

  return (
    <div className="flex items-start gap-[10px]">
      <div className={`w-[5px] h-[40px] rounded-[8px]`} style={{ backgroundColor: color }} />
      <div className="flex flex-col items-start gap-[10px]">
        <span className="text-xs font-normal text-gray01">{title}</span>
        <span className={`text-xs font-normal flex`} style={{ color }}>
          {currencyFormatter(amount)}
          <span className="mx-1">/</span>
          {isNaN(calc) ? "0" : calc.toFixed(2)}%
        </span>
      </div>
    </div>
  );
};

const PieChart = ({ chart_data, total }) => (
  <div className="bg-white flex flex-col p-5 gap-10 rounded-lg w-full">
    <span className="font-medium text-gray01">Portfolio Allocation</span>
    <div className="flex items-center justify-items-start w-[90%] m-auto justify-between">
      <div className="flex flex-col 2xl:w-[30%] items-center justify-between">
        <AppPieChart data={chart_data} />
        <div className="flex flex-col items-center gap-[10px]">
          <span className="text-xs font-normal text-gray01">Total Portfolio Value</span>
          <span className="text-[#1F2734] text-xs font-normal flex">{currencyFormatter(total)} / 100%</span>
        </div>
      </div>
      <div className="grid grid-cols-2 items-start 2xl:gap-x-[90px] 2xl:gap-y-[40px] xl:gap-x-[60px] xl:gap-y-[40px]">
        {chart_data.map(content => (
          <Stats title={content.title} amount={content.Amount} color={content.color} total={total} key={content.title} />
        ))}
      </div>
    </div>
  </div>
);

const GraphChart = ({ chart_data, total }) => {
  const [type, setType] = useState("saving");
  const tabs = { Saving: "", Investment: "", "Emergency Fund": "" };
  return (
    <div className="bg-white w-full 2xl:w-[484px] xl:w-auto rounded-lg flex-shrink-0 p-5 flex flex-col gap-y-[35px]">
      <div className="flex items-center justify-between">
        <HeadlessTab tabs={tabs} handlePress={index => setType(Object.keys(tabs)[index])} />
        <div>
          <span className="text-xs text-gray01">2023</span>
        </div>
      </div>
      <span className="font-medium capitalize text-gray01">{type}</span>
      <div>
        <AppAreaChart data={chart_data} total={total} />
      </div>
    </div>
  );
};

export const StatisticView = ({ data, total }) => (
  <>
    <PieChart chart_data={data} total={total} />
    {/* <GraphChart chart_data={data} total={total} /> */}
  </>
);

export const HPAProductCard = props => {
  return (
    <div className="flex px-5 py-3 max-h-[200px] w-full rounded-lg border items-center cursor-pointer border-gdlGrayBorder shadow-sm">
      <div>
        <img src={props.image} className="w-24 2xl:w-14 2xl:h-14 xl:w-14 xl:h-14 lg:h-20 lg:w-20 h-14" />
      </div>
      <div className="flex flex-col"></div>
    </div>
  );
};

export const ProductCard = props => {
  const total = currencyFormatter(props.amount);
  return (
    <div
      {...props}
      className={`w-full flex rounded-lg py-3 px-4 border justify-between items-center cursor-pointer  ${props.row ? "flex-row" : "flex-row-reverse"}`}
      onClick={() => props.onPress(props.id, props.index)}
    >
      <div className={`flex flex-1 ${props.row ? "justify-start" : `${props.justify || "justify-end flex-1"}`} items-end rounded-lg rounded-bl-none rounded-tl-none`}>
        <div className="flex items-center gap-1">
          <div className="w-11 h-11 rounded-full bg-[#FFF4F6] flex justify-center items-center">
            <img src={props.image} className="w-8 h-7" />
          </div>
          <div className="flex flex-col items-start">
            <span className="text-[#8C8C8C]">Product Name</span>
            <span className="font-medium text-primary">{props.name}</span>
          </div>
        </div>
      </div>
      <div className={`flex-1 ${props.row ? "flex justify-end items-center" : ""}`}>
        <div className={`flex flex-col justify-between items-start text-red-800 2xl:px-2 xl:px-2 lg:px-1`}>
          <div className="flex flex-col items-start">
            <span className="text-[#8C8C8C]">Interest Rate</span>
          </div>
          {!props.no_badge && (
            <div className="">
              <Badge content={`${props.interest || 6}% /year`} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ProfileCard = ({ title, sub_title, ...props }) => {
  // const sub_title_data = sub_title;
  const sub_title_data2 = props.sub_two;
  return (
    <CardView className="flex-col justify-center px-3 py-5 border border-gray-200 rounded-lg rounded-br-none md:px-8 md:py-0" align_item="flex-start">
      <div className="title">
        <span className="text-base font-normal text-gray-500">{title}</span>
      </div>
      <div className="flex">
        {sub_title && (
          <div className="sub-title">
            <div className="flex">
              <span className="flex items-end text-4xl font-bold">
                {sub_title}
                {/* <small className="text-xl text-gray-500">
                  .{sub_title_data[1]}
                </small> */}
              </span>
            </div>
          </div>
        )}
        {props.sub_two && (
          <div className="ml-10 sub-title">
            <div className="flex">
              <span className="flex items-end text-4xl font-bold">
                {sub_title_data2}
                {/* <small className="text-xl text-gray-500">
                  .{sub_title_data2[1]}
                </small> */}
              </span>
            </div>
          </div>
        )}
      </div>
    </CardView>
  );
};
export const CardSlide = ({ isLoading, ...props }) => {
  const { base_currency, available_balance, ledger_balance } = props?.content;
  const balance = currencyFormatter(available_balance || "0.00", base_currency);
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    if (!props.user_info?.has_txn_pin || !props.user_info?.has_identity_documents) {
      props.showModal("OPEN_ALERT", true);
    } else {
      props.showModal("OPEN_MODAL", true);
    }
  };

  // console.log(props.show_btn);
  return (
    <div className={`flex px-5 py-4 rounded-lg ${props.className || "bg-[#F3F3F3]"}  w-full h-[130px] items-center`}>
      <div className="flex flex-col items-start flex-1 gap-2">
        <div className="flex items-center gap-3">
          <span className="text-sm">{props.name}</span>
          <span onClick={() => setVisible(!visible)}>{visible ? <Eye size={20} color="#1F2734" variant="Outline" /> : <EyeSlash size={20} color="#1F2734" variant="Outline" />}</span>
        </div>
        <span className="font-semibold text-primary">{visible ? (!isLoading ? currencyFormatter(ledger_balance, base_currency) : balance) : "*".repeat(10)}</span>
        {props.imageSrc === "/safe.svg" && props.showBtn ? (
          <AppButton icon={<Add size="18" color="#FFF" variant="Outline" />} name="Add New Saving" size="190px" onPress={handleClick} />
        ) : (
          <div className="h-10"></div>
        )}
      </div>
      <div className="flex justify-end h-full">
        <div className="flex items-end gap-2">
          <span className="text-sm w-[91px]">{props.desc ? props.desc : "Enjoy Upto 6% Interest Yearly"}</span>
          <img src={props.imageSrc} className="w-16 h-fit" />
        </div>
      </div>
    </div>
  );
};

export const TopCardDisplay = props => {
  // console.log(props);
  return (
    <CardLayout>
      {props.isCard ? (
        <CardSlide content={props.wallet || {}} {...props} />
      ) : (
        <ProfileCardLayout>
          <ProfileCard title={props.title} sub_title={props.sub_title} {...props} />
        </ProfileCardLayout>
      )}
    </CardLayout>
  );
};

export const StatisticCard = ({ title, number, ...props }) => (
  <CardView
    className="flex flex-col items-start justify-between px-1 py-1 border rounded-md sm:items-center sm:p-0"
    style={{
      backgroundColor: props.isActive ? "#992333" : "#fff",
      color: props.isActive ? "#fff" : "#978E8E",
    }}
  >
    <div>{/* <AiOutlineBarChart size={30} fontWeight="800" /> */}</div>
    <div className="flex items-center justify-center px-5 my-1 text-center title">
      <span className="">{(title || "Transaction").toUpperCase()}</span>
    </div>
    <div className="sub-title">
      <span className="font-bold">{number || 75}</span>
    </div>
  </CardView>
);
export const CardLayout = ({ children }) => <div className="flex justify-between w-full">{children}</div>;

export const CardStatisticLayout = ({ children }) => <div className="items-center justify-center hidden w-full grid-cols-3 mx-0 sm:grid sm:gap-4 gap-x-2 sm:w-2/5 sm:mx-2">{children}</div>;

export const ProfileCardLayout = ({ children }) => <div className="grid items-center w-full grid-cols-1 mx-0 sm:w-3/5 sm:mx-2">{children}</div>;

export const DetailCard = props => (
  <div className="w-full h-full p-4 px-2 m-auto overflow-y-auto bg-black rounded-lg 2xl:px-4">
    {props.loading == true && Object.keys(props.cardInfo).length <= 0 ? (
      <div className="flex items-center justify-center w-full h-full">
        <Loader loading={props.loading} />
      </div>
    ) : (
      <div className="p-4" style={{ height: 650 }}>
        <div className="flex flex-col items-start justify-center pb-5 text-white 2xl:flex-row gap-y-3 2xl:justify-between 2xl:items-center hover:border-red-800">
          <span className="text-sm 2xl:text-base">BASIC INFORMATION</span>
          {props.hasBtn && <AppButton name="Update Profile" bg="transparent" className="border-2 rounded-lg" onPress={props.onPress} />}
        </div>
        <Divider />
        {Object.keys(props.cardInfo).map((content, index) => (
          <>
            <div className="flex items-center justify-between my-3 text-white">
              <div className="flex flex-col">
                <span className="text-sm font-light sm:text-base">{content}</span>
                <span className="text-sm font-semibold sm:text-base">{props.cardInfo[content]}</span>
              </div>
            </div>
            <Divider />
          </>
        ))}
      </div>
    )}
  </div>
);

export const HeaderProfile = props => (
  <ProfileHeaderView className={`flex flex-col rounded-2xl mt-4 ${props.className || "md:mx-6 mx-3 items-center"}`}>
    <div className="mb-1">
      <span className="text-base font-semibold text-white capitalize">{props.name}</span>
    </div>
    <div className="">
      <span className="text-sm font-medium text-white opacity-80">{props.description}</span>
    </div>
  </ProfileHeaderView>
);
