import React from "react";
import { connect } from "react-redux";
import DetailsCard from "../../components/Card/DetailsCard";
import { Badge } from "../../components/Widget/Badge/Badge";
import { IoWarning } from "react-icons/io5";

const FeeContent = props => {
  const feeObject = {
    name: props.details?.txn_fee?.recipient_account,
    amount: props.details?.txn_fee?.amount,
    row_two: {
      Channel: props.details?.txn_fee?.channel_id,
      "Fee Type": props.details?.txn_fee?.transaction_type,
      "Fee Status": (
        <Badge content={props.details?.txn_fee?.transaction_status} />
      ),
    },
  };
  return (
    <div>
      {props.details?.txn_fee != null ? (
        <DetailsCard isFee {...feeObject} />
      ) : (
        <div className="flex justify-center bg-gray-50 items-center py-10 px-5">
          <span className="md:text-sm text-sm px-5 py-3 flex items-center leading-5 rounded-lg bg-gray-50 text-gray-500">
            <IoWarning size={20} /> There are currently no available data for
            this.
          </span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  details: state.transaction.user_trans_detail.data,
});

export default connect(mapStateToProps, {})(FeeContent);
