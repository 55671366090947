import React, { useEffect } from "react";
import { connect } from "react-redux";
import { TopCardDisplay, DetailCard } from "../../components/Card/Card";
import { tabNames } from "./trans.data";
import { DetailTabs } from "../../components/DetailsComponent/DetailsTab";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_USER_TRANS_DETAIL } from "../../store/actions/action_types/action.types";
import { transactionURL } from "../../utils/endpoints";
import { FiArrowLeft } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

const TransactionDetails = ({ token, loading, ...props }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const top_data = {
    title: "Total Wallet Balance",
    sub_title: props.user_wallet[0]?.available_balance || "0.00",
    statistics: {
      "Total Debits": "N/A",
      "Total Credits": "N/A",
      "Total Rate": "N/A",
    },
  };

  useEffect(() => {
    let path_id = pathname;
    path_id = path_id.split("/");
    path_id = path_id[path_id.length - 1];
    const url = `${transactionURL}/${path_id}`;
    props.getAction(GET_USER_TRANS_DETAIL, url, token);
  }, []);

  const cardInfo = {
    // "User Name": JSON.parse(details?.user_object)?.first_name,
    // "Txn ref": props.details?.txn_ref,
    // "Request ref": props.details?.request_ref,
    "Debit account": props.details?.debited_account,
    Amount: props.details?.amount,
    "Channel id": props.details?.channel_id,
    "Reciever's Name": props.details?.recipient_Name,
    "Transaction type": props.details?.transaction_type,
    "Transaction status": props.details?.transaction_status,
    // "Internal status": props.details?.transaction_type,
    // "Transaction location": props.details?.transaction_location,
    Narration: props.details?.narration,
    // "Parent txn id": props.details?.parent_txn_id,
    "Txn service code": props.details?.txn_service_code,
    Reason: props.details?.reason,
    "Txn date": `${new Date(props.details?.txn_date).toLocaleDateString()} -- ${new Date(props.details?.txn_date).toLocaleTimeString()}`,
    // "User object": props.details?.user_object,
    "Recipient instituition name": props.details?.recipient_institution_name || "N/A",
    "Recipient instituition logo url": props.details?.recipient_institution_logo_url || "N/A",
    "Recipient account": props.details?.recipient_account,
    "Recipient name": props.details?.recipient_Name || "N/A",
    "Instituition tnx ref": props.details?.institution_txn_ref || "N/A",
    Token: props.details?.token,
    "Instituition response code": props.details?.instituition_response_code,
  };
  return (
    <div className="py-4 m-auto" style={{ width: "95%" }}>
      <div className="px-5">
        <FiArrowLeft className="font-semibold cursor-pointer" size={25} color="#922333" onClick={() => navigate(-1)} />
      </div>
      <TopCardDisplay {...top_data} />
      <div className="grid grid-cols-3 py-2">
        <div className="col-span-1">
          <DetailCard cardInfo={cardInfo} loading={loading} />
        </div>
        <div className="mx-4 p-4 bg-gray-100 rounded-lg col-span-2">
          <DetailTabs tabs={tabNames} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.transaction.user_trans_detail.loading,
  details: state.transaction.user_trans_detail.data,
  user_wallet: state.account.get_wallets.data,
  //   user_transactions_detail: state.transaction.user_trans_detail.data,
});

export default connect(mapStateToProps, { getAction })(TransactionDetails);
