import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { HeadlessTab } from "../../components/DetailsComponent/DetailsTab";
import { setDataId, showModal } from "../../store/actions/creators/ui.action";
import { currencyFormatter } from "../../utils/helper";
import { getAction } from "../../store/actions/creators/request.action";
import InvestmentWrapper from "./InvestmentWrapper";
import { ArrowLeft2 } from "iconsax-react";
import InvestInfo from "./InvestInfo";
import InvestInterestHistory from "./InterestHistory";
import { useLocation, useNavigate } from "react-router-dom";

const InvestmentDetails = ({ loading, token, ...props }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [walletId, setWalletId] = useState(0);
  const [type, setType] = useState("Details");
  const tabs = { Details: "", "Interest History": "" };

  useEffect(() => {
    let path_id = pathname;
    path_id = path_id.split("/");
    path_id = path_id[path_id.length - 1];
    setWalletId(path_id);
    props.setDataId(path_id);
  }, []);

  return (
    <InvestmentWrapper>
      <div className="bg-white w-full col-span-6 md:col-span-4 h-full py-5 px-12">
        <div className="flex justify-between w-7/12">
          <ArrowLeft2 size="24" onClick={() => navigate(-1)} className="cursor-pointer" />
          <HeadlessTab
            tabs={tabs}
            handlePress={index => {
              setType(Object.keys(tabs)[index]);
            }}
          />
        </div>
        <div className="w-full mt-5 flex sm:flex-row flex-col-reverse gap-x-5">
          {type === "Details" && <InvestInfo />}
          {type === "Interest History" && <InvestInterestHistory />}
        </div>
      </div>
    </InvestmentWrapper>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_wallet: state.account.get_wallets.data,
  invest_data: state.investment.get_sub_detail.detail_data,
  int_detail_data: state.investment.invest_history.int_detail_data,
  int_detail_loading: state.investment.invest_history.loading,
  interest_data: state.investment.interest_history.data,
  open: state.ui.modal.show,
});

export default connect(mapStateToProps, { setDataId, showModal, getAction })(InvestmentDetails);
