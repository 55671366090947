import { Add, EmptyWalletTime, Graph, ShieldSecurity, StatusUp, TrendUp, EmptyWallet, Send2, ExportSquare, ArrowUp2, ArrowDown2 } from "iconsax-react";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SubBarItem } from "../Topbar";
import { headerResolver } from "../../../utils/helper";
import { useDispatch } from "react-redux";
import { showModal } from "../../../store/actions/creators/ui.action";
import { AppButton } from "../../Widget/Button/AppButton";
import Dropdown from "../../Widget/Dropdown/Dropdown";
import { AssetDropdownContent } from "../../Products/assets/asset.dropdown";
import { useHeaderResolver } from "../../../hooks/path.hook";

// should move this to a seperate component
const AssetMgtDropdown = () => (
  <Dropdown className="md:-left-5 rounded-[10px] shadow-custom02 ring-0" content={<AssetDropdownContent />}>
    <div className="flex gap-[5px] items-center">
      <span>Asset Mgt.</span>
      <ArrowDown2 className="md:w-[18px] md:h-[18px]" />
    </div>
  </Dropdown>
);

// product link... could be moved somewhere else
export const product_link = [
  { name: "Invest", icon: <StatusUp size={18} />, url: "products/invest", ignore_navigation: true },
  { name: "Save", icon: <ShieldSecurity size={18} />, url: "products/savings", ignore_navigation: true },
  { name: <AssetMgtDropdown />, icon: <TrendUp size={18} />, url: "products/assets/subscription", ignore_navigation: true },
  { name: "Loans", icon: <Graph size={18} />, url: "products/emergency-funds", ignore_navigation: true },
  { name: "Timed Deposit", icon: <EmptyWalletTime size={18} />, url: "products/timed-deposit" },
];

// payment links...could be moved somewhere else
export const payment_links = [
  { name: "Timed Deposit", url: "products/timed-deposit", icon: <EmptyWalletTime size={18} /> },
  { name: "Fund Account", url: "dashboard/fund-account/bank-transfer", icon: <EmptyWallet size={18} /> },
  { name: "Send Money", url: "withdrawal", icon: <Send2 size={18} /> },
];

const CustomSubtab = ({ sub_link, btnPress }) => {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { path, path_arr } = useHeaderResolver();

  const path_for_btn = ["transactions", "timed deposit", "subscription"]; // url path that should have a button

  const include_btn = useMemo(() => path_for_btn.find(content => content === path));

  // function to know when icon should be bold variant or outline variant
  const activeSublink = link => {
    return path === link ? "Bold" : "Outline";
  };

  /** modal can't be here because this component needs to be reusable and modal needs to close when post request is successful
   * except you want to use props drilling to close modal, state management can be used
   */
  const handleOpenTimedeposit = () => {
    dispatch(showModal("OPEN_MODAL", true));
  };

  //function to render the right button
  const renderActionBtn = useMemo(() => {
    const action_btn = {
      transactions: <AppButton onPress={btnPress} size={"210px"} name="Generate Statement" icon={<ExportSquare size="16" color="#FFF" variant="Outline" />} />,
      "timed deposit": <AppButton name="Request New Deposit" icon={<Add size={18} color="#FFF" variant="Outline" />} onPress={handleOpenTimedeposit} className="py-2 border-0 !rounded-lg" />,
      subscription: (
        <AppButton
          name="Request New Subscription"
          icon={<Add size={18} color="#FFF" variant="Outline" />}
          onPress={() => navigate(`/products/assets/${path_arr[3]}`)}
          className="py-2 border-0 !rounded-lg"
        />
      ),
    };

    return action_btn[include_btn];
  }, [include_btn, location]);

  return (
    <>
      <div className="flex bg-white items-center justify-between px-8 py-2 border-y border-gray02">
        <div className="flex items-center gap-7">
          <span className="text-[#D3D8E1]">Quick Links</span>
          {sub_link.map((link, index) => {
            const link_splitter = link.url.split("/");
            const isActive = path_arr.map(content => content.replace(/-/g, " ")).includes(link_splitter[link_splitter.length - 1].replace(/-/g, " "));
            return (
              <div key={index} className={`${isActive ? "text-brand01 text-opacity-100" : "text-[#666666]"} cursor-pointer hover:text-brand01`}>
                <SubBarItem
                  name={link.name}
                  icon={<link.icon.type size={18} variant={activeSublink(link_splitter[link_splitter.length - 1].replace(/-/g, " "))} />}
                  href={!link.ignore_navigation && link.url}
                />
              </div>
            );
          })}
        </div>
        {path == include_btn && renderActionBtn}
      </div>
    </>
  );
};

export default CustomSubtab;
