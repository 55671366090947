import clsx from "clsx";
import { Copy } from "iconsax-react";
import { useState } from "react";

export const CopyView = ({ variant = "Bulk", text, className, children = null }) => {
  const [copied, setCopied] = useState(false);

  const copyTextToClipboard = async text => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  // onClick handler function for the copy button
  const handleCopy = () => {
    // Asynchronously call copyTextToClipboard
    const copy_text = copyTextToClipboard(text);

    if (copy_text) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  };

  return (
    <div className={clsx("flex items-center gap-[5px] cursor-pointer", className)} onClick={handleCopy}>
      {(copied ? <span className="2xl:text-xs xl:text-[10px] font-medium">Copied</span> : children) ?? (
        <>
          <Copy variant={variant} size="24" color="#9A2323" />
          <small className="underline underline-offset-4 text-xs font-normal text-brand01">{copied ? "Copied" : "Copy"}</small>
        </>
      )}
    </div>
  );
};
