import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import ReactHtmlParser from "react-html-parser";
import Loader, { LoaderII } from "../../components/Widget/Loader/Loader";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { dcryptPublicKey } from "../../utils/helper";
import { clearState, setSubscribed } from "../../store/actions/creators/ui.action";

const config = {
  public_key: "FLWPUBK-**************************-X",
  tx_ref: Date.now(),
  amount: 100,
  currency: "NGN",
  payment_options: false,
  customer: {
    email: "user@gmail.com",
    phone_number: "070********",
    name: "john doe",
  },
  customizations: {
    title: "my Payment Title",
    description: "Payment for items in cart",
    logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
  },
  // redirect_url: "",
};

const Checkout = ({ token, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [public_key, setPublicKey] = useState("");
  const [status, setStatus] = useState(true);
  const data = { ...config, ...props.card_fund, amount: Number(props.card_fund.amount), public_key };
  const handleFlutterPayment = useFlutterwave(data);

  const handleFlutterwavePayment = () => {
    handleFlutterPayment({
      callback: response => {
        console.log("callback response", response);
        if (response.status == "successful") {
          navigate(-1);
          // call the subscribed action
          dispatch(setSubscribed("OPEN_SUBSCRIBE"));
        }
        closePaymentModal();
        setStatus(false);
      },
      onClose: () => {
        navigate(-1);
      },
    });
  };

  useEffect(() => {
    const decrypt = dcryptPublicKey(process.env.REACT_APP_TK_EKY, props.card_fund.public_key);
    setPublicKey(decrypt);
  }, []);

  useEffect(() => {
    if (props.card_fund && public_key) handleFlutterwavePayment();
  }, [props.card_fund, public_key]);

  return (
    <div className="flex justify-center items-center h-[100dvh]">
      <div className="flex flex-col items-center">
        {status && (
          <>
            <Loader loading={status} color="#9A2333" />
            <span>Loading Payment Gateway</span>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  checkout: state.account.card.data,
  checkout_loading: state.account.card.loading,
  confirm_data: state.ui.modal.confirm_data,
  card_fund: state.account.card.fund_data,
});

export default connect(mapStateToProps, {})(Checkout);
