import React from "react";
import { connect } from "react-redux";
import { usePagination, DOTS } from "./usePagination";
import { PaginationContainer } from "./pagination.style";
import classnames from "classnames";

const Pagination = props => {
  const { totalCount, siblingCount = 1, currentPage, pageSize, onPageChange } = props;
  const paginationRange = usePagination({
    totalCount,
    siblingCount,
    currentPage,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) return null;

  const handleNext = () => {
    onPageChange(currentPage + 1);
  };

  const handlePrevious = () => {
    onPageChange(currentPage - 1);
  };
  let lastPage = paginationRange ? paginationRange[paginationRange.length - 1] : 1;
  return (
    <PaginationContainer className={classnames("h-14")}>
      <li
        className={classnames("pagination-item", {
          disabled: currentPage == 1,
        })}
        onClick={handlePrevious}
      >
        <div className={`p-2 rounded-lg flex justify-center items-center navitem border border-gray02 ${currentPage == 1 ? "text-[#D3D8E1] cursor-not-allowed" : ""}`}>Prev</div>
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li key={index} className="pagination-item dots">
              &#8230;
            </li>
          );
        }
        return (
          <li
            key={index}
            className={classnames("pagination-item numbers", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => {
              onPageChange(pageNumber);
            }}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames("pagination-item", {
          disabled: currentPage == lastPage,
        })}
        onClick={handleNext}
      >
        <div className={`p-2 rounded-lg flex justify-center items-center navitem border border-gray02 ${currentPage == lastPage ? "text-[#D3D8E1] cursor-not-allowed" : ""}`}>Next</div>
      </li>
    </PaginationContainer>
  );
};

export default connect(null, {})(Pagination);
