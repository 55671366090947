import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  data: null,
  error_msg: null,
};

const addAssetFundReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_ASSET_MGT_FUND:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_ASSET_MGT_FUND:
      return {
        ...state,
        data: action.payload.data.data,
        loading: false,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        loading: false,
        error: false,
        data: null,
        error_msg: null,
      };
    default:
      return state;
  }
};

const getAssetFundReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_ASSET_MGT_FUND:
      return {
        ...state,
        loading: true,
      };
    case type.GET_ASSET_MGT_FUND:
      return {
        ...state,
        data: action.payload.data.data,
        loading: false,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        loading: false,
        error: false,
        data: null,
        error_msg: null,
      };
    default:
      return state;
  }
};

const assetMgtReducer = combineReducers({
  add_asset: addAssetFundReducer,
  get_asset: getAssetFundReducer,
});

export default assetMgtReducer;
