import React from "react";
import { connect } from "react-redux";

const SaveHistory = props => {
  return <div>SaveHistory</div>;
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
});

export default connect(mapStateToProps, {})(SaveHistory);
