import { Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CardHeader } from "../../Common/common";
import { useEffect, useState } from "react";
import AppModal from "../../Widget/Modal/Modal";
import { AssetSubscriptionIntro } from "./intro";
import { useHeaderResolver } from "../../../hooks/path.hook";
import { useDispatch } from "react-redux";
import { setNextOfKin } from "../../../store/actions/creators/ui.action";

const AssetWrapper = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { path, path_arr } = useHeaderResolver();
  const asset_name = path_arr[3].replace(/-/g, " ");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    /* check if user is on subscription page and if it's not coming from the product page
     then show modal so they agree to what they're subscribing to**/
    if (!state && path != "subscription") setOpen(true);
  }, [state, path]);

  /** trick to know when to clear nextofkin */
  useEffect(() => {
    if (path_arr.length != 4) {
      dispatch(setNextOfKin(null));
    }
  }, [path_arr]);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="flex flex-col p-[26px_32px] gap-[27px] rounded-lg bg-white">
        <CardHeader name={asset_name ?? "Bank Details"} goBack={() => navigate(-1)} />
        <Outlet />
      </div>
      <AppModal open={open} title={asset_name} onClose={() => setOpen(false)} content={<AssetSubscriptionIntro onClose={closeModal} />} className="w-[40%]" />
    </>
  );
};

export default AssetWrapper;
