import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import HouseMoniHeader from "../../../components/HouseMoni/HouseMoniHeader";
import { ActivityRow } from "../../../components/HouseMoni/HouseMoniSubComps";
import { FlatList } from "../../../components/Widget/List/List";
import Loader from "../../../components/Widget/Loader/Loader";

const HouseMoniActivities = props => {
  const group_members = props.housemoni_subs?.group.group_members;
  const navigate = useNavigate();

  const navigateToMember = id => () => {
    navigate(`${id}`);
  };

  const renderItem = ({ item, index }) => <ActivityRow key={index} {...item} onPress={navigateToMember} />;

  return (
    <div className="flex w-full py-5 px-[52px] flex-col items-start gap-[20px]">
      <HouseMoniHeader title="Group Activities" />

      {group_members == null ? (
        <div className="flex self-stretch justify-center h-[50dvh] items-center">
          <Loader loading={true} color="#9A2333" />
        </div>
      ) : (
        <div className="flex flex-col items-start self-stretch overflow-y-auto">
          <span className="text-gray05">Group Members</span>
          <FlatList data={group_members} renderItem={renderItem} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  hpa_group_details: state.hpa.hpa_group_details.data,
  housemoni_subs: state.hpa.get_hpa_sub.hpa_data,
});
export default connect(mapStateToProps, {})(HouseMoniActivities);
