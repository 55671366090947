import { useMemo } from "react";
import { useSelector } from "react-redux";
import { processChartData, processData } from "../pages/Home/home.data";

export const useChartData = () => {
  const user_wallet = useSelector(state => state.account.get_wallets.data);
  const card_info = useMemo(() => user_wallet.map(processData), [user_wallet]);

  const chart_data = useMemo(
    () =>
      [
        {
          title: "Main",
          color: "#9A2333",
          Amount: Math.round(card_info[0]?.wallets[1]?.ledger_balance),
          Sales: 200,
        },
        {
          title: "Investment",
          color: "#3BB3C2",
          Amount: Math.round(card_info[2]?.wallets[0]?.ledger_balance),
          Sales: 100,
        },
        {
          title: "Savings",
          color: "#C32AFD",
          Amount: Math.round(card_info[1]?.wallets[0]?.ledger_balance),
          Sales: 120,
        },
        {
          title: "HouseMoni Contribution",
          color: "#FF9C33",
          Amount: Math.round(card_info[3]?.wallets[0]?.balance),
          Sales: 120,
        },
      ].map(processChartData),
    [card_info]
  );

  return { chart_data };
};
