import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { processData } from "./fund.data";
import { postAction, getAction } from "../../store/actions/creators/request.action";
import { GET_EXCHANGE_RATES, GET_FUND_TYPE, GET_VIRTUAL_ACCOUNT } from "../../store/actions/action_types/action.types";
import { exchangeRateURL, fundTypeURL, virtualAccountURL } from "../../utils/endpoints";
import { showModal } from "../../store/actions/creators/ui.action";
import AppModal from "../../components/Widget/Modal/Modal";
import { OpenBVNModal } from "./FundContent";
import { useNavigate } from "react-router-dom";
import { showIframe } from "../../store/actions/creators/ui.action";
import { currencyFormatter } from "../../utils/helper";
import Loader from "../../components/Widget/Loader/Loader";
import { FundRow } from "../../components/Widget/List/FundRow";
import { FlatList } from "../../components/Widget/List/List";
import { AppButton } from "../../components/Widget/Button/AppButton";
import Wrapper from "../../components/FundWithdraw/Wrapper";
import { USDModalComp } from "../../components/FundWithdraw/USDModalComp";
import { InfoCircle } from "iconsax-react";

const FundOptions = ({ token, loading, ...props }) => {
  const bal = currencyFormatter(props.user_wallet[0]?.available_balance) || 0;
  const [usdopen, setUsdOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    /** get all the funding options from the server if a user has a wallet */
    if (props.user_wallet != null) {
      const user_id = props.user_info?.id;
      const wallet_ref = props.user_wallet[0]?.wallet_ref;

      const data = { user_id, wallet_ref }; // data needed to get funding options

      // if the state has no fund type call api...this is to prevent recalls of API
      if (props.fund_type == null) {
        props.postAction(GET_FUND_TYPE, fundTypeURL, data, token);
      }

      // get user virtual account for bank transfer, store data in redux store so you can use when you navigate to bank transfer
      const url = `${virtualAccountURL}/${user_id}/${props.user_wallet[0]?.wallet_ref}`;
      props.getAction(GET_VIRTUAL_ACCOUNT, url, token);

      // if user has no BVN, show a modal for them to setup their BVN
      if (!props.user_wallet[0]?.wallets[1]?.bvn) {
        setOpen(true);
      }
      // }
    }
  }, [props.user_wallet]);

  /** get exchange rate */
  useEffect(() => {
    props.getAction(GET_EXCHANGE_RATES, exchangeRateURL, token);
  }, []);

  const handleNavigate = (url, name) => {
    // check if item is USD debit
    if (name == "USD Debit/Credit Card") {
      setUsdOpen(true);
    } else {
      navigate(url);
    }
  };

  const openRatePage = () => {
    navigate("usd-rate");
  };

  const renderItem = ({ item, index }) => <FundRow {...item} index={index} handlePress={handleNavigate} key={index} />;

  const cardInfo = props.fund_type?.map(processData);

  return (
    <>
      {/* {JSON.stringify(props.user_wallet[0].wallets)} */}
      {props.fund_type_load ? (
        <div className="h-full flex justify-center items-center">
          <Loader loading={props.fund_type_load} color="#922333" />
        </div>
      ) : (
        <>
          {props.user_wallet != null && (
            <Wrapper error={!props.fund_type}>
              {props.fund_type ? (
                <div className="flex flex-col items-end gap-10 self-stretch">
                  <div className="flex flex-col items-start gap-10 self-stretch">
                    <FlatList data={cardInfo} renderItem={renderItem} />
                  </div>
                  <AppButton variant="outline" name="cancel" />
                </div>
              ) : (
                <div className="self-stretch flex flex-col items-center text-gray05 py-5 gap-3">
                  <InfoCircle className="sm:w-[45px] sm:h-[45px] w-[24px] h-[24px] text-brand01" variant="Bold" />
                  <span>Could not fetch fund options</span>
                </div>
              )}
            </Wrapper>
          )}
          <AppModal
            open={usdopen}
            className="w-[750px]"
            title="Exchange Rates"
            onClose={() => setUsdOpen(false)}
            content={<USDModalComp rate={props?.exchange_rates[0]} close={() => setUsdOpen(false)} handleNavigate={openRatePage} />}
          />
          <AppModal
            open={open}
            title="Setup BVN"
            onClose={() => {
              return null;
            }}
            content={<OpenBVNModal {...props} setOpen={setOpen} />}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal.show,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  wallet_loading: state.account.get_wallets.loading,
  loading: state.account.fund_type.loading,
  fund_type: state.account.fund_type.payment_options,
  fund_type_load: state.account.fund_type.sending,
  confirm_open: state.ui.modal.show_confirm,
  confirm_data: state.ui.modal.confirm_data,
  total_bal: state.account.get_wallets.total_bal,
  open_pin: state.ui.modal.show_profile_modal,
  ip_data: state.auth.get_ip.data,
  exchange_rates: state.account.exchange_rates.rates,
});

export default connect(mapStateToProps, {
  postAction,
  showModal,
  showIframe,
  getAction,
})(FundOptions);
