import React from "react";
import { Routes, Route } from "react-router-dom";
import * as pages from "../../../../../pages";

const MoneyMarketRoute = () => {
  return (
    <>
      <Routes>
        <Route exact path={`/`} element={<pages.MoneyMarketFund />} />
        <Route path={`/subscription`} element={<pages.MoneyMarketSubscriptions />} />
      </Routes>
    </>
  );
};

export default MoneyMarketRoute;
