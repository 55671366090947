import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import rootReducer from "./indexReducer";
import { configureStore } from "@reduxjs/toolkit";
import { authApi, fixedDepositApi, investApi, savingsApi } from "../services";

let persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE", "persist/REGISTER"],
        // Ignore these paths in state
        ignoredPaths: ["register"],
      },
    })
      .concat(thunk)
      .concat(investApi.middleware)
      .concat(savingsApi.middleware)
      .concat(fixedDepositApi.middleware),
});

export const persistor = persistStore(store);
