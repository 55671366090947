import { Badge } from "../../components/Widget/Badge/Badge";
import { currencyFormatter, formatDate } from "../../utils/helper";
import FeeContent from "./FeeContent";

export const processData = currentPage => (data, index) => ({
  id: data.id,
  "S/N": 20 * (currentPage - 1) + (index + 1),
  "Transaction Ref": data.txn_ref,
  Amount: currencyFormatter(data.amount),
  "User Name": JSON.parse(data.user_object).first_name,
  // "Debited Account": data.debited_account,
  Date: new Date(data.txn_date).toLocaleDateString(),
  "Transaction Type": <Badge content={data.transaction_type} color={data.transaction_type.toUpperCase() == "CREDIT" ? "bg-green-100 text-green-500" : "bg-red-100 text-red-500"} />,
  Narration: data.narration,
  "Transaction Status": (
    <Badge
      content={data.transaction_status}
      color={data.transaction_status == "PENDING" ? "bg-yellow-100 text-yellow-500" : data.transaction_status == "COMPLETED" ? "bg-green-100 text-green-500" : "bg-red-100 text-red-800"}
    />
  ),
  "Receiver Account": data.recipient_account,
});

export const statementProcessData = currentPage => (data, index) => ({
  "Trans Date": formatDate(new Date(data.txn_date)),
  Reference: data.txn_ref,
  Debits: data?.transaction_type == "DEBIT" ? data.amount : "",
  Credits: data?.transaction_type != "DEBIT" ? data.amount : "",
  "Transaction Status": (
    <Badge
      content={data.transaction_status}
      color={data.transaction_status == "PENDING" ? "bg-yellow-100 text-yellow-500" : data.transaction_status == "COMPLETED" ? "bg-green-100 text-green-500" : "bg-red-100 text-red-800"}
    />
  ),
  Channel: data.channel_id,
  Narration: data.narration,
});

export const txnProcessData = (data, index) => ({
  txn_date: data.txn_date,
  trxn_status: data.transaction_status,
  amount: data.amount,
  Narration: data.narration,
});

export const tabNames = {
  "Transaction Fee": <FeeContent />,
  "Bulk Transactions": (
    <div className="w-3/5 bg-gray-50 shadow p-5 m-auto rounded-lg flex justify-center items-center h-full">
      <span className="text-sm font-semibold opacity-60">Coming soon</span>
    </div>
  ),
  "Scheduled Transactions": (
    <div className="w-3/5 bg-gray-50 shadow p-5 m-auto rounded-lg flex justify-center items-center h-full">
      <span className="text-sm font-semibold opacity-60">Coming soon</span>
    </div>
  ),
};

export const top_data = data => ({
  title: "All Transactions",
  sub_title: "",
  statistics: {
    "Total Transaction": 75,
    "Total Debit": "75,000",
  },
});
