import React, { useState } from "react";
import { ProductCard } from "../../Card/Card";
// import InvestImage from "../../../assets/images/invest2.png";
import { FiEyeOff } from "react-icons/fi";
import Pagination from "../Pagination/Pagination";
import Loader from "../Loader/Loader";
import { EmptyView2 } from "../EmptyView/EmptyView";
import { useNavigate } from "react-router-dom";

const NUMBER_ON_PAGE = 2;
export const ProductRow = props => {
  const navigate = useNavigate();
  return (
    <>
      {props.investData?.map((content, index) => (
        <ProductCard image={props.img || InvestImage} {...content} bg_color="#f2f2f2" onPress={() => navigate(`${content.id}`)} amount={"0"} />
      ))}
    </>
  );
};

const SubCard = ({ content, index, ...props }) => {
  const [visible, setVisible] = useState(content.hidden);
  const navigate = useNavigate();
  return (
    <>
      <div
        key={index}
        className={`cursor-pointer ${
          visible && props.showhidden == false ? "text-green-200 hidden" : props.showhidden == true && visible == true ? "block" : props.showhidden == false ? "block" : "hidden"
        }`}
      >
        {visible == true && <div className="bg-gray-50 absolute w-full h-[70px] opacity-70" />}
        <div className="flex items-center justify-between gap-x-2 px-5 bg-gray-50">
          <div className="min-h-[75px] flex items-center  w-[98%] gap-x-10 justify-between bg-gray-50 rounded-lg" onClick={() => navigate(`${content.id}`)}>
            {Object.keys(content).map((item, index) => (
              <>
                {item != "id" && item != "hidden" && (
                  <tr className="flex flex-1 flex-col">
                    <td className="text-sm text-gray-500 font-medium capitalize">{item}</td>
                    <td className={`font-medium text-sm ${item == "interest" ? "text-green-500" : ""}`}>{item == "interest" ? (parseFloat(content[item]) * 100) / 100 + "%" : content[item]}</td>
                  </tr>
                )}
              </>
            ))}
          </div>
          <div
            className="h-full min-w-[70px] z-10 flex items-center justify-center justify-items-end"
            onClick={() => {
              setVisible(!visible);
              props.hideView(content.id);
            }}
          >
            <>
              {visible ? (
                <div className="flex w-full">
                  <span className="text-sm hover:underline font-medium text-[#992333]">Undo Hide</span>
                </div>
              ) : (
                <div className="flex flex-col items-center gap-y-1 text-[#992333]">
                  <FiEyeOff color="#992333" />
                  <span className="text-sm font-medium">Hide</span>
                </div>
              )}
            </>
          </div>
        </div>
        <hr className="border-gray-100 mt-2" />
      </div>
    </>
  );
};

export const ProductSubRow = ({ children, ...props }) => {
  const [currentPage, setCurrentPage] = useState(props.paginate_num || 1);
  const handlePageChange = page => {
    setCurrentPage(page);
    props.setPagination(page);
  };
  return (
    <div className="flex flex-col justify-between col-start-1 col-end-9 ">
      {props.loading ? (
        <div className="flex justify-center items-center w-full h-[50dvh]">
          <Loader loading={props.loading} color="#9A2333" />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-5">
            {props.total <= 0 ? (
              <div className="flex h-[50dvh] justify-center">
                <EmptyView2 />
              </div>
            ) : (
              children
            )}
          </div>
          {props.total > 0 && (
            <div className="flex justify-center mt-7">
              <Pagination totalCount={props.total} pageSize={NUMBER_ON_PAGE} currentPage={currentPage} onPageChange={handlePageChange} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
