import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AppButton } from "../../components/Widget/Button/AppButton";
import Wrapper from "../../components/FundWithdraw/Wrapper";
import { TextField } from "../../components/Widget/Form/FormComponent";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const USDPayment = ({ token, loading, ...props }) => {
  const [amounts, setAmounts] = useState(0);
  const navigate = useNavigate();
  const { control, formState, register, watch, handleSubmit } = useForm({
    mode: "onChange",
  });

  /** listen for when amount changes and only make button active when amount is 50 and above */
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name == "amount" && +value.amount) {
        setAmounts(value.amount);
      }
      if (value.amount == "") {
        setAmounts(0);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /** submit data */
  const onSubmit = data => {
    //
  };

  const handleCancel = e => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <Wrapper name="USD Debit / Credit Card" desc="Your are funding your main wallet.">
      <form className="flex flex-col gap-10 self-stretch items-end" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-start self-stretch gap-10">
          <TextField label="Amount" type="currency" name="amount" prefix={"$"} control={control} placeholder="$0.00" error={formState.errors.amount} {...register("amount", { required: false })} />
        </div>
        <div className="flex gap-7 justify-end items-center self-stretch">
          <AppButton name="Cancel" variant="outline" className="w-[138px]" onPress={handleCancel} />
          <AppButton name="Next" className="w-[138px]" disabled={amounts < 50} />
        </div>
      </form>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
});

export default connect(mapStateToProps, {})(USDPayment);
