import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_SAVING_STATEMENT } from "../../store/actions/action_types/action.types";
import { statementHistoryUrl } from "../../utils/endpoints";
import { OptionButton } from "../../components/Widget/Button/OptionButton";
import { FiDownloadCloud } from "react-icons/fi";
import Loader from "../../components/Widget/Loader/Loader";
import { IoWarning } from "react-icons/io5";
import Table from "../../components/Widget/Table/Table";
import PrintModal from "../../components/Widget/Modal/PrintModal";
import { showModal } from "../../store/actions/creators/ui.action";
import { statementProcessData } from "../Transactions/trans.data";
import { getMinMaxDate, transformDataToGroupForm } from "../../utils/helper";
import AppModal from "../../components/Widget/Modal/Modal";
import { Calendar2, Export, ExportSquare } from "iconsax-react";
import { processInterestData } from "./save.data";
import TransactionView, { TransactionRow } from "../../components/Transaction/TransactionRow";

const SavingsTransactions = ({ savings_trnx, details, getAction, token, ...props }) => {
  const [maxDate, setMaxDate] = useState(0);
  const [minDate, setMinDate] = useState(0);
  const [open, setOpen] = useState(false);
  // console.log("hey");

  // useEffect(() => {
  //   const url = `${statementHistoryUrl}/${props.user_wallet[3]?.id}/NGN/savings?page=1&per_page=10&startDate=2021-08-20&endDate=2023-10-04`;
  //   getAction(GET_SAVING_STATEMENT, url, token);
  // }, []);

  useEffect(() => {
    if (savings_trnx) {
      const arr = savings_trnx.map(item => new Date(item.txn_date));
      arr.length > 0 && setMaxDate(getMinMaxDate(arr).max);
      arr.length > 0 && setMinDate(getMinMaxDate(arr).min);
    }
  }, [savings_trnx]);

  const rawData = savings_trnx?.map(processInterestData);

  const cardInfo = {
    "Statement Period": maxDate ? `${maxDate} - ${minDate}` : "",
    "Branch Name": "ilupeju",
    "Phone Number": details?.phone,
    "Referral ID": details?.referral_code,
    "Account Type": "savings",
    Currency: "Naira",
    "Opening Balance": "",
  };

  const interestData = transformDataToGroupForm(rawData);
  // console.log(interestData);
  return (
    <div className="">
      <div className="flex gap-2 pb-4">
        <OptionButton
          name="Export"
          icon={<ExportSquare size={"18"} variant="Outline" />}
          // onPress={() => props.showModal("OPEN_MODAL", true)}
        />
        <OptionButton
          name="Date Range"
          icon={<Calendar2 size={"18"} variant="Outline" />}
          // onPress={() => props.showModal("OPEN_MODAL", true)}
        />
      </div>
      {props.loading_trxn && !interestData ? (
        <div className="h-full flex justify-center items-center">
          <Loader loading={props.loading_trxn} color="#922333" />
        </div>
      ) : (
        <div className="border-t pt-5 border-gray02">
          {interestData?.length == 0 ? (
            <div className="flex flex-col gap-2 items-center pt-5">
              <h3 className="text-primary font-medium text-[22px]">No records</h3>
              <p className="text-gray01">There are no data yet.</p>
            </div>
          ) : (
            <div className="overflow-auto h-[700px]">{interestData && <TransactionView transactions={interestData} />}</div>
          )}
        </div>
      )}
      <AppModal
        open={open}
        onClose={() => setOpen(false)}
        no_header={true}
        content={<PrintModal cardInfo={cardInfo} tableData={rawData} owner={`${details?.first_name} ${details?.last_name}`} onClose={() => setOpen(false)} />}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading_trxn: state.saving.get_savings_trnx.loading_trxn,
  user_wallet: state.account.get_wallets.data,
  error_msg: state.saving.get_savings_trnx.error_msg,
  details: state.user.user_details.data,
  paginate_num: state.ui.add_paginate.paginate_link,
  open: state.ui.modal.show_modal,
  savings_trnx: state.saving.get_savings_trnx?.savings_trnx || [],
  error_msg: state.investment.interest_history.error_msg,
});

export default connect(mapStateToProps, { getAction, showModal })(SavingsTransactions);
