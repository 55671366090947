import { ArrowRight } from "iconsax-react";
import React from "react";
import { currencyFormatter } from "../../utils/helper";
import { Badge } from "../Widget/Badge/Badge";

export const HistoryLink = props => (
  <div className="flex items-center self-end gap-[5px] justify-end text-brand01 cursor-pointer" onClick={props.openLink}>
    <span className="text-sm font-medium underline underline-offset-4">View HouseMoni Payment History</span>
    <ArrowRight size={18} />
  </div>
);

export const SubDetailView = ({ renderItem, data, header }) => (
  <div className="flex flex-col items-start gap-[10px] self-stretch">
    <span className="text-primary font-medium">{header}</span>
    {Object.keys(data).map((content, index) => (
      <React.Fragment key={index}>{renderItem({ content, data, index })}</React.Fragment>
    ))}
  </div>
);

export const DetailRow = ({ index, content, data }) => (
  <div key={index} className="flex justify-between items-center self-stretch">
    <span className="text-gray01">{content}</span>
    <span className="text-primary font-medium capitalize">{data[content] || "-"}</span>
  </div>
);

export const ActivityRow = ({ full_name, is_approved, user_avatar_url, onPress, id }) => (
  <div className="flex py-5 cursor-pointer justify-between items-center self-stretch transition-all ease-in-out border-b hover:bg-gray-50" onClick={onPress(id)}>
    <div className="flex gap-[12px] items-center">
      <img src={user_avatar_url} alt="gp" className="md:h-[45px] md:w-[45px] rounded-full object-cover" />
      <div className="flex flex-col items-start gap-[10px]">
        <span className="text-primary font-medium">{full_name}</span>
        <Badge content={is_approved ? "Verified" : "Pending"} className={`${!is_approved ? "bg-[#FEF7EF] text-[#FF9C33]" : ""}`} />
      </div>
    </div>
    <ArrowRight size={24} />
  </div>
);
