import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
};

const userTransReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_USER_TRANS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USER_TRANS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data.data,
        total: action.payload.data.data.meta.total,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const userTransDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_USER_TRANS_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USER_TRANS_DETAIL:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const transactionReducer = combineReducers({
  user_trans: userTransReducer,
  user_trans_detail: userTransDetailReducer,
});

export default transactionReducer;
