import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { showModal, fadeContent, showMessage, clearState } from "../../store/actions/creators/ui.action";
import { postAction, getAction } from "../../store/actions/creators/request.action";
import { ADD_INVEST_SUB, GET_EMERGENCY_FUND } from "../../store/actions/action_types/action.types";
import { TextField, SelectField } from "../../components/Widget/Form/FormComponent";
import AppModal from "../../components/Widget/Modal/Modal";
import { Divider } from "@mui/material";
import { investSubURL, getEmergencyFundURL } from "../../utils/endpoints";
import { ProductCard, HeaderProfile, TopCardDisplay } from "../../components/Card/Card";
import { currencyFormatter } from "../../utils/helper";
import Loader, { LoaderII } from "../../components/Widget/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { SavingsTypeCard } from "../Savings/SaveContent";
import toast from "react-hot-toast";
import {
  useAddInvestmentSubscriptionMutation,
  useGetInvestmentProductDetailsQuery,
  useGetInvestmentProductQuery,
  useGetInvestmentSubscriptionTypesQuery,
  useGetInvestmentTenorQuery,
  useGetUserInvestmentWalletDetailsQuery,
} from "../../services/products/invest.services";

const Message = ({ token }) => {
  const dispatch = useDispatch();
  const useEmergencyFund = () => {
    dispatch(getAction(GET_EMERGENCY_FUND, getEmergencyFundURL, token));
  };

  return (
    <div className="p-5 flex flex-col">
      <span className="mb-2 text-red-800 text-lg">Oops!!</span>
      <span className="font-medium text-lg mb-3 uppercase">not enough balance</span>
      <span className="text-sm text-gray-600">
        You currently do not have sufficient <br /> balance to subscribe to this investment
      </span>
      <span className="text-sm text-gray-600 mt-5">
        You can request for Emergency Funds to aid your <br /> subscription, however, if you have an <br /> active savings with us
      </span>
      <span className="text-sm text-gray-600 mt-5">
        Your total savings amount must be equal to 120% <br /> of the investment sum for you to be <br /> eligible
      </span>
      <span className="text-sm text-gray-600 mt-3 font-medium">Try using emergency loan if you wish to continue</span>

      <div className="my-3 border-t" />
      <AppButton name="Use Emergency Funds" className="font-semibold rounded-md outline-none" pad=".7em" onPress={useEmergencyFund} />
    </div>
  );
};

export const InvestmentProductsErrorContent = () => {
  return (
    <div className="w-full">
      <div className="pt-8 pb-14 px-12">
        <p>Something went wrong</p>
      </div>
    </div>
  );
};

export const InvestmentProducts = props => {
  const { data, isLoading, isError } = useGetInvestmentProductQuery();

  if (isError) {
    return <InvestmentProductsErrorContent />;
  }

  return (
    <div className="w-full">
      <div className="pt-8 pb-14 px-12">
        {isLoading ? (
          <LoaderII />
        ) : (
          <>
            <p className="text-left">Select an investment product below to continue</p>
            <div className="flex flex-col gap-y-3 mt-5">
              {data?.map((content, index) => (
                <ProductCard
                  image={"/safe-green.svg"}
                  name={content.product_name}
                  row
                  interest={content.minimum_tenor.interest_rate}
                  key={content.id}
                  onPress={() => {
                    props.setId(content.id);
                    props.showModal("OPEN_SUB_MODAL", true, index);
                    props.showModal("HIDE_MODAL", false);
                    props.fadeContent("FADE", false);
                  }}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const OptionContent = props => (
  <div className="flex flex-col rounded-none p-3" style={{ width: "25em" }}>
    <HeaderProfile name="Choose Available Tenor" description={<small>This are the available tenor product</small>} />

    <div className="flex justify-start flex-col my-5 mt-2 p-3 overflow-scroll" style={{ maxHeight: "15em" }}>
      {props.tenor?.map((content, index) => (
        <>
          <div className="form-check flex flex-row bg-white justify-between my-1">
            <label
              className="form-check-label flex justify-between py-4 px-1 pr-0 cursor-pointer bg-gray-100 rounded-md w-full text-gray-800"
              // for="flexRadioDefault1"
            >
              <span className="text-sm">{content.tenor_name}</span>
              <input
                className="form-check-input rounded-full h-4 w-4 
          border border-gray-300 bg-white checked:bg-red-900 
          checked:border-red-900 focus:outline-none transition duration-200 
          mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onChange={() => props.getTenorId(index)}
              />
            </label>
          </div>
          <Divider
            style={{
              backgroundColor: "#F4F7FA",
              opacity: 0.4,
            }}
          />
        </>
      ))}
    </div>
  </div>
);

export const InvestTypeDetails = ({ id, fade }) => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useGetInvestmentProductDetailsQuery(id, { skip: !id });

  const handleContinue = e => {
    if (!fade) {
      e.preventDefault();
      dispatch(fadeContent("FADE", true));
    }
  };

  const investment_details = {
    "Product Name": data?.product_name,
    "Product Description": data?.description,
    "Minimum Amount": currencyFormatter(data?.minimum_amount),
    "Maximum Amount": currencyFormatter(data?.maximum_amount),
    Recurring: data?.recurring == true ? "Product Recurring" : "",
    "Minimum Tenure": "0-30days",
    "Maximum Tenure": "270-360days",
  };

  if (isError) {
    return (
      <div className="w-full">
        <div className="pt-8 pb-14 px-12">
          <p>Something went wrong</p>
        </div>
      </div>
    );
  }

  // console.log("resol");

  return (
    <div className="flex flex-col">
      <div className="flex flex-1">
        {isLoading ? (
          <div className="h-96 w-full flex justify-center items-center">
            <Loader loading={isLoading} color="#922333" />
          </div>
        ) : (
          <div className="w-full">
            <div className="py-8 px-12">
              <div className="border border-gray02 px-4 py-3 rounded-lg flex flex-col divide-y divide-gray02">
                <SavingsTypeCard saving_details={investment_details} type1={"Product Name"} />
                <SavingsTypeCard saving_details={investment_details} type1={"Product Description"} type2={"Recurring"} pt={"pt-4"} />
                <SavingsTypeCard saving_details={investment_details} type1={"Minimum Amount"} type2={"Maximum Amount"} pt={"pt-4"} />
                <SavingsTypeCard saving_details={investment_details} type1={"Minimum Tenure"} type2={"Maximum Tenure"} pt={"pt-4"} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end items-center gap-5 self-stretch px-12 pb-14">
        <AppButton name="Cancel" className="w-[135px]" variant="outline" onPress={() => dispatch(showModal("HIDE_SUB_MODAL", false))} />
        <AppButton name="Proceed" className="w-[135px]" onPress={handleContinue} />
      </div>
    </div>
  );
};

const SubscribeInvestment = ({ id, ...props }) => {
  const dispatch = useDispatch();
  const [addSubscription, { isLoading, error }] = useAddInvestmentSubscriptionMutation();
  const { data: investWalletData = [] } = useGetUserInvestmentWalletDetailsQuery({ userId: props.user_info?.id, wallet_ref: props.user_wallet[0]?.wallets[0]?.wallet_ref });
  const { data: investmentTenor } = useGetInvestmentTenorQuery();
  const { data: investmentSubTypes } = useGetInvestmentSubscriptionTypesQuery();

  const { handleSubmit, formState, register, control, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      user_id: props.user_info?.id,
      investment_product: id,
      transaction_location: "Not provided",
      user_ip: "N/A",
      channel: "WEB",
      subscription_type: investmentSubTypes ? investmentSubTypes[0].replace(/_/g, " ") : "",
    },
  });

  useEffect(() => {
    if (error) {
      const error_msg = Array.isArray(error?.data?.message) ? error?.data?.message[0] : error?.data?.message;
      dispatch(showMessage("OPEN_MSG_MODAL", { message: error_msg, msg_type: "error" }));
    }
  }, [error]);

  const getTenorId = id => {
    // setTenorId(id);
    dispatch(showModal("CLOSE_OPTION_MODAL", false, id));
  };

  const wallet_id = id - 1;

  const onSubmit = data => {
    if (data.subscription_amount > parseInt(props.user_wallet[0].available_balance)) {
      toast.error("Insufficient Balance");
    } else {
      data.investment_tenor = data.investment_tenor?.id;
      data.subscription_type = investmentSubTypes ? investmentSubTypes[0].replace(/_/g, " ") : "";
      // props.postAction(ADD_INVEST_SUB, investSubURL, data, props.token);
      addSubscription(data);
    }
  };

  return (
    <>
      <form className="px-0 w-full flex flex-col justify-end flex-1 pb-14" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-1">
          <div className="px-8 xl:px-12 py-8 gap-8 flex flex-col">
            <TopCardDisplay
              isCard
              wallet={wallet_id === 1 ? investWalletData[1] : investWalletData[0]}
              imageSrc={wallet_id === 1 ? "/invest_2.svg" : "/invest_1.svg"}
              name={wallet_id === 1 ? "Investment Balance (USD)" : "Investment Balance (NGN)"}
              desc={wallet_id === 1 && "Enjoy Upto 2% Interest Yearly"}
            />
            <div className="flex items-center gap-8">
              <TextField
                label="Starting Amount (NGN)"
                type="currency"
                mb={"flex-1"}
                placeholder="Starting Amount"
                control={control}
                error={formState.errors.subscription_amount}
                {...register("subscription_amount", { required: true })}
              />
              <SelectField
                item={investmentTenor ?? []}
                selected_item="tenor_name"
                label="Investment Term"
                control={control}
                error={formState.errors.investment_tenor}
                {...register("investment_tenor", { required: true })}
              />
            </div>
            <div className="">
              <SelectField
                item={investmentSubTypes ?? []}
                label="Subscription Type"
                defaultValue={investmentSubTypes ? investmentSubTypes[0].replace(/_/g, " ") : ""}
                disabled
                control={control}
                error={formState.errors.subscription_type}
                {...register("subscription_type", { required: false })}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end px-12">
          <div className="flex flex-row-reverse gap-x-8 items-center">
            <AppButton name="Subscribe" loading={isLoading} disabled={!formState.isDirty || !formState.isValid} />
            <AppButton name="Cancel" onPress={() => dispatch(showModal("HIDE_SUB_MODAL", false))} variant={"outline"} />
          </div>
        </div>
      </form>

      <AppModal open={props.show_option} onClose={() => dispatch(showModal("CLOSE_OPTION_MODAL", false))} content={<OptionContent {...props} getTenorId={getTenorId} />} />
    </>
  );
};

const InvestContent = ({ token, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.emergency_fund_success == true) {
      navigate("/loans-subscription", {
        data: { product: props.product_details, tenor: props.tenor_id },
      });
      dispatch(showModal("HIDE_INVEST_MODAL", false));
      dispatch(showModal("HIDE_SUB_MODAL", false));
    }
  }, [props.emergency_fund_success]);

  return (
    <>
      <div className="flex flex-col justify-start">{!props.fade ? <InvestTypeDetails fade={props.fade} id={props.product_id} /> : <SubscribeInvestment {...props} id={props.product_id} />}</div>
      <AppModal open={props.open} onClose={() => dispatch(showModal("HIDE_INVEST_MODAL", false))} content={<Message token={token} />} />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  fade: state.ui.fader.fade,
  modal_img: state.ui.modal.data,
  show_option: state.ui.modal.show_option,
  tenor: state.investment.get_tenor.data,
  tenor_id: state.ui.modal.data,
  sub_type: state.investment.get_tenor.sub_data,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  loading: state.investment.add_sub.sub_loading,
  sub_error: state.investment.add_sub.error_msg,
  message: state.ui.open_msg.response_msg,
  show_msg: state.ui.open_msg.msg_modal,
  open: state.ui.modal.show_interest,
  load_emergency_fund: state.loan.get_emergency_fund.loading,
  emergency_fund_success: state.loan.get_emergency_fund.success,
  emergency_fund_data: state.loan.get_emergency_fund.data,
});

export default connect(mapStateToProps, {
  showModal,
  fadeContent,
  postAction,
  showMessage,
  clearState,
  getAction,
})(InvestContent);
