import React from "react";
import { transformDataToGroupForm } from "../../utils/helper";
import { LoaderII } from "../Widget/Loader/Loader";
import { SectionList } from "../Widget/List/List";
import { TimedepositRow } from "./timedeposit/timedeposit.row";

const RecentRequests = ({ recentRequests, isLoading }) => {
  const sections = transformDataToGroupForm(recentRequests); // could be a hook instead?

  const renderHeader = ({ section }) => (
    <div className="text-left">
      <h4 className="2xl:text-sm xl:text-xs">{section.title}</h4>
    </div>
  );

  const renderItem = ({ item, index }) => <TimedepositRow {...item} />;

  return (
    <div className="">
      {isLoading ? (
        <div className="flex justify-center mt-10">
          <LoaderII />
        </div>
      ) : (
        <SectionList sections={sections} renderItem={renderItem} renderSectionHeader={renderHeader} />
      )}
    </div>
  );
};

export default RecentRequests;
