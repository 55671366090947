import React from "react";
import { HeaderProfile, ProductCard } from "../../components/Card/Card";
import { FaHouseUser, FaPiggyBank } from "react-icons/fa";
import { AiOutlineBarChart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { EmptyWallet, House, Security, StatusUp } from "iconsax-react";
import { connect } from "react-redux";
import { showModal } from "../../store/actions/creators/ui.action";

const GDLProduct = props => {
  return (
    <div className="flex p-5 justify-center items-center gap-[8px] rounded-lg border border-[#F8F7F7] cursor-pointer bg-white shadow-lg shadow-black/[0.03]" onClick={() => props.onPress(props.url)}>
      <div className="flex flex-col justify-center items-start gap-[20px] flex-1">
        <div className={`flex w-[42px] py-[9px] px-[12px] justify-center items-center rounded-full ${props.className}`}>{props.icon}</div>
        <div className="flex flex-col items-start gap-[5px] self-stretch">
          <span className="text-primary font-medium">{props.title}</span>
          <small className="2xl:h-[30px] text-[12px]">{props.sub_title}</small>
        </div>
      </div>
    </div>
    // <div className="flex p-3 bg-gray-100 rounded-lg items-center cursor-pointer" onClick={() => history.push(props.url)}>
    //   <div className="bg-red-800 p-2 rounded-full flex justify-center items-center">{props.icon || <FaHouseUser className="text-white" size={props.size || 40} />}</div>
    //   <div className="flex flex-col items-start mx-3 p-1">
    //     <span className="text-sm opacity-70 font-medium">{props.title}</span>
    //     <small className="text-xs opacity-60">{props.sub_title}</small>
    //   </div>
    // </div>
  );
};

const HomeModalContent = props => {
  const navigate = useNavigate();

  const data = [
    {
      title: "HouseMoni",
      sub_title: "Save up to own a home",
      url: "/housemoni",
      icon: <House className="2xl:w-[24px] text-brand01" />,
      className: "bg-brand02",
    },
    {
      title: "Fund Wallet",
      sub_title: "Add money to your account",
      url: "/dashboard/fund-account/bank-transfer",
      icon: <EmptyWallet className="2xl:w-[24px] text-[#FF9C33]" />,
      className: "bg-[#FEF7EF]",
    },
    { title: "Save", sub_title: "Boost your savings", url: "/products/savings", icon: <Security className="text-[#C32AFD] 2xl:w-[24px]" />, className: "bg-[#FAF2FD]" },
    { title: "Invest", sub_title: "Secure high-interest investment", url: "/products/invest", icon: <StatusUp className="2xl:w-[24px] text-[#3BB3C2]" />, className: "bg-[#F5F9FE]" },
  ];

  const handleNavigate = url => {
    props.showModal("HIDE_MODAL", false);
    navigate(url);
  };

  return (
    <div className="bg-white px-[30px] py-10 pb-14 grid grid-cols-2 gap-[20px]">
      {data.map((content, index) => (
        <React.Fragment key={index}>
          <GDLProduct {...content} onPress={handleNavigate} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default connect(null, { showModal })(HomeModalContent);
