import React from "react";
import { Badge } from "../../../components/Widget/Badge/Badge";
import { currencyFormatter } from "../../../utils/helper";

const HacdraRow = ({ data }) => (
  <div className="flex justify-between py-[10px] items-end self-stretch">
    <div className="flex flex-col items-start gap-[10px] flex-1">
      <span className="text-gray05 capitalize">{Object.keys(data)[0]}</span>
      <span className="text-primary">{data[Object.keys(data)[0]]}</span>
    </div>
    <div className="flex flex-col items-end gap-[10px] flex-1">
      <span className="text-gray05 capitalize">{Object.keys(data)[1]}</span>
      <span className="text-primary">{data[Object.keys(data)[1]]}</span>
    </div>
  </div>
);

const Hacdra = ({ hacdra, hacdra_repayment_amount }) => {
  const data_one = { "HACDRA balance": currencyFormatter(hacdra?.available_balance), "HACDRA Ledger Balance": currencyFormatter(hacdra.ledger_balance) };
  const data_two = { "HACDRA Repayment Amount": currencyFormatter(hacdra_repayment_amount), "Group Code": hacdra.group_code };
  return (
    <div className="flex py-[17px] px-5 flex-col items-start gap-[10px] self-stretch border rounded-xl border-gray04">
      <div className="flex flex-col items-start gap-5 self-stretch">
        <HacdraRow data={data_one} />
        <hr className="w-full border-[#E4E4E7]" />
        <HacdraRow data={data_two} />
      </div>
    </div>
  );
};

export default Hacdra;
