import { TickCircle, InfoCircle, ArrowLeft2 } from "iconsax-react";
import { clsx } from "clsx";

export const CustomCaption = ({ message, type }) => (
  <>
    {message && (
      <div className="flex items-center gap-[10px] self-stretch">
        <span>{type == "success" ? <TickCircle variant="Broken" size={18} className="text-[#449E6A]" /> : <InfoCircle variant="Bold" className="text-brand01" />}</span>
        <span className={clsx(type == "success" ? "text-[#449E6A]" : "text-brand01")}>{message}</span>
      </div>
    )}
  </>
);

export const CardHeader = ({ name, goBack }) => (
  <div className="flex border-b pb-[15px]">
    <div className="flex gap-[30px] cursor-pointer text-primary" onClick={goBack}>
      <ArrowLeft2 />
      <div className="flex flex-col gap-2.5">
        <span className="font-medium leading-[20.16px] capitalize">{name}</span>
      </div>
    </div>
  </div>
);
