import React, { useEffect } from "react";
import { AppButton } from "../Button/AppButton";
import { showMessage, clearState } from "../../../store/actions/creators/ui.action";
import { connect } from "react-redux";
import { InfoCircle, TickSquare } from "iconsax-react";

const MessageBox = ({ message, ...props }) => {
  return (
    <div className="flex flex-col py-5 pb-8 pt-0 px-12 justify-center items-center gap-10">
      <div className="flex flex-col items-center gap-5 self-stretch">
        <span>{props.msg_type == "success" ? <TickSquare variant="Bold" size={54} color="#449E6A" /> : <InfoCircle variant="Bold" size={54} color="#9A2333" />}</span>
        <div className="flex flex-col items-center gap-[10px] self-stretch">
          <span className="text-primary text-center font-bold capitalize">{props.msg_type == "success" ? props.title ?? "Submitted" : "Attention!"}</span>
          <span className="text-gray01 text-center">{message}</span>
        </div>
      </div>

      <AppButton
        name="Okay"
        className="w-[138px]"
        onPress={() => {
          props.showMessage("CLOSE_MSG_MODAL", props.callback);
          props.redirect && props.redirect();
          !props.is_login ? props.clearState() : props.clearState("CLEAR_LOGIN");
        }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  msg_type: state.ui.open_msg.message_type,
  is_login: state.ui.open_msg.is_login,
  title: state.ui.open_msg.title,
  callback: state.ui.open_msg.callback,
  redirect: state.ui.open_msg.redirect,
  message: state.ui.open_msg.response_msg,
});

export default connect(mapStateToProps, { showMessage, clearState })(MessageBox);
