import React, { useEffect, useState } from "react";
import { CustomTabs, CustomTab } from "./details.styles";
import { Box } from "@mui/material";
import { Tab as AppTab } from "@headlessui/react";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const HeadlessTab = ({ tabs, handlePress, ...props }) => {
  return (
    <AppTab.Group onChange={index => handlePress(index)}>
      <AppTab.List className={`flex p-[2px] items-center gap-[2px] border border-[#E4E4E7] bg-[#1A1A1A0D] rounded-lg ${props.className}`}>
        {Object.keys(tabs).map((content, index) => (
          <AppTab
            key={index}
            className={({ selected }) =>
              classNames(
                `flex py-0 px-[10px] cursor-pointer justify-center items-center gap-[10px] outline-0 ${props.tabStyle}`,
                selected ? "bg-brand01 rounded-md text-white border-[0.5px] border-[#E4E4E7] ring-0" : "text-gray01"
              )
            }
          >
            <span className="text-center md:text-[14px] text-xs leading-6">{content}</span>
          </AppTab>
        ))}
      </AppTab.List>
    </AppTab.Group>
  );
};

export const HeadlessTabTwo = ({ tabs, ...props }) => {
  return (
    <div className="w-full flex flex-col gap-[30px]">
      <AppTab.Group>
        <AppTab.List className="flex">
          {Object.keys(tabs).map((tab, index) => (
            <AppTab
              key={index}
              className={({ selected }) => classNames(`flex py-[12px] px-0 w-full justify-center items-center`, selected ? "border-b-2 border-brand01 text-brand01 font-medium" : "border-b-2")}
            >
              <span>{tab}</span>
            </AppTab>
          ))}
        </AppTab.List>
        <AppTab.Panels>
          {Object.keys(tabs).map((panel, index) => (
            <AppTab.Panel key={index}>{tabs[panel]}</AppTab.Panel>
          ))}
        </AppTab.Panels>
      </AppTab.Group>
    </div>
  );
};

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" className="my-5" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <div className={`pb-3`}>
          <>{children}</>
        </div>
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const DetailTabs = ({ tabs, onPress, ...props }) => {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (props.sub_data && props.sub_data.product_version?.product_code == "HAA") {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [props.sub_data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="w-full">
      <Box>
        <CustomTabs
          {...props}
          value={value}
          onChange={handleChange}
          aria-label="custom example"
          variant="scrollable"
          scrollButtons={false}
          // allowScrollButtonsMobile
          className="flex p-[2px] items-center gap-[2px] rounded-lg border border-[#E4E4E7]"
        >
          {Object.keys(tabs).map((content, index) => (
            <CustomTab {...props} label={content} {...a11yProps(index)} onClick={() => onPress(index)} />
          ))}
        </CustomTabs>
      </Box>
    </div>
  );
};

export const AppCustomTabs = ({ tabs, onPress, ...props }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (props.sub_data && props.sub_data.product_version?.product_code == "HAA") {
      setSelectedIndex(1);
    } else {
      setSelectedIndex(0);
    }
  }, [props.sub_data]);

  return (
    <div className="w-full">
      <AppTab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <AppTab.List className="flex space-x-1 rounded-xl bg-[#1a1a1a]/5 border border-gray02 w-fit p-[2px]">
          {Object.keys(tabs).map(tab => (
            <AppTab key={tab} className={({ selected }) => classNames("px-2 py-[2px] rounded-lg text-xs font-medium w-fit leading-5 ", selected ? "bg-brand01 text-white" : "text-gray01")}>
              {tab}
            </AppTab>
          ))}
        </AppTab.List>
        <AppTab.Panels className="mt-2">
          {Object.keys(tabs).map((content, idx) => (
            <AppTab.Panel key={idx} className={classNames("h-full p-3")}>
              {tabs[content]}
            </AppTab.Panel>
          ))}
        </AppTab.Panels>
      </AppTab.Group>
    </div>
  );
};
