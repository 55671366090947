import { SecuritySafe } from "iconsax-react";
import { currencyFormatter, formatTimeToAmPm } from "../../../utils/helper";
import { Badge } from "../../Widget/Badge/Badge";
import clsx from "clsx";

export const TimedepositRow = ({ updatedAt, purpose, deposit_amount, request_status }) => {
  const status = request_status?.toLowerCase();
  return (
    <div className="flex justify-between items-center py-5 border-b border-gray02">
      <div className="flex gap-4 items-center">
        <div className="w-10 h-10 flex justify-center items-center rounded-full bg-gray06 text-gray05">
          <SecuritySafe />
        </div>
        <div className="flex flex-col gap-2">
          <h5 className="text-primary font-medium">{purpose}</h5>
          <p>{formatTimeToAmPm(updatedAt)}</p>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-end">
        <span className="text-primary font-medium">{currencyFormatter(deposit_amount)}</span>
        <Badge content={status} className={clsx("text-base", status == "approved" ? "bg-green02 text-green01" : status == "pending" ? "bg-yellow01 text-yellow02" : "bg-brand02 text-brand01")} />
      </div>
    </div>
  );
};
