import React, { useEffect } from "react";
import { connect } from "react-redux";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_SECURITY_QUESTION, GET_WALLETS, GET_USER_DETAILS } from "../../store/actions/action_types/action.types";
import * as url from "../../utils/endpoints";
import { showModal, activateIdle, logOut } from "../../store/actions/creators/ui.action";
import AlertMessageBox from "../Widget/MessageBox/AlertMessageBox";
import AppModal from "../Widget/Modal/Modal";
import IdleLogoutContent from "../Widget/IdleLogout/IdleLogoutContent";
import ConfirmAmount from "../FundWithdraw/ConfirmAmout";
import { Outlet } from "react-router-dom";

const DashboardLayout = ({ children, token, details, user_info, security_question, success_msg, ...props }) => {
  const user_id = user_info?.id;
  const details_url = `${url.usersURL}/${user_id}`;

  //for security questions
  useEffect(() => {
    if (security_question == null || success_msg) {
      props.getAction(GET_SECURITY_QUESTION, url.securityURL, token);
    }
  }, [security_question, success_msg]);

  // for wallet recall
  useEffect(() => {
    if (props.user_wallet.length == 0 || props.bvn_validate_success || props.withdraw_success) {
      const url_wallet = `${url.walletURL}/${user_id}/${user_info?.user_txn_ref}`;
      props.getAction(GET_WALLETS, url_wallet, token);
    }
  }, [props.user_wallet, props.bvn_validate_success, props.withdraw_success]);

  // for user details
  useEffect(() => {
    if (!details || props.bvn_validate_success) props.getAction(GET_USER_DETAILS, details_url, token);
  }, [details, props.bvn_validate_success]);

  useEffect(() => {
    if (user_info?.account_status == "BLOCKED") {
      props.logOut();
    }
  }, [user_info?.account_status]);

  return (
    <>
      <div className="bg-[#FAFAFA] min-h-screen relative flex">
        <Sidebar />
        <div className="xl:pl-[15%] 2xl:pl-[260px] flex-1 flex flex-col 2xl:text-base xl:text-xs lg:text-xs text-sm">
          <Topbar {...props} />
          <div className="p-4 2xl:p-[32px] xl:p-[25px] h-full flex-1">
            <Outlet />
          </div>
        </div>
      </div>
      <AppModal open={props.show_alert} no_header className="md:w-[400px]" onClose={() => null} content={<AlertMessageBox />} />
      <AppModal open={props.open_idle} no_header onClose={() => null} content={<IdleLogoutContent />} />

      {/** modal for checkout */}
      <AppModal
        open={props.confirm_open}
        title="Checkout"
        className="w-[600px]"
        onClose={() => props.showModal("HIDE_MODAL", false)}
        content={<ConfirmAmount confirm_data={props.confirm_data} {...props} />}
      />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  login_token: state.auth.login.login_token,
  ip_data: state.auth.get_ip.data,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  security_question: state.user.security_question.data,
  open_idle: state.ui.is_idle.is_app_idle,
  time_left: state.ui.is_idle.time_left,
  show_alert: state.ui.modal.show_alert,
  confirm_open: state.ui.modal.show_confirm,
  confirm_data: state.ui.modal.confirm_data,
  bvn_validate_success: state.account.bvn_validate.success,
  details: state.user.user_details.data,
  withdraw_success: state.account.withdraw.success,
  success_msg: state.user.set_questions.success,
});

export default connect(mapStateToProps, {
  getAction,
  showModal,
  activateIdle,
  logOut,
})(DashboardLayout);
