import { TickCircle } from "iconsax-react";
import { currencyFormatter } from "../../utils/helper";
import { LoaderII } from "../../components/Widget/Loader/Loader";

export const AmountLabel = ({ balance }) => (
  <div className="flex justify-between items-start self-stretch">
    <span>Amount (NGN)</span>
    <div className="flex justify-end items-start gap-[5px] flex-1">
      <span>Wallet Balance:</span>
      <span>{currencyFormatter(balance)}</span>
    </div>
  </div>
);

export const AccountNumberLabel = ({ onPress }) => (
  <div className="flex justify-between items-start self-stretch">
    <span>Account Number</span>
    <span className="text-brand01 underline underline-offset-4 cursor-pointer" onClick={onPress}>
      Select Beneficiary
    </span>
  </div>
);

export const CustomLabel = ({ label, sub_label, balance }) => (
  <div className="flex justify-between items-start self-stretch">
    <span className="flex-1">{label}</span>
    <div className="flex justify-end items-start gap-[5px] flex-1">
      <span>{sub_label}</span>
      {balance && <span>{currencyFormatter(balance)}</span>}
    </div>
  </div>
);

export const CaptionComp = ({ account_name, loading }) => (
  <>
    {loading ? (
      <LoaderII loading={loading} color="#9A2333" height={15} />
    ) : (
      <div className="flex items-start gap-[10px] self-stretch text-green01">
        {account_name && (
          <>
            <TickCircle variant="Bold" size={24} />
            <span className="uppercase">{account_name}</span>
          </>
        )}
      </div>
    )}
  </>
);
