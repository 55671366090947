import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_INVEST_SUB_DETAILS } from "../../store/actions/action_types/action.types";
import { investSubURL } from "../../utils/endpoints";
import DetailsCard, { InterestCard } from "../../components/Card/DetailsCard";
import { Badge } from "../../components/Widget/Badge/Badge";
import Loader from "../../components/Widget/Loader/Loader";
import { currencyFormatter, formatDate, formatTimeToAmPm } from "../../utils/helper";

const InvestInfo = ({ loading, token, ...props }) => {
  useEffect(() => {
    if (props.invest_id) {
      const url = `${investSubURL}/${props.invest_id}`;
      props.getAction(GET_INVEST_SUB_DETAILS, url, token);
    }
  }, [props.invest_id]);

  console.log(props.invest_data);

  const investment_data = {
    name: props.invest_data?.investment_product.product_name,
    amount: props.invest_data?.subscription_amount,
    interest_earned: props.invest_data?.accrued_interest,
    status: props.invest_data?.subscription_status,
    row_two: {
      "subscription type": props.invest_data?.subscription_type.replace(/_/g, " "),
      "start date": `${formatDate(props.invest_data?.start_date, "DD MMM YYYY")} / ${formatTimeToAmPm(props.invest_data?.start_date)}`,
      "end date": new Date(props.invest_data?.end_date).toLocaleDateString(),
      "investment description": props.invest_data?.investment_product.description,
      "locked until": new Date(props.invest_data?.locked_until).toLocaleDateString() || "not provided",
      "minimum amount": currencyFormatter(props.invest_data?.investment_product.minimum_amount),
      "maximum amount": currencyFormatter(props.invest_data?.investment_product.maximum_amount),
      "product status": (
        <Badge
          content={props.invest_data?.investment_product.product_status}
          color={`${props.invest_data?.investment_product.product_status.toLowerCase() == "active" ? "bg-green-500" : "bg-red-500"} text-white py-1`}
          width="25%"
        />
      ),
      "Currency Type": props.invest_data?.investment_product.currency_type,
      enabled: props.invest_data?.investment_product.enabled ? "enabled" : "not enabled",
      recurring: props.invest_data?.investment_product.recurring ? "recurring" : "not recurring",
      "Target Amount Invested": currencyFormatter(props.invest_data?.subscription_amount),
    },
    row_three: {
      "tenor name": props.invest_data?.investment_tenor.tenor_name,
      "tenor description": props.invest_data?.investment_tenor.tenor_description,
      "currency type": props.invest_data?.investment_tenor.currency_type,
      "minimum days": props.invest_data?.investment_tenor.minimum_days,
      "maximum days": props.invest_data?.investment_tenor.maximum_days,
      "Interest rate": props.invest_data?.investment_tenor.interest_rate,
    },
  };

  return (
    <div className="w-full">
      {loading == true ? (
        <div className="col-span-3 h-full flex justify-center items-center">
          <Loader loading={true} color="#922333" />
        </div>
      ) : (
        <div className="">
          <DetailsCard {...investment_data} topic="Investment Product and Tenor Details" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  invest_id: state.ui.id_setter.id,
  loading: state.investment.get_sub_detail.loading,
  invest_data: state.investment.get_sub_detail.detail_data,
  error_msg: state.investment.get_sub_detail.error_msg,
});

export default connect(mapStateToProps, { getAction })(InvestInfo);
