import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/reduxStore";
import { Toaster } from "react-hot-toast";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));

const MainApp = () => (
  // <React.StrictMode> commented this out to prevent useEffect from running twice but in production you leave it
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <HelmetProvider>
        <App />
        <Toaster />
      </HelmetProvider>
    </PersistGate>
  </Provider>

  // </React.StrictMode>
);

root.render(<MainApp />);
