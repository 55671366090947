import { useForm } from "react-hook-form";
import { AppButton } from "../../Widget/Button/AppButton";
import { PhoneField, TextField, SearchDropdown, SelectField } from "../../Widget/Form/FormComponent";
import { countryCodeAndFlag } from "../../../utils/country_flag_and_calling_code";
import { allStates } from "../../../utils/nigeriaStateAndLGAs";
import { useDispatch, useSelector } from "react-redux";
import { setNextOfKin, showModal } from "../../../store/actions/creators/ui.action";
import { useState } from "react";

/*when next of kin is set, you either immediately call api to get next of kin for this user
 or you set the object of next of kin in state...but object should look like this **/
const processData = data => ({
  name: `${data.first_name} ${data.last_name}`,
  relation: data.relationship_with_next_of_kin,
  address: data.permanent_address,
  phone: data.phone_number,
  email: data.email,
});

const FilledItem = ({ data: { name, ...rest } }) => (
  <div className="flex flex-col gap-[5px] flex-1">
    <span className="font-medium leading-[20.16px] text-primary">{name}</span>
    {Object.values(rest).map((content, index) => (
      <small key={index} className="leading-[15.12px]">
        {content}
      </small>
    ))}
  </div>
);

export const NextOfKinComp = ({ label }) => {
  const dispatch = useDispatch();
  const [isFilled, setIsFilled] = useState(false); // remove this later
  const nextofkin_data = useSelector(state => state.ui.set_kin_data.selected_data);
  const kin_data = processData(nextofkin_data ?? {});

  const openModal = () => {
    dispatch(showModal("OPEN_MODAL", true));
  };

  return (
    <div className="grid grid-cols-2 gap-10">
      <div className="col-span-1 flex flex-col gap-2">
        <span className="leading-[20.16px]">{label}</span>
        <div className="flex justify-between items-center rounded-lg border border-[#F8F7F7] p-[24px] shadow-custom">
          {nextofkin_data ? (
            <FilledItem data={kin_data} />
          ) : (
            <div className="flex flex-col gap-[5px] flex-1">
              <span className="font-medium leading-[20.16px] text-primary">Add Next of Kin</span>
              <small className="leading-[15.12px]">Pass assets to a loved one, easily.</small>
            </div>
          )}

          <AppButton
            name={nextofkin_data ? "Change" : "Add"}
            onPress={openModal}
            type="button"
            className="bg-brand02 text-brand01 text-base cursor-pointer border-none rounded-[4px] 2xl:p-[4px_10px]"
          />
        </div>
      </div>
    </div>
  );
};

export const NextOfKinForm = () => {
  const nextofkin_data = useSelector(state => state.ui.set_kin_data.selected_data);

  const { register, formState, control, handleSubmit } = useForm({ mode: "onChange", defaultValues: { ...nextofkin_data } });
  const { errors, isDirty, isValid } = formState;
  const dispatch = useDispatch();

  const onSubmit = async data => {
    data.country = data.country.name;
    // set next of kin
    dispatch(setNextOfKin(data));
    dispatch(showModal("HIDE_MODAL", false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-10 px-12 py-10 pb-12">
      <div className="grid grid-cols-2 gap-8">
        <TextField label="First Name" type="text" placeholder="Enter First Name" error={errors.first_name} {...register("first_name", { required: true })} />
        <TextField label="Last Name" type="text" placeholder="Enter Last Name" error={errors.last_name} {...register("last_name", { required: true })} />
        <TextField label="Nationality" placeholder="e.g Nigerian" error={errors?.nationality} {...register("nationality", { required: true })} type="text" />
        <TextField type="text" label="Relationship With Next Of Kin" error={errors?.relation} placeholder="Cousin" {...register("relationship_with_next_of_kin")} />
        <PhoneField control={control} label={`Phone Number`} error={errors?.phone_number} {...register("phone_number", { required: true })} />
        <TextField type="email" label="Email" error={errors?.email} placeholder="emmanuel@here.com" {...register("email", { required: true })} />
        <div className="col-span-2">
          <TextField label="Permanent Address" placeholder="Enter Address" error={errors?.permanent_address} {...register("permanent_address", { required: true })} type="text" />
        </div>
        <SearchDropdown label="Country" placeholder="Select Country" control={control} val="name" items={countryCodeAndFlag} error={errors?.country} {...register("country", { required: true })} />
        <SelectField item={allStates} selected_item="state" value="alias" label="State" control={control} error={errors?.state} {...register("state", { required: true })} />
      </div>
      <div className="flex self-end items-center gap-5">
        <AppButton name="Cancel" type="button" variant="outline" className="w-[135px]" onPress={() => dispatch(showModal("HIDE_MODAL", false))} />
        <AppButton name="Save" className="w-[135px]" disabled={!isDirty || !isValid} />
      </div>
    </form>
  );
};
