import { useSelector } from "react-redux";
import { countryCodeAndFlag } from "../../../utils/country_flag_and_calling_code";
import { allStates } from "../../../utils/nigeriaStateAndLGAs";
import { SearchDropdown, TextField, SelectField, PhoneField } from "../../Widget/Form/FormComponent";

export const SelfInvestmentForm = ({ register, control, errors }) => {
  const { state } = useSelector(state => state.auth.login.data);
  return (
    <div className="grid grid-cols-2 gap-10">
      <TextField type="text" label="First Name" error={errors.self_investment?.first_name} disabled placeholder="Enter First Name" {...register("self_investment.first_name")} />
      <TextField type="text" label="Last Name" error={errors.self_investment?.last_name} disabled placeholder="Enter Last Name" {...register("self_investment.last_name")} />
      <PhoneField control={control} label="Phone Number" error={errors?.phone_number} disabled placeholder="08034868234" {...register("self_investment.phone_number")} />
      <TextField type="email" label="Email" error={errors.self_investment?.email} disabled placeholder="email@here.com" {...register("self_investment.email")} />
      <div className="col-span-2">
        <TextField
          type="text"
          label="Residential Address"
          disabled
          error={errors.self_investment?.residential_address}
          placeholder="Enter Address"
          {...register("self_investment.residential_address")}
        />
      </div>
      <SearchDropdown
        label="Country"
        placeholder="Select Country"
        control={control}
        val="name"
        items={countryCodeAndFlag}
        error={errors.self_investment?.country}
        {...register("self_investment.country")}
      />
      <SelectField
        item={allStates}
        selected_item="state"
        disabled={state}
        defaultValue={state}
        value="alias"
        label="State"
        control={control}
        error={errors.self_investment?.state}
        {...register("self_investment.state")}
      />
    </div>
  );
};
