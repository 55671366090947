import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  data: [],
  success: false,
  success_msg: false,
  sending: false,
};

const getUserNotifications = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_USER_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
      };
    case type.GET_USER_NOTIFICATIONS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data.data,
        error: false,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getUserNotificationsById = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_USER_NOTIFICATION_BY_ID:
      return {
        ...state,
        loading: false,
      };
    case type.GET_USER_NOTIFICATION_BY_ID:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        error: false,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const notificationReducer = combineReducers({
  get_notification: getUserNotifications,
  read_notification: getUserNotificationsById,
});

export default notificationReducer;
