import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeft2 } from "iconsax-react";
import AppModal from "../Widget/Modal/Modal";
import { connect } from "react-redux";
import BVNModal from "../Profile/BVNModal";
import { showModal } from "../../store/actions/creators/ui.action";
import { useEffect } from "react";
import { headerResolver } from "../../utils/helper";
import clsx from "clsx";
import { useHasAccount } from "../../hooks/account.hook";

const Wrapper = ({ children, name, desc, no_back, ignore_wallet_check, className, details, user_info, ...props }) => {
  const navigate = useNavigate();
  const { is_valid_user } = useHasAccount();

  useEffect(() => {
    if (!is_valid_user && !ignore_wallet_check) {
      navigate("/dashboard");
      props.showModal("OPEN_ALERT", true);
    }
  }, []);

  return (
    <>
      <div className={clsx("flex md:w-[913px] py-5 px-10 flex-col items-start gap-[35px] rounded-lg bg-white", className)}>
        <div className="flex flex-col self-stretch gap-[15px]">
          <div className="flex items-center gap-7 cursor-pointer text-[#1F2734]" onClick={() => navigate(-1)}>
            {!no_back && <ArrowLeft2 size={24} />}
            <span className="font-medium">{name || "Fund Account"}</span>
          </div>
          <hr className="self-stretch border-[#E4E4E7]" />
        </div>

        <div className="flex flex-col gap-[10px] self-stretch">
          {!props.error && !props.no_desc && (
            <div className="flex flex-col items-start gap-10 self-stretch">
              <span className="text-gray01">{desc || "Select one of the payment options to fund your account."}</span>
            </div>
          )}
          {children}
        </div>
      </div>
      {/** call bvn appmodal her */}

      <AppModal open={props.open} title="BVN" onClose={() => props.showModal("HIDE_MODAL", false)} content={<BVNModal onClose={() => props.showModal("HIDE_MODAL", false)} />} />
    </>
  );
};
const mapStateToProps = state => ({
  open: state.ui.modal.show,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  details: state.user.user_details.data,
});
export default connect(mapStateToProps, { showModal })(Wrapper);
