import React from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Legend, Line } from "recharts";
import { curveCardinal } from "d3-shape";

const data = [
  {
    name: "Jan",
    uv: 4000,
    pv: 20,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 60,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 100,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 140,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 180,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 220,
    amt: 2500,
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 300,
    amt: 2100,
  },
];

const cardinal = curveCardinal.tension(0.2);

const AppAreaChart = props => {
  return (
    <div className="w-full 2xl:w-[400px] xl:w-[200px]">
      <LineChart width={437} height={302} data={data}>
        <CartesianGrid vertical={false} strokeWidth={0.335} stroke="#E4E4E7" />
        <XAxis dataKey="name" strokeWidth={0} style={styles.xAxisTextStyle} />
        <YAxis strokeWidth={0} style={styles.yAxisTextStyle} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="pv" stroke="#8884d8" />
      </LineChart>
    </div>
  );
};

export default AppAreaChart;

const styles = {
  xAxisTextStyle: {
    fontSize: 12,
    color: "red",
    fontWeight: "400",
    lineHeight: 9,
  },
  yAxisTextStyle: {
    fontSize: 12,
    color: "red",
    fontWeight: "400",
    lineHeight: 9,
  },
};
