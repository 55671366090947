import React from "react";
import { connect } from "react-redux";
import { AppButton } from "../Button/AppButton";
import { showModal, activateIdle, logOut } from "../../../store/actions/creators/ui.action";

const IdleLogoutContent = props => {
  const handleReset = () => {
    props.resetIdle();
    props.activateIdle(false, {});
  };

  return (
    <div className="flex md:w-[25em] w-[20em] flex-col justify-start">
      <div className="py-5 px-3">
        <div className="header">
          <span className="text-lg">Are you still here ?</span>
        </div>
        <div className="pt-3 w-full flex justify-center px-2">
          <span className="text-sm text-center">If you're inactive on the app in the next 10 seconds, we'll log you out to protect your account</span>
        </div>
      </div>
      <div className=" py-5 flex px-4 justify-center items-center gap-3">
        <AppButton variant="outline" name="Yes, I'm still here" onPress={handleReset} className="text-sm" />
        <AppButton name="No, I'm Done" onPress={() => props.logOut()} className="text-sm" />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: "",
  time_left: state.ui.is_idle.time_left,
  resetIdle: state.ui.is_idle.handle_reset,
});

export default connect(mapStateToProps, { showModal, activateIdle, logOut })(IdleLogoutContent);
