import React, { useRef } from "react";
import { currencyFormatter, formatDate, formatTimeToAmPm } from "../../../utils/helper";
import { AppButton } from "../Button/AppButton";
import { Badge } from "../Badge/Badge";
import { CopyView } from "../Copy/Copy";
import Receipt from "../../../pages/Transactions/Receipt";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import clsx from "clsx";

const TransactionUnit = ({ label, value, sub_value, alignEnd, className }) => {
  return (
    <div className={clsx("flex flex-col gap-2", alignEnd ? "items-end text-right" : "items-start text-left", className)}>
      <span className="w-fit">{label}</span>
      {value && <span className="font-medium text-primary">{value}</span>}
      {sub_value && <span className="font-medium text-primary w-fit">{sub_value}</span>}
    </div>
  );
};

const TransactionModal = ({ transactionDetails, onClose }) => {
  const { amount, txn_ref, narration, transaction_status, txn_date } = transactionDetails;
  const receiptRef = useRef();

  const handleDownload = () => {
    const input = receiptRef.current;
    input.style.display = "block";

    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("receipt.pdf");
      input.style.display = "none"; // Hide the receipt after capturing
    });
  };

  return (
    <div>
      <div className="pt-8 pb-12 px-12">
        <div className="border border-gray02 rounded-xl px-4 py-3 flex flex-col divide-y divide-y-gray">
          <div className="flex justify-between items-center py-4">
            <TransactionUnit label="Amount" value={currencyFormatter(amount)} />
          </div>
          {/* <div className="flex justify-between items-center py-4">
            <TransactionUnit label="To" value={recipient_institution_name} sub_value={recipient_Name} />
          </div> */}
          <div className="flex justify-between items-end py-4">
            <TransactionUnit label="Reference" value={txn_ref} />
            <CopyView text={txn_ref} />
          </div>
          <div className="flex justify-between items-start py-4">
            <TransactionUnit label="Narration" value={narration} className="flex-1" />
            <TransactionUnit label="Transaction Fee" alignEnd="true" value={currencyFormatter(0)} className="flex-1" />
          </div>
          <div className="flex justify-between items-start py-4">
            <div className="flex flex-col gap-2">
              <span className="w-fit">Status</span>
              <Badge
                content={transaction_status}
                className={`font-medium !lowercase first-letter:uppercase text-base px-3 ${transaction_status === "COMPLETED" ? "bg-[#EDFDF3] text-[#449E6A]" : "bg-[#FFF4F6] text-brand01"}`}
              />
            </div>
            <TransactionUnit label="Date / Time" alignEnd="true" value={`${formatDate(txn_date, "DD MMM YYYY")} - ${formatTimeToAmPm(txn_date)}`} />
          </div>
        </div>
        <Receipt ref={receiptRef} transaction={transactionDetails} />
        <div className="pt-12 flex justify-end">
          <AppButton name="Download Receipt" size="175px" onPress={handleDownload} />
        </div>
      </div>
    </div>
  );
};

export default TransactionModal;
