import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import * as pages from "../../pages";
import { InvestmentRoute, LoansRoute, SavingsRoute, AssetRoute } from "./subRoute";

const ProductsRoute = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route exact path={`/`} element={<pages.ProductsPage />} />
        <Route path={`savings/*`} element={<SavingsRoute />} />
        <Route path={`invest/*`} element={<InvestmentRoute />} />
        <Route path={`emergency-fund/*`} element={<LoansRoute />} />
        <Route path={`timed-deposit/*`} element={<pages.TimedDepositPage />} />
        <Route path="assets/*" element={<AssetRoute />} />
      </Routes>
    </>
  );
};

export default ProductsRoute;
