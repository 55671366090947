import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DetailTabs } from "../../components/DetailsComponent/DetailsTab";
import { statementTabs } from "./invest.data";

const InvestHistory = ({ ...props }) => {
  const [index, setIndex] = useState(0);
  useEffect(() => {}, []);

  let currency = ["NGN", "USD"];
  return (
    <div>
      <DetailTabs
        tabs={statementTabs(currency[index + 1])}
        fontSize="12px"
        isInvest
        onPress={index => setIndex(index)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
});

export default connect(mapStateToProps, {})(InvestHistory);
