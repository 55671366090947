import React, { useEffect } from "react";
import { connect } from "react-redux";
import { GET_HACDRA_TRANS } from "../../../store/actions/action_types/action.types";
import { hacdraTransactionURL } from "../../../utils/endpoints";
import { getAction } from "../../../store/actions/creators/request.action";
import Table from "../../../components/Widget/Table/Table";
import TransactionView from "../../../components/Transaction/TransactionRow";

const HacdraTransaction = props => {
  useEffect(() => {
    const hacdra_id = props.hpa_group_details?.hacdra.id;

    const url = `${hacdraTransactionURL}/${hacdra_id}`;
    props.getAction(GET_HACDRA_TRANS, url, props.token);
  }, []);
  return (
    <div>
      <TransactionView transaction={props.hacdra_trans} />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  hpa_group_details: state.hpa.hpa_group_details.data,
  hacdra_trans: state.hpa.hacdra_trans.data,
});

export default connect(mapStateToProps, { getAction })(HacdraTransaction);
