import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  data: [],
  total_bal: 0,
  success: false,
  error_msg: null,
};

const getLoanSubReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_LOAN_SUB:
      return {
        ...state,
        loading: true,
      };
    case type.GET_LOAN_SUB:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
        error: true,
      };
    default:
      return state;
  }
};

const getLoanSubDetails = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_LOAN_SUB_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_LOAN_SUB_DETAILS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
        error: true,
      };
    default:
      return state;
  }
};

const addEmergencyFundReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_EMERGENCY_FUND:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_EMERGENCY_FUND:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.success,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
        error: true,
      };
    case "CLOSE_MSG_MODAL":
      return {
        ...state,
        loading: false,
        error_msg: null,
      };
    case "CLOSE_EMERGENCY_MODAL":
      return {
        ...state,
        loading: false,
        error: false,
        error_msg: null,
      };
    default:
      return state;
  }
};

const getEmergencyFundReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_EMERGENCY_FUND:
      return {
        ...state,
        loading: true,
      };
    case type.GET_EMERGENCY_FUND:
      return {
        ...state,
        loading: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
      };
    case "HIDE_SUB_MODAL":
      return {
        ...state,
        success: false,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
        error: true,
      };
    default:
      return state;
  }
};

const loanReducer = combineReducers({
  get_loan_sub: getLoanSubReducer,
  get_loan_sub_details: getLoanSubDetails,
  add_emergency_fund: addEmergencyFundReducer,
  get_emergency_fund: getEmergencyFundReducer,
});

export default loanReducer;
