import React, { useState } from "react";
import { Link } from "react-router-dom";
import AppModal from "../../../components/Widget/Modal/Modal";
import { AssetSubscriptionIntro } from "../../../components/Products/assets/intro";

// move this to a seperate component
export const ComingSoon = () => {
  return <span className="px-3 text-sm py-[2px] text-yellow02 bg-yellow01 ">Coming Soon</span>;
};

const AssetsList = ({ data, url }) => {
  const [open, setOpen] = useState(false);
  const [modalcontent, setModalContent] = useState(null);

  /** function to begin subscription  */
  const handleSubscription = content => () => {
    setModalContent(content);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <>
          {data.map(content => (
            <div key={content.name} onClick={handleSubscription(content)} className="cursor-pointer">
              <div className="flex justify-between items-center my-5">
                <div className="flex items-center gap-4">
                  <div className="w-[42px] h-[42px] rounded-full bg-[#FFF4F6] flex justify-center items-center">{content.icon}</div>
                  <div className="flex flex-col gap-2">
                    <div className="flex gap-5 items-center">
                      <h2 className="font-medium text-primary">{content.name}</h2>
                      {content.status && <ComingSoon />}
                    </div>
                    <p>{content.description}</p>
                  </div>
                </div>
              </div>

              <hr className="mx-auto w-full" />
            </div>
          ))}
        </>
      </div>
      <AppModal open={open} title={modalcontent?.name} onClose={() => setOpen(false)} content={<AssetSubscriptionIntro asset_type_data={modalcontent} onClose={closeModal} />} className="w-[40%]" />
    </>
  );
};

export default AssetsList;
