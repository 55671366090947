import React from "react";
import { Routes, Route } from "react-router-dom";
import * as pages from "../../../../pages";

const LoanSubRoute = () => {
  return (
    <>
      <Routes>
        <Route exact path={`/`} element={<pages.Loans />} />
        <Route exact path={`:id`} element={<pages.LoanDetails />} />
      </Routes>
    </>
  );
};

export default LoanSubRoute;
