import React, { useState } from "react";
import { connect } from "react-redux";
import { FiEdit, FiEye, FiTrash } from "react-icons/fi";
import { IoWarning } from "react-icons/io5";
import { LoaderII } from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";
import { showModal, setData, setPagination } from "../../../store/actions/creators/ui.action";
import { useNavigate } from "react-router-dom";

const NUMBER_ON_PAGE = 10;

const Table = props => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const key = props.tableData && props.tableData.length > 0 && Object.keys(props.tableData[0]);

  const handlePageChange = page => {
    console.log(page);
    setCurrentPage(page);
    props.setPagination(page);
  };

  return (
    <>
      <div className="flex flex-col bg-transparent gap-6">
        {props.error != null || props.error != "" ? (
          <div className="w-full flex flex-col justify-center overflow-auto pb-10 bg-white rounded-b-lg">
            {props.tableData && props.tableData.length < 1 ? (
              <div className="flex justify-center items-center py-10 px-5">
                <div className="flex sm:flex-row flex-col bg-gray-50 items-center sm:py-0 py-3">
                  <IoWarning size={20} />
                  <span className="md:text-sm text-sm px-5 py-3 flex items-center leading-5 rounded-lg text-gray-500 text-center">There are currently no available data for this.</span>
                </div>
              </div>
            ) : (
              <>
                {props.loading ? (
                  <div className="flex justify-center items-center py-5">
                    <LoaderII loading={props.loading} />
                  </div>
                ) : (
                  <table className="min-w-full divide-y border-b border-gray-200 divide-gray-200">
                    <thead className="bg-[#1A1A1A] bg-opacity-5">
                      <tr className="text-gray01">
                        {props.tableData &&
                          Object.keys(props.tableData[0]).map(content => (
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                              {content != "id" && content}
                            </th>
                          ))}
                        {!props.no_action && (
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                            Action
                          </th>
                        )}
                        {!props.no_option && (
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                            Option
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="bg-white text-primary divide-y divide-gray-200">
                      {props.tableData &&
                        props.tableData.map((content, index) => (
                          <tr key={index}>
                            {key.map(key_name => key_name != "id" && <td className="px-8 py-5 whitespace-nowrap text-sm text-left">{content[key_name]}</td>)}
                            {!props.no_action && (
                              <td className="px-8 py-5 whitespace-nowrap text-sm flex">
                                {!props.view_only && (
                                  <>
                                    <div className="mr-1">
                                      <FiTrash className="cursor-pointer" onClick={() => props.showModal("OPEN_DELETE_MODAL", true)} />
                                    </div>
                                    <div className="mx-1">
                                      <FiEdit className="cursor-pointer" onClick={() => props.openModal(content)} />
                                    </div>
                                  </>
                                )}

                                <div className="ml-1">
                                  <FiEye className="cursor-pointer" onClick={() => (!props.onepager ? navigate(`${content.id}`) : props.clickEvent(content.id))} />
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
        ) : (
          <div>error occured</div>
        )}

        {/* <DeleteModal delete={props.deleteItem} /> */}
      </div>
      {!props.loading && props.total > 0 && (
        <div className="flex justify-center mt-7">
          <Pagination totalCount={props.total} pageSize={NUMBER_ON_PAGE} currentPage={currentPage} onPageChange={handlePageChange} />
        </div>
      )}
    </>
  );
};

export default connect(null, { showModal, setData, setPagination })(Table);
