export { default as Login } from "./Auth/Login";
export { default as SignupOne } from "./Auth/Registeration/RegisterStep1";
export { default as SignupTwo } from "./Auth/Registeration/RegisterStep2";
export { default as OtpConfirm } from "./Auth/Registeration/OtpConfirm";
export { default as ForgotPassword } from "./Auth/ForgotPassword";
export { default as NewPassword } from "./Auth/NewPassword";
export { default as PinSetup } from "./Auth/Registeration/PinSetup";
export { default as Offerings } from "./Auth/Registeration/Offerings";
export { default as Home } from "./Home/Home";
export { default as Investment } from "./Investment/Investment";
export { default as InvestmentDetails } from "./Investment/InvestmentDetails";
export { default as ProductsPage } from "./products";
export { default as TimedDepositPage } from "./TimedDeposit";
export { default as CanaryFund } from "./Assets/canary/canary.fund";
export { default as CanarySubscriptions } from "./Assets/canary/canary.subview";
export { default as MoneyMarketFund } from "./Assets/moneymarket/moneymarket.fund";
export { default as MoneyMarketSubscriptions } from "./Assets/moneymarket/moneymarket.subview";
export { default as IncomeFund } from "./Assets/incomefund/income.fund";
export { default as IncomeSubscriptions } from "./Assets/incomefund/income.subview";
export { default as AssetBankDetails } from "./Assets/asset.bankdetails";
export { default as Savings } from "./Savings/Savings";
export { default as SavingDetails } from "./Savings/SavingDetails";
export { default as Loans } from "./Loans/Loans";
export { default as LoanDetails } from "./Loans/LoanDetails";
export { default as FundOptions } from "./FundWallet/FundOptions";
export { default as BankTransfer } from "./FundWallet/BankTransfer";
export { default as CardPayment } from "./FundWallet/CardPayment";
export { default as USDPayment } from "./FundWallet/USDPayment";
export { default as WithdrawLanding } from "./Withdrawal/Landing";
export { default as GdlTransfer } from "./Withdrawal/Gdl.account";
export { default as OtherTransfer } from "./Withdrawal/Other.account";
export { default as HouseMoni } from "./HPA/HouseMoni";
export { default as HouseMoniGroupDetails } from "./HPA/HPAsubscription/HouseMoniGroupDetails";
export { default as HouseMoniActivities } from "./HPA/HPAsubscription/HouseMoniGroupActivities";
export { default as HouseMoniMemberInfo } from "./HPA/HPAsubscription/HouseMoniMemberInfo";
export { default as SubscriptionPayment } from "./HPA/SubPayment/Payment";
// export { default as Settings } from "./Settings/Settings";
export { default as Profile } from "./Profile/UserAccount";
export { default as UserDocument } from "./Profile/IdentificationDoc";
export { default as LoginHisotry } from "./Profile/UserLoginHistory";
export { default as Security } from "./Profile/Security";
export { default as TransferLimit } from "./Profile/Transfer.limit";
export { default as Privacy } from "./Profile/Privacy";
export { default as Transaction } from "./Transactions/Transaction";
export { default as TransactionDetail } from "./Transactions/TransactionDetails";
export { default as Notification } from "./Notification/Notification";
export { default as Help } from "./Help/Help";
