import React, { useState } from "react";
import { Link } from "react-router-dom";
import SaveContent from "../../Savings/SaveContent";
import AppModal from "../../../components/Widget/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { fadeContent, showModal } from "../../../store/actions/creators/ui.action";
import clsx from "clsx";
import { ComingSoon } from "./assets";

const SavingsList = ({ data, url }) => {
  const [selectedId, setSelectedId] = useState(null);
  const dispatch = useDispatch();

  const sub_open = useSelector(state => state.ui.modal.show_sub_modal);

  const onClickHandler = id => {
    setSelectedId(id);
    dispatch(showModal("OPEN_SUB_MODAL", true));
    dispatch(showModal("HIDE_MODAL", false));
    dispatch(fadeContent("FADE", false));
  };

  return (
    <div>
      <div className="flex justify-end">
        {url && (
          <Link to={url} className="text-brand01 underline cursor-pointer">
            View Portfolio
          </Link>
        )}
      </div>
      <>
        {data.map(content => (
          <div key={content.name} className={clsx(!content.status && "cursor-pointer")} onClick={() => !content.status && onClickHandler(content.id)}>
            <div className="flex justify-between items-center my-5">
              <div className="flex items-center gap-4">
                <div className="w-[42px] h-[42px] rounded-full bg-[#FFF4F6] flex justify-center items-center">{content.icon}</div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-5 items-center">
                    <h2 className="font-medium text-primary">{content.name}</h2>
                    {content.status && <ComingSoon />}
                  </div>
                  <p>{content.description}</p>
                </div>
              </div>
            </div>

            <hr className="mx-auto w-full" />
          </div>
        ))}
      </>
      <AppModal
        open={sub_open}
        title="Goal Saving"
        className="w-[60%] 2xl:w-[40%]"
        onClose={() => {
          dispatch(showModal("HIDE_SUB_MODAL", false));
        }}
        content={<SaveContent product_id={selectedId} />}
      />
    </div>
  );
};

export default SavingsList;
