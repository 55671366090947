import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "../Widget/Form/FormComponent";
import { AppButton } from "../Widget/Button/AppButton";
import { connect } from "react-redux";
import { editAction } from "../../store/actions/creators/request.action";
import { showMessage, showModal } from "../../store/actions/creators/ui.action";
import { bvnURL } from "../../utils/endpoints";
import { VALIDATE_BVN } from "../../store/actions/action_types/action.types";
import { headerResolver } from "../../utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const BVNModal = ({ token, loading, user_info, user_wallet, bvn_validate_success, bvn_validate_error, ...props }) => {
  const { handleSubmit, formState, register, control } = useForm({
    mode: "onChange",
    defaultValues: {
      user_id: user_info?.id,
      wallet_ref: user_info?.user_txn_ref,
    },
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (bvn_validate_error) {
      toast.error(props.bvn_validate_error_msg);
      return;
    }

    if (bvn_validate_success) {
      props.showModal("HIDE_MODAL", false);
      toast.success("BVN validated successfully");
    }
  }, [bvn_validate_success, bvn_validate_error]);

  const onSubmit = data => {
    data.bvn = String(data.bvn);
    props.editAction(VALIDATE_BVN, bvnURL, data, token);
  };

  const handleCancel = e => {
    e.preventDefault();
    if (headerResolver(pathname).path == "fund wallet") {
      navigate("/");
    }
    props.showModal("HIDE_MODAL", false);
  };

  return (
    <div className="">
      {/* <HeaderView title="BVN" onClose={props.onClose} /> */}
      <div className="pt-8 pb-12 px-12">
        <p className="text-left mb-5">Please add a valid BVN</p>
        <form onSubmit={handleSubmit(onSubmit)} className="w-[385px]">
          <div className="pb-14">
            <TextField label="BVN" error={formState.errors.bvn} placeholder="0000 0000 000" {...register("bvn", { required: true })} type="number" control={control} />
          </div>
          <div className="flex justify-end pb-4">
            <div className="flex gap-x-5">
              <AppButton name="Cancel" onPress={handleCancel} variant="outline" className="sm:w-[135px]" />
              <AppButton name="Submit" loading={props.bvn_validate_loading} className="sm:w-[135px]" disabled={!formState.isDirty || !formState.isValid} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.user.user_details.loading,
  details: state.user.user_details.data,
  user_info: state.auth.login.data,
  modal_data: state.ui.modal.data,
  user_wallet: state.account.get_wallets.data,
  bvn_validate_loading: state.account.bvn_validate.loading,
  bvn_validate_success: state.account.bvn_validate.success,
  bvn_validate_error: state.account.bvn_validate.error,
  bvn_validate_error_msg: state.account.bvn_validate.error_msg,
  type: state.ui.modal.type,
});

export default connect(mapStateToProps, { editAction, showModal, showMessage })(BVNModal);
