import { useLocation, useNavigate } from "react-router-dom";
import { AppButton } from "../../Widget/Button/AppButton";
import { FlatList } from "../../Widget/List/List";
import { useHeaderResolver } from "../../../hooks/path.hook";
import { redeem_funds_arr } from "../../../lib/data";

const TextWrapper = ({ children }) => (
  <div className="border-b border-[#F0F0F0] p-[20px_0] w-full">
    <div className="flex flex-col gap-2.5 items-start">{children}</div>
  </div>
);

export const AssetSubscriptionIntro = ({ asset_type_data, onClose }) => {
  const { url, icon, ...data } = asset_type_data ?? {}; // get needed item from the data
  const navigate = useNavigate();
  const { path } = useHeaderResolver();

  const renderItem = ({ item, index }) => (
    <li>
      <span>{item}</span>
    </li>
  );

  const handleNavigation = () => {
    if (url) {
      navigate(url, { state: data });
      return;
    }
    onClose();
  };

  return (
    <div className="flex flex-col gap-[45px] p-[20px_50px_60px]">
      <div className="flex flex-col items-start self-stretch">
        <TextWrapper>
          <span className="font-medium text-primary leading-[20.16px]">Overview</span>
          <span className="text-left">
            GDL <span className="capitalize">{path}</span> is a high-yield savings fund that offers competitive interest rates and liquidity. It's designed to provide a safe and flexible way to grow
            your savings while maintaining easy access to your funds.
          </span>
        </TextWrapper>
        <TextWrapper>
          <span className="font-medium text-primary leading-[20.16px]">Redeeming Your Funds</span>
          <FlatList data={redeem_funds_arr} renderItem={renderItem} className="list-disc px-4" />
        </TextWrapper>
      </div>
      <div className="flex justify-end gap-5 items-center">
        <AppButton name="Cancel" type="button" className="md:w-[135px]" variant="outline" onPress={() => onClose()} />
        <AppButton name="Continue" className="md:w-[135px]" onPress={handleNavigation} />
      </div>
    </div>
  );
};
