import { Badge } from "../../components/Widget/Badge/Badge";
import { ProfileInfo } from "../../components/Card/DetailsCard";
import {
  AiOutlineIdcard,
  AiOutlineKey,
  AiOutlineQuestion,
} from "react-icons/ai";
import { currencyFormatter } from "../../utils/helper";

export const processData = (data, index) => ({
  id: data.id,
  "S/N": index + 1,
  email: data.email,
  firstname: data.first_name,
  lastname: data.last_name,
  "Wallet Reference": data.user_txn_ref,
  phone: data.phone,
  "Account Status": (
    <Badge
      content={data.account_status}
      color={
        data.account_status == "ACTIVE"
          ? "bg-green-100 text-green-800"
          : "bg-red-100 text-red-800"
      }
    />
  ),
});

export const menu_item = [
  {
    id: 1,
    name: "BVN",
    sub_name: "Provide a valid BVN",
    icon: <AiOutlineIdcard className="md:text-[25px] text-xl" />,
  },
  {
    id: 2,
    name: "Set Security Questions",
    sub_name: "Set your security question",
    icon: <AiOutlineQuestion className="md:text-[25px] text-xl" />,
  },
  {
    id: 3,
    name: "Set Transaction PIN",
    sub_name: "Set new transaction PIN",
    icon: <AiOutlineKey className="md:text-[25px] text-xl" />,
  },
];

export const top_data = (data, bal, main_bal) => ({
  title: <ProfileInfo {...data} />,
  statistics: {
    "Total Portfolio": currencyFormatter(bal),
    "Main Balance": currencyFormatter(main_bal),
    "Kyc level": "Bronze",
  },
});

export const processLoginHistory = currentPage => (data, index) => ({
  id: data.id,
  "S/N": 20 * (currentPage - 1) + (index + 1),
  // "User IP": data.user_ip,
  // "User Location": data.user_location,
  status: "Account was accessed",
  "Login Time":
    new Date(data.updated_at).toLocaleDateString() +
    " " +
    new Date(data.updated_at).toLocaleTimeString(),
});
