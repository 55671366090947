import React, { useEffect } from "react";
import { connect } from "react-redux";
import { HeaderProfile } from "../../components/Card/Card";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { postAction } from "../../store/actions/creators/request.action";
import { ADD_EMERGENCY_FUND } from "../../store/actions/action_types/action.types";
import { emergencyFundURL } from "../../utils/endpoints";
import { currencyFormatter } from "../../utils/helper";
import AppModal from "../../components/Widget/Modal/Modal";
import { showMessage, showModal, emergencyModal } from "../../store/actions/creators/ui.action";
import { useNavigate } from "react-router-dom";

const Message = ({ showModal, ...props }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col p-5">
      <span className="text-sm text-gray-600">
        Sorry, You Do Not Have Up To <br /> 120.00 % In Running Savings To Cover For <br />
        Your Emergency Fund Request.
      </span>
      <div className="my-3 border-t" />
      <AppButton
        name="Close"
        className="font-semibold rounded-md outline-none"
        pad=".7em"
        onPress={() => {
          props.emergencyModal("CLOSE_EMERGENCY_MODAL");
          navigate(`/investify`);
        }}
      />
    </div>
  );
};

const EmergencyFund = ({ token, ...props }) => {
  useEffect(() => {
    if (!props.data) {
      props.showModal("CLOSE_EMERGENCY_FUND", false);
    }
    props.emergencyModal("CLOSE_EMERGENCY_MODAL");
  }, [props.data]);
  const handleEmergencyFund = () => {
    const data = {
      user_id: props.user_info?.id,
      loan_product_id: props.emergency_fund_data.id,
      investment_product_id: props.data.data.product?.id,
      investment_tenor_id: props.data.data.tenor,
      request_amount: parseInt(props.data.data.product?.minimum_amount),
    };
    props.postAction(ADD_EMERGENCY_FUND, emergencyFundURL, data, token);
  };

  useEffect(() => {
    if (props.error == true) {
      props.emergencyModal("OPEN_EMERGENCY_MODAL");
      // props.showModal("CLOSE_EMERGENCY_FUND", false);
    } else {
    }
  }, [props.error]);

  const dummy_data = {
    "Loan Name": props.emergency_fund_data?.product_name,
    "Loan Description": props.emergency_fund_data?.description,
    "Investment Product": props.data?.data?.product?.product_name,
    "Investment Description": props.data?.data.product?.description,
    Amount: currencyFormatter(props.data?.data.product?.minimum_amount),
    "Loan Processing Fee": currencyFormatter(props.emergency_fund_data?.processing_fee),
    "Loan Savings Collateral Percentage": Math.round(props.emergency_fund_data.savings_collateral_percentage) + "%",
  };

  return (
    <>
      <div className="px-0 py-3" style={{ maxWidth: "28em" }}>
        <div className="pb-3 border-b header">
          <HeaderProfile
            name="Request Emergency Funds Confirmation"
            description={
              <span>
                We provide Emergency Funds to aid <br /> your Investments with us
              </span>
            }
          />
        </div>
        <div className="px-6 mt-5 body">
          {Object.keys(dummy_data).map((content, index) => (
            <>
              <div className="flex justify-between my-4">
                <span className="text-sm text-left opacity-60">{content}</span>
                <span className="flex justify-end w-2/5 text-sm text-right opacity-60">{dummy_data[content]}</span>
              </div>
              {Object.keys(dummy_data).length - 1 != index && <div className="border-b" />}
            </>
          ))}
        </div>
        <div className="px-5 py-5 footer">
          <AppButton
            name="Proceed"
            pad=".8em"
            loading={props.loading}
            className="inline-flex justify-center w-full px-4 py-3 text-base font-semibold text-white border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-900"
            onPress={handleEmergencyFund}
          />
        </div>
      </div>
      <AppModal open={props.open_msg} onClose={() => null} content={<Message {...props} token={token} />} />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_info: state.auth.login.data,
  loading: state.loan.add_emergency_fund.loading,
  error_msg: state.loan.add_emergency_fund.error_msg,
  error: state.loan.add_emergency_fund.error,
  tenor_id: state.ui.modal.data,
  emergency_fund_data: state.loan.get_emergency_fund.data,
  open: state.ui.modal.show_emergency_fund,
  open_msg: state.ui.emr_show.open_emr,
});

export default connect(mapStateToProps, {
  postAction,
  showMessage,
  showModal,
  emergencyModal,
})(EmergencyFund);
