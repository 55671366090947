import React, { useEffect } from "react";
import { connect } from "react-redux";
import BankTransfer from "./BankTransfer";
import CardPayment from "./CardPayment";
import { useForm } from "react-hook-form";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { showModal, showMessage } from "../../store/actions/creators/ui.action";
import USDPayment from "./USDPayment";
import { getAction } from "../../store/actions/creators/request.action";
import { HeaderProfile } from "../../components/Card/Card";

const InitialDiv = () => (
  <div className="px-5 flex flex-col h-full">
    <div className="2xl:py-5 lg:py-4 py-3 border-b">
      <span className="font-medium uppercase 2xl:text-sm lg:text-xs text-sm text-gray-500">Payment Options</span>
    </div>
    <div className=" flex flex-1 justify-center items-center py-5">
      <span className="2xl:text-sm lg:text-xs bg-gray-200 p-2 rounded-lg text-center" style={{ color: "#aeb9cc" }}>
        Transactions will appear here. click the “Add Naira” or “Transfer” to start your first transaction
      </span>
    </div>
  </div>
);

export const OpenBVNModal = props => (
  <div className="">
    <div className="my-8 px-12 flex flex-col justify-center">
      <span className="text-sm text-gray01 font-medium text-left">Please You need to set your BVN</span>
      <div className="my-5 mb-0 w-full">
        <AppButton
          name="Setup your BVN"
          className="w-full"
          onPress={() => {
            props.showModal("OPEN_MODAL", true);
            props.setOpen(false);
          }}
        />
      </div>
    </div>
  </div>
);

const FundContent = ({ token, ...props }) => {
  const formData = useForm({
    mode: "onChange",
  });
  const { handleSubmit } = formData;
  const onSubmit = data => {
    if (props.id !== 3) {
      if (data.amount < 1000) {
        props.showMessage("OPEN_MSG_MODAL", "This amount is below the minimum amount you can fund your wallet");
      } else {
        data.amount = parseInt(data.amount);
        // props.showModal("HIDE_MODAL", false);
        props.showModal("OPEN_CONFIRM_MODAL", true, data);

        // if (props.user_wallet[0].available_balance >= data) {
        // } else {
        //   props.showMessage("OPEN_MSG_MODAL", "Insufficient Fund");
        // }
      }
    } else {
      props.showModal("HIDE_MODAL", false);
    }
  };

  return (
    <>
      <div className="flex flex-col justify-start h-full">
        <form className="flex flex-col h-full justify-between" onSubmit={handleSubmit(onSubmit)}>
          {props.id == 0 ? <InitialDiv /> : props.id == 1 ? <BankTransfer /> : props.id == 2 ? <CardPayment formData={formData} /> : <USDPayment openModal={props.openModal} />}
          {props.id == 2 && (
            <div className="bg-gray-50 px-4 py-4 sm:px-6 sm:flex sm:flex-row-reverse justify-center">
              {props.id != 1 && (
                <AppButton
                  name={props.id == 3 ? "Accept & Continue" : "Proceed"}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 ring-2 ring-red-400 focus:ring-red-800 sm:ml-3 sm:w-64 sm:text-sm"
                />
              )}

              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 sm:mt-0 sm:ml-3 sm:w-60 sm:text-sm"
                onClick={() => props.showModal("HIDE_MODAL", false)}
              >
                {props.id == 1 ? "Done" : "Cancel"}
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  modal_data: state.ui.modal.data,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
});

export default connect(mapStateToProps, { showModal, getAction, showMessage })(FundContent);
