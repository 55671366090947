import { Menu } from "@headlessui/react";
import { NavLink as Link } from "react-router-dom";
import { assets_links } from "../../../lib/data";
import clsx from "clsx";

export const AssetDropdownContent = () => (
  <>
    {assets_links.map(link => (
      <Menu.Item key={link.id}>
        {({ active }) => (
          <Link to={link.url} className={clsx("block px-2.5 py-2 text-sm border-none", active ? "text-brand01" : "text-gray-700 ")} role="menuitem" tabIndex="-1" id="menu-item-0">
            {link.name}
          </Link>
        )}
      </Menu.Item>
    ))}
  </>
);
