import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { invest_endpoint } from "../../utils/endpoints";

export const investApi = createApi({
  reducerPath: "investApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${invest_endpoint}`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = state.auth.login.token;
      if (token) {
        headers.set("bearerauth", token);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    getInvestmentProduct: builder.query({
      query: () => `/investment/products`,
      transformResponse: response => {
        return response.data;
      },
    }),
    getInvestmentProductDetails: builder.query({
      query: id => `/investment/products/${id}`,
      transformResponse: response => {
        return response.data;
      },
    }),
    addInvestmentSubscription: builder.mutation({
      query: body => ({ url: `/investment/subscriptions`, method: "POST", body: body }),
      transformResponse: response => {
        return response.data;
      },
    }),
    getInvestmentSubByUserId: builder.query({
      query: ({ id, page = 1, per_page = 2, sub_status }) => {
        const params = new URLSearchParams({
          page: page.toString(),
          per_page: per_page.toString(),
        });

        if (sub_status) {
          params.append("subscription_status", sub_status);
        }

        return `/investment/subscriptions/user/${id}?${params.toString()}`;
      },
      transformResponse: response => {
        return response.data.data;
      },
    }),
    getUserInvestmentHistoryByUserCurrency: builder.query({
      query: ({ id, currency }) => `/investment/subscriptions/user/currency/${id}/${currency}`,
      transformResponse: response => {
        return response.data.data;
      },
    }),
    getInvestmentSubscriptionTypes: builder.query({
      query: () => `/investment/subscriptions/subs/types`,
      transformResponse: response => {
        return response.data;
      },
    }),
    getInvestmentTenor: builder.query({
      query: () => `/investment/tenors`,
      transformResponse: response => {
        return response.data;
      },
    }),
    getUserInvestmentWalletDetails: builder.query({
      query: ({ userId, wallet_ref }) => `/investment/wallet/user/inv/${userId}/${wallet_ref}`,
      transformResponse: response => {
        return response?.data;
      },
    }),
    getInvestmentStatement: builder.query({
      query: ({ wallet_id, currency = "NGN" }) => `/investment/statement/${wallet_id}/${currency}`,
      transformResponse: response => {
        return response.data.data;
      },
    }),
  }),
});

export const {
  useGetInvestmentProductDetailsQuery,
  useGetInvestmentProductQuery,
  useGetInvestmentSubByUserIdQuery,
  useGetInvestmentSubscriptionTypesQuery,
  useGetInvestmentTenorQuery,
  useGetUserInvestmentHistoryByUserCurrencyQuery,
  useGetUserInvestmentWalletDetailsQuery,
  useAddInvestmentSubscriptionMutation,
  useGetInvestmentStatementQuery,
} = investApi;

export const {
  getInvestmentProduct,
  getInvestmentProductDetails,
  getInvestmentSubByUserId,
  getInvestmentSubscriptionTypes,
  getInvestmentTenor,
  getUserInvestmentHistoryByUserCurrency,
  getUserInvestmentWalletDetails,
  addInvestmentSubscription,
  getInvestmentStatement,
} = investApi.endpoints;
