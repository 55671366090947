import React from "react";
import { Routes, Route } from "react-router-dom";
import * as pages from "../../../../../pages";

const CanaryFundRoute = () => {
  return (
    <Routes>
      <Route index path={`/`} element={<pages.CanaryFund />} />
      <Route path={`/subscription`} element={<pages.CanarySubscriptions />} />
    </Routes>
  );
};

export default CanaryFundRoute;
