import React from "react";
import { connect } from "react-redux";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { AppButton } from "../Button/AppButton";
import { LoaderII } from "../Loader/Loader";
import { IoWarning } from "react-icons/io5";

const PrintModal = ({ ...props }) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onPrintError: () => alert("there is an error when printing"),
  });

  const key = props.tableData && props.tableData.length > 0 && Object.keys(props.tableData[0]);

  return (
    <div ref={componentRef} className="sm:flex max-h-[90vh] overflow-auto relative w-full flex-col p-5 ">
      <img src="/logo.png" alt="" className="absolute right-8 w-10" />
      <div className="my-5 flex items-end justify-between">
        <table className="border">
          <tbody>
            {Object.keys(props.cardInfo).map(content => (
              <tr key={content} className=" border-y text-left first:border-t-0 last:border-b-0">
                <td className="font-medium py-1 border-r text-sm min-w-[130px]">{content}</td>
                <td className="text-sm pl-2 uppercase min-w-[250px]">{props.cardInfo[content]}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div>
          <h2 className="text-right capitalize font-medium text-gdl-red">Customer Statement</h2>
          <p className="capitalize text-xl">{props.owner}</p>
        </div>
      </div>
      {props.loading ? (
        <div className="flex justify-center items-center py-5">
          <LoaderII loading={props.loading} />
        </div>
      ) : props.tableData && props.tableData.length === 0 ? (
        <div className="flex justify-center items-center py-10 px-5">
          <div className="flex sm:flex-row flex-col bg-gray-50 items-center sm:py-0 py-3">
            <IoWarning size={20} />
            <span className="md:text-sm text-sm px-5 py-3 flex items-center leading-5 rounded-lg text-gray-500 text-center">There are currently no available data for this.</span>
          </div>
        </div>
      ) : (
        <table className="min-w-full divide-y border-b border-gray-200 divide-gray-200">
          <thead className="bg-[#1A1A1A] bg-opacity-5">
            <tr className="text-gray01">
              {props.tableData &&
                Object.keys(props.tableData[0]).map(content => (
                  <th key={content} scope="col" className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    {content != "id" && content}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className="bg-white text-primary divide-y divide-gray-200">
            {props.tableData &&
              props.tableData.map((content, index) => (
                <tr key={index}>{key.map(key_name => key_name != "id" && <td className="px-4 py-3 whitespace-wrap w-fit text-sm text-left">{content[key_name]}</td>)}</tr>
              ))}
          </tbody>
        </table>
      )}

      <div className="flex justify-center print:hidden">
        <AppButton name="Print" className="font-medium rounded-lg shadow-sm w-fit mt-8 shadow-gray-500" onPress={handlePrint} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  open: state.ui.modal.show,
});

export default connect(mapStateToProps)(PrintModal);
