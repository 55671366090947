import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_LOAN_SUB_DETAILS } from "../../store/actions/action_types/action.types";
import { loanURL } from "../../utils/endpoints";
import DetailsCard, { InterestCard } from "../../components/Card/DetailsCard";
import { Badge } from "../../components/Widget/Badge/Badge";

const LoanInfo = ({ loading, token, ...props }) => {
  useEffect(() => {
    if (props.loan_id) {
      const url = `${loanURL}/${props.loan_id}`;
      props.getAction(GET_LOAN_SUB_DETAILS, url, token);
    }
  }, [props.loan_id]);

  const loan_data = {
    name: props.loan_detail.loan_product?.product_name,
    amount: props.loan_detail?.request_amount,
    status: props.loan_detail?.active_status,
    row_two: {
      "Date Requested": new Date(
        props.loan_detail?.request_date
      ).toLocaleDateString(),
      "disbursed amount": props.loan_detail?.disbursed_amount,
      "disbursed date": props.loan_detail?.disburse_date,
      "approval status": (
        <Badge
          content={props.loan_detail?.loan_status}
          color="bg-green-500 py-1 text-white"
        />
      ),
      "Loan Description": props.loan_detail.loan_product?.description,
    },
    row_three: {},
  };

  return (
    <div className="sm:grid grid-cols-3 gap-5">
      <div className="col-span-2">
        <DetailsCard {...loan_data} />
      </div>
      <div>
        <InterestCard interest={18} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loan_id: state.ui.id_setter.id,
  loading: state.loan.get_loan_sub_details.loading,
  loan_detail: state.loan.get_loan_sub_details.data,
  error_msg: state.loan.get_loan_sub_details.error_msg,
});

export default connect(mapStateToProps, { getAction })(LoanInfo);
