export const LOADING = "LOADING";
export const SENDING = "SENDING";
export const DELETING = "DELETING";
export const SIGN_IN = "SIGN_IN";
export const SIGN_UP = "SIGN_UP";
export const MORE_INFO = "MORE_INFO";
export const CONFIRM_OTP = "CONFIRM_OTP";
export const RESEND_OTP = "RESEND_OTP";
export const RENEW_PASSWORD = "RENEW_PASSWORD";
export const ADD_NEW_PASSWORD = "ADD_NEW_PASSWORD";
export const CHANGE_NEW_PASSWORD = "CHANGE_NEW_PASSWORD";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const VALIDATE_PHONE = "VALIDATE_PHONE";
export const LOGOUT = "LOGOUT";
export const GET_IP = "GET_IP";
export const SET_PIN = "SET_PIN";
export const OUR_OFFERINGS = "OUR_OFFERINGS";
export const UPLOAD_CLOUDINARY = "UPLOAD_CLOUDINARY";

// action types for account
export const GET_WALLETS = "GET_WALLETS";
export const GET_FUND_TYPE = "GET_FUND_TYPE";
export const GET_BANKS = "GET_BANKS";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const WITHDRAW = "WITHDRAW";
export const WITHDRAW_INVESTMENT = "WITHDRAW_INVESTMENT";
export const WITHDRAW_SAVINGS = "WITHDRAW_SAVINGS";
export const GET_VIRTUAL_ACCOUNT = "GET_VIRTUAL_ACCOUNT";
export const GET_NUBAN_ACCOUNT = "GET_NUBAN_ACCOUNT";
export const FUND_CARD = "FUND_CARD";
export const FUND_WITH_NUBAN = "FUND_WITH_NUBAN";
export const GET_EXCHANGE_RATES = "GET_EXCHANGE_RATES";
export const GET_BENEFICIARY = "GET_BENEFICIARY";
export const ADD_BENEFICIARY = "ADD_BENEFICIARY";
export const DELETE_BENEFICIARY = "DELETE_BENEFICIARY";
export const GET_BENEFICIARY_ID = "GET_BENEFICIARY_ID";

// types concerning transaction
export const GET_USER_TRANS = "GET_USER_TRANS";
export const GET_USER_TRANS_DETAIL = "GET_USER_TRANS_DETAIL";

//action types for investment
export const GET_INVEST_PRODUCT = "GET_INVEST_PRODUCT";
export const GET_INVEST_PRODUCT_DETAIL = "GET_INVEST_PRODUCT_DETAIL";
export const GET_TENOR = "GET_TENOR";
export const GET_SUB_TYPE = "GET_SUB_TYPE";
export const ADD_INVEST_SUB = "ADD_INVESTMENT_SUB";
export const GET_INVEST_SUB = "GET_INVESTMENT_SUB";
export const GET_INVEST_SUB_DETAILS = "GET_INVEST_SUB_DETAILS";
export const GET_INVEST_HISTORY = "GET_INVEST_HISTORY";
export const GET_INTEREST_HISTORY = "GET_INTEREST_HISTORY";
export const GET_SUB_INTEREST_HISTORY = "GET_SUB_INTEREST_HISTORY";
export const GET_INTEREST_DETAIL = "GET_INTEREST_DETAILS";
export const GET_INVEST_WALLET = "GET_INVEST_WALLET";
export const GET_INTEREST_HISTORY_USER = "GET_INVEST_HISTORY_USER";
export const HIDE_INVESTMENT = "HIDE_INVESTMENT";
export const GET_INVEST_STATEMENT = "GET_INVEST_STATEMENT";

//action types for saving
export const GET_SAVING_PRODUCT = "GET_SAVING_PRODUCT";
export const GET_SAVING_PRODUCT_DETAIL = "GET_SAVING_PRODUCT_DETAIL";
export const ADD_SAVING_SUB = "ADD_SAVING_SUB";
export const GET_SAVING_SUB = "GET_SAVING_SUB";
export const GET_SAVING_SUB_DETAILS = "GET_SAVING_SUB_DETAILS";
export const GET_SAVING_HISTORY = "GET_SAVING_HISTORY";
export const GET_SAVE_INTEREST_HISTORY = "GET_SAVE_INTEREST_HISTORY";
export const GET_SAVING_WALLET = "GET_SAVING_WALLET";
export const TOP_UP_SAVING = "TOP_UP_SAVINGS";
export const HIDE_SAVING = "HIDE_SAVING";
export const GET_SAVING_STATEMENT = "GET_SAVING_STATEMENT";

// action types for asset management
export const ADD_ASSET_MGT_FUND = "ADD_ASSET_MGT_FUND";
export const GET_ASSET_MGT_FUND = "GET_ASSET_MGT_FUND";

//action types for loans
export const GET_LOAN_SUB = "GET_LOAN_SUB";
export const GET_LOAN_SUB_DETAILS = "GET_LOAN_SUB_DETAILS";
export const ADD_EMERGENCY_FUND = "ADD_EMERGENCY_FUND";
export const GET_EMERGENCY_FUND = "GET_EMERGENCY_FUND";

//action types for HPA
export const GET_HOME_COST_LIMIT = "GET_HOME_COST_LIMIT";
export const GET_PURCHASE_ACCOUNT = "GET_PURCHASE_ACCOUNT";
export const SUB_HPA = "SUB_HPA";
export const GET_HPA_SUBS = "GET_HPA_SUBS";
export const GET_HPA_CONFIG = "GET_HPA_CONFIG";
export const GET_HPTAB = "GET_HPTAB";
export const GET_HPA_ELIGIBILITY = "GET_HPA_ELIGIBILITY";
export const GET_HPA_GROUP = "GET_HPA_GROUP";
export const ADD_HPA_GROUP = "ADD_HPA_GROUP";
export const GET_HPA_GROUP_ID = "GET_HPA_GROUP_BY_ID";
export const GET_HPA_MEMBERS = "GET_HPA_GROUP_MEMBERS_DATA";
export const GET_HACDRA_TRANS = "GET_HACDRA_TRANSACTIONS";
export const MONTHLY_INCOME = "MONTHLY_INCOME";
export const GET_MONTHLY_CONTRB = "GET_MONTHLY_CONTRIBUTION";
export const ADJUST_HOME_COST = "ADJUST_HOME_COST";
export const GET_HPA_TRANSACTION_HISTORY = "GET_HPA_TRANSACTION_HISTORY";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_KYC = "GET_USER_KYC";
export const GET_USER_HISTORY = "GET_USER_HISTORY";
export const GET_USER_REFFERAL = "GET_USER_REFFERAL";
export const GET_SECURITY_QUESTION = "GET_SECURITY_QUESTION";
export const SET_SECURITY_QUESTION = "SET_SECURITY_QUESTION";
export const GET_USER_SECURITY_QUESTION = "GET_USER_SECURITY_QUESTION";
export const SET_TRANSACTION_PIN = "SET_TRANSACTION_PIN";
export const GET_OWNER_DOCS = "GET_OWNER_DOCS";
export const ADD_OWNER_DOCS = "ADD_OWNER_DOCS";
export const GET_USER_DOCS = "GET_USER_DOCS";
export const GET_PROOF_ADDR = "GET_PROOF_ADDR";
export const GET_USER_SAVINGS = "GET_USER_SAVINGS";
export const GET_USER_INVESTMENT = "GET_USER_INVESTMENT";
export const GET_USER_LOAN = "GET_USER_LOAN";
export const GET_USER_REPORT = "GET_USER_REPORT";

export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

export const VALIDATE_BVN = "VALIDATE_BVN";

export const GET_GENDER = "GET_GENDER";
export const GET_MARITAL_STATUS = "GET_MARITAL_STATUS";

export const GET_USER_NOTIFICATIONS = `GET_USER_NOTIFICATIONS`;
export const GET_USER_NOTIFICATION_BY_ID = "GET_USER_NOTIFICATION_BY_ID";

export const TRANSFER_FEE = "TRANSFER_FEE";
