import SaveHistory from "./SaveHistory";
import SaveInfo from "./SaveInfo";
import SaveInterestHistory from "./SaveInterestHistory";
import { Badge } from "../../components/Widget/Badge/Badge";
import SavingsHistory from "./SavingsHistory";
import SavingsTransactions from "./SavingsTransactions";
import { currencyFormatter } from "../../utils/helper";

export const processData = (data, index) => ({
  id: data.id,
  // "S/N": index + 1,
  name: data.savings_purpose,
  description: data.purpose_description,
  status: (
    <Badge
      content={data.status}
      color={
        data.status == "PENDING"
          ? "bg-yellow-100 text-yellow-500"
          : data.status == "RUNNING"
          ? "bg-green-100 text-green-500"
          : "bg-red-100 text-red-800"
      }
    />
  ),
  "annual interest": currencyFormatter(data.savings_rate?.annual_interest_rate),
  "interest earned": currencyFormatter(data.total_interest),
  hidden: data.hidden,
});

export const processSaveData = (data, index) => ({
  id: data.id,
  "S/N": index + 1,
  total_amount: data.total_amount,
  txn_date: data.interest_date,
  interest: data.interest,
  narration: data.user_savings?.savings_purpose,
});

export const processInterestData = (data, index) => ({
  id: data.id,
  "S/N": index + 1,
  amount: data.amount,
  narration: data.description,
  txn_date: data.createdAt,
  trxn_type: data.transaction_type,
});

export const tabNames = {
  "Savings Detail": <SaveInfo />,
  // "Saving History": <SaveHistory />,
  "Savings Interest History": <SaveInterestHistory />,
};

export const tabHeader = {
  "Savings Transactions": <SavingsTransactions />,
  // "Saving History": <SaveHistory />,
  "All Savings Interest History": <SavingsHistory />,
};
