import styled from "styled-components";

export const CardDiv = styled.div`
  background: ${props =>
    props.styles.bg ||
    "linear-gradient(136.67deg, #C93832 5.34%, #922333 90.26%, #8D2E36 90.26%)"};
  color: ${props => props.styles.color || "#FFFFFF"};
  padding: 1em 1.2em;
  display: flex;
  /* min-width: 35%; */
  /* border-radius: 0.8em; */
  flex-direction: column;
  border: 1px solid ${props => props.styles.border};
  /* box-shadow: 0 0 4px #efefef; */
  .wallet_text {
    font-size: 13px;
  }
  .wallet_currency {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    .wallet_text {
      font-size: 12px;
    }
  }
  @media (max-width: 1024px) {
    .wallet_text {
      font-size: 10px;
    }
    .wallet_currency {
      font-size: 10px;
    }
  }
`;

export const ProductCardView = styled.div`
  background: ${props => props.bg_color || "#fff"};
  padding: ${props => props.padding || 0};
  .card-image {
    width: ${props => (props.isClickable ? "50%" : props.ImgSize || "70px")};
    @media only screen and (max-width: 1024px), (max-width: 1536px) {
      width: ${props => (props.isClickable ? "50%" : props.ImgSize || "50px")};
    }
  }
`;

export const ProductSubText = styled.small`
  font-size: 14px;
  opacity: 0.7;
  letter-spacing: 0.4px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 11px;
  }
  @media only screen and (max-width: 1024px), (max-width: 1536px) {
    font-size: 10px;
    /* letter-spacing: 0.2px; */
  }
`;

export const ProductText = styled.span`
  font-size: ${props => props.fontSize || "16px"};
  font-weight: 600;
  letter-spacing: 0.4px;
  font-style: normal;
  /* opacity: 0.7; */
  color: ${props => props.textColor || "#922333"};
  margin-bottom: ${props => props.marginTop || ""};
  /* font-family: BR Firma; */
  @media (max-width: 768px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 1024px), (max-width: 1536px) {
    font-size: 14px;
    margin-bottom: 0px;
  }
`;

export const ReferralCardView = styled.div`
  background: #ffe4e4;
  border-radius: 10px;
  width: 100%;
  min-height: 77%;
  display: flex;
  & .referral {
    /* background-color: pink;
    border-radius: 15px; */
  }
`;

export const ReferralDetails = styled.div`
  padding: 1.5em 1em 1em;
`;

export const CardView = styled.div`
  height: ${props => props.heght || "85%"};
  display: flex;
  /* justify-content: center; */
  align-items: ${props => props.align_item || "center"};
  /* background-color: #f9f8f8; */
  /* border: 1px solid #dddddd; */
  .title {
    font-size: 15px;
  }
  .sub-title {
    font-size: 15px;
    margin-bottom: 0.5em;
  }
  @media (max-width: 1536px) {
    /* height: 60%; */
    .title {
      font-size: 10px;
    }
    .sub-title {
      font-size: 10px;
    }
  }
`;

export const CardLayoutGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* background-color: pink; */
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
  @media only screen and (max-width: 1024px), (max-width: 1536px) {
    height: auto;
  }
`;

export const FundCardView = styled.div`
  box-shadow: 0 0 3px #ccc;
  .fund-icon {
    width: 50px;
    height: 50px;
  }
  .fund-type {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    .fund-type {
      font-size: 10px;
    }
    .fund-icon {
      width: 20px;
      height: 20px;
    }
    .fund-time {
      font-size: 10px;
    }
  }
  @media only screen and (max-width: 1024px), (max-width: 1536px) {
    .fund-type {
      font-size: 12px;
    }
    .fund-icon {
      width: 0px;
      height: 0px;
      padding: 20px;
    }
    .fund-time {
      font-size: 11px;
    }
  }
`;

export const ProfileHeaderView = styled.div`
  background: linear-gradient(136.67deg, #8d2e36 8.34%, #ca3832 95.26%);
  padding: 1em 2em 1em;
  opacity: 1;
`;
