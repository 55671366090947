import { connect, useDispatch, useSelector } from "react-redux";
import { EmptyView2 } from "../../Widget/EmptyView/EmptyView";
import { useNavigate } from "react-router-dom";
import { useHeaderResolver } from "../../../hooks/path.hook";
import { SectionList } from "../../Widget/List/List";
import { transformDataToGroupForm } from "../../../utils/helper";
import { RecentAssetRow } from "./assets.row";
import { useEffect, useMemo } from "react";
import { getAction } from "../../../store/actions/creators/request.action";
import { GET_ASSET_MGT_FUND } from "../../../store/actions/action_types/action.types";
import { assetMgtUrl } from "../../../utils/endpoints";
import { processData } from "./asset.data";

const description = "You have not subscribed to the product. Your data will appear here when you subscribe.";

const AssetSubscription = ({ token, user_info, assets_funds, loading }) => {
  const navigate = useNavigate();
  const { path_arr, path } = useHeaderResolver();

  const assets_data = assets_funds?.map(processData); // transform data to look like what we have in the component
  const data_by_product_type = useMemo(() => assets_data?.filter(product => product.product_type.toLowerCase() == path_arr[3].replace(/-/g, " ")), [assets_data]); // filter all asset funds by product type so when you navigate to the rigth one, it show funds for that product type
  const sections = transformDataToGroupForm(data_by_product_type ?? []); // could be a hook instead?

  const dispatch = useDispatch();

  // get all asset subscriptions
  useEffect(() => {
    if (!assets_funds) dispatch(getAction(GET_ASSET_MGT_FUND, `${assetMgtUrl}/user/${user_info?.id}`, token));
  }, [assets_funds]);

  // navigate to where you can apply to fund subscription page
  const handleNavigation = () => {
    navigate(`/products/assets/${path_arr[3]}`); // use the item in index 3 which is always a url before the subscription url i.e either canary-fund|money-market-fund|income-fund
  };

  // render header for recent request
  const renderHeader = ({ section }) => (
    <div className="text-left">
      <h4 className="2xl:text-sm xl:text-xs">{section.title}</h4>
    </div>
  );

  // render items for recent request
  const renderItem = ({ item, index }) => <RecentAssetRow {...item} />;

  return (
    <>
      {loading ? (
        <div>loading...</div>
      ) : (
        <>
          {data_by_product_type?.length > 0 ? (
            <div className="flex flex-col gap-[27px]">
              <hr className="border-gray07" />
              <h4 className="text-primary font-medium">Recent Requests</h4>
              <SectionList sections={sections} renderSectionHeader={renderHeader} renderItem={renderItem} />
            </div>
          ) : (
            <EmptyView2 has_btn btn_name="Start Subscription" description={description} onPress={handleNavigation} />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_info: state.auth.login.data,
  assets_funds: state.assets.get_asset.data,
  loading: state.assets.get_asset.loading,
});

export default connect(mapStateToProps, {})(AssetSubscription);
