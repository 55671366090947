import { connect } from "react-redux";
import HouseMoniWrapper from "./HouseMoniWrapper";
import HouseMoniPlan from "./HPAplan/HouseMoniPlan";
import ConfirmAmount from "../../components/FundWithdraw/ConfirmAmout";
import React, { useEffect, useState } from "react";
import { showModal } from "../../store/actions/creators/ui.action";
import AppModal from "../../components/Widget/Modal/Modal";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_HPA_SUBS } from "../../store/actions/action_types/action.types";
import { hpaSubscriptionURL } from "../../utils/endpoints";
import HouseMoniSub from "./HPAsubscription/HouseMoniSub";
import { HouseMoniSubSkeleton } from "../../components/Widget/Loader/Skeleton";

const HouseMoni = ({ token, loading, housemoni_subs, ...props }) => {
  return <>{loading ? <HouseMoniSubSkeleton /> : housemoni_subs ? <HouseMoniSub /> : <HouseMoniPlan />}</>;
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  confirm_open: state.ui.modal.show_confirm,
  confirm_data: state.ui.modal.confirm_data,
  housemoni_subs: state.hpa.get_hpa_sub.hpa_data,
  loading: state.hpa.get_hpa_sub.loading,
  user_info: state.auth.login.data,
});

export default connect(mapStateToProps, { showModal, getAction })(HouseMoni);
