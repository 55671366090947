import React from "react";
import { Routes, Route } from "react-router-dom";
import * as pages from "../../../../pages";

const InvestmentSubRoute = () => {
  return (
    <>
      <Routes>
        <Route exact path={`/`} element={<pages.Investment />} />
        <Route exact path={`:id`} element={<pages.InvestmentDetails />} />
      </Routes>
    </>
  );
};

export default InvestmentSubRoute;
