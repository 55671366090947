import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AuthRoute from "./auth.route";
import AuthenticatedRoute from "./app.route";

const IndexRoute = props => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/*" element={<AuthRoute />} />
        {/* <PrivateRoute {...props} exact element={AuthenticatedRoute} /> */}
        <Route path="/*" element={props.token ? <AuthenticatedRoute /> : <Navigate to="/login" />} />
        <Route path="*" element={<div>{JSON.stringify(props.token)}</div>} />
      </Routes>
    </BrowserRouter>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_info: state.auth.login.data,
});

export default connect(mapStateToProps, {})(IndexRoute);
