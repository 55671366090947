import React, { useEffect } from "react";
import ProfileWrapper from "./ProfileWrapper";
import { connect } from "react-redux";
import { showModal } from "../../store/actions/creators/ui.action";
import Wrapper from "../../components/FundWithdraw/Wrapper";
import { useForm } from "react-hook-form";
import { PasswordField, TextField, Checkbox } from "../../components/Widget/Form/FormComponent";
import { AmountLabel, CustomLabel } from "../../components/FundWithdraw/withdrawalcomps";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { currencyFormatter } from "../../utils/helper";

// move this to a component later
export const LimitLabel = () => {
  // open modal for terms
  const handleReadTerms = e => {
    e.preventDefault();
    // alert("wassa");
  };

  return (
    <div>
      I have read & accepted the{" "}
      <span className="text-green01" onClick={handleReadTerms}>
        Terms of the Indemnity
      </span>
      , and to indemnify GDL.
    </div>
  );
};

const SubLabel = () => <span className="flex gap-x-1.5">Max {currencyFormatter(1000000)} Daily</span>;

const TransferLimit = ({ ...props }) => {
  const { register, formState, control, handleSubmit } = useForm({ mode: "onChange" });

  return (
    <Wrapper name="Set Transfer Limit" no_desc ignore_wallet_check className="!w-full">
      <form className="flex flex-col gap-[35px]">
        <div className="flex flex-col gap-[35px]">
          <TextField type="text" placeholder="₦0.00" label={<CustomLabel label="Transfer Limit" sub_label={<SubLabel />} />} {...register("limit", { required: true })} />
          <PasswordField label="Password" placeholder="************" {...register("password", { required: true })} />
          <Checkbox control={control} label={<LimitLabel />} {...register("agreement", { required: true })} />
        </div>
        <AppButton name="Next" className="self-end w-[138px]" disabled={!formState.isDirty || !formState.isValid} />
      </form>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal.show,
  open_sub: state.ui.modal.show_sub_modal,
  open_pass: state.ui.modal.show_pass,
  token: state.auth.login.token,
  question_count: state.user.get_user_sec_question.question_count,
  user_info: state.auth.login.data,
});

export default connect(mapStateToProps, { showModal })(TransferLimit);
