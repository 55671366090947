import React from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import * as pages from "../pages";

const AuthRoute = () => {
  return (
    <Routes>
      <Route path="login" element={<pages.Login />} />
      <Route path="signup" element={<pages.SignupOne />} />
      <Route path="more-info" element={<pages.SignupTwo />} />
      <Route path="forgot-password" element={<pages.ForgotPassword />} />
      <Route path="new-password" element={<pages.NewPassword />} />
      <Route path="verify-email" element={<pages.OtpConfirm />} />
      <Route path="pin-setup" element={<pages.PinSetup />} />
      <Route path="offerings" element={<pages.Offerings />} />
    </Routes>
  );
};

export default connect(null, {})(AuthRoute);
