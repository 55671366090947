import React, { useEffect, useState, useRef, forwardRef } from "react";
import ProgressBar from "@ramonak/react-progress-bar";

export const ItemOne = forwardRef((props, ref) => {
  const checker = props.currentmonth == props.value;

  return (
    <div className={`flex flex-col gap-[10px] items-center ${checker ? "md:min-w-[148px] py-1.5 px-5 gap-[10px] rounded-lg bg-white shadow-custom03 " : "flex-1 min-w-[40px]"}`}>
      {checker && <span className="text-gray01 text-[10px] font-semibold leading-[9.161px]">Total Months Passed</span>}
      <span className={`${checker ? "text-xs text-brand01 font-semibold mark" : "text-[10px] text-gray01"}`}>{props.value}</span>
      <div className={`w-[1px] h-[20px]  ${checker ? "bg-brand01" : "bg-[#E4E4E7]"}`} ref={ref} />
    </div>
  );
});

export const ItemTwo = props => {
  const checker = props.currentmonth == props.value;

  return (
    <div className={`flex flex-col gap-[10px] items-center ${checker ? "md:min-w-[148px] py-1.5 gap-[10px] rounded-lg bg-white shadow-custom03" : "flex-1 min-w-[40px]"}`}>
      <span className={`${checker ? "text-brand01 font-semibold" : "text-gray01"} text-xs`}>{checker && `NGN ${props.value_month}`}</span>
      <div className={`w-[1px] h-[20px]  ${checker ? "bg-brand01" : "hidden"}`} />
      {checker && <span className="text-gray01 text-[10px] font-semibold leading-[9.161px]">Total Monthly Contributions</span>}
    </div>
  );
};

const CustomProgressbar = props => {
  const [offset, setOffSet] = useState();
  const [currentmonth] = useState(props.data.total_months_passed);
  const [totalrange] = useState(props.data.pre_eligibility_period);
  const [fullwidth, setFullWidth] = useState();
  const month = props.data.total_monthly_contributions;
  const lineRef = useRef();
  const totalRangeArr = Array(parseInt(totalrange)).fill();

  useEffect(() => {
    const scaler = document.getElementById("scaler");
    setOffSet(scaler.getElementsByClassName("mark")[0]?.clientWidth);

    setFullWidth(lineRef.current.getBoundingClientRect().x - lineRef.current.getBoundingClientRect().top - 100);
  }, []);

  return (
    <div className="flex flex-col gap-5 scrollbar">
      <div className="flex justify-between items-end bg-white" id="scaler">
        {totalRangeArr.map((content, index) => (
          <ItemOne key={index} value={index + 1} currentmonth={currentmonth} ref={lineRef} />
        ))}
      </div>
      <div>
        <ProgressBar
          // completed={80}
          height="10px"
          completed={month < 1 ? 0 : offset - 0.8}
          width={`${fullwidth}px`}
          className=""
          bgColor="#9A2333"
          isLabelVisible={false}
        />
      </div>
      <div className="flex justify-between items-end">
        {totalRangeArr.map((content, index) => (
          <ItemTwo key={index} value={index + 1} currentmonth={currentmonth} value_month={month} />
        ))}
      </div>
    </div>
  );
};

export default CustomProgressbar;
