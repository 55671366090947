import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import * as pages from "../../pages";

const FundOptionRoute = () => {
  return (
    <>
      <Routes>
        <Route path={``} element={<pages.FundOptions />} />
        <Route path={`bank-transfer`} element={<pages.BankTransfer />} />
        {/* <Route path={`card-payment`} element={<pages.CardPayment />} />
        <Route path={`usd-rate`} element={<pages.USDPayment />} /> */}
      </Routes>
    </>
  );
};

export default connect(null, {})(FundOptionRoute);
