import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useHeaderResolver = () => {
  const { pathname } = useLocation();

  const { path_arr, path } = useMemo(() => {
    const array_path = pathname.split("/");
    const resolved_path = array_path[array_path.length - 1].replace(/-/g, " ");

    return { path_arr: array_path, path: resolved_path };
  }, [pathname]);

  return { path_arr, path };
};
