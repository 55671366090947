import { Call, MessageQuestion, Messages2, Transmit } from "iconsax-react";
import React from "react";
import { connect } from "react-redux";

const HelpCard = ({ icon, name, des, className }) => (
  <div className="flex p-[24px] justify-between items-center rounded-lg border border-[#F8F7F7] shadow-lg shadow-black/[0.03]">
    <div className="flex flex-col justify-center items-start flex-1 gap-5">
      <div className={`py-[9px] px-[12px] w-[42px] flex justify-center items-center rounded-full ${className}`}>{icon}</div>
      <div className="flex flex-col items-start gap-[5px] self-stretch">
        <span className="text-primary font-medium">{name}</span>
        <span className="text-xs">{des}</span>
      </div>
    </div>
    <Transmit size={18} className="transform -scale-x-100" />
  </div>
);

const Help = () => {
  const helpcard_data = [
    { name: "Chat Us", des: "Get answers to your questions about our product with 5 minutes", icon: <Messages2 variant="Outline" className="text-[#9A2333]" />, className: "bg-[#FFF4F6]" },
    { name: "Call Us", des: "Speak with our friendly customer experience specialists", icon: <Call variant="Outline" className="text-[#FF9C33]" />, className: "bg-[#FEF7EF]" },
    {
      name: "FAQs",
      des: "Our knowledge based resources provides you with answers to common questions others are asking",
      icon: <MessageQuestion variant="Outline" className="text-[#C32AFD]" />,
      className: "bg-[#FAF2FD]",
    },
  ];
  return (
    <div className="bg-white h-full flex py-5 px-[51px] flex-col items-start gap-[27px] rounded-lg">
      <div className="flex flex-col items-start gap-2.5">
        <span className="text-primary font-medium">Need Help?</span>
        <span className="text-xs">Use any of our channels below to get the required assistance</span>
      </div>
      <hr className="w-full border-[#E4E4E7]" />
      <div className="grid 2xl:grid-cols-2 grid-cols-1 self-stretch gap-[27px]">
        {helpcard_data.map((content, index) => (
          <React.Fragment key={index}>
            <HelpCard {...content} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default connect(null, {})(Help);
