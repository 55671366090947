import React, { useEffect, useRef, useState } from "react";
import { Edit2, Profile } from "iconsax-react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { getAction, postAction, editAction } from "../../store/actions/creators/request.action";
import { uploadFile } from "react-s3";
import { gdlAWSConfig } from "../../utils/helper";
import Loader from "../../components/Widget/Loader/Loader";
import { UPDATE_USER_PROFILE } from "../../store/actions/action_types/action.types";
import { usersURL } from "../../utils/endpoints";
import { Badge } from "../../components/Widget/Badge/Badge";
import { CopyView } from "../../components/Widget/Copy/Copy";
import { AppButton } from "../../components/Widget/Button/AppButton";
import toast from "react-hot-toast";

const OverviewInput = React.forwardRef(({ label, value, defaultValue, error, editStatus, onChange, disabled, name }, ref) => {
  return (
    <div className="flex-1 flex flex-col gap-y-2">
      <span className="text-[#666666]">{label}</span>
      {editStatus ? (
        <input
          disabled={disabled}
          name={name}
          ref={ref}
          className="bg-white disabled:bg-gray-100 rounded-lg text-[#1F2734] font-medium px-5 py-2 border border-[#E4E4E7]"
          error={error}
          value={value}
          onChange={onChange}
          type="text"
        />
      ) : (
        <span className="text-[#1F2734] font-medium">{defaultValue ?? "NILL"}</span>
      )}
    </div>
  );
});

const UserAccount = props => {
  const { details, total_bal, user_info, user_profile, update_profile_loading } = props;
  const [editStatus, setEditStatus] = useState(false);
  const imageFileRef = useRef(null);
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const { handleSubmit, formState, watch, setValue, register } = useForm({
    mode: "onChange",
    defaultValues: {
      first_name: details?.first_name,
      last_name: details?.last_name,
      email_address: details?.email,
      phone_number: details?.phone,
      residential_address: details?.address,
      marital_status: details?.marital_status,
      gender: details?.gender,
      country: details?.country,
    },
  });

  useEffect(() => {
    if (user_profile) {
      toast.success("Profile Updated Successfully");
      setEditStatus(false);
    }
  }, [user_profile]);

  useEffect(() => {
    if (image) {
      const data = { user_id: details.id, user_avatar_url: image };
      props.editAction(UPDATE_USER_PROFILE, `${usersURL}/${details.id}`, data, props.token, "PATCH");
    }
  }, [image]);

  const onFileChange = async event => {
    if (event.target.files[0]) {
      setLoading(true);
      try {
        const file_upload = await uploadFile(event.target.files[0], gdlAWSConfig);
        console.log({ file_upload });
        if (file_upload) {
          setImage(file_upload.location);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        toast.error("we are currently experiencing some issue uploading your image");
      }
    }
  };

  const onSubmit = data => {
    const payload = {};
    if (!details.id) {
      return;
    }
    payload["user_id"] = details.id;
    payload["address"] = data?.residential_address ? data?.residential_address : details.address;
    payload["country"] = data?.country ? data?.country : details.country;
    payload["marital_status"] = data?.marital_status ? data?.marital_status : details.marital_status;

    props.editAction(UPDATE_USER_PROFILE, `${usersURL}/${details.id}`, payload, props.token, "PATCH");
  };

  return (
    <div className="bg-white px-12 ">
      <div className="flex flex-col gap-2 py-5 border-b border-[#E4E4E7]">
        <h3 className="text-[#1F2734] font-medium">Basic Information</h3>
        <small className="text-[#666666]">The basic information can be edited and saved</small>
      </div>
      <div className="py-5 flex flex-col gap-y-8">
        {!editStatus && (
          <div className="flex justify-end">
            <button onClick={() => setEditStatus(true)} className="bg-[#3BB3C2] px-5 py-2 rounded-lg text-white flex items-center gap-x-1">
              <Edit2 size="18" color="#FFFFFF" />
              <span className="font-medium">Edit Profile</span>
            </button>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-8">
          <label htmlFor="photo-upload" className="custom-file-upload w-fit relative mx-auto">
            <div className="img-wrapper img-upload">
              {loading && (
                <div className="absolute bg-gray-500 flex w-full h-full rounded-full backdrop-filter backdrop-blur-sm bg-opacity-5 justify-center items-center">
                  <Loader loading={true} color="#FFF" />
                </div>
              )}
              <div className="w-[90px] h-[90px] rounded-full  bg-gray-100 flex justify-center items-center">
                {details?.user_avatar_url ? <img src={details?.user_avatar_url || ""} alt="" className="w-[90px] h-[90px] rounded-full object-cover" /> : <Profile size={50} variant="Bulk" />}
              </div>
            </div>
            <div className="absolute -right-3 bottom-0 p-[2px] bg-white rounded-full">
              <div className="rounded-full cursor-pointer flex justify-center items-center w-[38px] h-[38px] bg-[#3BB3C2]">
                <Edit2 size="18" color="#FFFFFF" />
              </div>
            </div>
            <input id="photo-upload" accept=".png, .jpeg, .jpg" type={"file"} ref={imageFileRef} onChange={onFileChange} hidden className="" />
          </label>
          <div className="grid grid-cols-2 gap-x-10 gap-y-8 items-start">
            <div className="flex-1 flex flex-col gap-y-2">
              <span className="text-[#666666]">Account status</span>
              <div>
                <Badge content={details?.account_status} />
              </div>
            </div>
            <div className="flex-1 flex flex-col gap-y-2">
              <span className="text-[#666666]">Referral ID</span>
              <div className="flex gap-2 items-center">
                <h3 className="font-medium text-primary">{details?.referral_code}</h3>
                <CopyView text={details?.referral_code} />
              </div>
            </div>
            <OverviewInput label={"First Name"} defaultValue={details?.first_name} disabled={true} editStatus={editStatus} error={formState.errors.first_name} {...register("first_name")} />
            <OverviewInput label={"Last Name"} defaultValue={details?.last_name} disabled={true} editStatus={editStatus} error={formState.errors.last_name} {...register("last_name")} />
            <OverviewInput label={"Email Address"} defaultValue={details?.email} disabled={true} editStatus={editStatus} error={formState.errors.email_address} {...register("email_address")} />
            <OverviewInput label={"Phone Number"} defaultValue={details?.phone} disabled={true} editStatus={editStatus} error={formState.errors.phone_number} {...register("phone_number")} />
            <OverviewInput
              label={"Resedential Address"}
              defaultValue={details?.address}
              editStatus={editStatus}
              error={formState.errors.residential_address}
              // disabled={true}
              {...register("residential_address")}
            />

            <OverviewInput label={"Country"} defaultValue={details?.country} editStatus={editStatus} error={formState.errors.country} {...register("country")} />
            <OverviewInput label={"Gender"} defaultValue={details?.gender} editStatus={editStatus} error={formState.errors.gender} {...register("gender")} />
            <OverviewInput label={"Marital Status"} defaultValue={details?.marital_status} editStatus={editStatus} error={formState.errors.marital_status} {...register("marital_status")} />
          </div>
          {editStatus && (
            <AppButton
              loading={update_profile_loading}
              disabled={update_profile_loading}
              className="bg-[#3BB3C2] self-start px-5 py-2 rounded-md border-0 text-white flex items-center gap-x-1"
              name={"Save Changes"}
            />
          )}
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.user.user_details.loading,
  details: state.user.user_details.data,
  user_info: state.auth.login.data,
  total_bal: state.account.get_wallets.total_bal,
  user_profile: state.user.update_profile.data,
  update_profile_loading: state.user.update_profile.loading,
});

export default connect(mapStateToProps, { getAction, postAction, editAction })(UserAccount);
