import { connect } from "react-redux";
import Wrapper from "../../components/FundWithdraw/Wrapper";
import { FlatList } from "../../components/Widget/List/List";
import { ArrowRight, Bank } from "iconsax-react";
import { useNavigate } from "react-router-dom";

/** move this to a seperate file later */
const PaymentRow = ({ icon, title, sub_title, route, handlePress }) => (
  <div className="flex self-stretch justify-between cursor-pointer items-center py-5 border-b border-b-[#F0F0F0]" onClick={handlePress(route)}>
    <div className="flex gap-[12px] items-center">
      {icon}
      <div className="flex flex-col gap-[10px]">
        <span className="font-medium text-primary leading-[20.16px]">{title}</span>
        <span className="text-sm">{sub_title}</span>
      </div>
    </div>
    <ArrowRight className="md:w-[18px] text-[#BABABA]" />
  </div>
);

const Img = <img src={"/bank_avater.svg"} className="md:w-[42px] md:h-[42px] border border-[#F0F0F0] rounded-full" />;

const cardInfo = [
  { icon: Img, title: "Send to GDL Account", sub_title: "Send money to a GDL account", route: "gdl-account" },
  {
    icon: (
      <div className="md:w-[42px] md:h-[42px] rounded-full p-[9px_12px] bg-brand02">
        <Bank className="md:w-[18px] md:h-[18px] text-brand01" />
      </div>
    ),
    title: "Send to Other Banks",
    sub_title: "Send money to other local bank accounts",
    route: "other-account",
  },
];

const WithdrawLanding = props => {
  const navigate = useNavigate();

  const handleNavigate = route => () => {
    navigate(`${route}`);
  };

  const renderItem = ({ item, index }) => <PaymentRow {...item} index={index} handlePress={handleNavigate} key={index} />;

  return (
    <>
      <Wrapper name="Payments" no_back desc="Send to account">
        <div className="flex flex-col items-start gap-10 self-stretch">
          <FlatList data={cardInfo} renderItem={renderItem} />
        </div>
      </Wrapper>
    </>
  );
};

export default connect(null, {})(WithdrawLanding);
