import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { account_endpoint } from "../../utils/endpoints";

export const fixedDepositApi = createApi({
  reducerPath: "fixedDepositApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${account_endpoint}`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = state.auth.login.token;
      if (token) {
        headers.set("bearerauth", token);
      }
      return headers;
    },
  }),
  tagTypes: ["TimedDeposit"],
  endpoints: builder => ({
    createFixedDepositRequest: builder.mutation({
      query: body => ({ url: `/fixed/deposit/requests`, method: "POST", body: body }),
      transformResponse: response => {
        return response.data;
      },
      invalidatesTags: ["TimedDeposit"],
    }),
    getAllFixedDepositRequests: builder.query({
      query: () => `/fixed/deposit/requests/all`,
      transformResponse: response => {
        return response.data;
      },
    }),
    getFixedDepositRequestById: builder.query({
      query: id => `/fixed/deposit/requests/${id}`,
      transformResponse: response => {
        return response.data;
      },
    }),
    getUserFixedDepositRequests: builder.query({
      query: userId => `/fixed/deposit/requests/user/p/${userId}`,
      transformResponse: response => {
        return response.data;
      },
      providesTags: ["TimedDeposit"],
    }),
    getFixedDepositRequestsByPhoneNumber: builder.query({
      query: phone_number => `/fixed/deposit/requests/user/bo/${phone_number}`,
      transformResponse: response => {
        return response.data;
      },
    }),
  }),
});

export const {
  useCreateFixedDepositRequestMutation,
  useGetAllFixedDepositRequestsQuery,
  useGetFixedDepositRequestByIdQuery,
  useGetUserFixedDepositRequestsQuery,
  useGetFixedDepositRequestsByPhoneNumberQuery,
} = fixedDepositApi;

export const { createFixedDepositRequest, getAllFixedDepositRequests, getFixedDepositRequestById, getUserFixedDepositRequests, getFixedDepositRequestsByPhoneNumber } = fixedDepositApi.endpoints;
