import React from "react";
// import Logo from "../../../assets/images/logo.png";

const AuthNav = ({ ...props }) => (
  <div className="bg-white w-full py-[12px] px-[130px] 2xl:px-[130px] xl:pl-[100px] lg:pl-[80px] pl-[10px] flex items-center">
    <header className="flex w-full">
      <nav className="nav nav-fill flex flex-wrap items-center justify-between text-lg text-gray-700">
        <div className="left-side flex items-center">
          <div className="logo-div md:block">
            <img src={"/images/logo.png"} className="w-[40px]" alt="logo" />
          </div>
        </div>
      </nav>
    </header>
  </div>
);

export default AuthNav;
