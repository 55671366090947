import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { processData } from "./loan.data";
import { showModal, emergencyModal } from "../../store/actions/creators/ui.action";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_LOAN_SUB } from "../../store/actions/action_types/action.types";
import { loanSubURL } from "../../utils/endpoints";
import Loader from "../../components/Widget/Loader/Loader";
import { useLocation } from "react-router-dom";
import AppModal from "../../components/Widget/Modal/Modal";
import EmergencyFund from "./EmergencyFund";
import { currencyFormatter } from "../../utils/helper";
import { ComingSoonDescription, EmptyView3 } from "../../components/Widget/EmptyView/EmptyView";

const Loans = ({ token, loading, ...props }) => {
  const [id, setId] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const loan_sub_url = `${loanSubURL}/${props.user_info.id}`;
    props.getAction(GET_LOAN_SUB, loan_sub_url, token);
  }, [id]);

  useEffect(() => {
    const redirect_data = location.state;
    if (Object.keys(props.emergency_fund_data).length > 0) {
      // alert(JSON.stringify(props.emergency_fund_data));
      props.showModal("OPEN_EMERGENCY_FUND", true);
    }
  }, [props.emergency_fund_data]);

  useEffect(() => {
    props.showModal("HIDE_MODAL", false);
    props.emergencyModal("CLOSE_EMERGENCY_MODAL");
    // props.showModal("CLOSE_EMERGENCY_FUND", false);
  }, []);

  useEffect(() => {
    if (Object.keys(props.emergency_fund_data).length > 0) {
      window.addEventListener("beforeunload", alertUser);
    }

    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [props.emergency_fund_data]);
  const alertUser = e => {
    e.preventDefault();
    e.returnValue = "";
  };

  // useEffect(() => {
  //   if (props.show_msg == false) {
  //     alert("closing...");
  //   }
  // }, [props.show_msg]);

  const top_data = {
    title: "Total Loan Balance",
    sub_title: currencyFormatter(props.loan_sub[0]?.disbursed_amount) || currencyFormatter("0.00"),
    statistics: {
      "Total Withdrawn": "N/A",
      "Total Credited": "N/A",
      "Some Extra": "N/A",
    },
  };

  const loanSub = props.loan_sub?.map(processData);

  //This method should navigate to Overview
  const navigateToOverview = () => {
    alert("navigate to overview");
  };
  return (
    <>
      <div className="h-full py-4 m-auto" style={{ width: "95%" }}>
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <Loader loading={props.loading_sub} color="#922333" />
          </div>
        ) : (
          <EmptyView3 desc={ComingSoonDescription} buttonTitle="Go back to overview" onPress={navigateToOverview} />
        )}
      </div>
      <AppModal open={props.open} onClose={() => null} content={<EmergencyFund data={location.state} />} />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal.show_emergency_fund,
  loading: state.loan.get_loan_sub.loading,
  loan_sub: state.loan.get_loan_sub.data,
  error_msg: state.loan.get_loan_sub.error_msg,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  emergency_fund_data: state.loan.get_emergency_fund.data,
  show_msg: state.ui.open_msg.msg_modal,
});

export default connect(mapStateToProps, {
  showModal,
  getAction,
  emergencyModal,
})(Loans);
