const baseURL = "https://test.gdlapi.com.ng/v1"; // suppose to come from the env file
const IP_ADD_KEY = process.env.REACT_APP_IP_ADD_KEY;

const error_codes = ["006"];

export const makeRequest = async (url, data = null, token = null, method_type = null) => {
  // try {
  const response = await fetch(`${url}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token ? { bearerauth: token } : {}),
    },
    method: method_type != null ? method_type : data == null ? "GET" : "POST",
    body: data == null ? null : JSON.stringify(data),
  });

  if (response.ok) {
    const data = await response.json();

    return { data };
  } else {
    const { response_description, message, response_code } = await response.json();
    throw new Error(error_codes.includes(response_code) ? "Oops! an error occured" : response_description == "Bad Request Exception" ? message : response_description);
  }
};

export const ipRequest = async url => {
  // const response = await axios.get(`${url}/?key=${IP_ADD_KEY}`)
  const response = await fetch(`${url}/?key=${IP_ADD_KEY}`);
  if (response.ok) {
    const data = await response.json();
    return { data };
  } else {
    const error = await response.json();
    throw new Error(error);
  }
};

export const fundRequest = async (url, data = null, token = null) => {
  const response = await fetch(`${url}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      bearerauth: token,
    },
    method: "POST",
    body: JSON.stringify(data),
  });

  if (response.ok) {
    const data = await response.json();
    return { data };
  } else {
    const { response_description } = await response.json();
    // alert(JSON.stringify(response_description));

    throw new Error(response_description);
  }
};

export const fileUpload = async (url = null, data, token = null) => {
  const response = await fetch("https://api.cloudinary.com/v1_1/dbkiemdqb/image/upload", {
    method: "POST",
    body: data,
  });

  if (response.ok) {
    const data = await response.json();
    return { data };
  } else {
    const error = await response.json();
    throw new Error(error);
  }
};
