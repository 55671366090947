import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PasswordField, SelectField, TextField } from "../../../components/Widget/Form/FormComponent";
import { useForm } from "react-hook-form";
import { AppButton } from "../../../components/Widget/Button/AppButton";
import { showModal, showMessage } from "../../../store/actions/creators/ui.action";
import { postAction } from "../../../store/actions/creators/request.action";
import { SET_SECURITY_QUESTION } from "../../../store/actions/action_types/action.types";
import { setSecurityURL } from "../../../utils/endpoints";
import { HeaderView } from "../../../components/Widget/Modal/Modal";
import toast from "react-hot-toast";

const SecurityQuestion = ({ token, success_msg, error, error_msg, ...props }) => {
  const { control, register, handleSubmit, formState, watch, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      user_id: props.user_info?.id,
      email: props.user_info?.email,
    },
  });

  const [showSuccess, setShowSuccess] = useState(false);

  const watchAllQuestions = watch(["answer1", "answer2", "answer3", "question1", "question2", "question3"]);

  const showpass = watchAllQuestions.every(element => element?.length > 0 || element != null);

  /** useEffect to show sucess modal when security question is successfully set */
  useEffect(() => {
    if (success_msg) {
      props.showModal("HIDE_SUB_MODAL", false);
      props.showMessage("OPEN_MSG_MODAL", { message: "Security Question set Successfully", msg_type: "success" });
    }
  }, [success_msg]);

  /** alert error if error comes up */
  useEffect(() => {
    if (error) {
      props.showModal("HIDE_SUB_MODAL", false);
      toast.error(error_msg);
    }
  }, [error]);

  const onSubmit = data => {
    const questions = [
      { question: data.question1?.question, answer: data.answer1 },
      { question: data.question2?.question, answer: data.answer2 },
      { question: data.question3?.question, answer: data.answer3 },
    ];

    data.security_questions = questions;

    // delete unneccessary data
    // delete data.question1;
    // delete data.question2;
    // delete data.question3;

    props.postAction(SET_SECURITY_QUESTION, setSecurityURL, data, token);
  };

  const handleClose = e => {
    e.preventDefault();
    props.showModal("HIDE_SUB_MODAL", false, props.modal_data);
  };

  return (
    <div className="">
      {/* <HeaderView title="Security Questions" onClose={props.onClose} /> */}
      <div className="px-12 pt-8 pb-12">
        <p className="text-left text-gray01">To complete your account setup, please enter answers for 3 security questions.</p>
        <form className="pt-4 w-full flex flex-col gap-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-5">
            <div className="flex items-center gap-x-5 justify-between py-5">
              <SelectField item={props.security_question} label={`Question 1`} control={control} selected_item="question" {...register("question1", { required: true })} />
              <TextField label="Answer" type="text" error={formState.errors[`answer1`]} name={`answer1`} {...register(`answer1`, { required: true })} />
            </div>
            <div className="flex items-center gap-x-5 justify-between py-5">
              <SelectField item={props.security_question} label={`Question 2`} control={control} selected_item="question" {...register("question2", { required: true })} />
              <TextField label="Answer" type="text" error={formState.errors[`answer2`]} name={`answer2`} {...register(`answer2`, { required: true })} />
            </div>
            <div className="flex items-center gap-x-5 justify-between py-5">
              <SelectField item={props.security_question} label={`Question 3`} control={control} selected_item="question" {...register("question3", { required: true })} />
              <TextField label="Answer" type="text" error={formState.errors[`answer3`]} name={`answer3`} {...register(`answer3`, { required: true })} />
            </div>

            {showpass && <PasswordField label="Password" name="password" placeholder="******" error={formState.errors.password} {...register("password", { required: true })} />}
          </div>
          <div className="flex justify-between items-center">
            <span onClick={() => reset()} className={`text-brand01 cursor-pointer text-sm underline ${!showpass ? "text-opacity-50 cursor-not-allowed" : ""}`}>
              Reset
            </span>
            <div className="flex flex-row-reverse gap-x-8 items-center">
              <AppButton name={"Done"} className="w-[135px]" loading={props.loading} disabled={!formState.isDirty || !formState.isValid} />
              <AppButton name="Cancel" className="w-[135px]" onPress={handleClose} variant={"outline"} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.user.set_questions.loading,
  success_msg: state.user.set_questions.success,
  error_msg: state.user.set_questions.error_msg,
  error: state.user.set_questions.error,
  user_info: state.auth.login.data,
  question: state.user.get_user_sec_question.user_question,
  security_question: state.user.security_question.data,
});

export default connect(mapStateToProps, { showModal, showMessage, postAction })(SecurityQuestion);
