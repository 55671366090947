import React from "react";
import { connect } from "react-redux";
import { OptionButton } from "../../components/Widget/Button/OptionButton";
import { Calendar2, ExportSquare } from "iconsax-react";
import TransactionView, { TransactionRow } from "../../components/Transaction/TransactionRow";
import { processInterestData } from "./save.data";
import { transformDataToGroupForm } from "../../utils/helper";

const SavingsHistory = ({ ...props }) => {
  const rawData = props.interest_data?.map(processInterestData);
  const interestData = transformDataToGroupForm(rawData);
  return (
    <div className="">
      <div className="flex gap-2 pb-4">
        <OptionButton
          name="Export"
          icon={<ExportSquare size={"18"} variant="Outline" />}
          // onPress={() => props.showModal("OPEN_MODAL", true)}
        />
        <OptionButton
          name="Date Range"
          icon={<Calendar2 size={"18"} variant="Outline" />}
          // onPress={() => props.showModal("OPEN_MODAL", true)}
        />
      </div>
      <div className="border-t pt-5 border-gray02">
        {interestData.length <= 0 ? (
          <div className="flex flex-col gap-2 items-center pt-5">
            <h3 className="text-primary font-medium text-[22px]">No records</h3>
            <p className="text-gray01">There are no data yet.</p>
          </div>
        ) : (
          <div className="overflow-auto h-[700px]">
            {interestData && (
              <TransactionView transactionDetails={[]} {...props}>
                <>
                  {interestData.map(content => (
                    <div key={content.transactions.id}>
                      <h4 className="text-sm">{content?.date}</h4>
                      {content?.transactions.map(data => (
                        <TransactionRow showReceipt={true} onClick={() => handleModalChange(data)} {...data} />
                      ))}
                    </div>
                  ))}
                </>
              </TransactionView>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  interest_data: state.saving.interest_history.data,
});

export default connect(mapStateToProps)(SavingsHistory);
