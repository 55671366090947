import React, { useEffect } from "react";
import { connect } from "react-redux";
import { showModal, clearState } from "../../../store/actions/creators/ui.action";
import { getAction } from "../../../store/actions/creators/request.action";
import { GET_MONTHLY_CONTRB } from "../../../store/actions/action_types/action.types";
import { hpaMonthlyContrbURL } from "../../../utils/endpoints";
import HousemoniFormOne from "./HousemoniFormOne";
import HousemoniFormTwo from "./HousemoniFormTwo";
import HouseMoniFormThree from "./HouseMoniFormThree";
import { NotStartedHouseMoni } from "../../../components/HouseMoni/HouseMoni";

const stages = { 0: <NotStartedHouseMoni />, 1: <HousemoniFormOne />, 2: <HousemoniFormTwo />, 3: <HouseMoniFormThree /> };

const HpaPlan = ({ token, ...props }) => {
  useEffect(() => {
    props.clearState("CLEAR_HOUSEMONI");
    props.getAction(GET_MONTHLY_CONTRB, hpaMonthlyContrbURL, token);
  }, []);
  return (
    <>
      <div className="h-[870px] flex w-full overflow-auto">{stages[props.next_form_data?.step]}</div>
    </>
  );
};

const mapStateToProps = state => ({
  next_form: state.ui.show_next_form.next_form,
  next_form_data: state.ui.show_next_form.form_data,
  product_code: state.ui.product_code.product_code_data?.product_code,
});

export default connect(mapStateToProps, { showModal, getAction, clearState })(HpaPlan);
