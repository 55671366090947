import { ArrowRight, DocumentText1 } from "iconsax-react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import AppModal from "../../components/Widget/Modal/Modal";
import { HouseMoniClassicRules, HouseMoniCommunityRules } from "../../components/HouseMoni/HouseMoniTerms";
import { setProductCode, showModal, showNextForm, clearState } from "../../store/actions/creators/ui.action";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_HPA_SUBS, GET_HPTAB } from "../../store/actions/action_types/action.types";
import { hpaSubscriptionURL, hpaTabURL } from "../../utils/endpoints";
import { Outlet } from "react-router-dom";
import { HouseMoniSkeleton } from "../../components/Widget/Loader/Skeleton";
import { useHasAccount } from "../../hooks/account.hook";

const BoxContent = ({ title, onPress }) => (
  <div className="flex flex-col justify-center items-center gap-5 self-stretch rounded-2xl">
    <div className="flex w-[42px] py-[9px] px-[12px] flex-col justify-center items-center rounded-full bg-brand02">
      <DocumentText1 size={24} className="text-brand01" />
    </div>
    <span className="text-primary text-xl font-medium">{title}</span>
    <span className="text-gray01 text-[15px]">To know your rights and how to qualify</span>
    <div className="flex items-center gap-[5px] cursor-pointer" onClick={() => onPress(title)}>
      <span className="text-gray01 text-[15px] font-medium underline underline-offset-4">Read Terms & Conditions</span>
      <ArrowRight size={18} className="text-gray01" />
    </div>
  </div>
);

const HouseMoniWrapper = ({ token, user_info, details, loading_product_data, housemoni_subs, ...props }) => {
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);
  const product_code = props.code_data?.product_code;
  const { is_valid_user } = useHasAccount(); // check for valid user

  /** call API to get user HouseMoni Subscription... this is to check is user has a subscription or they need to subscribe */
  useEffect(() => {
    if (!housemoni_subs) props.getAction(GET_HPA_SUBS, `${hpaSubscriptionURL}/${user_info.id}`, token);
  }, [housemoni_subs]);

  const handleOpenTermsCondition = title => {
    setOpen(true);
    setTitle(title);
  };

  // const handleLeaveSubscription = () => {
  //   if (props.next_form_data?.step == 1) {
  //     alert(props.next_form_data?.step);
  //   }
  // };

  useEffect(() => {
    if (!props.product_code_data) {
      props.getAction(GET_HPTAB, hpaTabURL, token); // different housemoni products
    }
    // return () => handleLeaveSubscription();
  }, [props.product_code_data]);

  const handleTabSelect = code => {
    setOpen(false);

    // show the alert to complete documents
    if (!is_valid_user) {
      props.showModal("OPEN_ALERT", true);
      return;
    }

    const product = props.product_code_data.find(product => product.product_code == code);
    !props.housemoni_subs && props.setProductCode(product);
    props.next_form_data?.step == "0" && props.showNextForm(true, { step: "1" }); // show next step only when form step is zero
  };
  const onDisagree = () => {
    setOpen(false);
    props.setProductCode(null);
    props.showNextForm(false, { step: "0" });
    props.clearState("CLEAR_HOUSEMONI");
  };

  return (
    <>
      <div className="">
        <div className="grid grid-cols-10 gap-8 items-start">
          {loading_product_data ? (
            <HouseMoniSkeleton />
          ) : (
            <div className="flex flex-col sm:col-span-3 col-span-4 p-5 items-start gap-10 rounded-lg bg-white md:max-h-auto">
              <div className="flex flex-col items-start gap-[10px]">
                <span className="text-primary font-medium">Terms & Conditions</span>
                <span className="text-gray01 text-[11px]">Please read these terms & conditions carefully before agreeing to continue</span>
              </div>
              {(!product_code || product_code == "HPA" || props.next_form_data?.step == "0") && (
                <>
                  <BoxContent title="HouseMoni Classic" onPress={handleOpenTermsCondition} />
                  <hr className={`w-full bg-gray04 ${product_code ? "hidden" : ""}`} />
                </>
              )}

              {(!product_code || product_code == "HAA" || props.next_form_data?.step == "0") && <BoxContent title="HouseMoni Community" onPress={handleOpenTermsCondition} />}
            </div>
          )}

          <div className="bg-white flex flex-col md:col-span-7 col-span-3 items-start min-h-[890px]">
            <Outlet />
          </div>
        </div>
      </div>
      <AppModal
        open={open}
        title={title}
        className="2xl:w-[900px] xl:w-[700px] lg:w-[500px]"
        onClose={() => setOpen(false)}
        content={title == "HouseMoni Classic" ? <HouseMoniClassicRules setOpen={onDisagree} onSelect={handleTabSelect} /> : <HouseMoniCommunityRules setOpen={onDisagree} onSelect={handleTabSelect} />}
      />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  product_code_data: state.hpa.get_hpa_tab.data,
  loading_product_data: state.hpa.get_hpa_tab.loading,
  code_data: state.ui.product_code.product_code_data,
  next_form_data: state.ui.show_next_form.form_data,
  user_info: state.auth.login.data,
  details: state.user.user_details.data,
  housemoni_subs: state.hpa.get_hpa_sub.hpa_data,
  hpa_success: state.hpa.add_hpa.success,
});

export default connect(mapStateToProps, { setProductCode, getAction, showModal, showNextForm, clearState })(HouseMoniWrapper);
