import React from "react";
import Carousel from "../../../components/Widget/Carousel/Carousel";
import TimedDepositCard from "../../../components/Card/TimedDepositCard";
import { LoaderII } from "../../../components/Widget/Loader/Loader";

const DATA = [
  {
    amount: 23083166,
    tenor_days: 172,
    maturation_date: "2024-04-15T00:00:00",
    should_rollover: false,
    deposit_status: "Closed",
  },
  {
    amount: 1046870,
    tenor_days: 91,
    maturation_date: "2024-06-03T00:00:00",
    should_rollover: false,
    deposit_status: "Closed",
  },
  {
    amount: 1080935,
    tenor_days: 182,
    maturation_date: "2024-10-14T00:00:00",
    should_rollover: false,
    deposit_status: "Active",
  },
  {
    amount: 938177.45,
    tenor_days: 90,
    maturation_date: "2026-12-31T00:00:00",
    should_rollover: false,
    deposit_status: "Active",
  },
  {
    amount: 1200000.0,
    tenor_days: 180,
    maturation_date: "2024-07-17T00:00:00",
    should_rollover: false,
    deposit_status: "Closed",
  },
  {
    amount: 4000.77,
    tenor_days: 364,
    maturation_date: "2030-11-14T00:00:00",
    should_rollover: false,
    deposit_status: "Active",
  },
  {
    amount: 5555990.0,
    tenor_days: 120,
    maturation_date: "2024-05-15T00:00:00",
    should_rollover: false,
    deposit_status: "Closed",
  },
  {
    amount: 30911.22,
    tenor_days: 60,
    maturation_date: "2026-08-22T00:00:00",
    should_rollover: false,
    deposit_status: "Active",
  },
  {
    amount: 8000.08,
    tenor_days: 180,
    maturation_date: "2024-02-23T00:00:00",
    should_rollover: false,
    deposit_status: "Closed",
  },
];

const FixedDeposits = ({ data, loading }) => {
  if (loading)
    return (
      <div className="flex justify-center mt-10">
        <LoaderII />
      </div>
    );
  const renderItem = ({ data, index }) => <TimedDepositCard key={index} {...data} />;

  return (
    <div className="border-t border-gray02">
      <Carousel data={DATA} chunkSize={6} renderItem={renderItem} />
    </div>
  );
};

export default FixedDeposits;
