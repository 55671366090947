import React, { useEffect } from "react";
import { connect } from "react-redux";
import AppAccordion from "../../components/Widget/Accordion/Accordion";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_USER_NOTIFICATION_BY_ID, GET_USER_NOTIFICATIONS } from "../../store/actions/action_types/action.types";
import { notificationsURL, userNotificationURL } from "../../utils/endpoints";
import { SectionList } from "../../components/Widget/List/List";
import { formatDate } from "../../utils/helper";

const Notification = ({ token, ...props }) => {
  useEffect(() => {
    if (props.notification_status) {
      const url = `${userNotificationURL}/${props.user_info.id}`;
      props.getAction(GET_USER_NOTIFICATIONS, url, token);
    }
  }, [props.notification_status]);

  const groupNotification = props.notifications.reduce((acc, obj) => {
    /** get date of each transactions  */
    const date = formatDate(obj.created_at, "DD MMM YYYY");

    // Check if there is an existing group for this date and merge the object to the array group
    acc[date] = [...(acc[date] || []), obj];

    // return the new object
    return acc;
  }, {});

  // function to read notification when it is clicked
  const handleRead = (id, detail_viewed) => {
    if (detail_viewed !== true) {
      const url = `${notificationsURL}/${id}`;
      props.getAction(GET_USER_NOTIFICATION_BY_ID, url, token);
    }
  };

  const notification_data =
    groupNotification &&
    Object.keys(groupNotification).map(date => ({
      title: date,
      data: groupNotification[date],
    }));

  const renderItem = ({ item }) => <AppAccordion {...item} onPress={handleRead} />;
  const renderHeader = ({ section }) => (
    <div className="text-left px-[30px]">
      <h4 className="text-sm">{section.title}</h4>
    </div>
  );

  return (
    <div className="flex flex-col bg-white py-[26px] gap-[27px] rounded-[8px]">
      <span className="text-primary font-medium px-[30px]">Notifications</span>
      <div className="flex flex-col items-start gap-10 self-stretch">
        {/* {JSON.stringify(notification_data[0].data)} */}
        <SectionList sections={notification_data} renderItem={renderItem} renderSectionHeader={renderHeader} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_info: state.auth.login.data,
  notifications: state.notification.get_notification.data,
  notification_status: state.notification.read_notification.data,
});

export default connect(mapStateToProps, { getAction })(Notification);
