import React from "react";
import { Routes, Route } from "react-router-dom";
import * as pages from "../../../../../pages";

const IncomeFundRoute = () => {
  return (
    <>
      <Routes>
        <Route exact path={`/`} element={<pages.IncomeFund />} />
        <Route path={`/subscription`} element={<pages.IncomeSubscriptions />} />
      </Routes>
    </>
  );
};

export default IncomeFundRoute;
