export const user_endpoint = "https://user.housemoni.net";
export const account_endpoint = "https://accts.housemoni.net/accounts";
export const invest_endpoint = "https://invests.housemoni.net/saveinvest";
const base4 = "https://test-loans.gdlapi.com.ng/loan";
const hpa_endpoint = "https://hpa.housemoni.net/hpa";
const transaction_endpoint = "https://trans.housemoni.net";
const base7 = "https://test-hpa.gdlapi.com.ng/v1/hpas/hpa";
const notificationBase = "https://notify.housemoni.net/notifications";

export const ipURL = "http://ipapi.co/json";
export const loginURL = `${user_endpoint}/auth/login`;
export const signupURL = `${user_endpoint}/auth/register`;
export const offeringsURL = `${invest_endpoint}/our/offerings/p`;
export const validateTokenURL = `${user_endpoint}/auth/validate/token`;
export const forgotPasswordURL = `${user_endpoint}/auth/forgot/password`;
export const newPasswordURL = `${user_endpoint}/auth/password/new/create`;
export const changePasswordURL = `${user_endpoint}/user/password/reset`;
export const confirmOtpURL = `${user_endpoint}/auth/email/verify/confirm`;
export const resendOtpURL = `${user_endpoint}/auth/verify/email/later`;
export const verifyPhoneURL = `${user_endpoint}/auth/validate/phone/exist`;
export const setPinURL = `${user_endpoint}/auth/set/temp/login`;
export const walletURL = `${account_endpoint}/wallet/all/user`;
export const fundTypeURL = `${account_endpoint}/wallet/funding/options`;
export const virtualAccountURL = `${account_endpoint}/virtual/accounts/user`;
export const nubanAccountURL = `${account_endpoint}/nuban/accounts`;
export const bankURL = `${account_endpoint}/withdrawals/list/banks`;
export const verifyGDLAccountURL = `${account_endpoint}/internal/transfers/account/enquiry`;
export const verifyOtherAccountURL = `${account_endpoint}/withdrawals/verify/account`;
export const externalWithdrawURL = `${account_endpoint}/withdrawals/init/transfer`;
export const intraWithdrawURL = `${account_endpoint}/internal/transfers/create`;
export const withdrawSavingURL = `${invest_endpoint}/savings/withdrawal`;
export const withdrawInvestmentURL = `${invest_endpoint}/investment/withdrawal`;
export const fundCardURL = `${account_endpoint}/wallet/fund/with/card`;
export const fundWithNubanURL = `${account_endpoint}/internal/transfers/fund/with/user/nuban`;
export const exchangeRateURL = `${account_endpoint}/exchange/rates`;
export const beneficiaryURL = `${account_endpoint}/transfer/beneficiaries`;
export const userBeneficiaryURL = `${beneficiaryURL}/types/user`;
export const transferFeeURL = `${account_endpoint}/withdrawals/txn/fee`;

export const investmentURL = `${invest_endpoint}/investment/products`;
export const tenorURL = `${invest_endpoint}/investment/tenors`;
export const investWalletURL = `${invest_endpoint}/investment/wallet/user/inv`;
export const investSubURL = `${invest_endpoint}/investment/subscriptions`;
export const investSubTypeURL = `${investSubURL}/subs/types`;
export const investUserSubURL = `${investSubURL}/user`;
export const interestHistoryURL = `${invest_endpoint}/investment/interest/history/subscription`;
export const investInterestHistoryURL = `${invest_endpoint}/investment/interest/history`;
export const hideInvestURL = `${investSubURL}/toggle/inv_sub`;
export const savingsURL = `${invest_endpoint}/savings/product`;
export const savingSubURL = `${invest_endpoint}/user/savings`;
export const saveInterestHistoryURL = `${invest_endpoint}/savings/interest/history/subs`;
export const saveInterestURL = `${invest_endpoint}/savings/interest/history`;
export const saveWalletURL = `${invest_endpoint}/savings/wallet/sw`;
export const hideSaveURL = `${savingSubURL}/toggle/savings_sub`;
export const statementHistoryUrl = `${invest_endpoint}/wallet/txns/history`;
export const loanSubURL = `${base4}/requests/user`;
export const loanURL = `${base4}/requests`;
export const emergencyFundURL = `${invest_endpoint}/investment/subscriptions/emergency/funds`;
export const getEmergencyFundURL = `${base4}/products/type/EMERGENCY_FUNDS`;
export const investmentHistoryByUserURL = `${investUserSubURL}/currency`;
export const assetMgtUrl = `${invest_endpoint}/asset/management/funds`;

export const hpaTabURL = `${hpa_endpoint}/home/product/versions`;
export const hpaURL = `${hpa_endpoint}/home/cost/limit`;
export const hpaHistoryURL = `${hpa_endpoint}/payments/history/user`;
export const purchaseAcctURL = `${hpa_endpoint}/plans`;
export const addHPAURL = `${hpa_endpoint}/subcriptions`;
export const hpaSubscriptionURL = `${addHPAURL}/user`;
export const hpaConfigURL = `${hpa_endpoint}/plan/configs`;
export const hpaEligibilityURL = `${hpa_endpoint}/home/cost/limit/test/eligibility`;
export const hpaGroupURL = `${hpa_endpoint}/groups`;
export const hpaGroupMemberURL = `${hpa_endpoint}/group/members`;
export const hacdraTransactionURL = `${hpa_endpoint}/hacdra/txns/hacdra`;
export const hpaMonthlyIncome = `${hpaURL}/validate/income`;
export const hpaMonthlyContrbURL = `${hpaURL}/monthly/contributions`;
export const hpaAdjustHomeCostURL = `${hpaURL}/calc/adjusted/home/cost`;

export const usersURL = `${user_endpoint}/user`;
export const userLoginHistoryURL = `${user_endpoint}/login/history`;
export const userRefferalURL = ``;
export const securityURL = `${user_endpoint}/config/security/questions`;
export const setSecurityURL = `${user_endpoint}/u/security/ques`;
export const securityUserURL = `${setSecurityURL}/user`;
export const setTxnPinURL = `${user_endpoint}/user/txn/pin/reset`;
export const docsTypeURL = `${user_endpoint}/owner/docs`;
export const userDocURL = `${docsTypeURL}/user`;
export const docsURL = `${docsTypeURL}/doc/types`;
export const proofURL = `${docsURL}/address/proof`;

export const bvnURL = `${account_endpoint}/virtual/accounts/bvn`;

export const genderURL = `${user_endpoint}/user/gender/types`;
export const maritalStatusURL = `${user_endpoint}/user/marital/statuses`;
export const transactionURL = `${transaction_endpoint}/transaction`;
export const userTransactionURL = `${transactionURL}/user`;
export const notificationsURL = `${notificationBase}`;
export const userNotificationURL = `${notificationsURL}/user`;
