import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { invest_endpoint } from "../../utils/endpoints";

export const savingsApi = createApi({
  reducerPath: "savingsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${invest_endpoint}`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const token = state.auth.login.token;
      if (token) {
        headers.set("bearerauth", token);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    getSavingsProduct: builder.query({
      query: () => `/savings/product`,
      transformResponse: response => {
        return response.data;
      },
    }),
    addSavingsSubscription: builder.mutation({
      query: body => ({ url: `/user/savings`, method: "POST", body: body }),
      transformResponse: response => {
        return response.data;
      },
    }),
    getSavingsProductDetails: builder.query({
      query: id => `/savings/product/${id}`,
      transformResponse: response => {
        return response.data;
      },
    }),
    getUserSavingsWalletDetails: builder.query({
      query: ({ userId, wallet_ref }) => `/savings/wallet/sw/${userId}/${wallet_ref}`,
      transformResponse: response => {
        return response?.data;
      },
    }),
  }),
});

export const { useGetSavingsProductQuery, useGetSavingsProductDetailsQuery, useGetUserSavingsWalletDetailsQuery, useAddSavingsSubscriptionMutation } = savingsApi;

export const { getSavingsProduct, getSavingsProductDetails, getUserSavingsWalletDetails, addSavingsSubscription } = savingsApi.endpoints;
