import styled from "styled-components";

export const PaginationContainer = styled.ul`
  display: flex;
  list-style-type: none;
  & .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 1px;
    color: #1f2734;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 8px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
    &.numbers {
      font-size: 14px;
    }
    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      ${"" /* background-color: rgba(0, 0, 0, 0.04); */}
      cursor: pointer;
    }
    &.selected {
      background-color: #9a2333;
      color: white;
    }

    &.disabled {
      pointer-events: none;

      &:hover {
        background-color: transparent;
        cursor: not-allowed;
      }
    }
  }
`;
