import React from "react";
import { connect } from "react-redux";
import TransactionView from "../../../components/Transaction/TransactionRow";

const processData = data => ({
  recipient_account: data.payment_reference,
  amount: data.amount,
  txn_date: new Date(data.createdAt).toLocaleDateString(),
});

const PaymentDefaults = props => {
  const tableInfo = props.hpa_member_data.payment_defaults?.map(processData);

  return (
    <div className="overflow-scroll">
      <TransactionView transaction={tableInfo} />
    </div>
  );
};

const mapStateToProps = state => ({
  hpa_member_data: state.hpa.hpa_member_data.data,
});

export default connect(mapStateToProps, {})(PaymentDefaults);
