import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { AppButton } from "../Widget/Button/AppButton";
import { showMessage, showModal } from "../../store/actions/creators/ui.action";
import { PasswordField, SelectField, TextField } from "../Widget/Form/FormComponent";
import { editAction, getAction } from "../../store/actions/creators/request.action";
import { GET_USER_DETAILS, GET_WALLETS, SET_TRANSACTION_PIN } from "../../store/actions/action_types/action.types";
import { setTxnPinURL, walletURL, usersURL } from "../../utils/endpoints";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { refine_txn_pin_schema, txn_pin_schema } from "../../schema/txn_pin.schema";
import { CustomCaption } from "../Common/common";

const TransactionPinModal = ({ token, user_info, ...props }) => {
  const { handleSubmit, formState, register, control, watch, setError } = useForm({
    mode: "onChange",
    defaultValues: {
      // question_id: props?.questions?.id,
      email: user_info?.email,
    },
    resolver: zodResolver(txn_pin_schema),
  });
  const [notMatchPin, setNotMatchPin] = useState();
  const watch_confirm_pin = watch("confirm_new_pin");

  const disabledState = !formState.isDirty || !formState.isValid;

  useEffect(() => {
    setNotMatchPin("");
  }, [watch_confirm_pin]);

  // useEffect(() => {
  //   getValues("question_id", props?.questions?.id);
  //   const user_sec_url = `${securityUserURL}/${props.user_info?.id}`;
  //   props.getAction(GET_USER_SECURITY_QUESTION, user_sec_url, token);
  // }, []);

  useEffect(() => {
    if (props.error_msg && props.error_msg != null) toast.error(props.error_msg);
  }, [props.error_msg]);

  useEffect(() => {
    if (props.success_msg) {
      props.showModal("HIDE_MODAL", false);
      props.showMessage("OPEN_MSG_MODAL", { message: "Your transaction PIN was successfully reset", msg_type: "success" });

      //recall wallets to get nuban account
      const url_wallet = `${walletURL}/${user_info?.id}/${user_info?.user_txn_ref}`;
      props.getAction(GET_WALLETS, url_wallet, token);
      props.getAction(GET_USER_DETAILS, `${usersURL}/${user_info?.id}`, token);
    }
  }, [props.success_msg]);

  const onSubmit = async data => {
    try {
      const valid_data = await refine_txn_pin_schema.parseAsync(data);
      valid_data.new_txn_pin = parseInt(valid_data.new_txn_pin);
      props.editAction(SET_TRANSACTION_PIN, setTxnPinURL, valid_data, token);
    } catch (error) {
      setNotMatchPin(error.errors[0].message);
      setError("confirm_new_pin", { message: error.errors[0].message });
    }
  };

  const handleCancel = e => {
    e.preventDefault();

    props.showModal("HIDE_MODAL", false);
  };

  const random_num = Math.floor(Math.random() * (props.questions?.length - 1));
  const random_question = useMemo(() => props.questions[random_num], [props.questions]); // prevents random question from recalculating

  return (
    <div className="">
      <div className="pt-8 pb-12 px-12">
        <p className="text-left mb-5">Your PIN secures your transaction. Your PIN must not start with 0</p>

        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="pb-14 flex flex-col gap-4">
            <div className="flex items-center gap-x-5 justify-between">
              <SelectField item={[random_question]} label={`Security question`} control={control} selected_item="question" value="id" {...register("question_id")} />
              <TextField label="Answer" type="text" error={formState.errors[`answer`]} {...register(`answer`)} />
            </div>
            <PasswordField label="Password" placeholder="******" error={formState.errors.password} {...register("password")} />
            <div className="flex items-center gap-x-5 justify-between">
              <PasswordField label="New PIN" placeholder="******" className="flex-1" error={formState.errors.new_txn_pin} {...register("new_txn_pin")} />
              <PasswordField
                label="Confirm new PIN"
                placeholder="******"
                className="flex-1"
                error={formState.errors.confirm_new_pin}
                {...register("confirm_new_pin")}
                caption={notMatchPin && <CustomCaption message={notMatchPin} />}
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <span className={`text-brand01 text-sm underline ${disabledState ? "text-opacity-50 cursor-not-allowed" : "cursor-pointer"}`}>Reset</span>
            <div className="flex flex-row-reverse gap-x-8 items-center">
              <AppButton name="Submit" loading={props.loading} disabled={disabledState} />
              <AppButton name="Cancel" onPress={handleCancel} variant={"outline"} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  questions: state.user.get_user_sec_question.user_question,
  user_info: state.auth.login.data,
  loading: state.user.set_txn_pin.updating,
  load_question: state.user.get_user_sec_question.loading,
  success: state.user.set_txn_pin.success,
  success_msg: state.user.set_txn_pin.data,
  error_msg: state.user.set_txn_pin.error_msg,
});

export default connect(mapStateToProps, {
  showModal,
  editAction,
  showMessage,
  getAction,
})(TransactionPinModal);
