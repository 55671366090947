import LoanInfo from "./LoanInfo";

export const processData = (data, index) => ({
  id: data.id,
  "S/N": index + 1,
  name: data.loan_product?.product_name,
  description: data?.purpose,
});

export const tabNames = {
  "Loan Detail": <LoanInfo />,
};
