import React, { useEffect, useState } from "react";
import { HeadlessTab } from "../../components/DetailsComponent/DetailsTab";
import { savingSubURL } from "../../utils/endpoints";
import { FilterComp } from "../../components/HOC/FilterHOC";
import SavingWrapper, { FilterComponent } from "./SavingWrapper";
import { ProductSubRow } from "../../components/Widget/List/ProductRow";
import SavingsCard from "../../components/Card/SavingsCard";
import { GET_SAVING_SUB } from "../../store/actions/action_types/action.types";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.action";
import { activateFiltering, setPagination, showModal } from "../../store/actions/creators/ui.action";
import Loader from "../../components/Widget/Loader/Loader";
import { EmptyView2 } from "../../components/Widget/EmptyView/EmptyView";

const getNextDate = (currentDate = new Date(), daystoadd = 1) => {
  const next_date = new Date(currentDate);
  next_date.setDate(currentDate.getDate() + daystoadd);

  const date = next_date.toISOString();
  return date.substring(0, date.indexOf("T"));
};

const SavingContent = ({ token, ...props }) => {
  const [type, setType] = useState("All Savings");
  const tabs = { "All Savings": "", Running: "running", Pending: "pending", Released: "ended" };
  const end_date = getNextDate();

  useEffect(() => {
    const sub_url = `${savingSubURL}/user/${props.user_info.id}?page=${props.paginate_num || 1}&per_page=2&startDate=2021-08-20&endDate=${end_date}${tabs[type] != "" ? `&status=${tabs[type]}` : ""}`;

    props.getAction(GET_SAVING_SUB, sub_url, token);
  }, [tabs[type], props.paginate_num, props.filter_state, props.saving_sub_msg]);

  const savingSub = props.saving_sub;

  const filter_obj = {
    filter_url: savingSubURL,
    getAction: props.getAction,
    activateFiltering: props.activateFiltering,
    token,
    type: GET_SAVING_SUB,
    data: savingSub,
    paginate_num: props.paginate_num,
  };
  return (
    <SavingWrapper>
      {(props.loading_sub || props.saving_sub === null) && !props.is_filter ? (
        <div className="flex justify-center h-full items-center">
          <Loader loading={true} color="#922333" />
        </div>
      ) : savingSub?.length <= 0 && !props.is_filter ? (
        <EmptyView2 has_btn />
      ) : (
        <>
          <div className="flex-1 bg-white rounded-lg px-12 py-3 relative">
            <div className="2xl:py-3 lg:py-2 flex justify-end 2xl:px-2 lg:px-0 sm:mb-0 mb-3">
              <div className="flex md:w-auto w-full flex-row md:gap-x-5 gap-x-3 items-center md:justify-start justify-between">
                <div className="flex flex-row items-center gap-x-2">
                  <HeadlessTab
                    tabs={tabs}
                    handlePress={index => {
                      setType(Object.keys(tabs)[index]);
                      props.activateFiltering(true);
                    }}
                  />
                  <FilterComp filter_obj={filter_obj} FilterComponent={FilterComponent} />
                </div>
              </div>
            </div>
            <div className="relative h-full">
              <ProductSubRow {...props} total={props.saving_total} loading={props.loading_sub}>
                <>
                  {savingSub?.map((item, idx) => {
                    return <SavingsCard key={item.id} {...item} {...props} />;
                  })}
                </>
              </ProductSubRow>
            </div>
          </div>
        </>
      )}
    </SavingWrapper>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal.show,
  loading_sub: state.saving.get_sub.loading,
  saving_sub: state.saving.get_sub.savings_data,
  saving_total: state.saving.get_sub.total,
  user_info: state.auth.login.data,
  paginate_num: state.ui.add_paginate?.paginate_link || 1,
  filter_state: state.ui.filter_data?.filter_savings,
  is_filter: state.ui.is_filter.isFilter,
  saving_sub_msg: state.saving.add_sub.success,
});

export default connect(mapStateToProps, {
  getAction,
  setPagination,
  activateFiltering,
  showModal,
})(SavingContent);
