import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AuthLayout, { AuthHeader } from "../../components/Layout/AuthLayout";
import { useForm } from "react-hook-form";
import { TextField } from "../../components/Widget/Form/FormComponent";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { postAction } from "../../store/actions/creators/request.action";
import { RENEW_PASSWORD } from "../../store/actions/action_types/action.types";
import { forgotPasswordURL } from "../../utils/endpoints";
import { clearState, showMessage } from "../../store/actions/creators/ui.action";

const ForgotPassword = ({ loading, token, ...props }) => {
  const [isvalid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const { handleSubmit, formState, getValues, register } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (props.success_msg == "Success" && props.success_msg != null) {
      navigate("/auth/new-password", {
        email: getValues("email"),
        request_token: props.data,
      });
      props.clearState();
    }
  }, [props.success_msg]);

  useEffect(() => {
    if (props.error_msg != null && getValues("email") != "") props.showMessage("OPEN_MSG_MODAL", props.error_msg);
  }, [props.error_msg]);

  const onSubmit = async data => {
    props.postAction(RENEW_PASSWORD, forgotPasswordURL, data, token);
  };

  return (
    <AuthLayout hasBanner>
      <div className="py-8 xl:py-10 px-8 xl:px-[51px] bg-white shadow-[0_15px_30px_0px_rgba(0,0,0,0.05)] w-full flex flex-col gap-10 justify-center border-[0.5px] border-[#E4E4E7] rounded-lg">
        <AuthHeader name="Reset Password" description={"Please enter your GDL account email and an OTP will be sent to your email."} />
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <TextField label="Email Address" placeholder="youremail@here.com" name="email" mb="mb-6" error={formState.errors.email} {...register("email", { required: true })} type="email" />
          <footer className="flex flex-col mt-6 md:mt-10">
            <AppButton name="Send OTP" className="w-fit md:w-[138px]" loading={loading} disabled={!formState.isDirty || !formState.isValid} />
          </footer>
        </form>
      </div>
    </AuthLayout>
  );
};

const mapStateToProps = state => ({
  loading: state.register.forgot_password.loading,
  token: state.auth.login.token,
  error_msg: state.register.forgot_password.error_msg,
  error: state.register.forgot_password.error,
  success_msg: state.register.forgot_password.message,
  data: state.register.forgot_password.data,
});

export default connect(mapStateToProps, {
  postAction,
  clearState,
  showMessage,
})(ForgotPassword);
