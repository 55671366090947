import styled from "styled-components";

export const BadgeText = styled.small`
  /* font-size: 10px; */
  @media only screen and (max-width: 1024px), (max-width: 1536px) {
    /* font-size: 6px; */
  }
  @media only screen and (max-width: 760px) {
    /* font-size: 10px; */
  }
`;
