import { Card, Profile } from "iconsax-react";

export const assets_links = [
  { id: 1, name: "Canary Fund", url: "/products/assets/canary-fund/subscription" },
  { id: 2, name: "Money Market Fund", url: "/products/assets/money-market-fund/subscription" },
  { id: 3, name: "Income Fund", url: "/products/assets/income-fund/subscription" },
];

export const radio_data = [
  { title: "Pay with bank card", value: "card", icon: <Card className="md:w-[24px] md:h-[24px]" /> },
  { title: "Pay with GDL Account", value: "api", icon: <Profile className="md:w-[24px] md:h-[24px]" /> },
];
export const redeem_funds_arr = [
  "Request a withdrawal or redemption by completing the required fields.",
  "Funds are typically disbursed within 1-2 business days.",
  "No penalties or fees for early withdrawal (subject to change).",
];
