import React from "react";
import { Routes, Route } from "react-router-dom";
import AssetWrapper from "../../../../components/Products/assets/asset.wrapper";
import CanaryFundRoute from "./canaryRoute/canary.route";
import MoneyMarketRoute from "./moneymarketRoute/moneymarket.route";
import IncomeFundRoute from "./incomeRoute/income.route";
import * as pages from "../../../../pages";

const AssetRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<AssetWrapper />}>
          <Route path={`/canary-fund/*`} element={<CanaryFundRoute />} />
          <Route path={`/money-market-fund/*`} element={<MoneyMarketRoute />} />
          <Route path={`/income-fund/*`} element={<IncomeFundRoute />} />
          <Route path="/bank-details" element={<pages.AssetBankDetails />} />
        </Route>
      </Routes>
    </>
  );
};

export default AssetRoute;
