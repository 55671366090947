import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AuthLayout, { AuthHeader } from "../../components/Layout/AuthLayout";
import { useForm } from "react-hook-form";
import { PasswordField, PinInput } from "../../components/Widget/Form/FormComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { postAction } from "../../store/actions/creators/request.action";
import { ADD_NEW_PASSWORD } from "../../store/actions/action_types/action.types";
import { newPasswordURL } from "../../utils/endpoints";
import { showMessage, showModal } from "../../store/actions/creators/ui.action";
import AppModal from "../../components/Widget/Modal/Modal";

const Message = connect(null, { showModal })(({ navigate, showModal, ...props }) => (
  <div className="p-5 flex flex-col">
    <span className="mb-2 text-red-800 text-lg">CONGRATS!!</span>
    <div className="flex flex-col">
      <span className="text-sm text-gray-600">Your password has been changed successfully</span>
      <span className="text-sm text-gray-600">Click the button below to continue to login</span>
    </div>
    <div className="my-3 border-t" />
    <AppButton
      name="Continue"
      className="font-semibold rounded-md outline-none"
      pad=".7em"
      onPress={() => {
        showModal("HIDE_MODAL", false);
        navigate("/login");
      }}
    />
  </div>
));

const NewPassword = ({ loading, token, ...props }) => {
  const [isvalid, setIsValid] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { handleSubmit, formState, setValue, register, control } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (props.error_msg != null) alert(JSON.stringify(props.error_msg));
  }, [props.error_msg]);

  useEffect(() => {
    if (props.success_msg == "Success") {
      props.showModal("OPEN_NEW_PASSWORD_MODAL", true);
    }
  }, [props.success_msg]);

  const onSubmit = async data => {
    data.email = location.state.email;
    data.request_token = location.state.request_token;
    props.postAction(ADD_NEW_PASSWORD, newPasswordURL, data);
  };

  return (
    <>
      <AuthLayout hasBanner>
        <div className="py-8 xl:py-10 px-8 xl:px-[51px] bg-white shadow-[0_15px_30px_0px_rgba(0,0,0,0.05)] w-full flex flex-col gap-10 justify-center border-[0.5px] border-[#E4E4E7] rounded-lg">
          <AuthHeader name="Reset Password" description={"Please enter your new password and the OTP that was sent to your email."} />
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-5">
              <PasswordField label="New Password" name="new_password" placeholder="Enter your new password" error={formState.errors.new_password} {...register("new_password", { required: true })} />
              <PinInput label="OTP" otp_length={6} name="request_otp" control={control} {...register("request_otp", { required: true })} />
            </div>

            <footer className="flex flex-col mt-6 xl:mt-14">
              <AppButton name="Reset Password" className="w-fit" loading={loading} disabled={!formState.isDirty || !formState.isValid} />
            </footer>
          </form>
        </div>
      </AuthLayout>
      <AppModal open={props.open} onClose={() => null} content={<Message navigate={navigate} {...props} token={token} />} />
    </>
  );
};

const mapStateToProps = state => ({
  loading: state.register.new_password.loading,
  token: state.auth.login.token,
  error_msg: state.register.new_password.error_msg,
  success_msg: state.register.new_password.message,
  open: state.ui.modal.show_new_pass,
});

export default connect(mapStateToProps, { postAction, showMessage, showModal })(NewPassword);
