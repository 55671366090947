import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Badge } from "../../../components/Widget/Badge/Badge";
import TransactionView from "../../../components/Transaction/TransactionRow";
import { GET_HPA_TRANSACTION_HISTORY } from "../../../store/actions/action_types/action.types";
import { hpaHistoryURL } from "../../../utils/endpoints";
import { getAction } from "../../../store/actions/creators/request.action";
import { useLocation } from "react-router-dom";
import { headerResolver } from "../../../utils/helper";
import { useHeaderResolver } from "../../../hooks/path.hook";

const processData = data => ({
  recipient_account: data.payment_reference,
  "Payment Status": <Badge content={data.payment_status} />,
  amount: data.amount_paid,
  txn_date: new Date(data.createdAt).toLocaleDateString(),
});

const PaymentHistory = ({ token, ...props }) => {
  const { pathname } = useLocation();
  const { path } = useHeaderResolver();

  useEffect(() => {
    /** get the payment history of a user */
    const member_id = path !== "housemoni" ? props.hpa_member_data?.user_id : props.user_info?.id;
    const url = `${hpaHistoryURL}/${member_id}`;
    props.getAction(GET_HPA_TRANSACTION_HISTORY, url, token);
  }, []);

  const tableInfo = props.transaction_data?.map(processData);
  return <div className="overflow-scroll px-2">{props.loading ? <div>loading...</div> : <TransactionView transaction={tableInfo} {...props} />}</div>;
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.hpa.hpa_transaction_history.loading,
  error_msg: state.hpa.hpa_member_data.error_msg,
  hpa_member_data: state.hpa.hpa_member_data.data,
  transaction_data: state?.hpa?.hpa_transaction_history?.data,
  user_info: state.auth.login.data,
});

export default connect(mapStateToProps, { getAction })(PaymentHistory);
