import { TickSquare } from "iconsax-react";
import React from "react";

const SuccessModal = ({ content }) => {
  return (
    <div className="p-14 flex justify-center items-center">
      <div className="flex flex-col items-center gap-4">
        <TickSquare size="50" color="#449E6A" variant="Bold" />
        <div className="text-center">
          <h2 className="text-primary">Submitted!</h2>
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
