import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  error: false,
  data: null,
  success: null,
  error_msg: null,
  user_question: null,
  updating: false,
  response_msg: "",
  question_count: 0,
};

const userDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_USER_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USER_DETAILS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        response_msg: "",
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const userUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.UPDATE_USER_DETAILS:
      return {
        ...state,
        updating: true,
      };
    case type.UPDATE_USER_DETAILS:
      return {
        ...state,
        updating: false,
        response_msg: action.payload.data.response_description,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        updating: false,
        response_msg: "",
      };
    case ERROR_OCCURED:
      return {
        ...state,
        updating: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getUserHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_USER_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USER_HISTORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data.data,
        total: action.payload.data.data.meta.total,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getUserRefferalReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_USER_REFFERAL:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USER_REFFERAL:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSecurityQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_SECURITY_QUESTION:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SECURITY_QUESTION:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getUserSecurityQuestion = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_USER_SECURITY_QUESTION:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USER_SECURITY_QUESTION:
      return {
        ...state,
        loading: false,
        question_count: action.payload.data.data.length,
        // user_question: action.payload.data.data[Math.floor(Math.random() * action.payload.data.data.length - 1)],
        user_question: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
        question_count: 0,
        user_question: null,
      };
    default:
      return state;
  }
};

const setSecurityQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.SET_SECURITY_QUESTION:
      return {
        ...state,
        loading: true,
      };
    case type.SET_SECURITY_QUESTION:
      return {
        ...state,
        loading: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
      };
    case "HIDE_SUB_MODAL":
      return {
        ...state,
        success: null,
        error: false,
        error_msg: null,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setTransactionPinReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.SET_TRANSACTION_PIN:
      return {
        ...state,
        updating: true,
        error_msg: null,
      };
    case type.SET_TRANSACTION_PIN:
      return {
        ...state,
        updating: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        updating: false,
        error: true,
        error_msg: action.payload,
      };
    case "HIDE_MODAL":
      return {
        error: false,
        error_msg: null,
        user_question: null,
        data: null,
      };
    default:
      return state;
  }
};

const getOwnersDocReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_OWNER_DOCS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_OWNER_DOCS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case type.GET_PROOF_ADDR:
      return {
        ...state,
        loading: false,
        proofs: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getUserDocReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_USER_DOCS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USER_DOCS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const updateUserProfile = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.UPDATE_USER_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case type.UPDATE_USER_PROFILE:
      return {
        ...state,
        loading: false,
        data: action.payload.data.response_description,
      };
    case type.GET_USER_DETAILS:
      return {
        ...state,
        loading: false,
        data: null,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const setOwnerDocsReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_OWNER_DOCS:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_OWNER_DOCS:
      return {
        ...state,
        loading: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
      };
    case "CLOSE_MSG_MODAL" || "CLEAR_STATE":
      return {
        loading: false,
        data: null,
        success: null,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const userReducer = combineReducers({
  user_details: userDetailReducer,
  user_history: getUserHistoryReducer,
  user_refferal: getUserRefferalReducer,
  security_question: getSecurityQuestionReducer,
  set_questions: setSecurityQuestionReducer,
  get_user_sec_question: getUserSecurityQuestion,
  set_txn_pin: setTransactionPinReducer,
  owner_docs: getOwnersDocReducer,
  set_docs: setOwnerDocsReducer,
  user_docs: getUserDocReducer,
  update_user: userUpdateReducer,
  update_profile: updateUserProfile,
});
export default userReducer;
