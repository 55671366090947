import React from "react";
import { Menu } from "@headlessui/react";
import { NavLink as Link } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../../store/actions/creators/ui.action";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TopbarData = props => (
  <>
    <div className="py-1">
      <Menu.Item>
        {({ active }) => (
          <Link to={"/profile"} className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-0">
            Account settings
          </Link>
        )}
      </Menu.Item>
    </div>

    <div className="py-1">
      <Menu.Item>
        {({ active }) => (
          <button className="text-gray-700 block w-full text-left px-4 py-2 text-sm" role="menuitem" tabIndex="-1" id="menu-item-3" onClick={() => props.logOut()}>
            Sign out
          </button>
        )}
      </Menu.Item>
    </div>
  </>
);

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { logOut })(TopbarData);
