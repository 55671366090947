import { z } from "zod";

const validateByInvestorType = (data, ctx) => {
  if (data.type_of_investor === "A Child") return;

  if (!data.phone_number) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["phone_number"],
      message: "phone number is required",
    });
  }

  if (!data.email) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["email"],
      message: "email is required",
    });
  }
};

export const self_investment_schema = z
  .object({
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    email: z.string().email().optional(),
    gender: z.string().optional(),
    date_of_birth: z.string().optional(),
    phone_number: z.string().optional(),
    residential_address: z.string({ message: "address is required" }).optional(),
    country: z.object({ name: z.string() }, { message: "country is required" }).optional(),
    state: z.string({ message: "state is required" }).optional(),
  })
  .optional();

export const others_investment_schema = z
  .object({
    type_of_investor: z.string({ message: "Investor type is required" }).optional(),
    relation: z.string({ message: "relationship is required" }).optional(),
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    gender: z.string().optional(),
    date_of_birth: z.string().optional(),
    phone_number: z.string().optional(),
    email: z.string().email().optional(),
    residential_address: z.string({ message: "address is required" }).optional(),
    nationality: z.string({ message: "nationality is required" }).optional(),
    country: z.object({ name: z.string(), id: z.number() }, { message: "country is required" }).optional(),
    state: z.string({ message: "state is required" }).optional(),
  })
  .superRefine(validateByInvestorType)
  .optional();

export const asset_schema = z
  .object({
    user_id: z.number({ message: "required" }),
    product_type: z.string({ message: "required" }),
    investment_amount: z.number({ message: "Investment Amount is required" }).refine(val => val > 0, { message: "Invalid Investment Amount" }),
    investing_for: z.enum(["Self", "Others"], { message: "Investing for is required" }),
    self_investment: self_investment_schema,
    others_investment: others_investment_schema,
  })
  // if investment type is Self and there's no first name, return true to make first_name required
  .superRefine((data, ctx) => {
    if (data.investing_for === "Self") {
      Object.keys(data.self_investment || {}).map(
        key =>
          !data.self_investment[key] &&
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["self_investment", key],
            message: `${key.replace("_", " ")} is required`,
          })
      );
    }

    if (data.investing_for === "Others") {
      Object.keys(data.others_investment || {}).map(
        key =>
          !data.others_investment[key] &&
          key !== "phone_number" &&
          key !== "email" &&
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["others_investment", key],
            message: `${key.replace("_", " ")} is required`,
          })
      );
    }
  });

export const bank_details_schema = z.object({
  bank_name: z.string({ message: "bank name is required" }),
  account_name: z.string({ message: "account name is required" }),
  account_number: z.string({ message: "account_number is required" }),
  dividends_should_be: z.string({ message: "dividends_should_be is required" }),
  confirmed_age_declaration: z.boolean().refine(val => val === true),
  confirmed_correctness_of_provided_information: z.boolean().refine(val => val === true),
  certified_legitimacy_of_provided_funds: z.boolean().refine(val => val === true),
  confirmed_they_understand_the_nature_of_investments: z.boolean().refine(val => val === true),
  initials: z.string().min(1),
});
