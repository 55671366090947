import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_INTEREST_HISTORY } from "../../store/actions/action_types/action.types";
import { investInterestHistoryURL, interestHistoryURL } from "../../utils/endpoints";
import { processInterestData } from "./invest.data";
import { GET_INTEREST_DETAIL } from "../../store/actions/action_types/action.types";
import { transformDataToGroupForm } from "../../utils/helper";

const InvestInterestHistory = ({ loading, token, ...props }) => {
  useEffect(() => {
    const url = `${interestHistoryURL}/${props.invest_id}`;
    props.getAction(GET_INTEREST_HISTORY, url, token);
  }, []);

  const handleOnClick = id => {
    const url = `${investInterestHistoryURL}/${id}`;
    props.getAction(GET_INTEREST_DETAIL, url, token);
  };

  const rawData = props.interest_data?.map(processInterestData);

  const tableInfo = transformDataToGroupForm(rawData);
  return (
    <div className="w-full h-full">
      {props.loading ? (
        <div className="h-full flex justify-center items-center">
          <Loader loading={props.loading} color="#922333" />
        </div>
      ) : (
        <>
          {tableInfo.length === 0 ? (
            <div className="flex flex-col gap-2 items-center pt-5">
              <h3 className="text-primary font-medium text-[22px]">No records</h3>
              <p className="text-gray01">There are no data yet.</p>
            </div>
          ) : (
            <TransactionView {...props}>
              <>
                {tableInfo.map(content => (
                  <div key={content.transactions?.id}>
                    <h4 className="text-sm">{content?.date}</h4>
                    {content?.transactions.map(data => (
                      <TransactionRow onClick={() => handleModalChange(data)} {...data} />
                    ))}
                  </div>
                ))}
              </>
            </TransactionView>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  invest_id: state.ui.id_setter.id,
  loading: state.investment.interest_history.loading,
  interest_data: state.investment.interest_history.data,
  error_msg: state.investment.interest_history.error_msg,
  id: state.ui.set_data.selected_data,
  int_detail_data: state.investment.invest_history.int_detail_data,
});

export default connect(mapStateToProps, { getAction })(InvestInterestHistory);
