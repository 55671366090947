import styled from "styled-components";

export const TopContainer = styled.div`
  background-color: #ffffff;
  padding: 1em 2em;
  box-shadow: 0 0 2px #ccc;
  @media (max-width: 768px) {
    padding: 1em 2em;
    /* display: bl; */
  }
  @media only screen and (max-width: 1024px), (max-width: 1536px) {
    padding: 0.3em 0.5em;
    /* background-color: red; */
  }
`;

export const BarContainer = styled.div`
  display: none;
  cursor: pointer;
  padding: 10px;
  @media (max-width: 768px) {
    display: inline-block;
    /* padding-right: 1em; */
  }
`;

export const Bar = styled.div`
  width: 22px;
  height: 2px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
`;
