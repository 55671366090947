import { Graph, Security, StatusUp, ArrowRight } from "iconsax-react";
import { currencyFormatter } from "../../utils/helper";
import clsx from "clsx";

const icon = [<Security color="#C32AFD" />, <StatusUp color="#3BB3C2" />, <Graph color="#FF9C33" />];
const color = ["#FAF2FD", "#F5F9FE", "#FEF7EF"];

export const ProductCard = ({ amount, index, onPress, url, icon, color, has_rate, ...props }) => (
  <div key={index + 1} className={clsx("rounded-lg bg-white flex justify-between 2xl:p-5 xl:p-3 p-3 w-full")}>
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-[5px]">
        <span className="font-normal md:text-xs leading-[15.12px]">{props.name}</span>
        <span className="text-primary font-semibold">{currencyFormatter(props.balance ?? 0)}</span>
      </div>
      <div className="flex items-center gap-[5px] text-gray01 cursor-pointer" onClick={() => url && onPress(url)}>
        <span className="2xl:text-xs xl:text-[10px] font-normal underline underline-offset-2">View More</span>
        <ArrowRight size={12} />
      </div>
    </div>

    <div
      className={`flex 2xl:w-[42px] xl:w-[36px] lg:w-[33px] w-[33px] 2xl:h-[42px] xl:h-[36px] lg:h-[33px] h-[33px] rounded-full py-[9px] px-3 flex-col justify-center items-center`}
      style={{ backgroundColor: color }}
    >
      {icon}
    </div>
  </div>
);
