import React, { useEffect, useMemo } from "react";
import "./topbar.css";
import { BarContainer, Bar } from "./topbar.style";
import { connect } from "react-redux";
import { openNotification, logOut, openSideBar, showModal } from "../../store/actions/creators/ui.action";
import NotificationBox from "../Widget/Notification/Notification";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_USER_NOTIFICATIONS } from "../../store/actions/action_types/action.types";
import { userNotificationURL } from "../../utils/endpoints";
import Dropdown, { TopbarDropDiv } from "../Widget/Dropdown/Dropdown";
import TopbarData from "./TopbarContent";
import { Link } from "react-router-dom";
import { NotificationBing } from "iconsax-react";
import CustomSubtab, { payment_links, product_link } from "./subtabs/Subtab";
import { useHeaderResolver } from "../../hooks/path.hook";

const ROUTES = ["transactions", "fund-account", "withdrawal"];

export const PRODUCT_ROUTES = ["invest", "savings", "emergency funds", "timed deposit", "subscription"];

export const SubBarItem = ({ onPress, ...props }) => {
  return (
    <Link to={props.href} className={`flex py-2 items-center gap-1 ${props.className}`}>
      {props.icon && props.icon}
      {props.name}
    </Link>
  );
};

const Topbar = props => {
  // const value = props.user_info.last_name + " " + props.user_info.first_name;
  const { path, path_arr } = useHeaderResolver();

  const notification_len = props.notifications.filter(val => val.detail_viewed == false).length;

  // check if current path matches any item in ROUTES array
  const isRouteMatch = ROUTES.some(route => path_arr.includes(route));
  const isProductRouteMatch = useMemo(() => PRODUCT_ROUTES.some(route => path_arr.includes(route.replace(/ /g, "-"))), [path]);

  useEffect(() => {
    const url = `${userNotificationURL}/${props.user_info.id}`;
    props.getAction(GET_USER_NOTIFICATIONS, url, props.token);
  }, []);

  return (
    <div className="sticky top-0 z-50">
      <div className="container mx-auto bg-white flex py-[15px] sm:px-[32px] px-2 sm:justify-center justify-between items-center">
        <BarContainer onClick={() => props.openSideBar()}>
          <Bar></Bar>
          <Bar></Bar>
          <Bar></Bar>
        </BarContainer>
        <div className="flex-col flex-1 hidden md:flex">
          <h3 className="capitalize 2xl:text-[22px] xl:text-[20px] lg:text-[18px] font-medium text-primary">{path_arr[1] == "dashboard" ? "Overview" : path_arr[1]}</h3>
        </div>
        <div className="relative flex items-center justify-end gap-5 2xl:gap-10 xl:gap-5 lg:gap-3">
          <Dropdown content={<NotificationBox />} className="right-24 z-[5000] mt-3">
            <div className="relative">
              <NotificationBing className="sm:w-[32px] sm:h-[32px]" color="#1F2734" variant="Outline" />
              <div
                className={`sm:text-sm text-xs text-white sm:w-[19px] w-[17px] absolute -top-1 -right-1 sm:h-[19px] h-[17px] ${
                  notification_len > 0 ? "flex" : "hidden"
                } justify-center items-center rounded-full bg-[#E51837]`}
              >
                {notification_len}
              </div>
            </div>
          </Dropdown>
          <>
            <Dropdown content={<TopbarData />}>
              <TopbarDropDiv details={props.details} user_info={props.user_info} />
            </Dropdown>
          </>
        </div>
      </div>
      {isRouteMatch && <CustomSubtab sub_link={payment_links} btnPress={() => props.showModal("OPEN_MODAL", true)} />} {/** include button when it's transaction */}
      {isProductRouteMatch && <CustomSubtab sub_link={product_link} />}
    </div>
  );
};

const mapStateToProps = state => ({
  user_info: state.auth.login.data,
  details: state.user.user_details.data,
  notify: state.ui.notify_box.open,
  token: state.auth.login.token,
  notifications: state.notification.get_notification.data,
});

export default connect(mapStateToProps, {
  openNotification,
  getAction,
  logOut,
  openSideBar,
  showModal,
})(Topbar);
