import { z } from "zod";

export const signin_schema = z.object({
  email: z.string().email(),
  password: z.string().min(1, { message: "password is required" }),
});

export const signup_schema = z
  .object({
    email: z.string().email(),
    password: z.string().min(8),
    password_confirm: z.string(),
    agreement: z.boolean().refine(val => val === true),
  })
  .refine(data => data.password == data.password_confirm, {
    message: "password must match",
    path: ["password_confirm"],
  });
