import { useMemo } from "react";
import { useSelector } from "react-redux";
import { processData, processProductData } from "../pages/Home/home.data";

export const useProductList = () => {
  const user_wallet = useSelector(state => state.account.get_wallets.data);

  const card_info = useMemo(() => user_wallet.map(processData), [user_wallet]);
  const product_list = useMemo(
    () => [
      { wallet_type: "Canary Fund", balance: 0 },
      { wallet_type: "Money Market Fund", balance: 0 },
      { wallet_type: "Income Fund", balance: 0 },
      { wallet_type: "Timed Deposit", balance: 0 },
      card_info[1]?.wallets[0],
      card_info[2]?.wallets[0],
      { wallet_type: "Emergency Funds", balance: 0 },
    ],
    [card_info]
  );

  const products = useMemo(() => product_list.map(processProductData), [product_list]); // this should come from the backend later

  return { products };
};
