import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { HeadlessTab } from "../../../components/DetailsComponent/DetailsTab";
import HouseMoniHeader from "../../../components/HouseMoni/HouseMoniHeader";
import { DetailRow, SubDetailView } from "../../../components/HouseMoni/HouseMoniSubComps";
import { HouseMoniGroupRow } from "../../../components/HouseMoni/JoinGroupComp";
import { Badge } from "../../../components/Widget/Badge/Badge";
import { getAction } from "../../../store/actions/creators/request.action";
import { GET_HPA_GROUP_ID } from "../../../store/actions/action_types/action.types";
import { hpaGroupURL } from "../../../utils/endpoints";
import Loader from "../../../components/Widget/Loader/Loader";
import Hacdra from "./Hacdra";
import HacdraTransaction from "./HacdraTransaction";
import { useLocation } from "react-router-dom";
import { currencyFormatter } from "../../../utils/helper";

const HouseMoniGroupDetails = ({ token, ...props }) => {
  const { state } = useLocation();
  const status = props.hpa_group_details?.certification_status.replace(/_/g, " ").toLowerCase();
  const [type, setType] = useState("HACDRA");

  useEffect(() => {
    /** call API to get group details */
    const URL = `${hpaGroupURL}/${state.id}`;
    props.getAction(GET_HPA_GROUP_ID, URL, token);
  }, []);

  const tabs = { HACDRA: <Hacdra {...props.hpa_group_details} />, "HACDRA Transactions": <HacdraTransaction /> };
  const group = {
    "Monthly Payment Amount": currencyFormatter(props.hpa_group_details?.monthly_contribution),
    "Monthly Repayment Amount": currencyFormatter(props.hpa_group_details?.monthly_repayment),
    "Certification Status": <Badge content={status} className="text-brand01 text-[16px] font-normal bg-brand02" />,
    "Created On": "Mon Sep 18 2023",
  };

  const renderItem = ({ content, data, index }) => <DetailRow content={content} data={data} index={index} />;

  return (
    <div className="flex w-full py-5 px-[52px] flex-col items-start gap-[30px]">
      <HouseMoniHeader title="Group Details" />
      {props.hpa_group_details ? (
        <div className="flex flex-col items-center gap-10 self-stretch">
          <HouseMoniGroupRow group_name={props.hpa_group_details?.group_name} />
          <SubDetailView renderItem={renderItem} data={group} />
          <HeadlessTab tabs={tabs} handlePress={index => setType(Object.keys(tabs)[index])} className="w-[546px] h-[40px] flex justify-between items-center" tabStyle="flex-1 h-full font-medium" />
          <div className="flex flex-col self-stretch">{tabs[type]}</div>
        </div>
      ) : (
        <div className="h-[50dvh] flex self-stretch justify-center items-center">
          <Loader loading={props.group_details_load} color="#9A2333" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  group_details_load: state.hpa.hpa_group_details.loading,
  hpa_group_details: state.hpa.hpa_group_details.data,
  housemoni_subs: state.hpa.get_hpa_sub.hpa_data,
});

export default connect(mapStateToProps, { getAction })(HouseMoniGroupDetails);
