import React, { useEffect, useState } from "react";
import Loader from "../../components/Widget/Loader/Loader";
import { connect } from "react-redux";
import { GET_INVEST_STATEMENT } from "../../store/actions/action_types/action.types";
import { statementHistoryUrl } from "../../utils/endpoints";
import { getAction } from "../../store/actions/creators/request.action";
import { statementProcessData, txnProcessData } from "../Transactions/trans.data";
import PrintModal from "../../components/Widget/Modal/PrintModal";
import { showModal } from "../../store/actions/creators/ui.action";
import AppModal from "../../components/Widget/Modal/Modal";
import { OptionButton } from "../../components/Widget/Button/OptionButton";
import { getMinMaxDate, transformDataToGroupForm } from "../../utils/helper";
import { Calendar2, ExportSquare } from "iconsax-react";
import TransactionView, { TransactionRow } from "../../components/Transaction/TransactionRow";
import { useGetInvestmentStatementQuery } from "../../services/products/invest.services";

const TransactionHistory = ({ currency, token, details, ...props }) => {
  const [maxDate, setMaxDate] = useState(0);
  const [minDate, setMinDate] = useState(0);

  const { data: statement_details = [], isLoading } = useGetInvestmentStatementQuery({ wallet_id: props.user_wallet[1]?.wallets[0]?.id });

  // useEffect(() => {
  //   const wallet_id = props.user_wallet[0]?.wallets[0]?.id;
  //   const url = `${statementHistoryUrl}/${wallet_id}/NGN/invest`;
  //   props.getAction(GET_INVEST_STATEMENT, url, token);
  // }, []);

  // console.log(props.statement_details);

  const tableInfo = statement_details?.map(statementProcessData(props.paginate_num || 1));

  const rawData = statement_details.map(txnProcessData);

  useEffect(() => {
    if (statement_details) {
      const arr = statement_details.map(item => new Date(item.txn_date));
      arr.length > 0 && setMaxDate(getMinMaxDate(arr).max);
      arr.length > 0 && setMinDate(getMinMaxDate(arr).min);
    }
  }, [statement_details]);

  const cardInfo = {
    "Statement Period": maxDate ? `${maxDate} - ${minDate}` : "",
    "Branch Name": "ilupeju",
    "Phone Number": details?.phone,
    "Referral ID": details?.referral_code,
    "Account Type": "savings",
    Currency: "Naira",
    "Opening Balance": "",
  };

  const tableData = transformDataToGroupForm(rawData);

  return (
    <div className="overflow-auto" style={{ height: "35em" }}>
      <div className="flex gap-2 pb-5">
        <OptionButton name="Export" icon={<ExportSquare size={"18"} variant="Outline" />} onPress={() => props.showModal("OPEN_PRINT_MODAL", true)} />
        <OptionButton name="Date Range" icon={<Calendar2 size={"18"} variant="Outline" />} />
      </div>
      {isLoading ? (
        <div className="mt-20 flex justify-center items-center">
          <Loader loading={isLoading} color="#922333" />
        </div>
      ) : (
        <div className="border-t pt-5 border-gray04">
          {statement_details?.length == 0 ? (
            <div className="flex flex-col gap-2 items-center pt-5">
              <h3 className="text-primary font-medium text-[22px]">No records</h3>
              <p className="text-gray01">There are no data yet.</p>
            </div>
          ) : (
            <div className="overflow-auto h-[700px]">
              {/* <Table tableData={tableInfo} /> */}
              {tableData && (
                <TransactionView {...props}>
                  <>
                    {tableData.map(content => (
                      <div key={content.transactions.id}>
                        <h4 className="text-sm">{content?.date}</h4>
                        {content?.transactions.map(data => (
                          <TransactionRow {...data} />
                        ))}
                      </div>
                    ))}
                  </>
                </TransactionView>
              )}
            </div>
          )}
        </div>
      )}
      <AppModal
        open={props.open || false}
        onClose={() => props.showModal("HIDE_MODAL", false)}
        no_header={true}
        content={<PrintModal cardInfo={cardInfo} tableData={tableInfo} owner={`${details?.first_name} ${details?.last_name}`} onClose={() => props.showModal("HIDE_MODAL", false)} />}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.investment.get_invest_statement.loading,
  statement_details: state.investment.get_invest_statement?.data?.data || [],
  paginate_num: state.ui.add_paginate.paginate_link,
  details: state.user.user_details.data,
  user_wallet: state.account.get_wallets.data,
  open: state.ui.modal.show_modal,
});
export default connect(mapStateToProps, { getAction, showModal })(TransactionHistory);
