import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import {
  ERROR_OCCURED,
  POST_ERROR_OCCURED,
} from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  sub_loading: false,
  error: false,
  data: [],
  interest_data: [],
  detail_data: null,
  int_sub_data: [],
  total_bal: 0,
  error_msg: null,
};

const getInvestProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_INVEST_PRODUCT:
      return {
        ...state,
        data: action.payload.data.data,
        loading: false,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const toggleInvestmentSubReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.HIDE_INVESTMENT:
      return {
        ...state,
        loading: true,
      };
    case type.HIDE_INVESTMENT:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
      };
  }
};

const getInvestProductDetail = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_INVEST_PRODUCT_DETAIL:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getInvestmentTenor = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: false,
      };
    case type.GET_TENOR:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case type.GET_SUB_TYPE:
      return {
        ...state,
        loading: false,
        sub_data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addInvestmentSub = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_INVEST_SUB:
      return {
        ...state,
        sub_loading: true,
      };
    case type.ADD_INVEST_SUB:
      return {
        ...state,
        sub_loading: false,
        data: action.payload.data.data,
        success: action.payload.data.response_description,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        sub_loading: false,
        error: true,
        error_msg: action.payload,
      };
    case "CLOSE_MSG_MODAL":
      return {
        ...state,
        sub_loading: false,
        error: false,
        error_msg: null,
      };
    default:
      return state;
  }
};

const getInvestmentSub = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_INVEST_SUB:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data.data,
        total: action.payload.data.data.meta.total,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getInvestmentDetail = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_INVEST_SUB_DETAILS:
      return {
        ...state,
        loading: false,
        detail_data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getInvestHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_INVEST_HISTORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case type.GET_SUB_INTEREST_HISTORY:
      return {
        ...state,
        loading: false,
        int_sub_data: action.payload.data.data,
      };
    case type.GET_INTEREST_DETAIL:
      return {
        ...state,
        loading: false,
        int_detail_data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getInterestHistory = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_INTEREST_HISTORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getInterestHistoryUser = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_INTEREST_HISTORY_USER:
      return {
        ...state,
        loading: false,
        interest_data: action.payload.data.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getInvestmentWallet = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_INVEST_WALLET:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getInvestmentWalletStatement = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: false,
      };
    case type.GET_INVEST_STATEMENT:
      return {
        ...state,
        data: action.payload.data.data,
        loading: false,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const investmentReducer = combineReducers({
  get_invest_product: getInvestProductReducer,
  get_invest_product_detail: getInvestProductDetail,
  get_tenor: getInvestmentTenor,
  add_sub: addInvestmentSub,
  get_sub: getInvestmentSub,
  get_sub_detail: getInvestmentDetail,
  invest_history: getInvestHistoryReducer,
  interest_history: getInterestHistory,
  invest_wallet: getInvestmentWallet,
  user_interests: getInterestHistoryUser,
  // toggle_invest: toggleInvestmentSubReducer,
  get_invest_statement: getInvestmentWalletStatement,
});

export default investmentReducer;
