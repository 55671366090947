import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AuthLayout, { AuthHeader } from "../../../components/Layout/AuthLayout";
import { useForm } from "react-hook-form";
import { PinInput } from "../../../components/Widget/Form/FormComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { AppButton } from "../../../components/Widget/Button/AppButton";
import { editAction } from "../../../store/actions/creators/request.action";
import { SET_PIN } from "../../../store/actions/action_types/action.types";
import { setPinURL } from "../../../utils/endpoints";
import { showMessage, showModal, clearState } from "../../../store/actions/creators/ui.action";
import AppModal from "../../../components/Widget/Modal/Modal";

const Message = ({ navigate, showModal, ...props }) => {
  return (
    <div className="p-5 flex flex-col">
      <span className="mb-2 text-red-800 text-lg">Congrats</span>
      <span className="text-sm text-gray-600">
        Pin has been set successfully
        <span className="text-lg">👍🏾</span>
      </span>
      <div className="my-3 border-t" />
      <AppButton
        name="Continue"
        className="font-semibold rounded-md outline-none"
        pad=".7em"
        onPress={() => {
          navigate(`/login`);
          showModal("HIDE_MODAL", false);
          props.clearState();
        }}
      />
    </div>
  );
};

const PinSetup = ({ loading, token, ...props }) => {
  const [isvalid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSubmit, formState, control, register, getValues } = useForm({
    mode: "onChange",
  });

  const onSubmit = async data => {
    data.session_token = location.state.session_token || token;
    // alert(JSON.stringify(data));
    // props.editAction(SET_PIN, setPinURL, data);
  };

  useEffect(() => {
    if (props.error_msg != null && getValues("pin") != null) {
      props.showMessage("OPEN_MSG_MODAL", props.error_msg);
    }
  }, [props.error_msg]);

  useEffect(() => {
    if (props.message) {
      props.showMessage("OPEN_MSG_MODAL", { message: "Pin setup was successful", msg_type: "success", redirect: () => navigate("/") });
    }
  }, [props.message]);

  // useEffect(() => {
  //   if (props.show_msg == "closing modal") {
  //     history.push("/");
  //   }
  // }, [props.show_msg]);

  const pinCallback = () => {
    alert("good");
  };

  return (
    <>
      <AuthLayout isRegister>
        <div className="py-8 xl:py-10 px-8 xl:px-14 bg-white shadow-[0_15px_30px_0px_rgba(0,0,0,0.05)] w-full flex flex-col justify-center border-[0.5px] border-[#E4E4E7] rounded-lg">
          <AuthHeader name="Create your Personalized login PIN" description="Setup your temporary login pin to use for your account and also for your transactions" />
          <form className="mt-2 md:mt-5" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex w-full flex-col items-start mb-3">
              {/* <small className="mb-1 text-xs">Enter PIN to setup</small> */}
              <PinInput label="Enter PIN" otp_length={6} name="pin" control={control} {...register("pin", { required: true })} />
            </div>
            <footer className="flex mt-3 flex-col">
              <AppButton name="Proceed" className="w-fit xl:w-[138px] font-semibold rounded-md" loading={loading} />
            </footer>
          </form>
        </div>
      </AuthLayout>
      <AppModal open={props.open} onClose={() => props.showModal("HIDE_MODAL", false)} content={<Message navigate={navigate} {...props} />} />
    </>
  );
};

const mapStateToProps = state => ({
  loading: state.register.set_pin.loading,
  token: state.auth.login.token,
  error_msg: state.register.set_pin.error_msg,
  message: state.register.set_pin.message,
  user_info: state.auth.login.data,
  show_msg: state.ui.open_msg.message,
  open: state.ui.modal.show,
});

export default connect(mapStateToProps, { editAction, showMessage, showModal, clearState })(PinSetup);
