import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { AppButton } from "../../../components/Widget/Button/AppButton";
import { SelectField, TextField } from "../../../components/Widget/Form/FormComponent";
import { GET_HOME_COST_LIMIT, MONTHLY_INCOME } from "../../../store/actions/action_types/action.types";
import { postAction } from "../../../store/actions/creators/request.action";
import { hpaMonthlyIncome, hpaURL } from "../../../utils/endpoints";
import { currencyFormat, currencyFormatter, handleFrequency } from "../../../utils/helper";
import { showNextForm } from "../../../store/actions/creators/ui.action";
import HouseMoniHeader from "../../../components/HouseMoni/HouseMoniHeader";
import { HouseMoniCustomLabel, IncomeCaption, Validator } from "../../../components/HouseMoni/HouseMoni";
import AppModal from "../../../components/Widget/Modal/Modal";
import { InfoCircle } from "iconsax-react";
import { Badge } from "../../../components/Widget/Badge/Badge";
import { setData } from "../../../store/actions/creators/ui.action";
import clsx from "clsx";
import { FlatList } from "../../../components/Widget/List/List";

export const HomeCostRow = ({ home_cost_limit, repayment_amount, index, onPress }) => (
  <div className="flex rounded-[12px] border p-[17px_20px] gap-2.5 cursor-pointer self-stretch" onClick={onPress}>
    <div className="flex w-full justify-between p-[10px_0]">
      <div className="flex gap-2.5 flex-col items-start">
        <span className="text-[15px] leading-[20.16px] text-[#8C8C8C]">Home Cost Limit</span>
        <span className="font-medium leading-[20.16px] text-primary">{currencyFormatter(home_cost_limit)}</span>
        <Badge content={index == 0 ? "No Approval Required" : "Approval Required"} className={clsx(index != 0 && "bg-yellow01 text-yellow02")} />
      </div>
      <div className="flex gap-2.5 flex-col items-end">
        <span className="text-[15px] leading-[20.16px] text-[#8C8C8C]">Monthly Repayment</span>
        <span className="font-medium leading-[20.16px] text-primary">{currencyFormatter(repayment_amount)}</span>
      </div>
    </div>
  </div>
);

export const HomeCostModal = ({ setOpen }) => {
  const home_costs = useSelector(state => state.hpa.cost_limit.data);
  const dispatch = useDispatch();

  const handleSelect = data => {
    dispatch(setData(data));
    setOpen(false);
    // alert(JSON.stringify(data));
  };

  const renderItem = ({ item, index }) => <HomeCostRow {...item} index={index} onPress={() => handleSelect(item)} />;

  return (
    <div className="my-[30px] mb-[50px] flex flex-col justify-start px-12 gap-[20px]">
      <div className="flex gap-2.5 text-gray01 items-center">
        <InfoCircle size={24} variant="Bold" className="text-gray01" />
        <span>Reviews and approvals will be communicated within 45 days.</span>
      </div>
      <FlatList data={home_costs} renderItem={renderItem} className="gap-[20px]" />
    </div>
  );
};

const HouseMoniFormOne = ({ token, ...props }) => {
  const { handleSubmit, formState, watch, register, control, getValues, setValue, setError } = useForm({
    mode: "onChange",
    defaultValues: {
      payment_frequency: "monthly",
      monthly_income: props.next_form_data.monthly_income,
    },
  });
  const [frequencies, setFrequencies] = useState("");
  const [contribution_text, setContributionText] = useState("");
  const balance = props.home_cost?.expected_min_home_cost_limit;
  const PEP = props.code_data?.pre_eligibility_period_months;
  const watch_monthly_contr = watch("monthly_contribution");
  const [open, setOpen] = useState(false);

  const expected_contr = props.code_data?.pep_minimum_monthly_contribution;

  /** watch for monthly contribution to compare with pep_minimum_monthly_contribution */
  useEffect(() => {
    if (watch_monthly_contr && watch_monthly_contr < expected_contr) {
      setContributionText(`The required minimum monthly contribution is NGN: ${expected_contr}`);
    } else {
      setContributionText("");
    }
  }, [watch_monthly_contr]);

  /** watch for when home cost limit is selected */
  useEffect(() => {
    if (props.selected_home_cost) {
      setValue("home_cost_limit", props.selected_home_cost?.home_cost_limit);
    }
  }, [props.selected_home_cost]);

  const handleValidateCostLimit = () => {
    const monthly_contribution = getValues("monthly_contribution");
    const hcl_data = { monthly_contribution, product_code: props.product_code };

    setFrequencies(monthly_contribution);
    props.postAction(GET_HOME_COST_LIMIT, `${hpaURL}/multiple`, hcl_data, token); // call API to get home cost
  };

  const onSubmit = data => {
    data.payment_frequency = handleFrequency(data.payment_frequency) || data.payment_frequency;

    props.showNextForm(true, { ...data, step: "2" });
  };

  /** when a user clicks on the validate income link, call the api to validate the income entered and display the message */
  const handleValidate = () => {
    const data = {
      monthly_income: getValues("monthly_income"),
      product_code: props.product_code,
    };
    if (getValues("monthly_income") != "") {
      props.postAction(MONTHLY_INCOME, hpaMonthlyIncome, data, token);
      return;
    }
    setError("monthly_income", { type: "manual" });
  };

  const openHomeCostModal = () => {
    if (props.home_cost) setOpen(true);
  };

  return (
    <>
      <div className="flex w-full py-5 px-[52px] flex-col items-start gap-[35px]">
        <HouseMoniHeader ignore_navigation />
        <form className="flex flex-col items-end gap-10 w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start gap-10 self-stretch">
            <TextField
              label="Monthly Income"
              type="currency"
              control={control}
              placeholder="₦0.00"
              rightProps={<Validator onPress={handleValidate} />}
              caption={<IncomeCaption message={props.income?.message} above_minimum={props.income?.is_above_minimum} />}
              error={formState.errors.monthly_income}
              {...register("monthly_income", { required: true })}
            />
            <TextField
              label={<HouseMoniCustomLabel balance={props.code_data?.pep_minimum_monthly_contribution} loading={props.income_loading} title={"Monthly Contribution"} sub_title="minimum" />}
              type="currency"
              control={control}
              placeholder="₦0.00"
              disabled={!props.income}
              rightProps={props.income && <Validator onPress={handleValidateCostLimit} />}
              caption={contribution_text && <IncomeCaption message={contribution_text} above_minimum={contribution_text == ""} />}
              error={formState.errors.monthly_contribution}
              {...register("monthly_contribution", { required: true })}
            />
            {/* <SelectField
            label="Monthly Contribution"
            item={props.monthly_contributions}
            selected_item="amount"
            defaultValue="Select your contribution"
            type="currency"
            disabled={!props.income?.message}
            control={control}
            error={formState.errors.investment_tenor}
            {...register("monthly_contribution", { required: false })}
          /> */}
            <SelectField
              label={<HouseMoniCustomLabel no_balance loading={props.load_home_cost} />}
              defaultValue={props.selected_home_cost ? currencyFormatter(props.selected_home_cost?.home_cost_limit) : "Select Home Cost Limit"}
              disabled={true}
              control={control}
              onPress={openHomeCostModal}
              className={clsx("cursor-pointer", props.selected_home_cost && "text-primary", props.home_cost && "!bg-white")}
              error={formState.errors.home_cost_limit}
              {...register("home_cost_limit", { required: false })}
            />
            {/* <TextField
            label={<HouseMoniCustomLabel balance={balance || 0} loading={props.load_home_cost} />}
            type="currency"
            disabled
            control={control}
            name="home_cost_limit"
            value={props.home_cost.home_cost_limit}
            placeholder="₦0.00"
            caption={props.home_cost?.message && <HomeCostCaption {...props} />}
            error={formState.errors.home_cost_limit}
            {...register("home_cost_limit", { required: false })}
          /> */}

            <TextField
              label="Pre Eligibility Period (PEP)"
              type="text"
              disabled
              value={`${PEP} Months`}
              name="eligibility"
              placeholder="42 Months"
              error={formState.errors.eligibility}
              {...register("eligibility", { required: false })}
            />
            <SelectField
              label="Preferred Repayment Frequency"
              item={[`₦${currencyFormat(frequencies / 30)} - Daily Post PEP`, `₦${currencyFormat(frequencies / 4)} -Weekly Post PEP`]}
              control={control}
              {...register("payment_frequency", { required: false })}
            />
          </div>
          <div className="flex">
            <AppButton name="Next" className="w-[138px]" disabled={!formState.isDirty || !formState.isValid || !props.selected_home_cost} />
          </div>
        </form>
      </div>
      <AppModal open={open} title={"Select Home cost Limit"} className="2xl:w-[600px] xl:w-[500px] lg:w-[500px]" onClose={() => setOpen(false)} content={<HomeCostModal setOpen={setOpen} />} />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  home_cost: state.hpa.cost_limit.data,
  load_home_cost: state.hpa.cost_limit.loading,
  hpa_resp_error: state.hpa.add_hpa.error_msg,
  hpa_loading: state.hpa.add_hpa.loading,
  income: state.hpa.income.income,
  income_loading: state.hpa.income.loading,
  monthly_contributions: state.hpa.monthly_contrb.contributions,
  product_code: state.ui.product_code.product_code_data?.product_code,
  code_data: state.ui.product_code.product_code_data,
  selected_home_cost: state.ui.set_data.selected_data,
  next_form_data: state.ui.show_next_form.form_data,
});
export default connect(mapStateToProps, { postAction, showNextForm })(HouseMoniFormOne);
