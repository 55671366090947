import styled from "styled-components";

export const OpButton = styled.button`
  background-color: ${props => (props.isFilter ? "#f2f2f2" : "#fff")};
  color: ${props => props.color || "#992333"};
  padding: 0.3em 0.6em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${props => (props.isFilter ? null : "600")};
  border-radius: 5px;
  min-width: ${props => (props.isFilter ? "70px" : null)};
`;
