import { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import HouseMoniHeader from "../../../components/HouseMoni/HouseMoniHeader";
import { GET_HPA_MEMBERS } from "../../../store/actions/action_types/action.types";
import { hpaGroupMemberURL } from "../../../utils/endpoints";
import { headerResolver } from "../../../utils/helper";
import { getAction } from "../../../store/actions/creators/request.action";
import { DetailRow, SubDetailView } from "../../../components/HouseMoni/HouseMoniSubComps";
import { Badge } from "../../../components/Widget/Badge/Badge";
import { HeadlessTabTwo } from "../../../components/DetailsComponent/DetailsTab";
import { tabs } from "./hpa.data";
import { useHeaderResolver } from "../../../hooks/path.hook";

const HouseMoniMemberInfo = ({ token, housemoni_subs, ...props }) => {
  const { pathname } = useLocation();
  const status = props.hpa_member_data?.is_approved;
  const member_id = useHeaderResolver().path;

  useEffect(() => {
    if (housemoni_subs) {
      const group_id = housemoni_subs?.group.id;

      const url = `${hpaGroupMemberURL}/${group_id}/${member_id}`;
      props.getAction(GET_HPA_MEMBERS, url, token);
    }
  }, [housemoni_subs]);

  const renderItem = ({ content, data, index }) => <DetailRow key={index} content={content} data={data} index={index} />;

  const member_data = {
    "Monthly Installment": props.hpa_member_data?.sub.monthly_contribution,
    "Total Monthly Payment": "",
    "Total Amount": "",
    "Last Month Paid": props.hpa_member_data?.sub.last_month_paid,
    "Eligible Amount": props.hpa_member_data?.sub.total_amount_eligible,
    Status: <Badge content={status ? "Verified" : "Pending"} className={`${!status ? "bg-[#FEF7EF] text-[#FF9C33]" : ""}`} />,
    "Member Role": props.hpa_member_data?.member_role?.replace(/_/g, " ").toLowerCase(),
  };

  return (
    <div className="flex w-full py-5 px-[52px] flex-col items-start gap-[20px]">
      <HouseMoniHeader title="Member Information" />

      {!props.hpa_member_load ? (
        <div className="flex flex-col items-start gap-[35px] self-stretch">
          <div className="flex items-center gap-3">
            <img src={props.hpa_member_data?.user_avatar_url} className="w-[45px] h-[45px] object-cover rounded-full" />
            <span className="capitalize text-primary font-medium">{props.hpa_member_data?.full_name}</span>
          </div>
          <SubDetailView renderItem={renderItem} data={member_data} />
          <HeadlessTabTwo tabs={tabs} />
        </div>
      ) : (
        <div>loading...</div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  hpa_member_data: state.hpa.hpa_member_data.data,
  housemoni_subs: state.hpa.get_hpa_sub.hpa_data,
  hpa_member_load: state.hpa.hpa_member_data.loading,
});

export default connect(mapStateToProps, { getAction })(HouseMoniMemberInfo);
