import styled from "styled-components";

export const Fieldset = styled.div`
  input,
  textarea {
    outline: none;
    font-size: 16px;
    background: transparent;
    width: 100%;
    box-sizing: border-box;
  }
  textarea {
    min-height: 8em;
    max-height: 8em;
  }
  .list-btn {
    box-sizing: border-box;
    height: 38px;
    padding-bottom: 0.5em;
  }
  input::placeholder {
    font-size: 14px;
    color: "#D3D8E1";
  }
  #banks {
    background-color: #fff !important;
    option {
      background-color: #fff !important;
      color: #000;
    }
  }
  .select-field {
    border: none !important;
    /* background-color: red; */
  }
  .css-1s2u09g-control {
    border-width: 0;
    outline: none;
    &&::focus {
      background-color: red;
      outline: none;
    }
  }
`;

export const PassType = styled.div`
  position: relative;
  display: flex;
  padding-right: 16px;
  width: 100%;
  input {
    width: 100%;
    border: none;
  }
  .p_visible {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: center;
    cursor: pointer;
    font-size: 16px;
    ${"" /* margin-bottom: 0.5em; */}
  }
`;

export const ToggleContainer = styled.div`
  .toggle-checkbox:checked {
    right: 0;
  }
  .toggle-checkbox:checked + .toggle-label {
    background-color: #992333;
  }
`;

export const Checkboxinput = styled.input`
  #checkbox1:checked {
    border-color: red;
    background-color: red;
  }
`;

export const CheckboxDiv = styled.div`
  /* background-color: #2c3e50; */
  color: #922333;
  font-family: sans-serif;
  /* font-weight: 500; */
  font-size: 14px;
  span.bigcheck-target {
    font-family: FontAwesome; /* use an icon font for the checkbox */
  }
  input[type="checkbox"].bigcheck {
    position: relative;
    left: -999em;
  }
  input[type="checkbox"].bigcheck + span.bigcheck-target:after {
  }
  input[type="checkbox"].bigcheck:checked + span.bigcheck-target:after {
  }
  span.bigcheck {
    display: block;
    padding: 0.5em;
  }
  @media only screen and (max-width: 768px) {
    span.bigcheck {
      display: flex;
      justify-content: flex-start;
      padding: 0.5em 0;
    }
  }
`;
