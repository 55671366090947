import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { AppButton } from "../Widget/Button/AppButton";
import { Menu, Transition } from "@headlessui/react";
import { FilterButton } from "../../pages/Profile/UserLoginHistory";

export const FilterComp = ({ filter_obj, setDateData, FilterComponent }) => {
  return (
    <Menu as="div" className="relative md:inline-block text-left">
      <Menu.Button className="inline-flex justify-center items-center w-full text-gray-700">
        <FilterButton />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute md:origin-top-left origin-top-left md:right-0 right-0 mt-2 md:w-[25rem] w-64 z-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          {FilterHOC({ filter_obj, setDateData })(FilterComponent)}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const FilterHOC =
  ({ setDateData, filter_obj }) =>
  Component => {
    const { control, register, handleSubmit, getValues } = useForm({
      mode: "onChange",
    });

    // console.log(setDateData);
    const onSubmit = data => {
      data.status = getValues("status");
      data = { ...data, ...data.date };
      console.log(data.date);
      setDateData(data.date);

      delete data.date;

      let filter_url = `${filter_obj.filter_url}/?page=${filter_obj.paginate_num || 1}`;

      Object.keys(data).forEach((element, index) => {
        if (data[element] === undefined || data[element] == "") {
          delete data[element];
        }
      });
      let i = 0;

      for (let key in data) {
        filter_url += `${i == 0 ? "&" : ""}${key}=${data[key].toLowerCase()}${i == Object.keys(data).length - 1 ? "" : "&"}`;
        i++;
      }
      console.log(filter_url, data);
      // props.getAction(props.type, filter_url, props.token);
    };

    return (
      <div className="p-5 px-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Component control={control} register={register} />
          <div className="flex justify-end px-5 pt-2">
            <AppButton name="Apply Filter" className="rounded" />
          </div>
        </form>
      </div>
    );
  };

export default FilterHOC;
