import { ArchiveTick, Clock, Coin, EmptyWallet, Graph, Lock, PercentageSquare, StatusUp, Timer1 } from "iconsax-react";
// import Flag_ng from "../../assets/images/flag_ng.svg";
// import Flag_usd from "../../assets/images/flag_usd.svg";

const ngn_img = "/images/flag_ng.svg";
const usd_img = "/images/flag_usd.svg";

// custom card setup ui
const card_data = [
  [
    {
      card_class: "text-[#FFFFFF] bg-brand01",
      dropdown_class: "bg-white text-brand01",
      account_num_name: "Account Number",
      icon: <EmptyWallet size={18} />,
      btn1_class: "2xl:px-[10px]",
      btn_name_two: "Send Money",
      fund_url: "/dashboard/fund-account/bank-transfer",
      withdraw_url: "/withdrawal",
    },
    {
      fund_url: "/dashboard/fund-account/bank-transfer",
      withdraw_url: "/withdrawal",
      wallet_type: "Wallet",
      account_num_name: "Wallet Number",
      card_class: "bg-[#F0F0F0] text-primary",
      btn1_class: "bg-primary border-primary",
      btn2_class: "bg-[#F0F0F0] border-[#F0F0F0] text-primary",
      dropdown_class: "bg-primary text-white",
      icon: <EmptyWallet size={18} />,
    },
  ],
  [
    {
      btn_name_one: "History",
      card_class: "!bg-[#C6DADC] !border-[#C6DADC] !text-primary",
      btn2_class: "!bg-[#F0F0F0] !border-[#F0F0F0] !text-primary",
      dropdown_class: "bg-primary text-white",
      dp_data: [{ name: "NGN", img: ngn_img }],
    },
  ],
  [
    {
      btn_name_one: "History",
      card_class: "bg-brand02 text-brand01",
      btn1_class: "bg-brand01 text-white",
      dropdown_class: "bg-brand01 text-white",
    },
    { card_class: "bg-primary border-primary text-white", btn2_class: "!bg-[#F0F0F0] !border-[#F0F0F0] !text-primary", dropdown_class: "bg-white text-primary" },
  ],

  [
    {
      btn_name_one: "View Plan",
      btn_name_two: "History",
      icon: <ArchiveTick size={18} />,
      icon2: <Clock size={18} />,
      card_class: "bg-[#F6EFE3] text-brand01",
      btn1_class: "bg-brand01 text-white",
      hide_account_num_name: true,
    },
  ],
];

// function to determine dropdown data
const dpDataOptions = init_index => {
  return init_index == 0
    ? [{ name: "NUBAN" }, { name: "WALLET" }]
    : [
        { name: "NGN", img: ngn_img },
        { name: "USD", img: usd_img },
      ];
};

const processWallet = (init_index, init_arr) => (data, index, arr) => {
  const name = init_arr[init_index]?.wallet_type?.replace(/_/g, " ").toLowerCase().split(" ")[0];
  const currency_name = { NGN: "Nigerian Naira", USD: "US Dollar" };
  const idx = arr.length < card_data[init_index].length ? index + 1 : index;
  let dp_data = dpDataOptions(init_index);

  // check if user has nuban account to give an option to select nuban else just give WALLET as only option
  if (init_index === 0 && arr.length === 1) {
    dp_data = [{ name: "WALLET" }];
  }

  return {
    wallet_type: name == "hpa" ? "Housing Fund" : name,
    balance: data.available_balance,
    base_currency: data.base_currency,
    ledger_balance: data.ledger_balance,
    withdraw_to: data.withdrawable_to,
    currency: data.currency,
    currency_name: currency_name[data.base_currency],
    name: data.hpa_config?.name,
    wallet_number: data.wallet_ref || data.nuban_account,
    dp_data,
    ...card_data[init_index][idx], // merge custom urls to the data from backend
  };
};

export const processData = (data, index, arr) => ({
  wallets: data.wallets?.map(processWallet(index, arr)),
});

export const processChartData = (data, index) => ({
  Amount: parseInt(data.Amount),
  Sales: parseInt(data.Sales),
  "Order Date": data["Order Date"],
  title: data.title,
  color: data.color,
});

export const processRecentActivities = {
  "Recent Transactions": "12-20-2020",
  "Last Login": "29-10-2000",
  "Activity Name": "Funded Investment Wallet",
};
const extra_product_data = [
  { icon: <Graph color="#1F2734" size={20} />, color: "#F0F0F0", url: "/products/assets/canary-fund/subscription" },
  { icon: <Coin color="#FF9C33" size={20} />, color: "#FEF7EF", url: "/products/assets/money-market-fund/subscription" },
  { icon: <PercentageSquare color="#9A2333" size={20} />, color: "#FFF4F6", url: "/products/assets/income-fund/subscription" },
  { icon: <EmptyWallet color="#1F2734" size={20} />, color: "#F0F0F0", url: "/products/timed-deposit" },
  {
    icon: <Lock color="#9A2333" size={20} />,
    color: "#FFF4F6",
    //  url: "/products/savings"
  },
  {
    icon: <StatusUp color="#FF9C33" size={20} />,
    color: "#FEF7EF",
    // url: "/products/invest"
  },
  { icon: <Timer1 color="#1F2734" size={20} />, color: "#F0F0F0", url: "" },
];

export const processProductData = (data, index) => ({
  name: data?.wallet_type?.replace("wallet", ""),
  balance: data?.balance,
  base_currency: data?.base_currency,
  ledger_balance: data?.ledger_balance,
  ...extra_product_data[index],
});
