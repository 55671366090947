import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Wrapper from "../../components/FundWithdraw/Wrapper";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { FlatList } from "../../components/Widget/List/List";
import { TextField } from "../../components/Widget/Form/FormComponent";
import { process_virtual_account } from "./fund.data";
import { CopyView } from "../../components/Widget/Copy/Copy";
import { useNavigate } from "react-router-dom";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_NUBAN_ACCOUNT } from "../../store/actions/action_types/action.types";
import { nubanAccountURL } from "../../utils/endpoints";

const BankTransferInfo = props => (
  <div className="flex flex-col gap-8 items-start self-stretch">
    {Object.keys(props)?.map((content, index) => (
      <div key={index + 1} className="flex flex-col items-start self-stretch">
        <TextField label={content} type="text" value={props[content]} disabled className="text-[#1F2734] font-medium" rightProps={content == "Account Number" && <CopyView text={props[content]} />} />
      </div>
    ))}
  </div>
);

const BankTransfer = ({ token, loading, ...props }) => {
  const navigate = useNavigate();
  const wallet_data = props.user_wallet[0]?.wallets[0];
  const account_name = `${props.user_info?.first_name} ${props.user_info?.last_name}`.toUpperCase();

  return (
    <Wrapper name="" desc="Make a transfer to the account number below and your GDL NUBAN account will be funded instantly.">
      <div className="flex flex-col gap-10 self-stretch items-end">
        <div className="flex flex-col items-start self-stretch gap-10">
          {/* <FlatList data={nuban_account} renderItem={renderItem} /> */}
          <form className="flex flex-col items-start self-stretch gap-10">
            <TextField label="Bank Name" type="text" value="GDL Finance" disabled className="text-[#1F2734] font-medium !bg-white" />
            <TextField
              label="Account Number"
              type="text"
              value={wallet_data?.nuban_account}
              disabled
              className="text-[#1F2734] font-medium !bg-white"
              rightProps={wallet_data?.nuban_account && <CopyView text={wallet_data?.nuban_account} />}
            />
            <TextField label="Account Name" type="text" value={account_name} disabled className="text-[#1F2734] font-medium !bg-white" />
          </form>
        </div>
        <div className="flex gap-5">
          <AppButton name="Share" variant="outline" className="w-[138px]" />
          <AppButton name="Go to Home" className="w-[138px]" onPress={() => navigate(-1)} />
        </div>
      </div>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  acct_details: state.account.virtual_acct.data,
  nuban_details: state.account.nuban_acct.data,
  nuban_loading: state.account.nuban_acct.loading,
});

export default connect(mapStateToProps, { getAction })(BankTransfer);
