import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { statementProcessData } from "./trans.data";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_USER_TRANS } from "../../store/actions/action_types/action.types";
import { userTransactionURL } from "../../utils/endpoints";
import { useForm } from "react-hook-form";
import { FilterComp } from "../../components/HOC/FilterHOC";
import { SelectField, DateRanger } from "../../components/Widget/Form/FormComponent";
import { setPagination, showModal } from "../../store/actions/creators/ui.action";
import PrintModal from "../../components/Widget/Modal/PrintModal";
import AppModal from "../../components/Widget/Modal/Modal";
import { getMinMaxDate } from "../../utils/helper";
import TransactionView from "../../components/Transaction/TransactionRow";
import { SearchNormal1 } from "iconsax-react";

const FilterComponent = ({ control, register }) => (
  <>
    <DateRanger control={control} {...register("date")} />
    <div className="py-3 pt-5">
      <hr className="border-gray-100" />
    </div>
    <div className="px-5">
      <SelectField item={["Completed", "Abandoned", "Failed", "Pending", "Voiding"]} control={control} label="Status" {...register("transaction_status")} />
    </div>
    <div className="py-3 pt-0">
      <hr className="border-gray-100" />
    </div>
    <div className="px-5">
      <SelectField item={["Debit", "Credit"]} control={control} label="Transaction Type" {...register("transaction_type")} />
    </div>
    <div className="py-3 pt-0">
      <hr className="border-gray-100" />
    </div>
  </>
);

const Transaction = ({ token, loading, details, user_wallet, user_info, ...props }) => {
  const { watch } = useForm({ mode: "onChange" });
  const [dateData, setDateData] = useState({
    startDate: new Date("2021-08-20").toJSON().split("T")[0],
    endDate: new Date(Date.now()).toJSON().split("T")[0],
  });

  const account_number = user_wallet[0]?.wallets[0]?.nuban_account;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => alert(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  /** method to call transaction API by date */
  const callAction = useCallback(() => {
    const end = new Date(Date.now()).toJSON().split("T")[0];
    const start = new Date("2021-08-20").toJSON().split("T")[0];

    const url = `${userTransactionURL}/${user_info?.id}/${account_number}?page=${props.paginate_num || 1}&per_page=6${`&startDate=${start}&endDate=${end}`}`;
    props.getAction(GET_USER_TRANS, url, token);
  }, [user_info, account_number, props.getAction]);

  useEffect(() => {
    if (account_number) {
      callAction();
    }
  }, [props.paginate_num, account_number, callAction]);

  const statementTableInfo = props.user_transactions?.map(statementProcessData(props.paginate_num || 1));

  const filter_obj = {
    filter_url: `${userTransactionURL}/${user_info?.id}/${account_number}`,
    getAction: props.getAction,
    token,
    type: GET_USER_TRANS,
    data: props.user_transactions,
    paginate_num: props.paginate_num,
  };

  const cardInfo = {
    "Statement Period": dateData.startDate && dateData.endDate ? `${dateData.startDate} - ${dateData.endDate}` : "",
    "Branch Name": "ilupeju",
    "Phone Number": details?.phone,
    "Referral ID": details?.referral_code,
    "Account Type": "savings",
    Currency: "Naira",
  };

  return (
    <div className="h-full px-8 py-5 mx-auto overflow-auto bg-white max-h-fit">
      <div className="flex items-center justify-between w-full pt-2 options gap-x-3">
        <h2 className="flex-1 font-medium text-primary">Transactions</h2>
        <div className="flex justify-end flex-1">
          <div className="flex gap-8">
            <div className="sm:flex items-center border border-[#E4E4E7] px-5 py-2 rounded-lg gap-x-2 w-[296px] hidden">
              <SearchNormal1 size="14" color="#666666" variant="Outline" />
              <input type="text" placeholder="Search Transactions" className="" />
            </div>
            <FilterComp filter_obj={filter_obj} setDateData={setDateData} FilterComponent={FilterComponent} />
          </div>
        </div>
      </div>

      <div className="mt-6">
        <>
          <TransactionView showReceipt={true} transaction={props.user_transactions} {...props} />
        </>
      </div>

      <AppModal
        open={props.open || false}
        onClose={() => props.showModal("HIDE_MODAL", false)}
        no_header={true}
        content={<PrintModal cardInfo={cardInfo} tableData={statementTableInfo} owner={`${details?.first_name} ${details?.last_name}`} onClose={() => props.showModal("HIDE_MODAL", false)} />}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  user_info: state.auth.login?.data,
  user_wallet: state.account.get_wallets?.data,
  loading: state.transaction.user_trans.loading,
  user_transactions: state.transaction.user_trans?.data,
  total: state.transaction.user_trans.total,
  error_msg: state.transaction.user_trans.error_msg,
  paginate_num: state.ui.add_paginate.paginate_link,
  total_bal: state.account.get_wallets.total_bal,
  details: state.user.user_details.data,
  open: state.ui.modal?.show || false,
  open_sub: state.ui.modal.show_sub_modal,
});

export default connect(mapStateToProps, {
  getAction,
  showModal,
  setPagination,
})(Transaction);
