import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";

const initialState = {
  loading: false,
  loading_trxn: false,
  error: false,
  error_msg: null,
  data: [],
  savings_data: null,
  total_bal: 0,
  success: null,
  savings_trnx: null,
};

const getSavingProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SAVING_PRODUCT:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const toggleSavingSubReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.HIDE_SAVING:
      return {
        ...state,
        loading: true,
      };
    case type.HIDE_SAVING:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
      };
  }
};

const getSavingProductDetail = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SAVING_PRODUCT_DETAIL:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addSavingSub = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_SAVING_SUB:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_SAVING_SUB:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.response_description,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    case "CLOSE_MSG_MODAL":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "HIDE_MODAL":
      return {
        success: null,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        success: null,
      };
    default:
      return state;
  }
};

const topupSavingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.TOP_UP_SAVING:
      return {
        ...state,
        loading: true,
      };
    case type.TOP_UP_SAVING:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        success: action.payload.data.response_description,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    case "CLOSE_MSG_MODAL":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "HIDE_MODAL":
      return {
        success: null,
        error: false,
      };
    default:
      return state;
  }
};

const getSavingSub = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SAVING_SUB:
      return {
        ...state,
        loading: false,
        savings_data: action.payload.data.data.data,
        total: action.payload.data.data.meta.total,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSavingDetail = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SAVING_SUB_DETAILS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSavingHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SAVING_HISTORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getInterestHistory = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SAVE_INTEREST_HISTORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data.data,
        total: action.payload.data.data.meta.total,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSavingWallet = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SAVING_WALLET:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getSavingWalletTransactions = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING:
      return {
        ...state,
        loading_trxn: true,
      };
    case type.GET_SAVING_STATEMENT:
      return {
        ...state,
        loading_trxn: false,
        savings_trnx: action.payload.data.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading_trxn: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const savingsReducer = combineReducers({
  get_save_product: getSavingProductReducer,
  get_save_product_detail: getSavingProductDetail,
  add_sub: addSavingSub,
  get_sub: getSavingSub,
  get_sub_detail: getSavingDetail,
  save_history: getSavingHistoryReducer,
  interest_history: getInterestHistory,
  save_wallet: getSavingWallet,
  topup: topupSavingsReducer,
  get_savings_trnx: getSavingWalletTransactions,
});

export default savingsReducer;
