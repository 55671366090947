import { Check, InfoCircle, Scan, TickCircle } from "iconsax-react";
import { currencyFormatter } from "../../utils/helper";
import Loader from "../Widget/Loader/Loader";
import HouseMoniHeader from "./HouseMoniHeader";
import { HouseMoniGroupRow } from "./JoinGroupComp";
import clsx from "clsx";
import { Tooltip } from "antd";

export const Validator = ({ onPress }) => (
  <div className="flex items-center gap-[5px] text-brand01 w-[160px] justify-end" onClick={onPress}>
    <Scan variant="Bulk" />
    <span className="text-sm underline underline-offset-2 cursor-pointer">Validate</span>
  </div>
);

export const HouseMoniCustomLabel = ({ balance, loading, title, sub_title, no_balance }) => (
  <div className="flex justify-between items-start self-stretch">
    <div className="flex justify-start items-center gap-x-3">
      <span>{title || "Home Cost Limit"}</span>
      {loading && <Loader loading={loading} color="#922333" />}
    </div>
    {!no_balance && (
      <div className="flex justify-end items-start gap-[5px] flex-1">
        {balance && <span>{currencyFormatter(balance)}</span>}
        <span>{sub_title || "expected limit"}</span>
      </div>
    )}
  </div>
);

export const WhatIsThis = ({ text, description }) => (
  <Tooltip placement="topRight" title={description} trigger="click" overlayClassName="w-fit max-w-[500px]" overlayInnerStyle={{ padding: "8px 20px" }}>
    <span className="text-[#FF9C33] cursor-pointer">{text}</span>
  </Tooltip>
);

export const HomeCostCaption = props => (
  <div className="flex items-start gap-[10px] self-stretch">
    {props.home_cost?.home_cost_limit > props.income?.required_minimum_home_cost_limit ? (
      <>
        <span>
          <TickCircle variant="Broken" size={24} className="text-green01" />
        </span>
        <span className="text-green01 flex gap-x-1">
          You’ve met the minimum expected Home Cost Limit of
          {currencyFormatter(props.home_cost?.expected_min_home_cost_limit)}
        </span>
      </>
    ) : (
      <>
        <span>
          <InfoCircle variant="Bold" className="text-brand01" />
        </span>
        <span className="text-brand01">
          To qualify, consider raising your <strong>Monthly Contribution</strong> to meet the minimum expected Home Cost Limit of ₦10,000,000.00, or you will be required to increase your{" "}
          <strong>Annual Rent Repayment Percentage.</strong>
        </span>
      </>
    )}
  </div>
);

export const IncomeCaption = ({ message, above_minimum }) => (
  <>
    {message && (
      <div className="flex items-center gap-[10px] self-stretch">
        <span>{above_minimum ? <TickCircle variant="Broken" size={24} className="text-[#449E6A]" /> : <InfoCircle variant="Bold" className="text-brand01" />}</span>
        <span className={clsx(above_minimum ? "text-[#449E6A]" : "text-brand01")}>{message}</span>
      </div>
    )}
  </>
);

export const NotStartedHouseMoni = () => (
  <div className="flex w-full py-5 px-[52px] flex-col items-start gap-[35px]">
    <HouseMoniHeader no_back title="HouseMoni Summary" ignore_navigation />

    <div className="flex flex-col items-center gap-[10px] self-stretch">
      <span className="text-primary text-[22px] font-medium">Nothing in summary</span>
      <span className="2xl:w-[430px] text-gray01 text-center">You don’t have any HouseMoni summary yet. Your summary will appear here when you subscribe.</span>
    </div>
  </div>
);

export const SubscriptionInfo = props => (
  <div className="flex flex-col items-start gap-10 self-stretch">
    {props.product_code == "HAA" && (
      <>
        <span>Create or join a group to be part of the HouseMoni Community program.</span>
        {props.group_data != null && (
          <div className="md:w-[430px] flex flex-col items-start gap-y-[10px]">
            <span className="text-gray05 text-[15px]">{props.group_data.group_action == "CREATE" ? "Created" : "Joined"} a Group</span>
            <HouseMoniGroupRow has_change={props.group_data.group_action != "CREATE"} {...props.group_data} handleChange={() => props.showModal("OPEN_MODAL", true)} />
          </div>
        )}
        <div className="flex items-center gap-10 self-stretch text-brand01 underline underline-offset-4 font-medium">
          <span
            className={`${props.group_data?.group_action != "CREATE" ? "cursor-pointer" : "opacity-50 cursor-not-allowed"}`}
            onClick={props.group_data?.group_action != "CREATE" && props.createGroup}
          >
            Create Group
          </span>
          <span onClick={props.group_data?.group_action != "JOIN" && props.joinGroup} className={`${props.group_data?.group_action != "JOIN" ? "cursor-pointer" : "opacity-50 cursor-not-allowed"}`}>
            Join a Group
          </span>
        </div>
      </>
    )}
    <div className="flex flex-col items-start gap-[10px] py-[17px] px-5 rounded-xl border border-gray04 self-stretch">
      <div className="flex flex-col items-start gap-5 self-stretch">
        <div className="flex py-[10px] justify-between items-end self-stretch">
          <div className="flex flex-col items-start gap-[10px] flex-1">
            <span className="text-gray05 capitalize">Monthly Instalment</span>
            <span className="text-primary">{currencyFormatter(props.next_form_data.monthly_contribution ?? 0)}</span>
          </div>
          <div className="flex flex-col items-end gap-[10px] flex-1">
            <span className="text-gray05 capitalize">Monthly Repayment Amount</span>
            <span className="text-primary">{currencyFormatter(props.next_form_data.monthly_repayment ?? 0)}</span>
          </div>
        </div>
        {/* <hr className="w-full" />
        <div className="flex py-[10px] justify-between items-end self-stretch">
          <div className="flex flex-col items-start gap-[10px]">
            <span className="text-gray05 capitalize">Adjusted Home Cost Limit</span>
            <span className="text-primary font-medium">{currencyFormatter(props.adjusted_data?.adjusted_home_cost_limit)}</span>
          </div>
        </div> */}
      </div>
    </div>
  </div>
);
