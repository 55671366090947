import { z } from "zod";

export const txn_pin_schema = z.object({
  question_id: z.number().min(1, { message: "Security Question is required!" }),
  email: z.string().email(),
  answer: z.string().min(1, { message: "answer is required" }),
  password: z.string().min(1, { message: "requires" }),
  new_txn_pin: z.string().min(4).max(4),
  confirm_new_pin: z.string().min(4).max(4),
});

// to check if pin matches with confirm pin
export const refine_txn_pin_schema = txn_pin_schema.refine(data => data.new_txn_pin == data.confirm_new_pin, {
  message: "pin must match",
  path: ["confirm_new_pin"],
});
