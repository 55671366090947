import { currencyFormatter } from "../../utils/helper";
import { AppButton } from "../Widget/Button/AppButton";

export const USDModalComp = ({ rate, ...props }) => (
  <div className="flex flex-col items-end gap-10 self-stretch px-[50px] pt-5 pb-10">
    <div className="flex flex-col items-start gap-5 self-stretch">
      <span className="text-gray01">
        How we buy and sell USD. Note that rates depend on market fluctuations.
      </span>
      <div className="flex py-[17px] px-5 flex-col items-start gap-[10px] self-stretch rounded-xl border border-gray04">
        <div className="flex py-[10px] justify-between items-end self-stretch">
          <div className="flex flex-col items-start flex-1 gap-[10px]">
            <span className="text-[#8C8C8C] capitalize">Buy At</span>
            <span className="text-[#1F2734] font-medium">
              {currencyFormatter(rate.naira_buy_rate)}
            </span>
          </div>
          <div className="flex flex-col items-end justify-start flex-1 gap-[10px]">
            <span className="text-[#8C8C8C] capitalize">Sell At</span>
            <span className="text-[#1F2734] font-medium">
              {currencyFormatter(rate.naira_sell_rate)}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div className="flex justify-end items-center gap-7 self-stretch">
      <AppButton
        name="Cancel"
        className="w-[138px]"
        variant="outline"
        onPress={props.close}
      />
      <AppButton
        name="Okay"
        className="w-[138px]"
        onPress={props.handleNavigate}
      />
    </div>
  </div>
);
