import { combineReducers } from "redux";
import * as type from "../actions/action_types/action.types";
import { ERROR_OCCURED, POST_ERROR_OCCURED } from "../actions/action_types/error.types";
import { data } from "autoprefixer";

const initialState = {
  loading: false,
  sub_loading: false,
  error: false,
  error_msg: null,
  data: null,
  hpa_data: null,
  total_bal: 0,
  income: null,
  contributions: [],
  eligibility: null,
};

const getHpaTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_HPTAB:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HPTAB:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data.sort((a, b) => a.id - b.id),
      };
    default:
      return state;
  }
};

const getHpaCostLimitReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.GET_HOME_COST_LIMIT:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HOME_COST_LIMIT:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case "SET_TAB_INDEX":
      return {
        loading: false,
        data: null,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getPurchaseAcctReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_PURCHASE_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case type.GET_PURCHASE_ACCOUNT:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addHPASubscription = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.SUB_HPA:
      return {
        ...state,
        loading: true,
      };
    case type.SUB_HPA:
      return {
        ...state,
        loading: false,
        success: action.payload.data.success,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        error: false,
        error_msg: null,
        data: null,
        loading: false,
      };
    default:
      return state;
  }
};

const getUserHpaSubs = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_HPA_SUBS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HPA_SUBS:
      return {
        ...state,
        loading: false,
        hpa_data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getInitialConfig = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_HPA_CONFIG:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HPA_CONFIG:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getHpaEligibilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HPA_ELIGIBILITY:
      return {
        ...state,
        loading: false,
        eligibility: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getHpaGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_HPA_GROUP:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HPA_GROUP:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getHpaGroupById = (state = {}, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_HPA_GROUP_ID:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HPA_GROUP_ID:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getHpaMembersData = (state = {}, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_HPA_MEMBERS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HPA_MEMBERS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
        error_msg: null,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getHacdraTransactions = (state = {}, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_HACDRA_TRANS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HACDRA_TRANS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error: true,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const incomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.MONTHLY_INCOME:
      return {
        ...state,
        loading: true,
      };
    case type.MONTHLY_INCOME:
      return {
        ...state,
        loading: false,
        income: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
        income: null,
      };
    case "CLEAR_HOUSEMONI":
      return {
        ...state,
        income: null,
      };
    default:
      return state;
  }
};

const getMonthlyContrbReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_MONTHLY_CONTRB:
      return {
        ...state,
        loading: true,
      };
    case type.GET_MONTHLY_CONTRB:
      return {
        ...state,
        loading: false,
        contributions: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        laoding: false,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const getHpaTransactionHistroyReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOADING + "_" + type.GET_HPA_TRANSACTION_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case type.GET_HPA_TRANSACTION_HISTORY:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        laoding: false,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const adjustmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADJUST_HOME_COST:
      return {
        ...state,
        loading: true,
      };
    case type.ADJUST_HOME_COST:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const addHpaGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SENDING + "_" + type.ADD_HPA_GROUP:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_HPA_GROUP:
      return {
        ...state,
        loading: false,
        data: action.payload.data.data,
      };
    case POST_ERROR_OCCURED:
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    default:
      return state;
  }
};

const hpaReducer = combineReducers({
  get_hpa_tab: getHpaTabReducer,
  cost_limit: getHpaCostLimitReducer,
  purchase_acct: getPurchaseAcctReducer,
  add_hpa: addHPASubscription,
  get_hpa_sub: getUserHpaSubs,
  hpa_config: getInitialConfig,
  hpa_eligibility: getHpaEligibilityReducer,
  hpa_group: getHpaGroupReducer,
  add_hpa_group: addHpaGroupReducer,
  hpa_group_details: getHpaGroupById,
  hpa_member_data: getHpaMembersData,
  hacdra_trans: getHacdraTransactions,
  income: incomeReducer,
  monthly_contrb: getMonthlyContrbReducer,
  adjust: adjustmentReducer,
  hpa_transaction_history: getHpaTransactionHistroyReducer,
});

export default hpaReducer;
