import React, { useState, useCallback } from "react";
import { TransactionDiv, Narration } from "./row.style";
import { currencyFormatter, formatDate, formatTimeToAmPm } from "../../utils/helper";
import Pagination from "../Widget/Pagination/Pagination";
import { ArrowLeft, ArrowRight, Copy, Warning2 } from "iconsax-react";
import AppModal, { HeaderView } from "../Widget/Modal/Modal";
import { AppButton } from "../Widget/Button/AppButton";
import TransactionModal from "../Widget/Modal/TransactionModal";
import Loader from "../Widget/Loader/Loader";
import { SectionList } from "../Widget/List/List";
import { EmptyView } from "../Widget/EmptyView/EmptyView";
import { connect, useSelector } from "react-redux";
import { showModal } from "../../store/actions/creators/ui.action";
import { useNavigate } from "react-router-dom";
import { SkeletonTransaction } from "../Widget/Loader/Skeleton";

const NUMBER_ON_PAGE = 6;

export const RecentTransactionView = ({ user_transactions }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col sm:h-[100%] sm:absolute p-[20px] w-full overflow-y-auto no-scrollbar sm:min-h-[300px]">
      <div className="flex items-start sticky self-stretch justify-between w-full mb-8">
        <span className="font-medium text-gray01 2xl:text-base xl:text-sm">Recent Transaction</span>
        <div className="flex gap-[5px] items-center cursor-pointer" onClick={() => navigate("/transactions")}>
          <span className="text-gray01 2xl:text-xs xl:text-[10px] font-normal underline underline-offset-4">View More</span>
          <ArrowRight size={12} />
        </div>
      </div>
      <TransactionView transaction={user_transactions} />
    </div>
  );
};

export const TransactionRow = ({ ...props }) => {
  const amount = currencyFormatter(props.amount || props.interest);
  const total_amount = currencyFormatter(props?.total_amount || "0");

  return (
    <>
      <div
        onClick={props.onClick}
        className="flex cursor-pointer group hover:bg-gray-100 bg-white hover:scale-[1.02] px-1 hover:rounded-lg justify-between transition ease-in-out border-gray02 border-b items-start py-3 xl:py-4"
      >
        <div className="flex items-start justify-start transition group-hover:translate-x-1 gap-x-2">
          <div className="md:w-auto md:h-auto flex items-start w-[35px] h-[50px] justify-center">
            <div
              className={`rounded-full 2xl:w-11 xl:w-9 2xl:h-11 xl:h-9 ${
                (props.transaction_type || props.trxn_type)?.toUpperCase() == "DEBIT" ? "bg-[#FFF4F6]" : "bg-[#E7FFF1]"
              } flex justify-center items-center`}
            >
              <span className="text-xs 2xl:w-[24px] xl:w-[20px] flex justify-center">
                {props.transaction_type?.toUpperCase() == "DEBIT" ? <ArrowLeft color="#9A2333" variant="Outline" /> : <ArrowRight color="#449E6A" variant="Outline" />}
              </span>
            </div>
          </div>

          <Narration className="flex flex-col pr-4 ">
            <div className="truncate w-52">
              <span className={`text-primary font-medium ${props.showReceipt ? "" : "2xl:text-sm xl:text-xs"}`}>{props.narration ? props.narration : props.recipient_account}</span>
            </div>

            <small className="mt-1 text-xs text-gray-400 time_text">{formatTimeToAmPm(props?.txn_date ? props.txn_date : props?.interest_date)}</small>
          </Narration>
        </div>
        <div className="flex flex-col items-end transition group-hover:-translate-x-1">
          <span className="flex items-center font-medium text-primary 2xl:text-sm xl:text-xs">{amount}</span>
          {props.total_amount && <span className="flex items-center text-sm font-medium text-primary">{total_amount}</span>}
        </div>
      </div>
    </>
  );
};

/** This view is to display transaction or views similar to transaction view */
const TransactionView = ({ showReceipt, transaction, ...props }) => {
  const [currentPage, setCurrentPage] = useState(props.paginate_num || 1);
  const user_wallet = useSelector(state => state.account.get_wallets.data);
  const loading = useSelector(state => state.transaction.user_trans.loading);
  const wallet_loading = useSelector(state => state.account.get_wallets.loading);
  const [transactionDetails, setTransactionDetails] = useState();
  const [showModal, setShowModal] = useState(false);

  const handlePageChange = page => {
    setCurrentPage(page);
    props.setPagination(page);
  };
  const account_number = user_wallet[0]?.wallets[0]?.nuban_account;

  const groupedTransactionData = transaction?.reduce((acc, obj) => {
    /** get date of each transactions  */
    const date = formatDate(obj.txn_date, "DD MMM YYYY");

    // Check if there is an existing group for this date and merge the object to the array group
    acc[date] = [...(acc[date] || []), obj];

    // return the new object
    return acc;
  }, {}); // initialize accumulator to be an empty object

  // Convert the grouped data into an array of objects (if needed)
  const tableInfo =
    groupedTransactionData &&
    Object.keys(groupedTransactionData)?.map(date => ({
      title: date,
      data: groupedTransactionData[date],
    }));

  // function to open transaction details modal
  // const showDetails = items => () => {
  //   // alert(JSON.stringify(items));
  //   props.showModal("HIDE_SUB_MODAL", true);
  // };

  const showDetails = useCallback(
    data => () => {
      setTransactionDetails(data);
      setShowModal(true);
    },
    []
  );

  const renderItem = ({ item }) => <TransactionRow {...item} onClick={showDetails(item)} />;

  const renderHeader = ({ section }) => (
    <div className="text-left">
      <h4 className="2xl:text-sm xl:text-xs">{section.title}</h4>
    </div>
  );

  return (
    <>
      {loading || wallet_loading ? (
        <SkeletonTransaction />
      ) : (
        <TransactionDiv>
          <div className="h-full">
            <div className="flex flex-col gap-8 bg-white">
              <>{transaction?.length == 0 || account_number == undefined ? <EmptyView /> : <SectionList sections={tableInfo} renderItem={renderItem} renderSectionHeader={renderHeader} />}</>
            </div>
            {!loading && props.total > 0 && (
              <div className="flex justify-center mt-7">
                <Pagination totalCount={props.total} pageSize={NUMBER_ON_PAGE} currentPage={currentPage} onPageChange={handlePageChange} />
              </div>
            )}
          </div>
          {showReceipt && (
            <AppModal
              open={showModal}
              title="Transaction"
              className="w-[45%] 2xl:w-[35%]"
              onClose={() => {
                setShowModal(false);
              }}
              content={
                <TransactionModal
                  transactionDetails={transactionDetails}
                  onClose={() => {
                    setShowModal(false);
                  }}
                />
              }
            />
          )}
        </TransactionDiv>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
});

export default connect(mapStateToProps, { showModal })(TransactionView);
