import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { currencyFormatter } from "../../utils/helper";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { connect } from "react-redux";
import { FiXCircle, FiCheckCircle } from "react-icons/fi";

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const PaymentSummary = props => {
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const query = useQuery();
  const processCost = (amount, percent = 1.4) => {
    const new_amount = parseInt(amount);
    let result = (percent / 100) * new_amount;
    return result + new_amount;
  };

  const data = {
    "Amount charged from card": processCost(props.confirm_data?.amount, props.percent),
    "Processing Fee": props.percent ? JSON.stringify(props.percent) + "%" : "1.40%",
  };

  // useEffect(() => {
  //   if (query.get("status") !== null) {
  //     const status = query.get("status");

  //     if (status == "cancelled") {
  //       setMessage("You cancelled your payment process with the details below");
  //     } else {
  //       setMessage("Your payment process with the details below failed");
  //     }
  //   }
  // }, [query]);
  return (
    <div className="bg-gray-100 flex flex-col py-40 items-center" style={{ height: "100vh" }}>
      <div className="bg-gray-300 flex flex-col items-center justify-end h-2/5 rounded-lg w-1/5">
        <div className="-mb-12 z-10">
          {query.get("status") == "cancelled" || query.get("status") == "failed" ? <FiXCircle size={100} color="#922333" className="" /> : <FiCheckCircle size={100} color="green" />}
        </div>
        <div className="py-12 px-5 bg-gray-50">
          <div className="mb-5 flex justify-center">
            <span className="text-center text-sm pt-5 opacity-60">
              {query.get("status") == "cancelled"
                ? "You cancelled your payment process"
                : query.get("status").includes("success")
                ? "Your payment process was carried out successfully"
                : "Your payment process failed"}
            </span>
          </div>

          {/* <div className="flex flex-col items-center">
            <div className="px-4 w-full">
              {Object.keys(data).map(content => (
                <div className="flex justify-between items-center py-3">
                  <span
                    className="text-gray-500 font-medium capitalize"
                    style={{ fontSize: 14, color: "#6c82a3" }}
                  >
                    {content}
                  </span>
                  <div className="flex justify-between items-center">
                    <span
                      className={`font-medium ${
                        content == "Account Name" ? "uppercase" : "capitalize"
                      }`}
                      style={{ fontSize: 14, color: "#922333" }}
                    >
                      {content == "Amount charged from card"
                        ? currencyFormatter(data[content])
                        : data[content]}
                    </span>
                    {content == "Account Number" && (
                      <FiCopy color="#922333" style={{ marginLeft: 5 }} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div> */}

          <div className="mt-5 flex p-3 pb-0 justify-center">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => navigate("/dashboard")}
            >
              Return to Home Page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  confirm_data: state.ui.modal.confirm_data,
});

export default connect(mapStateToProps, {})(PaymentSummary);
