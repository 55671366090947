import { ArrowRight } from "iconsax-react";

export const FundRow = ({ icon, name, fee, url, handlePress }) => (
  <div
    className="flex py-5 justify-between cursor-pointer hover:bg-gray-50 transition ease-in-out items-center self-stretch border-b border-b-[#E4E4E7]"
    onClick={() => handlePress(url, name)}
  >
    <div className="flex items-center gap-3">
      <div className="flex w-[45px] h-[45px] p-[10px] justify-center items-center rounded-full bg-brand02">
        {icon}
      </div>
      <div className="flex flex-col items-start gap-[10px]">
        <span className="text-primary font-medium">{name}</span>
        <div className="flex items-center gap-[5px]">
          <span className="text-gray01 text-[14px] font-normal">Fee:</span>
          <span className="text-primary text-[14px]">{fee}%</span>
        </div>
      </div>
    </div>
    <ArrowRight variant="Outline" size={24} color="#292D32" />
  </div>
);
