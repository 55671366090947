import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showModal, showMessage } from "../../store/actions/creators/ui.action";
import AppModal from "../../components/Widget/Modal/Modal";
import SecurityQuestion from "./Overview/SecurityQuestion";
import TransactionPinModal from "../../components/Profile/TransactionPinModal";
import ChangePasswordModal from "../../components/Profile/ChangePasswordModal";
import { ListCardSkeleton } from "../../components/Widget/Loader/Skeleton";
import { useHasAccount } from "../../hooks/account.hook";
import { securityUserURL } from "../../utils/endpoints";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_USER_SECURITY_QUESTION } from "../../store/actions/action_types/action.types";

export const ListCard = ({ title, desc, actionName, hideBorder, onPress, disabled, loading }) => {
  return (
    <>
      {loading ? (
        <ListCardSkeleton />
      ) : (
        <div className={`flex justify-between py-4 items-center ${hideBorder ? "border-none" : " border-b border-[#E4E4E7]"} ${disabled ? "text-opacity-50" : ""}`}>
          <div className={`flex flex-col gap-y-2`}>
            <h2 className={`text-[#1F2734] font-medium  ${disabled ? "text-opacity-50" : ""}`}>{title}</h2>
            <p className={`text-[#666666] ${disabled ? "text-opacity-50" : ""}`}>{desc}</p>
          </div>
          <span onClick={() => onPress()} className={`underline cursor-pointer text-[#9A2333] ${disabled ? "text-opacity-50 cursor-not-allowed" : ""}`}>
            {actionName}
          </span>
        </div>
      )}
    </>
  );
};

const Security = ({ token, details, details_loading, question_loading, user_info, success_msg, ...props }) => {
  const { has_txn_pin } = useHasAccount();

  useEffect(() => {
    if (props.question_count == 0) {
      const user_sec_url = `${securityUserURL}/${user_info?.id}`;
      props.getAction(GET_USER_SECURITY_QUESTION, user_sec_url, token);
    }
  }, [props.question_count, success_msg]);

  const handleSetTxnPin = () => {
    if (props.question_count > 0) {
      props.showModal("OPEN_MODAL", true);

      return;
    }
    props.showMessage("OPEN_MSG_MODAL", { message: "Please setup your security questions" });
  };

  return (
    <>
      <div className="bg-white h-full flex flex-col gap-2 px-12  py-5">
        <h3 className="text-[#1F2734] pb-5 font-medium">Security</h3>
        <div className="flex flex-col">
          <ListCard
            title={"Security Questions"}
            desc={"Keep your accounts safe from others"}
            disabled={question_loading}
            // loading={question_loading}
            actionName={props.question_count > 0 ? "Edit" : "Set Questions"}
            onPress={() => props.showModal("OPEN_SUB_MODAL", true)}
          />
          <ListCard title={"Password"} disabled={question_loading} desc={"Manage your account password"} actionName={"Change Password"} onPress={() => props.showModal("OPEN_PASSWORD_MODAL", true)} />
          <ListCard
            title={"Transaction PIN"}
            desc={"Secure your transactions"}
            actionName={props.question_count > 0 && has_txn_pin ? "Change PIN" : "Set PIN"}
            hideBorder={true}
            // loading={question_loading}
            disabled={question_loading}
            onPress={handleSetTxnPin}
          />
        </div>
      </div>

      <AppModal
        open={props.open}
        className="w-[50%]"
        title="Transaction PIN"
        onClose={() => {
          props.showModal("HIDE_MODAL", false);
        }}
        content={
          <TransactionPinModal
            onClose={() => {
              props.showModal("HIDE_MODAL", false);
            }}
          />
        }
      />

      <AppModal
        open={props.open_pass}
        className="w-[50%]"
        title="Change Password"
        onClose={() => {
          props.showModal("HIDE_MODAL", false);
        }}
        content={
          <ChangePasswordModal
            onClose={() => {
              props.showModal("HIDE_MODAL", false);
            }}
          />
        }
      />

      <AppModal
        open={props.open_sub}
        className="w-[60%] xl:w-[50%]"
        title="Set Security Questions"
        onClose={() => {
          props.showModal("HIDE_SUB_MODAL", false);
        }}
        content={<SecurityQuestion />}
      />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal.show,
  open_sub: state.ui.modal.show_sub_modal,
  open_pass: state.ui.modal.show_pass,
  token: state.auth.login.token,
  question_count: state.user.get_user_sec_question.question_count,
  question_loading: state.user.get_user_sec_question.loading,
  user_info: state.auth.login.data,
  details: state.user.user_details.data,
  details_loading: state.user.user_details.loading,
  security_question: state.user.security_question.data,
  questions: state.user.get_user_sec_question.user_question,
  success_msg: state.user.set_questions.success,
});

export default connect(mapStateToProps, { showModal, getAction, showMessage })(Security);
