import React, { useState } from "react";
import { HeadlessTab } from "../../components/DetailsComponent/DetailsTab";
import { ProductSubRow } from "../../components/Widget/List/ProductRow";
import SavingsCard from "../../components/Card/SavingsCard";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.action";
import Loader from "../../components/Widget/Loader/Loader";
import InvestmentWrapper from "./InvestmentWrapper";
import { EmptyView2 } from "../../components/Widget/EmptyView/EmptyView";
import { showModal, activateFiltering } from "../../store/actions/creators/ui.action";
import { useGetInvestmentSubByUserIdQuery } from "../../services/products/invest.services";

const Invest = ({ token, ...props }) => {
  const [type, setType] = useState("All Investment");
  const tabs = { "All Investment": "", Running: "running", Released: "ended" };
  const { data: investmentSub = [], isLoading } = useGetInvestmentSubByUserIdQuery({ id: props.user_info.id, page: props.paginate_num, sub_status: tabs[type] });

  return (
    <InvestmentWrapper>
      <div className="flex-1 bg-white rounded-lg px-12 py-3 h-full">
        <div className="2xl:py-3 lg:py-2 flex justify-end 2xl:px-2 lg:px-0 sm:mb-0 mb-3">
          <div className="flex md:w-auto w-full flex-row md:gap-x-5 gap-x-3 items-center md:justify-start justify-between">
            <div className="flex flex-row items-center gap-x-2">
              <HeadlessTab
                tabs={tabs}
                handlePress={index => {
                  setType(Object.keys(tabs)[index]);
                  props.activateFiltering(true);
                }}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center h-full w-full col-span-6 md:col-span-4  items-center">
            <Loader loading={true} color="#922333" />
          </div>
        ) : investmentSub?.length === 0 ? (
          <EmptyView2 hasModal={true} title="Nothing in Investment" />
        ) : (
          <div className="relative h-full overflow-auto max-h-[38em]">
            <ProductSubRow {...props} total={props.invest_sub_total} loading={props.loading_sub}>
              <>
                {investmentSub.map((item, idx) => {
                  return <SavingsCard key={item.id} {...item} {...props} />;
                })}
              </>
            </ProductSubRow>
          </div>
        )}
      </div>
    </InvestmentWrapper>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  loading: state.investment.get_invest_product.loading,
  invest_product: state.investment.get_invest_product.data,
  invest_sub_msg: state.investment.add_sub.success,
  loading_sub: state.investment.get_sub.loading,
  invest_sub: state.investment.get_sub.data || [],
  invest_sub_total: state.investment.get_sub.total,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  invest_wallet: state.investment.invest_wallet.data,
  add_error_msg: state.investment.add_sub.error_msg,
  paginate_num: state.ui.add_paginate?.paginate_link || 1,
  statement_details: state.investment.get_invest_statement?.data?.data,
  filter_state: state.ui.filter_data?.filter_savings,
  is_filter: state.ui.is_filter.isFilter,
});

export default connect(mapStateToProps, { getAction, showModal, activateFiltering })(Invest);
