import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { AppButton } from "../../components/Widget/Button/AppButton";
import { postAction, getAction } from "../../store/actions/creators/request.action";
import { showModal, fadeContent, showMessage } from "../../store/actions/creators/ui.action";
import { DateField, TextField, ToggleField } from "../../components/Widget/Form/FormComponent";
import { ADD_SAVING_SUB } from "../../store/actions/action_types/action.types";
import { savingSubURL } from "../../utils/endpoints";
import { CardSlide, ProductCard } from "../../components/Card/Card";
import { currencyFormatter } from "../../utils/helper";
import Loader, { LoaderII } from "../../components/Widget/Loader/Loader";
import toast from "react-hot-toast";
import { FlatList } from "../../components/Widget/List/List";
import { useAddSavingsSubscriptionMutation, useGetSavingsProductDetailsQuery, useGetSavingsProductQuery, useGetUserSavingsWalletDetailsQuery } from "../../services/products/savings.services";
import { InvestmentProductsErrorContent } from "../Investment/InvestContent";

export const SavingsProducts = props => {
  const { data = [], isLoading, isError } = useGetSavingsProductQuery();

  if (isError) {
    return <InvestmentProductsErrorContent />;
  }

  const handleClick = (id, index) => {
    props.setId(id);
    props.showModal("OPEN_SUB_MODAL", true, props.images[index]);
    props.showModal("HIDE_MODAL", false);
    props.fadeContent("FADE", false);
  };
  const renderItem = ({ item, index }) => <ProductCard {...item} name={item.product_name} image={"/safe-green.svg"} index={index} onPress={handleClick} />;

  return (
    <div className="">
      <div className="pt-8 pb-14 px-12">
        {isLoading ? (
          <LoaderII />
        ) : (
          <>
            <p className="text-left">Select a savings product below to continue</p>
            <div className="flex flex-col gap-y-2 mt-4">
              <FlatList data={data} renderItem={renderItem} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const SavingsTypeCard = ({ type1, saving_details, type2, pt }) => {
  return (
    <div className={`${pt}`}>
      <div className="flex justify-between items-center py-2">
        <div className="flex flex-col items-start">
          <span className="">{type1}</span>
          <span className="font-medium text-left text-primary">{saving_details[type1]}</span>
        </div>
        <div className="flex flex-col items-end">
          <span className="">{type2}</span>
          <span className="font-medium text-primary">{saving_details[type2]}</span>
        </div>
      </div>
    </div>
  );
};

const SavingTypeDetails = ({ id, fade }) => {
  const dispatch = useDispatch();
  const { data = {}, isLoading } = useGetSavingsProductDetailsQuery(id, { skip: !id });

  const saving_details = {
    "Product Name": data.product_name,
    "Product Description": data.product_description,
    "Minimum Amount": currencyFormatter(data.minimum_amount),
    "Maximum Amount": currencyFormatter(data.maximum_amount),
    Recurring: data.recurring ? "recurring" : "not recurring",
    "Lockup Period": `${data.savings_rate?.lockup_period_days} days`,
    // "Maximum Tenure": "270-360days",
  };

  const handleContinue = e => {
    if (!fade) {
      e.preventDefault();
      dispatch(fadeContent("FADE", true));
    }
  };

  return (
    <div className="flex flex-col gap-[40px]">
      <div className="flex flex-1">
        {isLoading ? (
          <div className="h-96 w-full flex justify-center items-center">
            <Loader loading={true} color="#922333" />
          </div>
        ) : (
          <div className="w-full">
            <div className="pt-10 px-12">
              <div className="border border-gray02 px-4 py-3 rounded-lg flex flex-col divide-y divide-gray02">
                <SavingsTypeCard saving_details={saving_details} type1={"Product Name"} type2={"Lockup Period"} />
                <SavingsTypeCard saving_details={saving_details} type1={"Product Description"} type2={"Recurring"} pt={"pt-4"} />
                <SavingsTypeCard saving_details={saving_details} type1={"Minimum Amount"} type2={"Maximum Amount"} pt={"pt-4"} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end items-center gap-5 self-stretch px-12 pb-14">
        <AppButton name="Cancel" className="w-[135px]" variant="outline" onPress={() => dispatch(showModal("HIDE_SUB_MODAL", false))} />
        <AppButton name="Proceed" className="w-[135px]" onPress={handleContinue} />
      </div>
    </div>
  );
};

const SubscribeSavings = props => {
  const dispatch = useDispatch();
  const { data: savingsWalletData = {} } = useGetUserSavingsWalletDetailsQuery({ userId: props.user_info?.id, wallet_ref: props.user_wallet[0]?.wallets[0]?.wallet_ref });
  const [addSavingsSubscription, { isLoading, error, data: responseData, isSuccess }] = useAddSavingsSubscriptionMutation();

  console.log(props.id);

  const { handleSubmit, formState, getValues, register, control, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      savings_product_id: props.id,
      user_kyc_level: props.details?.kyc_level.level_number,
      user_id: props.user_info?.id,
      channel: "WEB",
      auto_save: false,
      user_ip: props.ip_data?.ip || "41.204.236.114",
      transaction_location: JSON.stringify({
        long: props.ip_data?.longitude,
        lat: props.ip_data?.latitude,
      }),
    },
  });
  Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  const watchAll = watch(["savings_purpose", "purpose_description", "maturity_date", "starting_amount", "target_amount"]);

  // const disabledState = watchAll.every(element => element?.length > 0);

  let time = JSON.stringify(new Date(Date.now()));
  time = time.substring(1, time.indexOf("T"));
  time = JSON.stringify(new Date(time).addDays(props.product_details.savings_rate?.lockup_period_days));
  time = time.substring(1, time.indexOf("T"));

  const watchMinAmount = watch(["starting_amount"]);

  useEffect(() => {
    if (error) {
      toast.error(error.data?.message || "");
      // dispatch(showMessage("OPEN_MSG_MODAL", { message: error.data?.message, msg_type: "error" }));
    }
    if (isSuccess) {
      dispatch(showMessage("OPEN_MSG_MODAL", { message: "Your savings was successfully subscribed", msg_type: "success" }));
      dispatch(showModal("HIDE_SUB_MODAL", false));
      dispatch(fadeContent("FADE", false));
      // props.getAction(GET_SAVING_SUB, , token);
    }

    if (isSuccess && responseData) {
      dispatch(showModal("HIDE_SUB_MODAL", false));
      dispatch(fadeContent("FADE", false));
      const amount = responseData.amount;
      if (amount != null || amount !== "") {
        dispatch(
          showModal("OPEN_CONFIRM_MODAL", true, {
            amount,
            request_ref: responseData.payment_reference,
            service_type: "SAVEINVEST",
            user_savings_id: responseData?.user_savings_id,
          })
        );
      }
    }
  }, [error, responseData, isSuccess]);

  // const handleAutoSave = () => {
  //   props.autoSave();
  // };

  const onSubmit = data => {
    if (data.starting_amount < 500) {
      toast.error("Starting Amount must be 2000 and above");
    } else {
      // props.postAction(ADD_SAVING_SUB, savingSubURL, data, props.token);
      addSavingsSubscription(data);
      // props.showModal("HIDE_SUB_MODAL", false);
    }
  };
  return (
    <form className="px-0 w-full flex flex-col justify-end flex-1 pb-14" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-1">
        <div className="bg-white px-8 xl:px-12 py-8 flex flex-col gap-3">
          <CardSlide content={savingsWalletData} imageSrc={"/safe.svg"} name="Savings Balance" showBtn={false} {...props} />
          <div className="flex gap-4 xl:gap-8 justify-between">
            <TextField
              label="Savings Purpose"
              type="text"
              placeholder="Add Savings Purpose"
              mb={"flex-1"}
              error={formState.errors.savings_purpose}
              {...register("savings_purpose", { required: true })}
            />
            <TextField
              label="Purpose Description"
              type="text"
              mb={"flex-1"}
              placeholder="Purpose Description"
              error={formState.errors.purpose_description}
              {...register("purpose_description", { required: true })}
            />
          </div>
          <div className="flex gap-4 xl:gap-8">
            <TextField
              label="Starting Amount"
              type="currency"
              mb={"flex-1"}
              placeholder="Enter your Starting Amount"
              control={control}
              error={formState.errors.starting_amount}
              {...register("starting_amount", { required: true })}
            />
            <TextField
              label="Target Amount"
              type="currency"
              mb={"flex-1"}
              placeholder="Enter Target Amount"
              control={control}
              error={formState.errors.target_amount}
              {...register("target_amount", { required: true })}
            />
          </div>
          <div className="">
            <DateField label="Maturity Date" min={time} mb={"w-[48%]"} control={control} error={formState.errors.maturity_date} {...register("maturity_date", { required: true })} />
            {getValues("starting_amount") && (
              <div className="w-1/2 mt-5">
                <ToggleField label={`Automatically repeat this savings monthly`} control={control} {...register("auto_save", { required: false })} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end px-12">
        <div className="flex flex-row-reverse gap-x-8 items-center">
          <AppButton name="Subscribe" type={"submit"} loading={isLoading} disabled={!formState.isDirty || !formState.isValid} />
          <AppButton name="Cancel" type={"button"} onPress={() => dispatch(showModal("HIDE_SUB_MODAL", false))} variant={"outline"} />
        </div>
      </div>
    </form>
  );
};

const SavingContent = ({ product_id, ...props }) => {
  // useEffect to know when a savings was successful
  useEffect(() => {
    if (props.saving_sub_msg) {
      props.showMessage("OPEN_MSG_MODAL", { message: "Your savings was successfully subscribed", msg_type: "success" });
      // props.getAction(GET_SAVING_SUB, , token);
    }
  }, [props.saving_sub_msg]);

  return <div className="flex flex-col justify-start">{!props.fade ? <SavingTypeDetails id={product_id} fade={props.fade} /> : <SubscribeSavings id={product_id} {...props} />}</div>;
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  fade: state.ui.fader.fade,
  modal_img: state.ui.modal.data,
  product_details: state.saving.get_save_product_detail.data,
  product_details_loading: state.saving.get_save_product_detail.loading,
  user_info: state.auth.login.data,
  details: state.user.user_details.data,
  loading: state.saving.add_sub.loading,
  user_wallet: state.account.get_wallets.data,
  ip_data: state.auth.get_ip.data,
  saving_error: state.saving.add_sub.error_msg,
  saving_sub_msg: state.saving.add_sub.success,
});

export default connect(mapStateToProps, {
  showModal,
  fadeContent,
  postAction,
  showMessage,
  getAction,
})(SavingContent);
