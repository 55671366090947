import { People, SearchNormal } from "iconsax-react";
import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { currencyFormat, currencyFormatter } from "../../utils/helper";
import { AppButton } from "../Widget/Button/AppButton";
import { FlatList } from "../Widget/List/List";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_HPA_GROUP } from "../../store/actions/action_types/action.types";
import { hpaGroupURL } from "../../utils/endpoints";
import { setGroupData, showModal, showMessage } from "../../store/actions/creators/ui.action";
import { GroupSkeleton } from "../Widget/Loader/Skeleton";
import { useForm } from "react-hook-form";

export const HouseMoniGroupRow = ({ group_name, monthly_contribution, has_change, ...props }) => (
  <div className={`flex justify-between items-start self-stretch transition-all ease-in-out ${props.className}`} onClick={props.joinGroup}>
    <div className="flex items-center gap-[12px]">
      <div className="flex w-[44px] h-[44px] p-[10px] justify-center items-center rounded-full bg-brand02">
        <People size={24} className="text-brand01" variant="Bold" />
      </div>
      <div className="flex flex-col items-start gap-[5px]">
        <span className="text-primary font-medium">{group_name}</span>
        {monthly_contribution && (
          <div className="flex items-start gap-[5px] text-[14px]">
            <span className="text-gray01">Monthly Contribution:</span>
            <span className="text-primary">{currencyFormatter(monthly_contribution)}</span>
          </div>
        )}
      </div>
    </div>
    {has_change && <AppButton name="Change" onPress={props.handleChange} className="py-[2px] px-[12px] rounded-[4px] bg-brand02 border-none text-brand01 text-sm" />}
  </div>
);

const JoinGroupContent = ({ token, ...props }) => {
  const { register, watch } = useForm({ mode: "onChange" });
  const full_name = `${props.user_info?.first_name} ${props.user_info?.last_name}`; // get full name of the person joining the group
  const watch_search = watch("search"); // for filtering

  /** method to add a user to a particular group */
  const joinGroup = index => () => {
    const { group_ref, monthly_contribution, monthly_repayment, group_name } = props.groups[index];
    const data = {
      group_ref,
      group_name,
      monthly_contribution: parseFloat(monthly_contribution),
      monthly_repayment: parseFloat(monthly_repayment),
      group_action: "JOIN",
      group_member: [{ user_id: props.user_info?.id, full_name, user_avater_url: props.user_info?.user_avatar_url }],
    };

    /** check if the user's monthly contribution is same as the group's monthly contribution */
    if (monthly_contribution == props.next_form_data?.monthly_contribution) {
      props.setGroupData(data);
    } else {
      props.showMessage("OPEN_MSG_MODAL", `Adjust your monthly contribution to match the group’s monthly contribution of ${currencyFormat(monthly_contribution)}`);
    }

    props.showModal("HIDE_MODAL", false);
  };

  /** function to filter groups */
  const filterGroups = (search, groups) => {
    return !search ? groups : groups.filter(group => group.group_name?.toLowerCase().replace(/\s+/g, "").includes(search?.toLowerCase().replace(/\s+/g, "")));
  };

  useEffect(() => {
    // call API to get all groups
    if (!props.groups || props.groups?.length == 0) props.getAction(GET_HPA_GROUP, hpaGroupURL, token);
  }, [props.groups, token]);

  const renderItem = ({ item, index }) => <HouseMoniGroupRow {...item} key={index} joinGroup={joinGroup(index)} className="py-5 cursor-pointer hover:bg-gray-50" />;

  const filtered_groups = useMemo(() => filterGroups(watch_search, props.groups), [watch_search, props.groups]);

  return (
    <>
      {props.loading ? (
        <GroupSkeleton />
      ) : (
        <div className="md:my-7 md:mx-12 flex flex-col items-start gap-8 self-stretch">
          <form className="flex py-[10px] px-5 items-center gap-[10px] self-stretch rounded-lg border border-gray04">
            <SearchNormal size={18} color="#1F2734" variant="Outline" />
            <input type="text" placeholder="Search Group" className="placeholder:text-[#D3D8E1] placeholder:font-medium flex-1" {...register("search")} />
          </form>
          <FlatList data={filtered_groups} renderItem={renderItem} className="md:h-[500px] overflow-scroll" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  groups: state.hpa.hpa_group.data,
  loading: state.hpa.hpa_group.loading,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  home_cost: state.hpa.cost_limit.data,
  next_form_data: state.ui.show_next_form.form_data,
});

export default connect(mapStateToProps, { getAction, setGroupData, showModal, showMessage })(JoinGroupContent);
