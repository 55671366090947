import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { processInterestData, tabHeader } from "./save.data";
import { CardSlide, TopCardDisplay } from "../../components/Card/Card";
import AppModal from "../../components/Widget/Modal/Modal";
import { showModal, fadeContent, showMessage, activateFiltering, setPagination, setFilterStatus } from "../../store/actions/creators/ui.action";
import { getAction, postAction, editAction } from "../../store/actions/creators/request.action";
import { GET_SAVING_PRODUCT, GET_SAVING_PRODUCT_DETAIL, GET_SAVING_WALLET, GET_USER_DETAILS, GET_IP, GET_SAVING_STATEMENT } from "../../store/actions/action_types/action.types";
import SaveContent, { SavingsProducts } from "./SaveContent";
import { savingsURL, saveWalletURL, usersURL, ipURL, statementHistoryUrl } from "../../utils/endpoints";
import { ipRequest } from "../../api/request.api";
import ConfirmAmount from "../../components/FundWithdraw/ConfirmAmout";
import { saveInterestURL } from "../../utils/endpoints";
import { GET_SAVE_INTEREST_HISTORY } from "../../store/actions/action_types/action.types";
import { DateRanger, SelectField } from "../../components/Widget/Form/FormComponent";
import { HeadlessTab } from "../../components/DetailsComponent/DetailsTab";
import SavingsTransactions from "./SavingsTransactions";
import SavingsHistory from "./SavingsHistory";
import { useGetUserSavingsWalletDetailsQuery } from "../../services/products/savings.services";

export const FilterComponent = ({ control, register }) => (
  <>
    <DateRanger control={control} {...register("date")} />
    <div className="py-3 pt-5">
      <hr className="border-gray-100" />
    </div>
    <div className="px-5">
      <SelectField item={["Running", "Pending", "Ending"]} control={control} label="Status" {...register("status")} />
    </div>
    <div className="py-3 pt-0">
      <hr className="border-gray-100" />
    </div>
  </>
);

const SavingWrapper = ({ token, loading, children, ...props }) => {
  const [id, setId] = useState(null);
  const { data: savingsWalletData = {} } = useGetUserSavingsWalletDetailsQuery({ userId: props.user_info?.id, wallet_ref: props.user_wallet[0]?.wallets[0]?.wallet_ref });

  const tabs = { "All Transactions": <SavingsTransactions />, "Interest History": <SavingsHistory /> };
  const [type, setType] = useState("All Transactions");

  useEffect(() => {
    props.getAction(GET_SAVING_WALLET, `${saveWalletURL}/${props.user_info?.id}/${props.user_wallet[0]?.wallets[0]?.wallet_ref}`, token);
  }, []);

  useEffect(() => {
    const url = `${statementHistoryUrl}/${props.user_wallet[3]?.wallets[0]?.hpa_config?.id}/NGN/savings?page=1&per_page=10&startDate=2021-08-20&endDate=2023-10-04`;
    props.getAction(GET_SAVING_STATEMENT, url, token);
  }, []);

  useEffect(() => {
    const url = `${usersURL}/${props.user_info.id}`;
    props.getAction(GET_USER_DETAILS, url, token);

    props.getAction(GET_SAVING_PRODUCT, savingsURL, token);

    props.getAction(GET_IP, ipURL, null, ipRequest);

    const user_id = props.user_info?.id;

    const interest_url = `${saveInterestURL}?user_id=${user_id}&startDate=${"2021-08-20"}&endDate=${"2023-06-02"}`;
    // alert(interest_url);
    props.getAction(GET_SAVE_INTEREST_HISTORY, interest_url, token);

    if (id) {
      const url = `${savingsURL}/${id}`;
      props.getAction(GET_SAVING_PRODUCT_DETAIL, url, token);
    }

    if (props.saving_sub_msg == "Success" && props.save_sub_data && !loading) {
      props.showModal("HIDE_SUB_MODAL", false);
      const amount = props.save_sub_data.amount;
      if (amount != null || amount !== "") {
        props.showModal("OPEN_CONFIRM_MODAL", true, {
          amount,
          request_ref: props.save_sub_data.payment_reference,
          service_type: "SAVEINVEST",
          user_savings_id: props.save_sub_data?.user_savings_id,
        });
      }
    } else if (props.saving_sub_msg == "Success" && !loading) {
      props.showModal("HIDE_SUB_MODAL", false);
      // props.showMessage("OPEN_MSG_MODAL", props.saving_sub_msg, "success");
    }
    // if (props.saving_error != "")
    //   props.showMessage("OPEN_MSG_MODAL", props.saving_error);
  }, [id, props.saving_sub_msg]);

  useEffect(() => {
    props.showModal("HIDE_MODAL", false);
  }, []);

  const interest_data = props.interest_data?.map(processInterestData);

  return (
    <>
      <div className="h-full w-full">
        <>
          <div className="grid grid-cols-6 gap-8 h-full">
            <div className="col-span-4 sm:col-span-4 flex flex-col gap-8">
              <CardSlide content={savingsWalletData || {}} imageSrc={"/safe.svg"} name="Savings Balance" showBtn={true} {...props} />
              {children}
            </div>
            <div className="bg-white rounded-lg w-full col-span-4 sm:col-span-2">
              <div className="h-full p-4">
                <div className="">
                  <div className="flex flex-col gap-4">
                    <div className="w-full flex justify-between items-center">
                      <HeadlessTab
                        tabs={tabs}
                        handlePress={index => {
                          setType(Object.keys(tabs)[index]);
                        }}
                      />
                    </div>
                    <div className="">{tabs[type]}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      <AppModal
        open={props.open}
        className="w-[40%]"
        title="Available Product"
        onClose={() => props.showModal("HIDE_MODAL", false)}
        content={<SavingsProducts {...props} onClose={() => props.showModal("HIDE_MODAL", false)} setId={id => setId(id)} />}
      />
      <AppModal
        open={props.sub_open}
        title="Goal Saving"
        className="w-[60%] 2xl:w-[40%]"
        onClose={() => {
          props.showModal("HIDE_SUB_MODAL", false);
        }}
        content={<SaveContent product_id={id} {...props} />}
      />
    </>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  open: state.ui.modal.show,
  loading: state.saving.get_save_product.loading,
  saving_product: state.saving.get_save_product.data,
  product_details_loading: state.saving.get_save_product_detail.loading,
  saving_sub_msg: state.saving.add_sub.success,
  save_sub_data: state.saving.add_sub.data,
  saving_error: state.saving.add_sub.error_msg,
  loading_sub: state.saving.get_sub.loading,
  saving_sub: state.saving.get_sub.savings_data,
  saving_total: state.saving.get_sub.total,
  user_info: state.auth.login.data,
  user_wallet: state.account.get_wallets.data,
  sub_open: state.ui.modal.show_sub_modal,
  save_wallet: state.saving.save_wallet.data,
  confirm_open: state.ui.modal.show_confirm,
  confirm_data: state.ui.modal.confirm_data,
  ip_data: state.auth.get_ip.data,
  int_loading: state.investment.interest_history.loading,
  interest_data: state.saving.interest_history.data,
  error_msg: state.investment.interest_history.error_msg,
  paginate_num: state.ui.add_paginate?.paginate_link || 1,
  filter_state: state.ui.filter_data?.filter_savings,
  is_filter: state.ui.is_filter.isFilter,
  savings_trnx: state.saving.get_savings_trnx?.savings_trnx,
});

export default connect(mapStateToProps, {
  showModal,
  fadeContent,
  showMessage,
  getAction,
  postAction,
  editAction,
  activateFiltering,
  setPagination,
  setFilterStatus,
})(SavingWrapper);
