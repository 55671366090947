import { countryCodeAndFlag } from "../../../utils/country_flag_and_calling_code";
import { allStates } from "../../../utils/nigeriaStateAndLGAs";
import { SearchDropdown, TextField, SelectField, DateField, PhoneField } from "../../Widget/Form/FormComponent";

const investment_type = [
  { id: 1, name: "A Co-Investor", label: "Co-Investor" },
  { id: 2, name: "A Child", label: "Child" },
];

const gender = ["Male", "Female"];

export const OthersInvestmentForm = ({ register, control, errors, watch }) => {
  const error = errors.others_investment;
  const watch_investor_type = watch("others_investment.type_of_investor");
  const is_child = watch_investor_type == "A Child" ? true : "";

  return (
    <div className="grid grid-cols-2 gap-10">
      <SelectField
        item={investment_type}
        selected_item="label"
        value="name"
        label="Type of Investor"
        control={control}
        error={error?.type_of_investor}
        {...register("others_investment.type_of_investor")}
      />
      {watch_investor_type && (
        <>
          <TextField type="text" label="Relationship With Investor" error={error?.relation} placeholder="Cousin" {...register("others_investment.relation")} />
          <TextField type="text" label="First Name" error={error?.first_name} placeholder="Enter First Name" {...register("others_investment.first_name")} />
          <TextField type="text" label="Last Name" error={error?.last_name} placeholder="Enter Last Name" {...register("others_investment.last_name")} />
          <SelectField item={gender} label="Gender" control={control} error={error?.gender} {...register("others_investment.gender")} />
          <DateField label="Date Of Birth" control={control} error={error?.date_of_birth} {...register("others_investment.date_of_birth")} />
          <PhoneField control={control} label={`Phone Number ${is_child && "(Optional)"}`} error={!is_child && error?.phone_number} {...register("others_investment.phone_number")} />
          <TextField type="email" label={`Email ${is_child && "(Optional)"}`} error={!is_child && error?.email} placeholder="email@here.com" {...register("others_investment.email")} />
          <TextField label="Residential Address" placeholder="Enter your Address" error={error?.residential_address} {...register("others_investment.residential_address")} type="text" />
          <TextField label="Nationality" placeholder="e.g Nigerian" error={error?.nationality} {...register("others_investment.nationality")} type="text" />
          <SearchDropdown label="Country" placeholder="Select Country" control={control} val="name" items={countryCodeAndFlag} error={error?.country} {...register("others_investment.country")} />
          <SelectField item={allStates} selected_item="state" value="alias" label="State" control={control} error={error?.state} {...register("others_investment.state")} />
        </>
      )}
    </div>
  );
};
