import { connect } from "react-redux";
import Withdrawal from "./Withdrawal";

const GdlTransfer = () => {
  return <Withdrawal />;
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(GdlTransfer);
