import React from "react";

export const SectionList = ({ sections, renderItem, renderSectionHeader }) => {
  return (
    <>
      {sections?.map((content, index) => (
        <div key={index} className="w-full">
          {renderSectionHeader({ section: content })}
          <>
            {content.data?.map((item, index) => (
              <React.Fragment key={index}>{renderItem({ item, index })}</React.Fragment>
            ))}
          </>
        </div>
      ))}
    </>
  );
};

export const FlatList = ({ data, renderItem, className }) => {
  return (
    <ul className={`flex flex-col items-start self-stretch ${className}`}>
      {data?.map((item, index) => (
        <React.Fragment key={index}>{renderItem({ item, index })}</React.Fragment>
      ))}
    </ul>
  );
};
