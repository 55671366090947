import { Copy } from "iconsax-react";

export const PasteComp = props => {
  return (
    <div className="flex gap-[5px] text-brand01 items-center cursor-pointer" onClick={props.handlePaste}>
      <Copy variant="Bulk" size={24} />
      <span className="text-xs underline underline-offset-4">Paste</span>
    </div>
  );
};
