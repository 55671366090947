import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAction } from "../../store/actions/creators/request.action";
import { GET_SAVE_INTEREST_HISTORY } from "../../store/actions/action_types/action.types";
import { saveInterestHistoryURL } from "../../utils/endpoints";
import { processSaveData } from "./save.data";
import TransactionView, { TransactionRow } from "../../components/Transaction/TransactionRow";
import { transformDataToGroupForm } from "../../utils/helper";
import Loader from "../../components/Widget/Loader/Loader";
import { setPagination } from "../../store/actions/creators/ui.action";

const SaveInterestHistory = ({ loading, token, ...props }) => {
  useEffect(() => {
    const url = `${saveInterestHistoryURL}/${props.save_id}?page=${props.paginate_num}&per_page=4`;
    props.getAction(GET_SAVE_INTEREST_HISTORY, url, token);
  }, [props.paginate_num]);
  const rawData = props.interest_data?.map(processSaveData) || [];

  const tableInfo = transformDataToGroupForm(rawData);

  return (
    <div className="w-full h-full">
      {/* <Table tableData={tableInfo} loading={loading} no_action no_option /> */}
      {loading ? (
        <div className="h-full flex justify-center items-center">
          <Loader loading={true} color="#922333" />
        </div>
      ) : (
        <>
          {tableInfo.length === 0 ? (
            <div className="flex flex-col gap-2 items-center pt-5">
              <h3 className="text-primary font-medium text-[22px]">No records</h3>
              <p className="text-gray01">There are no data yet.</p>
            </div>
          ) : (
            <TransactionView {...props} total={props.interest_total}>
              <>
                {tableInfo.map(content => (
                  <div key={content.transactions?.id}>
                    <h4 className="text-sm">{content?.date}</h4>
                    {content?.transactions.map(data => (
                      <TransactionRow onClick={() => handleModalChange(data)} {...data} />
                    ))}
                  </div>
                ))}
              </>
            </TransactionView>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.auth.login.token,
  save_id: state.ui.id_setter.id,
  loading: state.saving.interest_history.loading,
  interest_data: state.saving.interest_history.data,
  interest_total: state.saving.interest_history.total,
  error_msg: state.saving.interest_history.error_msg,
  paginate_num: state.ui.add_paginate?.paginate_link || 1,
});

export default connect(mapStateToProps, { getAction, setPagination })(SaveInterestHistory);
