import { currencyFormatter } from "../../utils/helper";

export const PaymentDetailView = ({ type, amount }) => (
  <div className="flex bg-gray06 p-[17px_20px] gap-2.5 rounded-xl">
    <div className="flex justify-between items-center p-[10px_0] w-full">
      <div className="flex flex-col gap-2.5 items-start">
        <p className="leading-[20.16px] text-gray05">Total Amount</p>
        <span className="text-primary leading-[20.16px]">{currencyFormatter(amount)}</span>
      </div>
      <div className="flex flex-col gap-2.5 items-end">
        <p className="leading-[20.16px] text-gray05">Processing Fee</p>
        <p className="text-primary leading-[20.16px]">{type == "api" ? "0%" : "1.4%"}</p>
      </div>
    </div>
  </div>
);
