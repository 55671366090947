import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";
import * as pages from "../../pages";

const WithdrawalRoute = () => {
  return (
    <>
      <Routes>
        <Route path={`/`} element={<pages.WithdrawLanding />} />
        <Route path={`/gdl-account`} element={<pages.GdlTransfer />} />
        <Route path={`/other-account`} element={<pages.OtherTransfer />} />
      </Routes>
    </>
  );
};

export default connect(null, {})(WithdrawalRoute);
